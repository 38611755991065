import PropTypes from 'prop-types';

function Input({ text, name, type, isRequired, onChange, inputs }) {
  return (
    <div className="Input">
      <label className="Input_label" htmlFor={name}>
        {text}
      </label>
      <input
        className="Input_content"
        type={type}
        id={name}
        name={name}
        required={isRequired}
        // Reduce accessibility but increase practicality because one click less for each ingredient
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        value={inputs ? inputs[name] : 0}
        onChange={(e) => onChange({ ...inputs, [name]: e.target.value })}
      />
    </div>
  );
}

Input.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.shape({}).isRequired,
};

export default Input;
