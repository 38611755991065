import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import Chevrons from 'components/GameBoard/ReusableComponents/Actions/Chevrons';
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import ModalEnigmaEmotion from 'components/GameBoard/ReusableComponents/Modal/ModalEnigmaEmotion';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import WarningLights from 'components/GameBoard/Emotion/Solo/WarningLights';
import RoomContainer from 'components/GameBoard/Emotion/Solo/Rooms/RoomContainer';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import BackgroundStyle from 'components/GameBoard/ReusableComponents/BackgroundStyle';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';

// Selector functions
import { selectRoom } from 'components/Reducers/Room';
import { selectSession } from 'components/Reducers/session';
import { selectModal } from 'components/Reducers/Modal/Modal';
import { selectEmotion } from 'components/Reducers/emotion';
import { selectSteps } from 'components/Reducers/Steps/Steps';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

// Constant
import {
  backgroundStyle,
  volume,
} from 'components/GameBoard/Emotion/Solo/constants';
// Utils
import useInitGame from 'utils/useInitGame';
import {
  clickCount,
  centerPoint,
  getRightCorner,
  useStartMessageOrQuestionary,
} from 'utils/utilityFunctions';
import useWindowResize from 'utils/useWindowResize';
// Assets
import mainTheme from 'sound/Emotion_solo/chapter_3_theme.mp3';
import click from 'sound/click-ongame.mp3';

function BehaviourMachineCalibrationRoom() {
  // `t` is a function coming from the i18next library used to translate text
  /* Load the `common` namespace = the one containing translations that are common between games
  and the `emotion` one (see public-> locales -> fr-FR) */
  const { t } = useTranslation('emotion');

  const dispatch = useDispatch();

  // This ref is connected to the image background
  const backgroundImageRef = useRef();

  const { startMessage, startQuestionnary, currentStep } = useSelector(selectSteps);
  const { idSessionHasRoom, clicked, cursor, soundtrack, music } =
    useSelector(infoGameUser);
  const modalIsOpen = useSelector(selectModal).modal.isOpen;
  const modalEnigmaEmotionIsOpen =
    useSelector(selectModal).modalEnigmaEmotion.isOpen;
  const { currentRoomId, listRooms } = useSelector(selectRoom);
  const session = useSelector(selectSession);

  const currentRoom = Object.values(listRooms).find(
    (roomObject) => roomObject.id === currentRoomId
  );

  const backgroundUrl = currentRoom.url;

  const { backgroundDimensions } = useSelector(selectEmotion);

  const [backgroundPosition, setBackgroundPosition] = useState('right-from-center');

  useWindowResize(backgroundImageRef);

  // Define variables used for the translation of the background
  const screenWidth = window.innerWidth;
  const imgWidth = backgroundDimensions.width;
  // Number of pixels needed to center a point of interest in the background
  const pointOfInterestCentered =
    centerPoint(backgroundStyle.pointOfInterest, imgWidth, screenWidth) >= 0
      ? `-${centerPoint(backgroundStyle.pointOfInterest, imgWidth, screenWidth)}px`
      : '0px';

  // Initiate the session, the room, the objects in the room and the prevention messages
  useInitGame(currentRoomId, session.id);

  // Save the prevention message/questionary step in database and store and launch it
  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary
  );

  // Launch a modal to explain how to start the enigma
  useEffect(() => {
    dispatch({
      type: 'OPEN_MODAL',
      payload: t('enigma3', { returnObjects: true }),
    });
  }, [dispatch, t]);

  return (
    <RoomContainer id="container">
      <Audio sound={mainTheme} loop muted={!music} volume={volume} />
      <Audio
        sound={click}
        condition={!clicked && soundtrack && !modalEnigmaEmotionIsOpen}
      />
      <div
        aria-hidden="true"
        className={clicked ? 'cursor-clicked' : 'Hide'}
        style={{ top: cursor.y, left: cursor.x }}
      >
        <p>+1</p>
      </div>
      <header>
        <Steps />
        <Hud />
      </header>
      <BackgroundStyle
        position={backgroundPosition}
        start={pointOfInterestCentered}
        right={imgWidth && getRightCorner(imgWidth, screenWidth)}
        left={backgroundStyle.imgLeft}
        duration={backgroundStyle.duration}
        timing={backgroundStyle.timing}
        fillMode={backgroundStyle.fillMode}
        backgroundWidth={backgroundStyle.width}
        onClick={(event) => {
          clickCount(dispatch, event);
        }}
        type="button"
      >
        <img
          src={backgroundUrl}
          width="100%"
          height="100%"
          alt="Lab"
          id="img-background"
          ref={backgroundImageRef}
        />
        <ListObjects />
        <WarningLights />
      </BackgroundStyle>
      <Chevrons
        position={backgroundPosition}
        setPosition={setBackgroundPosition}
        modalEnigmaIsOpen={modalIsOpen}
        hide={modalEnigmaEmotionIsOpen}
      />
      {modalIsOpen && <Modal />}
      {modalEnigmaEmotionIsOpen && <ModalEnigmaEmotion />}
    </RoomContainer>
  );
}

export default BehaviourMachineCalibrationRoom;
