import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import PlutchikWheel from 'components/GameBoard/ReusableComponents/PlutchikWheel';

// scss
import styles from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Type/PlutchikWheelType/PlutchikWheelType.module.scss';
import { useState } from 'react';

function PlutchikWheelType({ question, activeIndex, handleAnswer, handleReturn }) {
  const { t } = useTranslation('common');

  const [clickedPlutchikEmotion, setClickedPlutchikEmotion] = useState();

  return (
    <div className={styles['plutchik-wheel-type']}>
      <h4>{question.title}</h4>
      <img src={question.image} alt="emotion react" />

      <PlutchikWheel
        setSelectedEmotion={(event) => setClickedPlutchikEmotion(event)}
        responses={question.responses}
        combinePetals
      />
      {activeIndex && (
        <Button
          buttonType="action"
          title={t('buttonFunction.return')}
          onClick={() => handleReturn()}
        />
      )}
      {clickedPlutchikEmotion && (
        <Button
          onClick={() => handleAnswer(clickedPlutchikEmotion, question)}
          title={t('buttonFunction.next')}
          buttonType="action"
        />
      )}
    </div>
  );
}

PlutchikWheelType.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    image: PropTypes.string,
    is_debriefing: PropTypes.number,
    level: PropTypes.number,
    maximum: PropTypes.string,
    minimum: PropTypes.string,
    response_type: PropTypes.string,
    responses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        level: PropTypes.number,
        weighting: PropTypes.number,
      })
    ),
  }).isRequired,
  activeIndex: PropTypes.number.isRequired,
  handleAnswer: PropTypes.func.isRequired,
  handleReturn: PropTypes.func.isRequired,
};

export default PlutchikWheelType;
