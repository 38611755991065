/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../../../../assets/css/components/GameBoard/TDE/Enigma/Hatch.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const idShowelObject = 178;
const idLetterObject = 177;
const idHatchObject = 223;
const idSwitchLight = 148;

function Hatch({ image, objectContent, idSessionHasRoom, allObjects, dispatch }) {
  const addToInventory = (object, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: object.id,
        isInventory: true,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isInventory: true,
            type: 'inventory',
          },
        });
        dispatch({
          type: 'ADD_INVENTORY',
          payload: object,
        });
        dispatch({
          type: 'UPDATE_GAME_SCORE',
          payload: 100,
        });
        dispatch({
          type: 'CLOSE_MODAL',
        });
      });
  };

  const handleImage = (description) => {
    if (description && description.images) {
      const objectIndex = description.images.findIndex(
        (object) => object.type === 'clicked'
      );
      return description.images[objectIndex].image;
    }
  };

  const handleMessage = (description) => {
    if (description && description.images) {
      const objectIndex = description.images.findIndex(
        (object) => object.type === 'clicked'
      );
      return description.images[objectIndex].message;
    }
  };

  const changeStatusOfObject = (object, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: object.id,
        isClicked: object.isClicked !== undefined ? object.isClicked + 1 : 1,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isClicked: object.isClicked !== undefined ? object.isClicked + 1 : 1,
            type: 'clicked',
          },
        });
        const descriptionModal = {};
        descriptionModal.message = handleMessage(object);
        descriptionModal.image = handleImage(object);

        if (!object.wrongobject) {
          const isShowelObject = object.id === idShowelObject;
          const isLetterObject = object.id === idLetterObject;

          if (isShowelObject) {
            descriptionModal.buttonDescription = {
              title: 'Inventaire',
              onClick: () => addToInventory(object, index),
            };
          } else if (isLetterObject) {
            descriptionModal.buttonDescription = {
              title: 'Inventaire',
              onClick: () => addToInventory(object, index),
            };
          }
        }

        dispatch({
          type: 'OPEN_MODAL',
          payload: descriptionModal,
        });
      });
  };

  const retrieveShowelOrLetter = () => {
    const objectShowel = allObjects.filter(
      (objectContentElement) =>
        objectContentElement.id === idShowelObject && objectContentElement
    );
    const indexShowel = allObjects.findIndex(
      (objectContentElement) => objectContentElement.id === idShowelObject
    );

    const objectLetter = allObjects.filter(
      (objectContentElement) =>
        objectContentElement.id === idLetterObject && objectContentElement
    );
    const indexLetter = allObjects.findIndex(
      (objectContentElement) => objectContentElement.id === idLetterObject
    );
    if (objectShowel[0].isChecked && objectLetter.isInventory) {
      return;
    }
    if (objectShowel[0].isInventory) {
      if (!objectLetter[0].isInventory) {
        changeStatusOfObject(objectLetter[0], indexLetter);
      }
    } else {
      changeStatusOfObject(objectShowel[0], indexShowel);
    }
  };

  const handleHatchStatus = () => {
    const objectHatch = allObjects.filter(
      (item) => item.id === idHatchObject && item
    );
    const objectShowel = allObjects.filter(
      (item) => item.id === idShowelObject && item
    );

    const objectLetter = allObjects.filter(
      (item) => item.id === idLetterObject && item
    );

    const objectSwitchLight = allObjects.filter(
      (item) => item.id === idSwitchLight && item
    );
    let imageObjectHatch;
    if (objectShowel[0].isInventory && !objectLetter[0].isInventory) {
      if (objectSwitchLight[0].status === 'open') {
        imageObjectHatch = objectHatch[0].images.filter(
          (imageContent) =>
            imageContent.slug === 'trappe-background-open-without-showel-night' &&
            imageContent.image
        );
      } else {
        imageObjectHatch = objectHatch[0].images.filter(
          (imageContent) =>
            imageContent.type === 'checked' &&
            imageContent.slug === 'trappe-background-open-without-showel' &&
            imageContent.image
        );
      }
      return imageObjectHatch[0].image;
    }
    if (objectLetter[0].isInventory && objectShowel[0].isInventory) {
      if (objectSwitchLight[0].status === 'open') {
        imageObjectHatch = objectHatch[0].images.filter(
          (imageContent) =>
            imageContent.type === 'empty-night' && imageContent.image
        );
      } else {
        imageObjectHatch = objectHatch[0].images.filter(
          (imageContent) => imageContent.type === 'empty' && imageContent.image
        );
      }
      return imageObjectHatch[0].image;
    }
    return image;
  };
  return (
    <div className="hatch">
      <img
        className="hatch_image"
        onClick={() => retrieveShowelOrLetter()}
        src={handleHatchStatus()}
        alt={objectContent.title}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  allObjects: state.Objects.AllObjects,
});

Hatch.propTypes = {
  idSessionHasRoom: PropTypes.number.isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  image: PropTypes.string.isRequired,
  objectContent: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Hatch);
