import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useEffect, useState } from 'react';

// selector functions
import { selectSession } from 'components/Reducers/session';

// components
import { fetchObjectVaccination, fetchSteps } from '../../Action';

// assets
import * as images from '../../../img/vaccination-solo/index';

// css
import './VaccinationSolo.css';

const arcadeRoomId = 10;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const todayFr = `${new Date()
  .toISOString()
  .slice(0, 10)} ${new Date().toLocaleTimeString('fr-FR')}`;

function LoaderVaccination() {
  const dispatch = useDispatch();
  const session = useSelector(selectSession);

  const [completed, setCompleted] = useState(0);
  useEffect(() => {
    const urlSession = `${BASE_URL}/api/sessionsHasRoom/`;
    axios.get(`${BASE_URL}/api/objects/game/145`).then((res) => {
      dispatch(fetchObjectVaccination(res.data));
    });
    // initialize room with session
    axios
      .post(urlSession, {
        sessions_id: session.id,
        rooms_id: arcadeRoomId,
        date: todayFr,
      })
      .then((res) => {
        // if room already initialized
        if (res.data === 'Room already initialized') {
          // get everything from BDD to come back where we left.
          // session has room id FIRST.
          axios
            .get(`${BASE_URL}/api/sessionsHasRoom/${session.id}`)
            .then((response) => {
              const { id, count, score } = response.data[0];
              dispatch({
                type: 'SET_SESSION_HAS_ROOM_ID',
                payload: id,
              });
              dispatch({
                type: 'SET_COUNT_BACK',
                payload: count,
              });
              dispatch({ type: 'UPDATE_GAME_SCORE', payload: score });
              // get back object here in the future after refacto...
              //
              //
              // get back step = prevention message
              axios
                .get(
                  `${BASE_URL}/api/sessionsHasRoomHasMessage/${id}/rooms/${arcadeRoomId}/messages`
                )
                .then((res2) => {
                  const sortSteps = [...res2.data];
                  sortSteps.sort((a, b) => {
                    return a.level - b.level;
                  });
                  if (sortSteps.length !== 0) {
                    const currentStep = sortSteps.find((step) => !step.isFound);
                    dispatch(fetchSteps(sortSteps));
                    // COME BACK TO THE CURRENT STEP IF EXIST //
                    if (currentStep) {
                      dispatch({
                        type: 'CURRENT_STEP',
                      });
                      // retrieve question depending on current step
                      axios
                        .get(`${BASE_URL}/api/messages/${currentStep.id}/questions`)
                        .then((response2) => response2.data)
                        .then((data) => {
                          data.questions.map((question) => {
                            return axios
                              .get(
                                `${BASE_URL}/api/question/${question.id}/responses`
                              )
                              .then((response2) => response2.data)
                              .then((dataResponse) => {
                                dataResponse.sort((a, b) => {
                                  return a.level - b.level;
                                });
                                dispatch({
                                  type: 'ADD_RESPONSE',
                                  payload: dataResponse,
                                });
                                return { ...question, response: dataResponse };
                              });
                          });
                          dispatch({
                            type: 'ADD_QUESTION',
                            payload: data,
                          });
                        }); // ACTION LOGIC TO COME BACK TO WHERE THE GAME WAS
                      switch (currentStep.id) {
                        // Confiance / Intro
                        case 99:
                          if (!currentStep.startIntro) {
                            dispatch({
                              type: 'START_GAME_TOUR',
                              payload: true,
                            });
                          }
                          if (currentStep.startIntro) {
                            dispatch({
                              type: 'START_GAME_TOUR',
                              payload: false,
                            });
                            dispatch({
                              type: 'STEP_INTRO',
                              payload: true,
                            });
                          }
                          if (currentStep.startIntro && currentStep.startMessage) {
                            dispatch({ type: 'START_GAME', payload: 1 });
                            dispatch({
                              type: 'STEP_INTRO',
                              payload: false,
                            });
                            dispatch({
                              type: 'VACC_SCENES',
                              payload: {
                                scenes: 'finishFloppy',
                                status: true,
                              },
                            });
                          }
                          if (
                            currentStep.startIntro &&
                            currentStep.startMessage &&
                            currentStep.startQuestionnary
                          ) {
                            dispatch({
                              type: 'STEP_INTRO',
                              payload: false,
                            });
                            dispatch({
                              type: 'VACC_SCENES',
                              payload: {
                                scenes: 'finishFloppy',
                                status: false,
                              },
                            });
                            dispatch({
                              type: 'START_QUESTIONNARY',
                            });
                          }
                          break;
                        // Complaisance / Wires & Simon
                        case 100:
                          if (!currentStep.startIntro) {
                            dispatch({ type: 'START_GAME', payload: 1 });
                            dispatch({
                              type: 'START_GAME_TOUR',
                              payload: false,
                            });
                            dispatch({
                              type: 'VACC_SCENES',
                              payload: {
                                scenes: 'InterludePuckLab',
                                status: true,
                              },
                            });
                          }
                          if (currentStep.startIntro) {
                            dispatch({ type: 'START_GAME', payload: 1 });
                            dispatch({
                              type: 'START_GAME_TOUR',
                              payload: false,
                            });
                            dispatch({
                              type: 'STEP_INTRO',
                              payload: true,
                            });
                            setTimeout(() => {
                              dispatch({
                                type: 'VACC_ACTION',
                                payload: {
                                  object: 'puckLab',
                                  action: 'isClicked',
                                  status: true,
                                },
                              });
                            }, 10700);
                          }
                          if (currentStep.startIntro && currentStep.startMessage) {
                            dispatch({ type: 'START_GAME', payload: 1 });
                            dispatch({
                              type: 'START_GAME_TOUR',
                              payload: false,
                            });
                            dispatch({
                              type: 'STEP_INTRO',
                              payload: false,
                            });
                            dispatch({
                              type: 'VACC_ACTION',
                              payload: {
                                object: 'puckLab',
                                action: 'isChecked',
                                status: true,
                              },
                            });
                            dispatch({
                              type: 'VACC_SCENES',
                              payload: {
                                scenes: 'finishPuckLab',
                                status: true,
                              },
                            });
                          }
                          if (
                            currentStep.startIntro &&
                            currentStep.startMessage &&
                            currentStep.startQuestionnary
                          ) {
                            dispatch({ type: 'START_GAME', payload: 1 });
                            dispatch({
                              type: 'START_GAME_TOUR',
                              payload: false,
                            });
                            dispatch({
                              type: 'VACC_SCENES',
                              payload: {
                                scenes: 'finishPuckLab',
                                status: false,
                              },
                            });
                            dispatch({
                              type: 'START_QUESTIONNARY',
                            });
                          }
                          break;
                        case 101:
                          if (!currentStep.startIntro) {
                            dispatch({ type: 'START_GAME', payload: 1 });
                            dispatch({
                              type: 'START_GAME_TOUR',
                              payload: false,
                            });
                            dispatch({
                              type: 'VACC_SCENES',
                              payload: {
                                scenes: 'InterludeElectricPanel',
                                status: true,
                              },
                            });
                          }
                          if (currentStep.startIntro) {
                            dispatch({ type: 'START_GAME', payload: 1 });
                            dispatch({
                              type: 'START_GAME_TOUR',
                              payload: false,
                            });
                            dispatch({
                              type: 'VACC_ACTION',
                              payload: {
                                object: 'martinSays',
                                action: 'isClicked',
                                status: true,
                              },
                            });
                          }
                          if (currentStep.startIntro && currentStep.startMessage) {
                            dispatch({
                              type: 'VACC_ACTION',
                              payload: {
                                object: 'martinSays',
                                action: 'isChecked',
                                status: true,
                              },
                            });
                            dispatch({
                              type: 'VACC_SCENES',
                              payload: {
                                scenes: 'finishSimon',
                                status: true,
                              },
                            });
                          }
                          if (
                            currentStep.startIntro &&
                            currentStep.startMessage &&
                            currentStep.startQuestionnary
                          ) {
                            dispatch({
                              type: 'VACC_SCENES',
                              payload: {
                                scenes: 'finishSimon',
                                status: false,
                              },
                            });
                            dispatch({
                              type: 'START_QUESTIONNARY',
                            });
                          }
                          break;
                        case 102:
                          if (!currentStep.startIntro) {
                            dispatch({ type: 'START_GAME', payload: 1 });
                            dispatch({
                              type: 'START_GAME_TOUR',
                              payload: false,
                            });
                            dispatch({
                              type: 'VACC_SCENES',
                              payload: {
                                scenes: 'InterludeDailySeeker',
                                status: true,
                              },
                            });
                          }
                          if (currentStep.startIntro) {
                            dispatch({ type: 'START_GAME', payload: 1 });
                            dispatch({
                              type: 'START_GAME_TOUR',
                              payload: false,
                            });
                            dispatch({
                              type: 'STEP_INTRO',
                              payload: true,
                            });
                            setTimeout(() => {
                              dispatch({
                                type: 'VACC_ACTION',
                                payload: {
                                  object: 'dailySeeker',
                                  action: 'isClicked',
                                  status: true,
                                },
                              });
                            }, 10700);
                          }
                          if (currentStep.startIntro && currentStep.startMessage) {
                            dispatch({
                              type: 'STEP_INTRO',
                              payload: false,
                            });
                            dispatch({
                              type: 'VACC_ACTION',
                              payload: {
                                object: 'dailySeeker',
                                action: 'isChecked',
                                status: true,
                              },
                            });
                            dispatch({
                              type: 'VACC_SCENES',
                              payload: {
                                scenes: 'finishDailySeeker',
                                status: true,
                              },
                            });
                          }
                          if (
                            currentStep.startIntro &&
                            currentStep.startMessage &&
                            currentStep.startQuestionnary
                          ) {
                            dispatch({
                              type: 'VACC_SCENES',
                              payload: {
                                scenes: 'finishDailySeeker',
                                status: false,
                              },
                            });
                            dispatch({
                              type: 'START_QUESTIONNARY',
                            });
                          }
                          break;
                        case 103:
                          if (!currentStep.startIntro) {
                            dispatch({ type: 'START_GAME', payload: 1 });
                            dispatch({
                              type: 'START_GAME_TOUR',
                              payload: false,
                            });
                            dispatch({
                              type: 'VACC_SCENES',
                              payload: {
                                scenes: 'InterludeColoveDuty',
                                status: true,
                              },
                            });
                          }
                          if (currentStep.startIntro) {
                            dispatch({ type: 'START_GAME', payload: 1 });
                            dispatch({
                              type: 'START_GAME_TOUR',
                              payload: false,
                            });
                            dispatch({
                              type: 'STEP_INTRO',
                              payload: true,
                            });
                            setTimeout(() => {
                              dispatch({
                                type: 'VACC_ACTION',
                                payload: {
                                  object: 'coloveduty',
                                  action: 'isClicked',
                                  status: true,
                                },
                              });
                            }, 10700);
                          }
                          if (currentStep.startIntro && currentStep.startMessage) {
                            dispatch({
                              type: 'STEP_INTRO',
                              payload: false,
                            });
                            dispatch({
                              type: 'VACC_ACTION',
                              payload: {
                                object: 'coloveduty',
                                action: 'isChecked',
                                status: true,
                              },
                            });
                            dispatch({
                              type: 'VACC_SCENES',
                              payload: {
                                scenes: 'finishColoveDuty',
                                status: true,
                              },
                            });
                          }
                          if (
                            currentStep.startIntro &&
                            currentStep.startMessage &&
                            currentStep.startQuestionnary
                          ) {
                            dispatch({
                              type: 'VACC_SCENES',
                              payload: {
                                scenes: 'finishColoveDuty',
                                status: false,
                              },
                            });
                            dispatch({
                              type: 'START_QUESTIONNARY',
                            });
                          }
                          break;
                        default:
                      }
                    } else {
                      dispatch({ type: 'START_GAME', payload: 1 });
                      dispatch({
                        type: 'START_GAME_TOUR',
                        payload: false,
                      });
                      dispatch({
                        type: 'VACC_SCENES',
                        payload: {
                          scenes: 'conclusionIa',
                          status: true,
                        },
                      });
                    }
                  }
                });
            });
        } else {
          // new session so we post everything to initialize room
          const sessionHasRoomId = res.data.id; // WARNING
          dispatch({
            type: 'SET_SESSION_HAS_ROOM_ID',
            payload: sessionHasRoomId,
          });
          // initiliaze object linked to the given room
          axios
            .get(`${BASE_URL}/api/roomHasObjects/${arcadeRoomId}`)
            .then((response) => {
              const url = `${BASE_URL}/api/sessionsHasRoomHasObject/`;
              response.data.map((element) => {
                return axios
                  .post(url, {
                    sessions_has_room_id: sessionHasRoomId,
                    object_id: element.object_id,
                    room_id: arcadeRoomId,
                    isChecked: false,
                    isClicked: false,
                    status: null,
                    date: todayFr,
                  })
                  .then();
              });
            });
          // initialise message and so the steps
          axios
            .get(`${BASE_URL}/api/roomHasMessages/${arcadeRoomId}`)
            .then((resp) => {
              const urlMessage = `${BASE_URL}/api/sessionsHasRoomHasMessage`;
              resp.data.map((el) => {
                return axios
                  .post(urlMessage, {
                    sessions_has_room_id: sessionHasRoomId,
                    message_id: el.id,
                    room_id: arcadeRoomId,
                    isFound: 0,
                    date: todayFr,
                    startMessage: 0,
                    startQuestionnary: 0,
                  })
                  .then(() =>
                    axios
                      .get(
                        `${BASE_URL}/api/sessionsHasRoomHasMessage/${sessionHasRoomId}/rooms/${arcadeRoomId}/messages`
                      )
                      .then((res2) => {
                        const sortSteps = [...res2.data];
                        sortSteps.sort((a, b) => {
                          return a.level - b.level;
                        });

                        if (sortSteps.length !== 0) {
                          dispatch(fetchSteps(sortSteps));
                          // INITIATE THE CURRENT STEP //
                          dispatch({
                            type: 'CURRENT_STEP',
                          });
                        }
                      })
                  );
              });
            });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set the % of loading
  useEffect(() => {
    if (completed < 100) {
      const interval = setInterval(() => {
        setCompleted(completed + 25);
      }, 800);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [completed]);

  return (
    <div className="Loader-Vaccination-Container">
      <div className="Loader-Computer-Container">
        <img
          src={images.computerScreen}
          alt="Ordinateur"
          draggable="false"
          className="Loader-Computer"
        />
      </div>
      <div className="Loader-Container">
        <div className="Loader-Progress-Bar-Container">
          <div className="Loader-Progress-Bar" style={{ width: `${completed}%` }}>
            <span className="Loader-Progress-Bar-Text">{`${completed}%`}</span>
          </div>
          <span className="Loader-Progress-Bar-Loading">
            Chargement de la salle d&apos;arcade
          </span>
        </div>
      </div>
      <img
        src={images.ScreenSaver}
        alt="SAV"
        className="Loader-Screen-Saver"
        draggable="false"
      />
    </div>
  );
}

LoaderVaccination.propTypes = {
  currentStep: PropTypes.shape({
    startMessage: PropTypes.bool,
    startQuestionnary: PropTypes.number,
    id: PropTypes.number,
    startIntro: PropTypes.bool,
  }),
};

LoaderVaccination.defaultProps = {
  currentStep: undefined,
};

export default LoaderVaccination;
