const initialState = {
  id: null,
  title: '',
  language: '',
};

const game = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INFOGAME':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

// Define the selector functions
export const selectInfoGame = (state) => state.game;

export default game;
