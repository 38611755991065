const initialState = {
  questions: [
    {
      response: {
        text: 'Bonjour à tous! Je m’appelle [nom du bot], votre assistant Tricky Je peux vous aider à [....] via le chat et je peux également vous donner des indices. Pour commencer, s’il vous plaît vous pouvez poser une question, et je serai heureux de vous aider tout de suite !',
      },
    },
  ],
  level: null,
};

const ChatBot = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_LEVEL':
      return {
        ...state,
        level: action.payload,
      };
    case 'SEND_QUESTION': {
      const newQuestion = state.questions;
      newQuestion.push(action.payload);
      return {
        ...state,
        questions: [...newQuestion],
      };
    }
    case 'LOADING_TRUE': {
      const newQuestion = state.questions;
      return {
        ...state,
        questions: [
          ...newQuestion.filter((q) =>
            q.response ? q.response.text !== '...' : true
          ),
        ],
      };
    }
    case 'RETRIEVE_RESPONSE': {
      const newQuestion = state.questions;
      newQuestion.push(action.payload);
      return {
        ...state,
        questions: [...newQuestion],
      };
    }
    case 'LOADING_CHAT-BOT': {
      const newQuestion = state.questions;
      newQuestion.push({ response: { text: '...' } });
      return {
        ...state,
        questions: [...newQuestion],
      };
    }
    case 'FEEDBACK': {
      const newQuestion = state.questions;
      newQuestion[
        newQuestion.findIndex((a) => a.id === action.payload)
      ].feedback = true;
      return {
        ...state,
        questions: [...newQuestion],
      };
    }
    default:
      return state;
  }
};

export default ChatBot;
