import '../../../../../assets/css/components/GameBoard/Obesity/Enigma/Phone/Camera.css';

const Camera = () => {
  return (
    <div className="Camera">
      <p className="Camera_title Camera_firstTitle">Caméra</p>
      <div className="Camera_top">
        <p className="Camera_secondTitle">
          Vous ne pouvez pas utiliser la caméra pour le moment.
        </p>
      </div>
    </div>
  );
};

export default Camera;
