import { connect, useDispatch } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';

// css
import 'assets/css/components/GameBoard/ReusableComponents/Inventory.css';

// components
import startedMessage from 'components/constants';

// utils
import { retrieveQuestionnary } from 'utils/utilityFunctions';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Inventory({ collectedObjects, idSessionHasRoom, objects }) {
  const idBatteryObject = 143;
  const idLampObject = 147;
  const idLightObject = 148;
  const idCodeLightObject = 149;
  const idCodeMorseObject = 160;
  const idShovel = 178;
  const idGrass = 180;
  const idKey = 181;
  const idDiary = 182;
  const idPageOne = 183;
  const idPageTwo = 184;
  const idPageThree = 229;
  const idPageQuestionnary = 185;
  const idBalance = 186;
  const idCardAnniversary = 187;
  const idMark = 188;
  const idList = 191;
  const idApportColoric = 192;
  const idCaloric = 193;
  const idRemote = 195;
  const idTimeOne = 196;
  const idTimeTwo = 197;
  const idTimeThree = 198;
  const idField = 207;
  const idFolderPatientOne = 210;
  const idFolderPatientTwo = 211;
  const idRecap = 215;
  const idTimeBot = 200;
  const idTimeBotTwo = 201;
  const idTimeBotThree = 202;
  const idBookRPS = 244;
  const idAfficheYoga = 245;
  const idCarteRemerciement = 248;
  const idTableauConges = 249;
  const idCagnotteRaclette = 250;
  const idReservationRestaurant = 251;
  const idRdvMedical = 253;
  const idRdvManager = 254;

  const dispatch = useDispatch();

  const dragStartRPS = (event, id) => {
    event.dataTransfer.setData('id', id);
  };

  const handleImage = (collectObject) => {
    if (collectObject && collectObject.images) {
      const objectIndex = collectObject.images.findIndex(
        (object) => object.type === 'inventory'
      );
      if (collectObject.id === idGrass && collectObject.status === 'open') {
        // CASE PARTICULAR BETWEEN GRASS
        const objectIndexOpen = collectObject.images.findIndex(
          (object) => object.type === 'open'
        );
        return collectObject.images[objectIndexOpen].image;
      }
      if (collectObject.id === idDiary && collectObject.status === 'open') {
        // CASE PARTICULAR BETWEEN DIARY
        const objectIndexOpen = collectObject.images.findIndex(
          (object) => object.type === 'open'
        );
        return collectObject.images[objectIndexOpen].image;
      }

      if (collectObject.id === idLampObject && collectObject.status === 'open') {
        // CASE PARTICULAR BETWEEN LAMP UV
        const objectIndexOpen = collectObject.images.findIndex(
          (object) => object.type === 'open'
        );
        return collectObject.images[objectIndexOpen].image;
      }

      return collectObject.images[objectIndex].image;
    }
    return null;
  };

  const handleMessage = (collectObject) => {
    const batteryObject = objects.filter(
      (objectContent) => objectContent.id === idBatteryObject
    );

    const shovelObject = objects.filter(
      (objectContent) => objectContent.id === idShovel
    );

    const keyObject = objects.filter((objectContent) => objectContent.id === idKey);

    const grassObject = objects.filter(
      (objectContent) => objectContent.id === idGrass
    );

    const diaryObject = objects.filter(
      (objectContent) => objectContent.id === idGrass
    );

    let message;

    if (collectObject.images) {
      if (collectObject.id === idLampObject) {
        // CASE PARTICULAR BETWEEN BATTERY AND LAMP UV AND LIGHT BUTTON
        const objectIndexWithoutPiles = collectObject.images.findIndex(
          (object) =>
            object.type === 'inventory' && object.slug === 'lampe-uv-inventory'
        );
        const objectIndexWithPiles = collectObject.images.findIndex(
          (object) =>
            object.type === 'inventory' &&
            object.slug === 'lampe-uv-inventory-with-piles'
        );

        const objectIndexReady = collectObject.images.findIndex(
          (object) =>
            object.type === 'inventory' && object.slug === 'lampe-uv-ready'
        );

        if (batteryObject[0].isChecked) {
          const lightObject = objects.filter(
            (objectContent) => objectContent.id === idLightObject
          );

          if (lightObject[0].status === 'open') {
            message = collectObject.images[objectIndexReady].message;
          } else {
            message = collectObject.images[objectIndexWithPiles].message;
          }
        } else {
          message = collectObject.images[objectIndexWithoutPiles].message;
        }
      } else if (collectObject.id === idGrass) {
        // CASE PARTICULAR BETWEEN GRASS AND SHOVEL
        const objectWithoutShovel = collectObject.images.findIndex(
          (object) => object.type === 'closed'
        );
        message = collectObject.images[objectWithoutShovel].message;

        if (shovelObject[0].isInventory) {
          if (collectObject.status !== 'open') {
            const objectWithShovel = collectObject.images.findIndex(
              (object) => object.type === 'inventory'
            );

            message = collectObject.images[objectWithShovel].message;
          } else {
            const objectGrassOpen = collectObject.images.findIndex(
              (object) => object.type === 'open'
            );
            message = collectObject.images[objectGrassOpen].message;
          }
        }
      } else if (collectObject.id === idDiary) {
        // CASE PARTICULAR BETWEEN Diary AND key
        const objectIndexWitoutKey = collectObject.images.findIndex(
          (object) => object.type === 'inventory'
        );

        message = collectObject.images[objectIndexWitoutKey].message;

        if (keyObject[0].isInventory) {
          if (collectObject.status !== 'open') {
            const objectIndexWithKey = collectObject.images.findIndex(
              (object) => object.type === 'closed'
            );
            message = collectObject.images[objectIndexWithKey].message;
          } else {
            const objectIndexOpen = collectObject.images.findIndex(
              (object) => object.type === 'open'
            );
            message = collectObject.images[objectIndexOpen].message;
          }
        }
      } else if (collectObject.id === idShovel) {
        const objectIndex = collectObject.images.findIndex(
          (object) => object.type === 'inventory'
        );

        message = collectObject.images[objectIndex].message;

        if (grassObject[0].isInventory) {
          const objectIndexWithGrass = collectObject.images.findIndex(
            (object) => object.type === 'open'
          );
          message = collectObject.images[objectIndexWithGrass].message;
        }
      } else if (collectObject.id === idKey) {
        const objectIndex = collectObject.images.findIndex(
          (object) => object.type === 'inventory'
        );

        message = collectObject.images[objectIndex].message;

        if (diaryObject[0].isInventory) {
          const objectIndexWithDiary = collectObject.images.findIndex(
            (object) => object.type === 'open'
          );

          message = collectObject.images[objectIndexWithDiary].message;
        }
      } else if (collectObject.id === idPageOne || collectObject.id === idPageTwo) {
        const objectIndex = collectObject.images.findIndex(
          (object) => object.type === 'inventory'
        );

        message = collectObject.images[objectIndex].message;
        const pageOneObject = objects.filter(
          (objectContent) => objectContent.id === idPageOne
        )[0];

        const pageTwoObject = objects.filter(
          (objectContent) => objectContent.id === idPageTwo
        )[0];

        if (pageOneObject.isInventory && pageTwoObject.isInventory) {
          const objectIndexPageOne = collectObject.images.findIndex(
            (object) => object.type === 'closed'
          );

          message = collectObject.images[objectIndexPageOne].message;
        }
      } else {
        const objectIndex = collectObject.images.findIndex(
          (object) => object.type === 'inventory'
        );

        message = collectObject.images[objectIndex].message;
      }

      return message;
    }
    return null;
  };

  const checkedObject = (collectObject, index, isMessage) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        isChecked: 1,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isChecked: 1,
            type: 'checked',
          },
        });
        dispatch({
          type: 'REMOVE_OF_INVENTORY',
          payload: collectObject.id,
        });

        if (!isMessage) {
          dispatch({
            type: 'START_MESSAGE_PREVENTION',
          });
        }
      });
  };

  const usedObject = (collectObject, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        status: 'open',
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            status: 'open',
            type: 'used',
          },
        });
        dispatch({
          type: 'UPDATE_GAME_SCORE',
          payload: 100,
        });
      });
  };

  const addInventory = (objectElement, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: objectElement.id,
        isInventory: true,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isInventory: true,
            type: 'inventory',
          },
        });
        dispatch({
          type: 'ADD_INVENTORY',
          payload: objectElement,
        });
        dispatch({
          type: 'UPDATE_GAME_SCORE',
          payload: 100,
        });
        dispatch({
          type: 'COUNT_MOVE',
        });
        dispatch({
          type: 'CLOSE_MODAL',
        });
      });
  };

  const openModal = (collectObject, index) => {
    const descriptionModal = {};
    descriptionModal.message = handleMessage(collectObject);
    descriptionModal.image = handleImage(collectObject);

    if (
      collectObject.id !== idBatteryObject &&
      collectObject.id !== idLampObject &&
      collectObject.id !== idCodeMorseObject &&
      collectObject.id !== idCodeLightObject &&
      collectObject.id !== idShovel &&
      collectObject.id !== idGrass &&
      collectObject.id !== idKey &&
      collectObject.id !== idDiary &&
      collectObject.id !== idPageOne &&
      collectObject.id !== idPageTwo &&
      collectObject.id !== idPageThree &&
      collectObject.id !== idBalance &&
      collectObject.id !== idCardAnniversary &&
      collectObject.id !== idMark &&
      collectObject.id !== idList &&
      collectObject.id !== idApportColoric &&
      collectObject.id !== idRemote &&
      collectObject.id !== idTimeOne &&
      collectObject.id !== idTimeTwo &&
      collectObject.id !== idTimeThree &&
      collectObject.id !== idCaloric &&
      collectObject.id !== idField &&
      collectObject.id !== idFolderPatientOne &&
      collectObject.id !== idFolderPatientTwo &&
      collectObject.id !== idRecap &&
      collectObject.id !== idTimeBot &&
      collectObject.id !== idTimeBotTwo &&
      collectObject.id !== idTimeBotThree &&
      collectObject.id !== idBookRPS &&
      collectObject.id !== idCarteRemerciement &&
      collectObject.id !== idRdvManager &&
      collectObject.id !== idRdvMedical &&
      collectObject.id !== idTableauConges &&
      collectObject.id !== idAfficheYoga &&
      collectObject.id !== idCagnotteRaclette &&
      collectObject.id !== idReservationRestaurant
    ) {
      descriptionModal.buttonDescription = {
        title: 'Transmettre',
        onClick: () => {
          axios
            .put(
              `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
              {
                current_step: collectObject.id_message,
                start_message: startedMessage,
              }
            )
            .then(() => {
              dispatch({
                type: 'PAUSE_TIMER',
                payload: true,
              });
              dispatch({
                type: 'CURRENT_STEP',
                payload: collectObject.id_message,
              });

              checkedObject(collectObject, index);
            });
        },
      };
    } else if (collectObject.id === idLampObject) {
      // CASE PARTICULAR BETWEEN BATTERY AND LAMP UV AND LIGHT BUTTON

      const batteryObject = objects.filter(
        (objectContent) => objectContent.id === idBatteryObject
      );
      const batteryObjectIndex = objects.findIndex(
        (objectContent) => objectContent.id === idBatteryObject
      );

      const lampObject = objects.filter(
        (objectContent) => objectContent.id === idLampObject
      );

      const lampObjectIndex = objects.findIndex(
        (objectContent) => objectContent.id === idLampObject
      );

      if (batteryObject[0].isInventory) {
        if (batteryObject[0].isChecked) {
          descriptionModal.buttonDescription = {
            title: 'Utiliser',
            onClick: () => {
              usedObject(lampObject[0], lampObjectIndex);
              dispatch({
                type: 'CLOSE_MODAL',
              });
              dispatch({
                type: 'COUNT_MOVE',
              });
            },
          };
        } else {
          descriptionModal.buttonDescription = {
            title: 'Associer',
            onClick: () => {
              checkedObject(batteryObject[0], batteryObjectIndex, 'not-message');
              dispatch({
                type: 'COUNT_MOVE',
              });
              dispatch({
                type: 'CLOSE_MODAL',
              });
            },
          };
        }
      }
    } else if (collectObject.id === idGrass) {
      const shovelObject = objects.filter(
        (objectContent) => objectContent.id === idShovel
      );

      const shovelIndex = objects.findIndex(
        (objectContent) => objectContent.id === idShovel
      );

      const grassIndex = objects.findIndex(
        (objectContent) => objectContent.id === idGrass
      );

      const keyObject = objects.filter(
        (objectContent) => objectContent.id === idKey
      );
      const keyIndex = objects.findIndex(
        (objectContent) => objectContent.id === idKey
      );

      if (shovelObject[0].isInventory && collectObject.isInventory) {
        if (collectObject.status === 'open') {
          descriptionModal.buttonDescription = {
            title: "Ajouter à l'inventaire",
            onClick: () => {
              checkedObject(collectObject, grassIndex, 'not-message');
              addInventory(keyObject[0], keyIndex);
              dispatch({
                type: 'COUNT_MOVE',
              });
              dispatch({
                type: 'CLOSE_MODAL',
              });
            },
          };
        } else {
          descriptionModal.buttonDescription = {
            title: 'Utiliser la pelle',
            onClick: () => {
              checkedObject(shovelObject[0], shovelIndex, 'not-message');
              usedObject(collectObject, grassIndex);
              dispatch({
                type: 'COUNT_MOVE',
              });
              dispatch({
                type: 'CLOSE_MODAL',
              });
            },
          };
        }
      }
    } else if (collectObject.id === idDiary) {
      const keyObject = objects.filter(
        (objectContent) => objectContent.id === idKey
      );
      const keyIndex = objects.findIndex(
        (objectContent) => objectContent.id === idKey
      );

      const diaryIndex = objects.findIndex(
        (objectContent) => objectContent.id === idDiary
      );

      if (keyObject[0].isInventory && collectObject.isInventory) {
        if (collectObject.status === 'open') {
          descriptionModal.buttonDescription = {
            title: 'Transmettre',
            onClick: () => {
              axios
                .put(
                  `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
                  {
                    current_step: collectObject.id_message,
                    start_message: startedMessage,
                  }
                )
                .then(() => {
                  dispatch({
                    type: 'PAUSE_TIMER',
                    payload: true,
                  });
                  dispatch({
                    type: 'CURRENT_STEP',
                    payload: collectObject.id_message,
                  });

                  retrieveQuestionnary(collectObject.id_message, dispatch);
                });
              checkedObject(collectObject, index);
              dispatch({
                type: 'CLOSE_MODAL',
              });
            },
          };
        } else {
          descriptionModal.buttonDescription = {
            title: 'Ouvrir',
            onClick: () => {
              checkedObject(keyObject[0], keyIndex, 'not-message');
              usedObject(collectObject, diaryIndex);
              dispatch({
                type: 'CLOSE_MODAL',
              });
            },
          };
        }
      }
    } else if (
      collectObject.id === idPageOne ||
      collectObject.id === idPageTwo ||
      collectObject.id === idPageThree
    ) {
      const pageOneObject = objects.filter(
        (objectContent) => objectContent.id === idPageOne
      )[0];
      const pageOneIndex = objects.findIndex(
        (objectContent) => objectContent.id === idPageOne
      );

      const pageTwoObject = objects.filter(
        (objectContent) => objectContent.id === idPageTwo
      )[0];
      const pageTwoIndex = objects.findIndex(
        (objectContent) => objectContent.id === idPageTwo
      );

      const pageThreeObject = objects.filter(
        (objectContent) => objectContent.id === idPageThree
      )[0];
      const pageThreeIndex = objects.findIndex(
        (objectContent) => objectContent.id === idPageThree
      );

      const pageQuestionnaryObject = objects.filter(
        (objectContent) => objectContent.id === idPageQuestionnary
      )[0];
      const pageQuestionnaryIndex = objects.findIndex(
        (objectContent) => objectContent.id === idPageQuestionnary
      );

      if (
        pageOneObject.isInventory &&
        pageTwoObject.isInventory &&
        pageThreeObject.isInventory
      ) {
        descriptionModal.buttonDescription = {
          title: 'Réunir',
          onClick: () => {
            checkedObject(pageOneObject, pageOneIndex, 'not-message');
            checkedObject(pageTwoObject, pageTwoIndex, 'not-message');
            checkedObject(pageThreeObject, pageThreeIndex, 'not-message');
            addInventory(pageQuestionnaryObject, pageQuestionnaryIndex);
            dispatch({
              type: 'CLOSE_MODAL',
            });
          },
        };
      }
    } else if (collectObject.id === idRemote) {
      const listIndex = objects.findIndex(
        (objectContent) => objectContent.id === idRemote
      );
      descriptionModal.buttonDescription = {
        title: 'Utiliser',
        onClick: () => {
          usedObject(collectObject, listIndex);
          dispatch({
            type: 'CLOSE_MODAL',
          });
        },
      };
    } else if (collectObject.id === idFolderPatientOne) {
      const folderPatientOneContent = objects.find(
        (objectContent) => objectContent.id === idFolderPatientOne
      );
      const folderPatientOneIndex = objects.findIndex(
        (objectContent) => objectContent.id === idFolderPatientOne
      );
      if (objects[objects.findIndex((object) => object.id === 209)].isClicked > 0) {
        descriptionModal.buttonDescription = {
          title: 'Intégrer dans le dossier',
          onClick: () => {
            checkedObject(
              folderPatientOneContent,
              folderPatientOneIndex,
              'not-message'
            );
            dispatch({
              type: 'COUNT_MOVE',
            });
            dispatch({
              type: 'CLOSE_MODAL',
            });
          },
        };
      }
    } else if (collectObject.id === idFolderPatientTwo) {
      const folderPatientTwoContent = objects.find(
        (objectContent) => objectContent.id === idFolderPatientTwo
      );
      const folderPatientTwoIndex = objects.findIndex(
        (objectContent) => objectContent.id === idFolderPatientTwo
      );

      if (objects[objects.findIndex((object) => object.id === 209)].isClicked > 0) {
        descriptionModal.buttonDescription = {
          title: 'Intégrer dans le dossier',
          onClick: () => {
            checkedObject(
              folderPatientTwoContent,
              folderPatientTwoIndex,
              'not-message'
            );
            dispatch({
              type: 'COUNT_MOVE',
            });
            dispatch({
              type: 'CLOSE_MODAL',
            });
          },
        };
      }
    }
    dispatch({
      type: 'OPEN_MODAL',
      payload: descriptionModal,
    });
  };

  return (
    <div className="Inventory">
      <p>Inventaire</p>
      {collectedObjects.map(
        (collectObject, index) =>
          collectObject.rpsDisplay && (
            <div
              className="Inventory_object"
              onClick={() => openModal(collectObject, index)}
              draggable="true"
              onDragStart={(event) => dragStartRPS(event, collectObject.id)}
              role="presentation"
              key={collectObject.id}
            >
              <img src={handleImage(collectObject)} alt={collectObject.title} />
            </div>
          )
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  collectedObjects: state.Inventory.list,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  objects: state.Objects.AllObjects,
});

Inventory.propTypes = {
  collectedObjects: PropTypes.arrayOf(
    PropTypes.shape({
      rpsDisplay: PropTypes.bool,
      title: PropTypes.string,
      id: PropTypes.number,
    })
  ).isRequired,
  idSessionHasRoom: PropTypes.number,
  objects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      isClicked: PropTypes.number,
    })
  ).isRequired,
};

Inventory.defaultProps = {
  idSessionHasRoom: null,
};

export default connect(mapStateToProps)(Inventory);
