import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import 'assets/css/components/GameBoard/RPS/Enigma/Patients.css';

function Patients({
  patients,
  setPatients,
  indexPatient,
  startGame,
  indexLastPatientLine,
  dispatch,
  setShowButton,
  time,
}) {
  const [walking, setWalking] = useState(false);
  const [showBubble, setShowBubble] = useState(null);
  const speed = 6.5;
  const [numberPatientDisplayed, setNumberPatientDisplayed] = useState(2);
  const [currentPatientDisplay, setCurrentPatientDisplay] = useState(2);
  const [firstPatient, setFirstPatient] = useState(false);

  // Number position of the image per patient
  const handlePatientBackgroundNumber = () => {
    switch (indexPatient) {
      case 1:
        return 203.25;
      case 2:
        return 173.57;
      case 3:
        return 250;
      case 4:
        return 220.69;
      case 5:
        return 174.27;
      case 6:
        return 166.23;
      case 7:
        return 219.55;
      case 8:
        return 211.91;
      case 9:
        return 203.25;
      case 10:
        return 178.57;
      default:
        return 206;
    }
  };

  // Update the position, the background position and step number of one patient
  const step = () => {
    const newPatients = [...patients];

    const positionPatient = newPatients[indexPatient].position + 1 * speed;
    const backgroundPositionPatient = `${
      -handlePatientBackgroundNumber() * newPatients[indexPatient].stepNumber
    }px`;
    newPatients[indexPatient].position = positionPatient;
    newPatients[indexPatient].backgroundPosition = backgroundPositionPatient;
    newPatients[indexPatient].stepNumber =
      (newPatients[indexPatient].stepNumber + 1) % 8;
    setPatients(newPatients);
  };

  // Update the patient to walk
  const handleWalk = () => {
    setWalking(true);
  };

  useEffect(() => {
    if (startGame) {
      setNumberPatientDisplayed(numberPatientDisplayed - 1);
      dispatch({ type: 'REMOVE_PATIENT' });
      handleWalk();
    }
    // TO REFACTO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startGame, indexPatient, dispatch]);

  useEffect(() => {
    let patientLine;
    setShowButton(false);

    // Add patient in the waiting line and the current patient
    if (startGame) {
      patientLine = setInterval(() => {
        dispatch({ type: 'SET_TOTAL_NUMBER_PATIENTS' });
        dispatch({ type: 'SET_PATIENT_LINE' });
      }, 10000);
    }
    return () => {
      clearInterval(patientLine);
    };
    // TO REFACTO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startGame]);

  const numberPatientBetween = indexLastPatientLine - indexPatient - 1;

  useEffect(() => {
    // Add stress if there is more than two patients in line
    const interval = setInterval(() => {
      if (numberPatientBetween >= 2) {
        dispatch({
          type: 'ADD_STRESS',
          payload: {
            stress: 0.3,
            type: 'positive',
          },
        });
        setTimeout(() => {
          dispatch({
            type: 'INIT_NUMBER_STRESS',
          });
        }, 1000);
      }
    }, 2000);

    // Stop the stress added if there is less than two patients in line
    return () => {
      clearInterval(interval);
    };
    // TO REFACTO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, numberPatientBetween]);

  useEffect(() => {
    /* this useEffect will make the next patient appear if 2 patient are not already displayed */
    if (firstPatient && startGame && numberPatientDisplayed < 2) {
      setNumberPatientDisplayed(numberPatientDisplayed + 1);
      setCurrentPatientDisplay(currentPatientDisplay + 1);
    }
    setFirstPatient(true);
    // TO REFACTO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, startGame]);

  useEffect(() => {
    // move patient with function step if it's the time of the patient to move
    if (walking) {
      // disabled button and don't show the bubble message when a patient walk
      setShowButton(false);
      setShowBubble(null);
      const interval = setInterval(() => {
        if (indexLastPatientLine <= 10 && indexPatient <= 10) {
          step();
        }
      }, 700 / speed);
      // when the patient finish to move, the bubble of description appear and enabled the button
      setTimeout(() => {
        setWalking(false);
        clearInterval(interval);
        setShowBubble(indexPatient);
        setShowButton(true);
      }, 6000);
    }
    // TO REFACTO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walking]);

  if (indexPatient === 11) {
    return null;
  }

  return (
    <div className="Patients">
      <div
        className={`bulle${
          showBubble === indexPatient ? '--active' : '--not-active'
        }`}
      >
        <p>{patients[indexPatient].description}</p>
      </div>
      {patients
        .filter((patient, index) => index !== 0)
        .filter((patient) => !patient.finish)
        .map((patient) => {
          return (
            <div
              className={`Patient_${patient.id}`}
              style={{
                backgroundImage: `url(${patient.image})`,
                backgroundPosition: `${patient.backgroundPosition} center`,
                left: `${patient.position}px`,
              }}
              key={patient.id}
            />
          );
        })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  indexPatient: state.RPS.dataGame.indexPatient,
  indexLastPatientLine: state.RPS.dataGame.indexLastPatientLine,
  startGame: state.GameUsers.gameStarted,
  numberPatient: state.RPS.dataGame.numberPatient,
  time: state.RPS.dataGame.time,
});

Patients.propTypes = {
  startGame: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  patients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
      urlInfo: PropTypes.string,
      description: PropTypes.string,
      isOpen: PropTypes.bool,
      residentGoodObject: PropTypes.arrayOf(PropTypes.number),
      inventoryResident: PropTypes.arrayOf(PropTypes.number),
      isChecked: PropTypes.bool,
    })
  ).isRequired,
  setPatients: PropTypes.func.isRequired,
  indexPatient: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  indexLastPatientLine: PropTypes.number.isRequired,
  setShowButton: PropTypes.func.isRequired,
  time: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(Patients);
