import styled, { keyframes } from 'styled-components';
import * as images from '../../img/vaccination-solo/index';
import * as imageNext from '../../img/vaccination-solo/PuckLab';

const rotateAnimation = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
`;

const ImageDefault = styled.img`
  margin: 20px;
  width: 50%;
`;

const Coin = styled.img`
  margin: 20px;
  width: 50%;
  animation-name: ${rotateAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

const stepsObesity = [
  {
    target: '.KitchenLivingRoom-img',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Bienvenue
        </h2>
        <p>
          Aider Arthur à retrouver les données collectées par son compagnon
          supra-intelligent
        </p>
      </div>
    ),
    placement: 'center',
  },
  {
    target: '#right-chevron',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Navigation
        </h2>
        <p>Vous pouvez naviguer dans la cuisine d&apos;Arthur</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space evenly',
            alignItems: 'center',
          }}
        >
          <img
            style={{ margin: 20, width: 50 }}
            src={images.chevronLeft}
            alt="Tricky"
          />
          <img
            style={{ margin: 20, width: 50 }}
            src={images.chevronRight}
            alt="Tricky"
          />
        </div>
      </div>
    ),
    placement: 'left',
  },
  {
    target: '#left-chevron',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Navigation
        </h2>
        <p>Vous pouvez naviguer dans la cuisine d&apos;Arthur</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space evenly',
            alignItems: 'center',
          }}
        >
          <img
            style={{ margin: 20, width: 50 }}
            src={images.chevronLeft}
            alt="Tricky"
          />
          <img
            style={{ margin: 20, width: 50 }}
            src={images.chevronRight}
            alt="Tricky"
          />
        </div>{' '}
      </div>
    ),
    placement: 'right',
  },
  {
    target: '.bounceRobot',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Alexo
        </h2>
        <p>Bonjour, moi c’est Alexo le robot d’Arthur !</p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space evenly',
            alignItems: 'center',
          }}
        >
          <img
            style={{ margin: 20, width: 100 }}
            src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FObesity%2Fassets%2Fobjects%2Frobot.svg?alt=media&token=30b58f7e-f4f1-4fd4-95bb-936360d3278e"
            alt="Tricky"
          />
        </div>{' '}
      </div>
    ),
    placement: 'center',
  },
  {
    target: '.Inventory',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Inventaire
        </h2>
        <ImageDefault
          src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FObesity%2Fassets%2Fobjects%2Fquestionnnaire%20cpt.svg?alt=media&token=55aba0ad-264b-4185-823b-9afd84894632"
          alt="Tricky"
        />
        <p>Vous collecterez des objets durant le jeu, ils apparaîtront ici</p>
      </div>
    ),
    placement: 'bottom',
  },
  {
    target: '.Steps',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Etapes
        </h2>
        <p>Collectez les cartes d&apos;activitées d&apos;Arthur</p>
      </div>
    ),
  },
  {
    target: '.Hud-Item-Text-time',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Temps
        </h2>
        <p>Chaque minute compte</p>
        <ImageDefault src={imageNext.time} alt="Tricky" />
        <p>
          Obtenez un meilleur score en finissant l&apos;expérience le plus vite
          possible
        </p>
      </div>
    ),
    placement: 'left',
  },
  {
    target: '.Hud-Item-Text-click',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Coups
        </h2>
        <p>Le nombre de coups est comptabilisé</p>
        <ImageDefault src={imageNext.cursor} alt="Tricky" />{' '}
        <p>
          Obtenez un meilleur score en finissant l&apos;expérience avec le moins de
          coup possible
        </p>
      </div>
    ),
    placement: 'bottom',
  },
  {
    target: '.Hud-Item-Text-score',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Points
        </h2>
        <p>Gagnez des points en finissant les jeux de chaque étape</p>
        <Coin src={imageNext.coinScore} alt="Tricky" />{' '}
      </div>
    ),
    placement: 'bottom',
  },
  {
    target: '.Hud-Item-Text-sound',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Son
        </h2>
        <p>Activez ou désactivez le son ici.</p>
        <ImageDefault src={imageNext.notMuteIcon} alt="Tricky" />{' '}
      </div>
    ),
    placement: 'bottom',
  },
  {
    target: '.Hud-Item-Text-music',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Musique
        </h2>
        <p>Activez ou désactivez la musique d&apos;ambiance ici.</p>
        <ImageDefault src={imageNext.unMuteMusic} alt="Tricky" />{' '}
      </div>
    ),
    placement: 'bottom',
  },

  {
    target: '.KitchenLivingRoom-img',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Bonne chance
        </h2>
        <img
          style={{ margin: 20, width: 100 }}
          src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FObesity%2Fassets%2Fobjects%2Frobot.svg?alt=media&token=30b58f7e-f4f1-4fd4-95bb-936360d3278e"
          alt="Tricky"
        />
        <p>Je vous laisse en compagnie d&apos;Alexo</p>
      </div>
    ),
    placement: 'center',
  },
];

export default stepsObesity;
