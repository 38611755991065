import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import styles from 'components/GameBoard/Emotion/Solo/Enigma/FooterPanel.module.scss';
import { enigmas } from 'components/GameBoard/Emotion/Solo/constants';
import BaseAnimation from 'assets/css/BaseAnimation';
import TranslateKeyframe from 'assets/css/TranslateKeyframe';

const { duration, timing, fillMode } = enigmas.EmotionGeneration;

// CSS in JS
const FooterStyle = styled(BaseAnimation)`
  animation-name: ${({ coordinates }) =>
    TranslateKeyframe(0, 0, coordinates.startY, coordinates.endY)};
  display: ${({ isLoaded }) => !isLoaded && 'none'};
`;

export const FooterContentStyle = styled.div`
  position: absolute;
  top: ${({ footerStyle }) => footerStyle.top ?? '1rem'};
  left: ${({ footerStyle, footerDimensions }) =>
    footerStyle.leftRatio * footerDimensions.width}px;
  width: ${({ footerStyle, footerDimensions }) =>
    footerStyle.widthRatio * footerDimensions.width}px;
  height: ${({ footerStyle, footerDimensions }) =>
    footerStyle.heightRatio * footerDimensions.height}px;
  display: flex;
  align-items: ${({ footerStyle }) => footerStyle.alignItems ?? 'flex-start'};
`;

export const FooterTextStyle = styled.div`
  width: ${({ footerStyle, footerWidth }) =>
    footerStyle.textWidthRatio * footerWidth}px;
  margin-left: ${({ footerStyle }) => footerStyle.marginLeft ?? '2rem'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: ${({ footerStyle }) =>
    footerStyle.justifyContent ?? 'flex-start'};

  h1 {
    font-size: ${({ footerStyle }) => footerStyle.h1 ?? '1.25rem'};
    font-weight: 700;
  }

  p {
    margin: 0;
    font-size: ${({ footerStyle }) => footerStyle.p};
  }
`;

const FooterPanel = ({
  room,
  imageUrl,
  imageAlt,
  handleNextText,
  content,
  style,
}) => {
  const { t } = useTranslation('emotion');

  const { footerPanelUrl } = enigmas[room];

  const [footerImageDimensions, setFooterImageDimensions] = useState({
    height: 0,
    width: 0,
  });

  // Display the next text when the user correctly answers
  useEffect(() => {
    handleNextText();
  }, [handleNextText]);

  const coordinates = {
    startY: `${footerImageDimensions.height}px`,
    endY: '0px',
  };

  return (
    <FooterStyle
      as="div"
      coordinates={coordinates}
      isLoaded={!!footerImageDimensions.height}
      duration={duration}
      timingFunction={timing}
      fillMode={fillMode}
      playState="running"
      iterationCount="1"
      className={styles['footer-panel']}
    >
      <img
        alt={t('panel')}
        src={footerPanelUrl}
        onLoad={(event) =>
          setFooterImageDimensions({
            width: event.target.width,
            height: event.target.height,
          })
        }
      />
      <FooterContentStyle
        footerStyle={style}
        footerDimensions={footerImageDimensions}
        widthRatio={style.widthRatio}
      >
        {imageUrl && <img src={imageUrl} alt={t(imageAlt)} />}
        <FooterTextStyle
          footerWidth={footerImageDimensions.width}
          footerStyle={style}
        >
          <h1>{content?.title}</h1>
          <p>{content?.description}</p>
        </FooterTextStyle>
      </FooterContentStyle>
    </FooterStyle>
  );
};

FooterPanel.propTypes = {
  handleNextText: PropTypes.func,
  imageAlt: PropTypes.string,
  imageUrl: PropTypes.string,
  room: PropTypes.string.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  style: PropTypes.shape({
    leftRatio: PropTypes.string.isRequired,
    widthRatio: PropTypes.string.isRequired,
  }).isRequired,
};

FooterPanel.defaultProps = {
  handleNextText: () => {},
  imageAlt: '',
  imageUrl: '',
};

export default FooterPanel;
