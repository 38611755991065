import 'assets/css/components/GameBoard/Obesity/Enigma/Phone/Timer.css';

function Timer() {
  return (
    <div className="Timer">
      <p className="Timer_title Timer_firstTitle">Temps d&apos;écran</p>
      <div className="Timer_top">
        <p className="Timer_title Timer_secondTitle">Aujourd&apos;hui</p>
        <p className="Timer_time Timer_title">08h43mn</p>
        <div className="Timer_content">
          <p className="Timer_title">Augmentation de 12% par rapport à hier</p>
        </div>
      </div>
      <div className="Timer_bar" />
      <div className="Timer_bottom">
        <p className="Timer_title Timer_secondTitle">Ce mois-ci</p>
        <p className="Timer_time Timer_title">08h43mn</p>
        <div className="Timer_bottom-contents">
          <img
            className="Timer_bottom-contents-graph"
            src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FObesity%2Fassets%2Fobjects%2Factivity.png?alt=media&token=654b3c7d-7a74-4720-9492-901f93007dc3"
            alt="taux activity"
          />
          <div className="Timer_botton-contents-description">
            <p className="Timer_botton-contents-title">
              Taux d&apos;activité par rapport au mois précédent.
            </p>
            <p className="Timer_botton-contents-subtitle Timer_title">
              NOVEMBRE 2018
            </p>
            <div className="Timer_content">
              <p className="Timer_title">Augmentation de 27%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timer;
