import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enigmas } from 'components/GameBoard/Emotion/Solo/constants';

// Components
import ObjectContent from 'components/GameBoard/ReusableComponents/Objects/ObjectContent';
import EmotionsPanel from 'components/GameBoard/Emotion/Solo/Enigma/EmotionsPanel';
import NarratorTextHeader from 'components/GameBoard/Emotion/Solo/Enigma/NarratorTextHeader';
import TranslateKeyframe from 'assets/css/TranslateKeyframe';
import BaseAnimation from 'assets/css/BaseAnimation';

// Style
import styles from 'components/GameBoard/Emotion/Solo/Enigma/InterpretationMachineCalibration.module.scss';
import styled from 'styled-components';

// Selector functions
import {
  selectEmoticonObjects,
  selectSelectedEmoticon,
  selectValidatedEmoticons,
} from 'components/Reducers/GameData/fetchObjects';
import {
  selectEmoticonsState,
  selectInterpretationMachineCalibrationEnigma,
  selectWrongIntensities,
  selectAnswersState,
} from 'components/Reducers/emotion';
import { selectRoom } from 'components/Reducers/Room';

// Utils
import { setOnLoadImageDimensions } from 'utils/utilityFunctions';

// CSS in JS - enable to use JS constants
const FooterPanel = styled(BaseAnimation)`
  animation-name: ${({ coordinates }) =>
    TranslateKeyframe(0, 0, coordinates.startY, coordinates.endY)};
  display: ${({ isLoaded }) => !isLoaded && 'none'};
  position: absolute;
  width: ${({ imageWidth }) => imageWidth}px;
  height: ${({ imageHeight }) => imageHeight}px;
  background-image: ${({ footerPanelUrl }) => `url(${footerPanelUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

let isMounted = false;

const { lightsUrl, footerPanelUrl, duration, fillMode, timing } =
  enigmas.InterpretationMachineCalibration;

function InterpretationMachineCalibration() {
  // `t` is a function coming from the i18next library used to translate text
  /* Load the `common` namespace = the one containing translations that are common between games
  and the `emotion` one (see public-> locales -> fr-FR) */
  const { t } = useTranslation('emotion');

  const dispatch = useDispatch();

  // Select from the store
  const { currentRoomId } = useSelector(selectRoom);
  const emoticons = useSelector(selectEmoticonObjects);
  const selectedEmoticon = useSelector(selectSelectedEmoticon);
  const validatedEmoticons = useSelector(selectValidatedEmoticons);
  const enigma = useSelector(selectInterpretationMachineCalibrationEnigma);
  const emoticonsState = useSelector(selectEmoticonsState);
  const answersState = useSelector((state) =>
    selectAnswersState(state, selectedEmoticon?.id)
  );
  const wrongIntensities = useSelector((state) =>
    selectWrongIntensities(state, selectedEmoticon?.id)
  );
  // Image dimensions of the footer panel
  const [imageDimensions, setImageDimensions] = useState({
    height: 0,
    width: 0,
  });

  // Set image dimension on loading
  useEffect(() => {
    setOnLoadImageDimensions(setImageDimensions, footerPanelUrl);
  }, []);

  // We retrieve the right answers for each validated emoticon
  useEffect(() => {
    // Execute the effect only once at mouting
    if (isMounted) return;

    isMounted = true;

    if (validatedEmoticons.length) {
      validatedEmoticons.forEach((validatedEmoticon) => {
        dispatch({
          type: 'SET_USER_ANSWERS',
          payload: {
            emoticonId: validatedEmoticon.id,
            userAnswers: enigma[validatedEmoticon.id].rightAnswers,
          },
        });
      });
    }
  }, [dispatch, enigma, validatedEmoticons]);

  // Concatenate the name of the wrong intensities
  const concatenateWrongIntensities = () => {
    let text = '';
    if (wrongIntensities.length) {
      wrongIntensities.forEach((intensityName, index) => {
        text += index ? ` / ${t(intensityName)}` : `${t(intensityName)}`;
      });
    }
    return text;
  };

  // Access the i18next object about the messages displayed at the top
  const messages = t('enigma1.rules.messages', {
    returnObjects: true,
    /* this object depends on values. Use count from i18next to handle singular
      and plural versions of the values */
    val1: `${t('the', {
      count: wrongIntensities.length,
    })}`,
    val2: `${t('emotion', {
      count: wrongIntensities.length,
    })}`,
    val3: `${concatenateWrongIntensities()}`,
    val4: `${t('match', {
      count: wrongIntensities.length,
    })}`,
  });

  const calculateMessageIndex = () => {
    if (!selectedEmoticon) return 0;
    if (emoticons.length === validatedEmoticons.length) return 4;
    switch (answersState) {
      case 'unanswered':
        return 1;
      case 'right':
        return 2;
      case 'wrong':
        return 3;
      default:
        return 0;
    }
  };

  const coordinates = {
    startY: `${imageDimensions.height}px`,
    endY: '0px',
  };

  return (
    <div className={styles['enigma-container']}>
      <NarratorTextHeader message={messages[calculateMessageIndex()]} />

      {/* Middle part with the emotion panel,  only if one emoticon is selected */}
      {selectedEmoticon && <EmotionsPanel />}

      {/* Footer part with emoticons */}
      <FooterPanel
        currentRoomId={currentRoomId}
        footerPanelUrl={footerPanelUrl}
        imageWidth={imageDimensions.width}
        imageHeight={imageDimensions.height}
        as="div"
        coordinates={coordinates}
        isLoaded={!!imageDimensions.height}
        duration={duration}
        timingFunction={timing}
        fillMode={fillMode}
        playState="running"
        iterationCount="1"
      >
        {imageDimensions.height && imageDimensions.width && (
          <div className={styles['enigma-footer']}>
            {emoticons?.map((emoticon) => (
              <div key={emoticon.id}>
                <button type="button">
                  <ObjectContent object={emoticon} />
                </button>
                <img
                  src={lightsUrl[emoticonsState[emoticon.id]]}
                  alt={t('warningLight')}
                />
              </div>
            ))}
          </div>
        )}
      </FooterPanel>
    </div>
  );
}

export default InterpretationMachineCalibration;
