import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

// components
import Inventory from 'components/GameBoard/ReusableComponents/Inventory';
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import Zoom from 'components/GameBoard/ReusableComponents/Modal/Zoom';
import ModalZoom from 'components/GameBoard/ReusableComponents/Modal/ModalZoom';
import ModalEnigma from 'components/GameBoard/ReusableComponents/Modal/ModalEnigma';
import Advice from 'components/GameBoard/ReusableComponents/Advice';
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import Scoreboard from 'components/GameBoard/ReusableComponents/Scoreboard/Scoreboard';
import ChatBot from 'components/GameBoard/ReusableComponents/ChatBot/ChatBot';
import stepsTMSCongres from 'components/Joyride/StepsTMSCongres';
import Intro from 'components/GameBoard/ReusableComponents/Intro';

// selector functions
import { selectSession } from 'components/Reducers/session';

// utils
import useInitGame from 'utils/useInitGame';
import { useStartMessageOrQuestionary } from 'utils/utilityFunctions';

// css
import 'assets/css/components/GameBoard/TMS/Rooms/CorridorTMS.css';

// assets
import tmsTheme from '../../../../sound/TMS/subsets-destinations1.ogg';
import click from '../../../../sound/click-ongame.mp3';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function CorridorTMSCongres({
  modalIsOpen,
  currentRoom,
  dispatch,
  startMessage,
  currentStep,
  startQuestionnary,
  idSessionHasRoom,
  zoomIsOpen,
  modalZoomIsOpen,
  isAdvice,
  count,
  modalEnigmaIsOpen,
  listRooms,
  game,
  MouseClicked,
  isScoreBoard,
  isTutoFinish,
  isStarted,
}) {
  const urlRoom = listRooms.CorridorTMS.url;
  const [stepGame, setStepGame] = useState({
    steps: stepsTMSCongres,
    stepIndex: 0,
  });
  const session = useSelector(selectSession);

  const [residents, setResidents] = useState([
    {
      id: 1,
      name: 'Albert',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fportes%2Falbert.svg?alt=media&token=6dd198f4-3569-4a9c-a320-63dfb5f57b5c',
      urlInfo:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fportes%2Falbert.svg?alt=media&token=6dd198f4-3569-4a9c-a320-63dfb5f57b5c',
      description: [
        'Homme, âge: 75 ans, poids: 82kg',
        'Se déplace seul mais utilise une canne à certains moments.',
        "Autonome, il est capable de se laver et de s'habiller seul.",
        'Ne présente pas de risque de surcharge statique ou dynamique.',
        'La stimulation de ses capacités fonctionnelles est très importante.',
        'Continent.',
      ],
      isOpen: false,
      residentGoodObject: [4, 6],
      inventoryResident: [],
      isChecked: false,
    },
    {
      id: 2,
      name: 'Barbara',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fboard%2Ftv-perso-barbara.svg?alt=media&token=c4057dba-bfa6-483c-847f-fd2e9eea025a',
      urlInfo:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fportes%2Fbarbara.svg?alt=media&token=d6b463c1-4ccb-49b4-b6db-45c856853426',
      description: [
        'Femme, âge: 82 ans, poids: 61kg',
        'Se déplace seule mais utilise un déambulateur.',
        'Dépendante pour certaines activitées.',
        'Normalement pas de risque de surcharge dynamique.',
        'Il faut la stimuler pour maintenir sa capacité fonctionnelle.',
        'Début d’incontinence.',
      ],
      isOpen: false,
      residentGoodObject: [4, 5, 9],
      inventoryResident: [],
      isChecked: false,
    },

    {
      id: 3,
      name: 'Carles',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fboard%2Ftv-perso-carles.svg?alt=media&token=3a94b851-266a-433a-bc9d-24b624fbfbf7',
      urlInfo:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fportes%2Fcarl.svg?alt=media&token=f79065c4-dbf8-4ce1-b8aa-71576eeb905a',
      description: [
        'Homme, âge: 80 ans, poids: 95kg',
        'Peut supporter une partie de son poids sur une jambe.',
        'Dépend des soignants pour de nombreuses tâches.',
        'Risque de surcharge statique et dynamique si équipement pas adapté.',
        'Il faut le stimuler pour maintenir sa capacité fonctionnelle.',
        'Il est incontinent urinaire.',
      ],
      isOpen: false,
      residentGoodObject: [1, 4, 7, 8],
      inventoryResident: [],
      isChecked: false,
    },

    {
      id: 4,
      name: 'Doris',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fboard%2Ftv-perso-doris.svg?alt=media&token=a87dbceb-e3d0-4d2b-9341-726e5be358dd',
      urlInfo:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fportes%2Fdoris.svg?alt=media&token=7675ba70-999f-4fdf-ab7a-3371f5399ae7',
      description: [
        'Femme, âge: 80 ans, poids: 90 kg',
        'Ne peut pas se tenir debout et nécessite un soignant pour les tâches de la vie quotidienne.',
        'Présente un haut risque de surcharge statique et dynamique si équipement pas adapté.',
        'Il faut la stimuler pour maintenir sa capacité fonctionnelle.',
        'Incontinence totale.',
      ],
      isOpen: false,
      residentGoodObject: [2, 3, 7, 10],
      inventoryResident: [],
      isChecked: false,
    },

    {
      id: 5,
      name: 'Emma',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fboard%2Ftv-perso-emma.svg?alt=media&token=2aece55a-743d-427e-b476-5aa6ab4815de',
      urlInfo:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fportes%2Femma.svg?alt=media&token=e8f04c24-80d9-4abd-b952-a2fcd7906edb',
      description: [
        'Femme, âge: 86 ans, poids: 42kg',
        'Grabataire ou quasiment, elle reste au lit ou dans des fauteuils adaptés.',
        'Elle dépend du soignant en permanence.',
        'Présente un haut risque de surcharge statique et dynamique si équipement pas adapté.',
        'Il faut la stimuler pour maintenir sa capacité fonctionnelle.',
        'Incontinence totale.',
      ],
      isOpen: false,
      residentGoodObject: [3, 6, 10],
      inventoryResident: [],
      isChecked: false,
    },
  ]);

  const [inventoryCorridor, setInventoryCorridor] = useState([
    {
      id: 1,
      name: 'Elimination',
      isOpen: false,
      list: [
        {
          id: 1,
          title: 'WC Stacy',
          url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fchambres%2Fchaise-percee.svg?alt=media&token=842d5c1d-2dc0-4fed-b535-58172c4b4785',
          subDescription: 'Chaise percée',
          description:
            'Chaise percée pour les résidents ayant des difficultés à se déplacer aux toilettes.',
          usedBy: [],
          goodResident: [3],
          count: 1,
          top: '49.5%',
          width: '8%',
          left: '24%',
        },
        {
          id: 2,
          title: 'Carendo',
          url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fchambres%2Fcarendo.svg?alt=media&token=88192c56-7026-4b2d-8f3c-a6d4fd6604a2',
          subDescription: 'Fauteuil de douche',
          description:
            'Fauteuil de douche offrant un soutien supplémentaire aux résidents dont la stabilité du haut du corps est réduite.',
          usedBy: [],
          goodResident: [4],
          count: 1,
          top: '48%',
          width: '8%',
          left: '24%',
        },
      ],
    },
    {
      id: 2,
      name: 'Lits médicalisés',
      isOpen: false,
      list: [
        {
          id: 3,
          title: 'Entreprise 8000',
          url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fchambres%2Fenterprise.svg?alt=media&token=19d7ba02-0861-4cc1-b793-9f2b1013e1c2',
          subDescription: 'Lit médicalisé',
          description:
            'Lit médicalisé pour lequel la sécurité du résident et du soignant est renforcée.',
          usedBy: [],
          goodResident: [4, 5],
          count: 2,
          top: '45.5%',
          width: '11%',
          left: '43.5%',
        },
        {
          id: 4,
          title: 'Minuet 2',
          url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fchambres%2Fminuet.svg?alt=media&token=94e23af8-a633-4746-84d5-9b27b426be92',
          subDescription: 'Lit médicalisé',
          description: 'Lit médicalisé favorisant l’autonomie du résident.',
          usedBy: [],
          goodResident: [1, 2, 3],
          count: 3,
          top: '46.5%',
          width: '12%',
          left: '43.3%',
        },
      ],
    },

    {
      id: 3,
      name: 'Assises',
      isOpen: false,
      list: [
        {
          id: 5,
          title: 'Fero',
          url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fchambres%2Ffero.svg?alt=media&token=74dd34c8-51dc-45d4-a676-9edca0b39ece',
          subDescription: 'Fauteuil',
          description:
            'Fauteuil qui peut offrir une position assise/allongée pour soulager les jambes du résident.',
          usedBy: [],
          goodResident: [2],
          count: 1,
          top: '42.8%',
          width: '5.5%',
          left: '57.2%',
        },
        {
          id: 6,
          title: 'Alto',
          url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fchambres%2Fchaise.svg?alt=media&token=ec9deaaa-d508-4ade-8235-16114fac7f5e',
          subDescription: 'Chaise',
          description:
            'Chaise sans accoudoir pour les résidents étant totalement autonome, ou pour les visiteurs.',
          usedBy: [],
          goodResident: [1, 5],
          count: 2,
          top: '46%',
          width: '5%',
          left: '57.5%',
        },

        {
          id: 7,
          title: 'V500',
          url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fchambres%2Ffauteuil-roulant.svg?alt=media&token=f67de36e-c153-4c8e-8520-40707f8fd20d',
          subDescription: 'Fauteuil roulant',
          description:
            'Fauteuil roulant pour les résidents qui n’ont plus ou trop peu d’appuis sur leurs jambes pour se déplacer.',
          usedBy: [],
          goodResident: [3, 4],
          count: 2,
          top: '45.5%',
          width: '6%',
          left: '57%',
        },
      ],
    },

    {
      id: 4,
      name: 'Manutention sécurisée',
      isOpen: false,
      list: [
        {
          id: 8,
          title: 'Sara Flex',
          url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fchambres%2Fsara-flex.svg?alt=media&token=23023276-2d48-411a-83fe-a3b44c2ae551',
          subDescription: 'Mobilisation',
          description:
            'Mobilisation de résident ayant un appuis sur leurs jambes, sans avoir la force de se redresser avec les bras.',
          usedBy: [],
          goodResident: [3],
          count: 1,
          top: '53%',
          width: '14%',
          left: '15.5%',
        },
        {
          id: 9,
          title: 'Sara Stedy',
          url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fchambres%2Fsara-study.svg?alt=media&token=0f22ad33-fb6d-4051-b1e1-7c47b80fe799',
          subDescription: 'Mobilisation',
          description:
            'Mobilisation de résident ayant un bon appui sur leurs jambes et qui peuvent s’aider de leurs bras.',
          usedBy: [],
          goodResident: [2],
          count: 1,
          top: '48.5%',
          width: '14%',
          left: '16%',
        },
        {
          id: 10,
          title: 'Maxi Sky 2',
          url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fcouloir%2Fchambres%2Fmaxi-sky.svg?alt=media&token=78ed0ca4-1147-458f-81c1-6b1206715884',
          subDescription: 'Mobilisation',
          description:
            'Mobilisation de résidents ne pouvant ni se tenir debout, ni supporter leur poids.',
          usedBy: [],
          goodResident: [4, 5],
          count: 2,
          top: '17.5%',
          width: '35%',
          left: '31%',
        },
      ],
    },
  ]);

  const callback = (data) => {
    const { action, index, type, status } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // const stepsOpenInformation = 6;

      // if (index === stepsOpenInformation) {
      //   setIsInformationOpen(!isInformationOpen);
      // }
      setStepGame({
        ...stepGame,
        stepIndex: index + (action === ACTIONS.NEXT ? 1 : -1),
      });
    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED, STATUS.FINISHED].includes(status)
    ) {
      // Need to set our running state to false, so we can restart if we click start again.
      setStepGame({
        ...stepGame,
        run: false,
      });

      axios
        .put(`${BASE_URL}/api/sessions/${session.id}/modification`, {
          isTutoFinish: 1,
        })
        .then(() => {
          dispatch({ type: 'RESET_TUTO', payload: 1 });
        });
    }
    if (action === 'reset' || action === 'close') {
      setStepGame({
        ...stepGame,
        run: false,
      });
      axios
        .put(`${BASE_URL}/api/sessions/${session.id}/modification`, {
          isTutoFinish: 1,
        })
        .then(() => {
          dispatch({ type: 'RESET_TUTO', payload: 1 });
        });
    }
  };

  const handleResidents = (listObjects) => {
    const doorAlbertId = 222;
    const doorBarbaraId = 225;
    const doorCarlesId = 226;
    const doorDorisId = 227;
    const doorEmmaId = 228;
    const albertId = 1;
    const barbaraId = 2;
    const carlesId = 3;
    const dorisId = 4;
    const emmaId = 5;

    const albertObject = listObjects.find(
      (objectElement) => objectElement.id === doorAlbertId
    );

    const barbaraObject = listObjects.find(
      (objectElement) => objectElement.id === doorBarbaraId
    );

    const carlesObject = listObjects.find(
      (objectElement) => objectElement.id === doorCarlesId
    );
    const dorisObject = listObjects.find(
      (objectElement) => objectElement.id === doorDorisId
    );
    const emmaObject = listObjects.find(
      (objectElement) => objectElement.id === doorEmmaId
    );

    const newResidents = [...residents];

    // eslint-disable-next-line no-unused-expressions
    newResidents.length > 0 &&
      // eslint-disable-next-line array-callback-return
      newResidents.map((residentElement) => {
        switch (residentElement.id) {
          case albertId: {
            // eslint-disable-next-line no-param-reassign
            residentElement.isChecked = albertObject.isChecked;
            if (albertObject.isChecked) {
              inventoryCorridor.map((inventoryElement, indexInventory) =>
                // eslint-disable-next-line array-callback-return
                inventoryElement.list.map((listObject, indexListObject) => {
                  const isResidentUsedObject = listObject.goodResident.find(
                    (residentId) => residentId === albertId
                  );

                  if (isResidentUsedObject !== undefined) {
                    const newInventory = [...inventoryCorridor];
                    const newArrayUsedBy = [
                      ...newInventory[indexInventory].list[indexListObject].usedBy,
                    ];
                    const isAlreadyInUsedObject = newArrayUsedBy.find(
                      (residentId) => residentId === albertId
                    );
                    if (isAlreadyInUsedObject === undefined) {
                      newArrayUsedBy.push(albertId);
                    }
                    newInventory[indexInventory].list[indexListObject].usedBy =
                      newArrayUsedBy;
                    setInventoryCorridor(newInventory);
                  }
                })
              );
            }

            break;
          }
          case barbaraId:
            // eslint-disable-next-line no-param-reassign
            residentElement.isChecked = barbaraObject.isChecked;
            if (barbaraObject.isChecked) {
              inventoryCorridor.map((inventoryElement, indexInventory) =>
                // eslint-disable-next-line array-callback-return
                inventoryElement.list.map((listObject, indexListObject) => {
                  const isResidentUsedObject = listObject.goodResident.find(
                    (residentId) => residentId === barbaraId
                  );

                  if (isResidentUsedObject !== undefined) {
                    const newInventory = [...inventoryCorridor];
                    const newArrayUsedBy = [
                      ...newInventory[indexInventory].list[indexListObject].usedBy,
                    ];
                    const isAlreadyInUsedObject = newArrayUsedBy.find(
                      (residentId) => residentId === barbaraId
                    );
                    if (isAlreadyInUsedObject === undefined) {
                      newArrayUsedBy.push(barbaraId);
                    }
                    newInventory[indexInventory].list[indexListObject].usedBy =
                      newArrayUsedBy;
                    setInventoryCorridor(newInventory);
                  }
                })
              );
            }
            break;

          case carlesId:
            // eslint-disable-next-line no-param-reassign
            residentElement.isChecked = carlesObject.isChecked;
            if (carlesObject.isChecked) {
              inventoryCorridor.map((inventoryElement, indexInventory) =>
                // eslint-disable-next-line array-callback-return
                inventoryElement.list.map((listObject, indexListObject) => {
                  const isResidentUsedObject = listObject.goodResident.find(
                    (residentId) => residentId === carlesId
                  );

                  if (isResidentUsedObject !== undefined) {
                    const newInventory = [...inventoryCorridor];
                    const newArrayUsedBy = [
                      ...newInventory[indexInventory].list[indexListObject].usedBy,
                    ];
                    const isAlreadyInUsedObject = newArrayUsedBy.find(
                      (residentId) => residentId === carlesId
                    );
                    if (isAlreadyInUsedObject === undefined) {
                      newArrayUsedBy.push(carlesId);
                    }
                    newInventory[indexInventory].list[indexListObject].usedBy =
                      newArrayUsedBy;
                    setInventoryCorridor(newInventory);
                  }
                })
              );
            }
            break;

          case dorisId:
            // eslint-disable-next-line no-param-reassign
            residentElement.isChecked = dorisObject.isChecked;
            if (dorisObject.isChecked) {
              inventoryCorridor.map((inventoryElement, indexInventory) =>
                // eslint-disable-next-line array-callback-return
                inventoryElement.list.map((listObject, indexListObject) => {
                  const isResidentUsedObject = listObject.goodResident.find(
                    (residentId) => residentId === dorisId
                  );

                  if (isResidentUsedObject !== undefined) {
                    const newInventory = [...inventoryCorridor];
                    const newArrayUsedBy = [
                      ...newInventory[indexInventory].list[indexListObject].usedBy,
                    ];
                    const isAlreadyInUsedObject = newArrayUsedBy.find(
                      (residentId) => residentId === dorisId
                    );
                    if (isAlreadyInUsedObject === undefined) {
                      newArrayUsedBy.push(dorisId);
                    }
                    newInventory[indexInventory].list[indexListObject].usedBy =
                      newArrayUsedBy;
                    setInventoryCorridor(newInventory);
                  }
                })
              );
            }
            break;

          case emmaId:
            // eslint-disable-next-line no-param-reassign
            residentElement.isChecked = emmaObject.isChecked;
            if (emmaObject.isChecked) {
              inventoryCorridor.map((inventoryElement, indexInventory) =>
                // eslint-disable-next-line array-callback-return
                inventoryElement.list.map((listObject, indexListObject) => {
                  const isResidentUsedObject = listObject.goodResident.find(
                    (residentId) => residentId === emmaId
                  );

                  if (isResidentUsedObject !== undefined) {
                    const newInventory = [...inventoryCorridor];
                    const newArrayUsedBy = [
                      ...newInventory[indexInventory].list[indexListObject].usedBy,
                    ];
                    const isAlreadyInUsedObject = newArrayUsedBy.find(
                      (residentId) => residentId === emmaId
                    );
                    if (isAlreadyInUsedObject === undefined) {
                      newArrayUsedBy.push(emmaId);
                    }
                    newInventory[indexInventory].list[indexListObject].usedBy =
                      newArrayUsedBy;
                    setInventoryCorridor(newInventory);
                  }
                })
              );
            }
            break;

          default:
            break;
        }
      });

    setResidents(newResidents);
  };

  useInitGame(currentRoom, session.id, handleResidents);

  // COUNTER
  useEffect(() => {
    if (idSessionHasRoom) {
      axios.put(
        `${BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
        {
          count,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, currentRoom]);

  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary
  );

  const clickOnGame = () => {
    dispatch({ type: 'INCREMENT_COUNTER' });
  };

  useEffect(() => {
    dispatch({
      type: 'CLICKED_MOUSE_DOWN',
    });
    setTimeout(() => {
      dispatch({
        type: 'CLICKED_MOUSE_UP',
      });
    }, 1000);
  }, [dispatch, game.count]);

  const updateMousePosition = (ev) => {
    const offset = document
      .querySelector('#CorridorTMS-BKG')
      .getBoundingClientRect();
    dispatch({
      type: 'PLACE_CURSOR',
      payload: {
        x: ev.pageX - offset.left,
        y: ev.pageY - offset.top,
      },
    });
  };

  useEffect(() => {
    document
      .getElementById('CorridorTMS-BKG')
      .addEventListener('mouseup', updateMousePosition);
    return () => window.removeEventListener('mouseup', updateMousePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="CorridorTMS" id="CorridorTMS-BKG">
      <Audio sound={tmsTheme} loop id="TMS-Theme-Corridor" muted={!game.music} />
      <Audio sound={click} condition={!MouseClicked && game.soundtrack} />
      <div
        className={MouseClicked ? 'cursor-clicked' : 'Hide'}
        style={{ top: game.cursor.y, left: game.cursor.x }}
      >
        <p>+1</p>
      </div>
      {!isScoreBoard && (
        <header>
          <Inventory />
          <Steps />
          <Hud />
        </header>
      )}
      <div
        className="CorridorTMS-img-div"
        onClick={() => clickOnGame()}
        role="presentation"
      >
        <img
          className="CorridorTMS_background"
          src={urlRoom}
          alt="Fond TMS"
          draggable="false"
        />
      </div>
      {!isTutoFinish && (
        <Joyride
          callback={callback}
          steps={stepGame.steps}
          run={stepGame.run}
          stepIndex={stepGame.stepIndex}
          showSkipButton
          continuous
          disableCloseOnEsc
          disableOverlayClose
          hideBackButton
          hideCloseButton
          locale={{
            close: 'Fermer',
            last: 'Commencer',
            next: 'Suivant',
            skip: 'Passer',
          }}
          styles={{
            options: {
              overlayColor: 'rgba(0,0,0, 0.5)',
              arrowColor: '#fff',
            },
            buttonNext: {
              backgroundColor: '#4ed199',
              border: '1px solid #48c48e',
              padding: '15px 25px',
              fontWeight: '700',
            },
            buttonBack: {
              marginRight: 10,
              color: '#000',
              border: '1px solid #9fa2b4',
              backgroundColor: '#fff',
            },
            buttonLast: {
              marginRight: 20,
            },
            buttonSkip: {
              color: '#9fa2b4',
              fontWeight: '700',
            },
          }}
        />
      )}
      {modalIsOpen && <Modal />}
      {modalZoomIsOpen && <ModalZoom />}
      {zoomIsOpen && <Zoom />}
      {modalEnigmaIsOpen && (
        <ModalEnigma
          inventory={inventoryCorridor}
          setInventory={setInventoryCorridor}
          residents={residents}
          setResidents={setResidents}
        />
      )}
      {!isStarted && isTutoFinish && (
        <Intro translationFileName="tms" modalAfterIntro />
      )}
      {isScoreBoard && <Scoreboard />}
      {isAdvice && <Advice />}
      <ListObjects />
      <ChatBot />
    </div>
  );
}

const mapStateToProps = (state) => ({
  modalIsOpen: state.Modal.modal.isOpen,
  isFirstTime: state.Tms.dataGame.isStarted,
  currentRoom: state.Room.currentRoomId,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  startMessage: state.Steps.startMessage,
  currentStep: state.Steps.currentStep,
  startQuestionnary: state.Steps.startQuestionnary,
  zoomIsOpen: state.Modal.zoom.isOpen,
  modalZoomIsOpen: state.Modal.modalZoom.isOpen,
  isAdvice: state.Steps.isAdvice,
  count: state.GameUsers.count,
  modalEnigmaIsOpen: state.Modal.modalEnigma.isOpen,
  listRooms: state.Room.listRooms,
  game: state.GameUsers,
  MouseClicked: state.GameUsers.clicked,
  isScoreBoard: state.Steps.isScoreBoard,
  isTutoFinish: state.Steps.isTutoFinish,
  isStarted: state.GameUsers.gameStarted,
});

CorridorTMSCongres.propTypes = {
  MouseClicked: PropTypes.bool.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  currentRoom: PropTypes.number.isRequired,
  idSessionHasRoom: PropTypes.number,
  startMessage: PropTypes.number.isRequired,
  currentStep: PropTypes.shape({
    id: PropTypes.number,
  }),
  startQuestionnary: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  zoomIsOpen: PropTypes.bool.isRequired,
  modalZoomIsOpen: PropTypes.bool.isRequired,
  isAdvice: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  modalEnigmaIsOpen: PropTypes.bool.isRequired,
  listRooms: PropTypes.shape({
    CorridorTMS: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  game: PropTypes.shape({
    soundtrack: PropTypes.bool,
    music: PropTypes.bool,
    count: PropTypes.number,
    cursor: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }).isRequired,
  isScoreBoard: PropTypes.bool.isRequired,
  isTutoFinish: PropTypes.number.isRequired,
  isStarted: PropTypes.number.isRequired,
};

CorridorTMSCongres.defaultProps = {
  idSessionHasRoom: null,
  currentStep: null,
};

export default connect(mapStateToProps)(CorridorTMSCongres);
