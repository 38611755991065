import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';

// utils
import { retrieveQuestionnary } from 'utils/utilityFunctions';

// css
import 'assets/css/components/GameBoard/ReusableComponents/Steps.css';
import { selectSteps } from 'components/Reducers/Steps/Steps';

function Steps({
  steps,
  dispatch,
  currentStep,
  startQuestionnary,
  listRoom,
  gameUsers,
  gameId,
  userId,
  isAdvice,
  session,
  stepsLoading,
}) {
  const { messages } = useSelector(selectSteps);
  useEffect(() => {
    // when a user finish a room, we need to retrieve
    // if it's the last room and if all steps are found of all gmae
    const currentStepFinish = steps.filter((step) => !step.isFound);
    const indexLastStep = steps.length - 1;
    const lastStepsFinish = steps?.[indexLastStep]?.isFound;
    const lastRoomIndex = Object.keys(listRoom).length - 1;
    const isLastRoomActive =
      listRoom[Object.keys(listRoom)[lastRoomIndex]].isActive;
    const indexLastMessage = messages.length - 1;
    const finalMessageId = messages[indexLastMessage].id;

    if (
      currentStepFinish.length === 0 &&
      steps.length !== 0 &&
      startQuestionnary === 0 &&
      isLastRoomActive &&
      lastStepsFinish &&
      finalMessageId === steps[indexLastStep].id
    ) {
      if (!isAdvice && !stepsLoading) {
        dispatch({
          type: 'PAUSE_TIMER',
          payload: true,
        });
        const url = `${process.env.REACT_APP_BASE_URL}/api/scores/scoreboard`;
        const dataScoreboard = {
          score: gameUsers.gameScore.present,
          count: gameUsers.count,
          game_id: gameId,
          session_id: session.id,
          user_id: userId,
          pseudo: gameUsers.userDescription.firstname,
          time: gameUsers.time,
        };

        if (gameUsers.time !== '00:00:00' || gameUsers.time !== 0) {
          axios.post(url, dataScoreboard);
        }

        if (!isAdvice) {
          dispatch({
            type: 'START_SCOREBOARD',
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, startQuestionnary, gameUsers.time]);

  // START QUESTIONNARY OR PREVENTION MESSAGE //
  useEffect(() => {
    if (currentStep && currentStep.messages) {
      const step = steps.find((stepElement) => stepElement.id === currentStep.id);
      const isMessage = step.startMessage;
      const isQuestionnary = step.startQuestionnary;
      const { isFound } = step;

      if (isMessage && !isQuestionnary && !isFound) {
        dispatch({
          type: 'PAUSE_TIMER',
          payload: true,
        });
        dispatch({
          type: 'START_MESSAGE_PREVENTION',
        });
      }

      retrieveQuestionnary(currentStep.id, dispatch);

      /* IsMessage is set to 0 when isQuestionnary is set to 1 so the condition
         seems never filled
           TODO refacto */
      if (isMessage && isQuestionnary && !isFound) {
        retrieveQuestionnary(currentStep.id, dispatch, 'start');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, startQuestionnary]);
  // END START QUESTIONNARY OR PREVENTION MESSAGE //

  const handleImage = (description) => {
    if (description.isFound) {
      return description.imageInventory;
    }
    return description.imageNotFound;
  };

  const handleImageZoom = (description) => {
    if (description.isFound) {
      return description.imageFoundZoom;
    }
    return description.imageNotFound;
  };

  const openModal = (dataModal) => {
    dispatch({
      type: 'OPEN_MODAL',
      payload: dataModal,
    });
  };

  return (
    <div className="Steps">
      <div className="Steps-content">
        <p>Etapes</p>
        {steps.map((step) => {
          const dataModal = {
            image: handleImageZoom(step),
          };
          return (
            <button
              key={step.id}
              className="Steps-content-card"
              type="button"
              onClick={() => openModal(dataModal)}
            >
              <img src={handleImage(step)} alt={step.title} key={step.id} />
            </button>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  steps: state.Steps.list,
  currentRoom: state.Room.currentRoomId,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  currentStep: state.Steps.currentStep,
  questions: state.questionnary.list,
  startQuestionnary: state.Steps.startQuestionnary,
  listRoom: state.Room.listRooms,
  gameUsers: state.GameUsers,
  gameId: state.game.id,
  userId: state.GameUsers.userDescription.id,
  isAdvice: state.Steps.isAdvice,
  timerRoom: state.GameUsers.time,
  session: state.session,
  stepsLoading: state.Steps.loading,
});

Steps.propTypes = {
  stepsLoading: PropTypes.bool.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      isFound: PropTypes.number,
    })
  ).isRequired,
  currentStep: PropTypes.shape({
    isFound: PropTypes.number,
    messages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
    id: PropTypes.number,
  }),
  dispatch: PropTypes.func.isRequired,
  startQuestionnary: PropTypes.number.isRequired,
  listRoom: PropTypes.shape({}).isRequired,
  gameUsers: PropTypes.shape({
    gameScore: PropTypes.objectOf(PropTypes.number),
    count: PropTypes.number,
    time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    userDescription: PropTypes.shape({
      firstname: PropTypes.string,
    }),
  }).isRequired,
  gameId: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  isAdvice: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

Steps.defaultProps = {
  currentStep: null,
};

export default connect(mapStateToProps)(Steps);
