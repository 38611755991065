import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

/**
 * Get all responses of a question
 * @param {number} questionId
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const getResponses = async (questionId) => {
  const { data } = await axios.get(
    `${baseURL}/api/question/${questionId}/responses`
  );
  return data;
};

/**
 * Create an answer
 * @param {Object} answer Contain the data of the response of the user
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const createResponse = async (answer) => {
  const { data } = await axios.post(`${baseURL}/api/response`, {
    title: answer.text,
  });
  return data;
};

/**
 * Get all questions of a questionnary (alias messages)
 * @param {number} messageId
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const getQuestions = async (messageId) => {
  const { data } = await axios.get(
    `${baseURL}/api/messages/${messageId}/questions`
  );

  data.questions
    .sort((firstQuestion, nextQuestion) => firstQuestion.level - nextQuestion.level)
    .map(async (question) => {
      const responses = await getResponses(question.id, data.questions);
      const newQuestion = question;
      newQuestion.responses = responses;

      return question;
    });
  return data;
};

/**
 * Link an User, a survey and a session because before the user answers a
 * questionnary we need to link it to the user via DB UserHasSurvey
 * @param {number} userId
 * @param {number} surveyId
 * @param {number} sessionId
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const createLinkBetweenUserSurveySession = async (userId, surveyId, sessionId) => {
  const { data } = await axios.post(`${baseURL}/api/userSurvey`, {
    date: new Date().toISOString().slice(0, 19).replace('T', ' '),
    users_id: userId,
    survey_id: surveyId,
    sessions_id: sessionId,
  });
  return data;
};

/**
 * Link an UserHasSurvey and question and response because after the user answers a
 * questionnary we need to link the session / the user / the survey / the response
 * / the question ...
 * @param {number} userId
 * @param {number} surveyId
 * @param {number} sessionId
 * @param {string} isNew
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const createLinkResponseBetweenUserHasSurvey = async (
  userHasSurveyId,
  responseUser,
  questionId,
  isNew
) => {
  const { data } = await axios.post(`${baseURL}/api/usersHasSurveyHasResponse`, {
    date: new Date().toISOString().slice(0, 19).replace('T', ' '),
    users_has_survey_id: userHasSurveyId,
    response_id: isNew ? responseUser.response_id : responseUser.id,
    question_id: questionId,
    weighting: responseUser.weighting,
  });
  return data;
};

export {
  getQuestions,
  getResponses,
  createResponse,
  createLinkBetweenUserSurveySession,
  createLinkResponseBetweenUserHasSurvey,
};
