const fetchMessage = (
  state = {
    allMessages: [],
    message: { title: '', message: '', show: false },
  },
  action
) => {
  switch (action.type) {
    // REFACTO page 404
    case 'MESSAGE_NOTIF':
      return {
        ...state,
        message: {
          ...state.message,
          show: action.payload,
        },
      };
    // REFACTO page 404
    case 'FETCH_MESSAGE':
      return {
        ...state,
        allMessages: action.messages,
      };
    // REFACTO page 404
    case 'SHOW_MESSAGE':
      return {
        ...state,
        message: {
          title: action.payload.title,
          message: action.payload.message,
          show: action.payload.show,
        },
      };
    default:
      return state;
  }
};
export default fetchMessage;
