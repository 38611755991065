import { connect, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

// css
import 'assets/css/components/GameBoard/ReusableComponents/Advice.css';

// assets
import trickyLogo from 'assets/images/tricky_conseil.svg';

// selector functions
import { selectSession } from 'components/Reducers/session';

// components
import Button from './Actions/Button';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Advice({ dispatch, userId, gameId, advice }) {
  const [totalOfWeighting, setTotalOfWeighting] = useState();
  const [maxWeighting, setMaxWeithing] = useState(0);
  const [numberTotalOfResponse, setNumberTotalOfResponse] = useState(0);

  const session = useSelector(selectSession);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/games/${gameId}`)
      .then((response) => response.data)
      .then((data) => setMaxWeithing(data.max_weighting));
    axios
      .get(`${BASE_URL}/api/userSurvey/users/${userId}/sessions/${session.id}`)
      .then((response) => response.data)
      .then((data) => {
        let arraySurvey = [];
        const arrayTotalResponse = [];
        data.map((survey, index) => {
          arraySurvey = [...data];
          return axios
            .get(`${BASE_URL}/api/usersHasSurveyHasResponse/${survey.id}/responses`)
            .then((response2) => response2.data)
            .then((data2) => {
              const arrayResponseDimension = [];
              const arrayTotalOfWeighting = [];

              data2.map((responseElement) =>
                arrayResponseDimension.push(responseElement.weighting)
              );
              const sumAverage = (arrayResponse) => {
                arrayResponse.reduce((previous, current) => previous + current, 0);
              };

              const calculateAverage = (arrayResponse) =>
                sumAverage(arrayResponse) / arrayResponse.length;

              const averageResponsesDimension = calculateAverage(
                arrayResponseDimension
              );
              arraySurvey[index].averageResponses = averageResponsesDimension;
              arrayTotalResponse.push(...data2);

              arrayTotalResponse.map((response) =>
                arrayTotalOfWeighting.push(response.weighting)
              );
              setNumberTotalOfResponse(arrayTotalResponse.length);
              const averageResponsesTotal = calculateAverage(arrayTotalOfWeighting);

              const calculateSum = (accumulator, curr) => accumulator + curr;
              setTotalOfWeighting(arrayTotalOfWeighting.reduce(calculateSum));

              axios
                .get(`${BASE_URL}/api/games/${gameId}/references`)
                .then((response) => response.data)
                .then((data3) => {
                  const referenceMinimum = data3.filter(
                    (reference) => reference.type === 'minimum'
                  )[0].reference_general;
                  const referenceMaximum = data3.filter(
                    (reference) => reference.type === 'maximum'
                  )[0].reference_general;

                  let firstNumber;
                  if (averageResponsesTotal < referenceMinimum) {
                    firstNumber = 3;
                  } else if (
                    referenceMinimum < averageResponsesTotal &&
                    averageResponsesTotal < referenceMaximum
                  ) {
                    firstNumber = 2;
                  } else if (averageResponsesTotal >= referenceMaximum) {
                    firstNumber = 1;
                  }

                  arraySurvey.map((surveyElement, index2) => {
                    if (
                      surveyElement.averageResponses <
                      surveyElement.reference_dimension
                    ) {
                      arraySurvey[index2] = 2;
                    } else if (
                      surveyElement.averageResponses >=
                      surveyElement.reference_dimension
                    ) {
                      arraySurvey[index2] = 1;
                    }

                    return arraySurvey;
                  });

                  const suitCode = arraySurvey.join('');
                  const code = `${firstNumber}${suitCode}`;
                  axios
                    .get(`${BASE_URL}/api/games/${gameId}/advices/${code}`)
                    .then((response) => response.data[0])
                    .then((data4) => {
                      dispatch({
                        type: 'ADD_ADVICE',
                        payload: data4,
                      });
                    });
                });
            });
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePercentage = () => {
    const hesitationScore = Math.round(
      (totalOfWeighting / (maxWeighting * numberTotalOfResponse)) * 100
    );
    return `${hesitationScore} %`;
  };

  const finishGame = () => {
    // CALL API TO FINISH GAME
    dispatch({
      type: 'STOP_ADVICE',
    });
    if (gameId === 145) {
      dispatch({
        type: 'VACC_SCENES',
        payload: {
          scenes: 'conclusionLouis',
          status: true,
        },
      });
    } else {
      window.open(process.env.REACT_APP_BASE_URL_DJANGO, '_self');
    }
  };

  return (
    <div className="Advice">
      {advice && (
        <div className="Advice_content">
          <div className="Advice_content_header">
            <img src={trickyLogo} alt="logo" />
          </div>
          <div className="Advice_content_advice">
            <h1>
              {handlePercentage()} {advice.name}
            </h1>
            <p>{advice.message}</p>
          </div>
          <img
            className="Advice_content_arrow"
            alt="flèche en bas scroll"
            src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTDE%2Fassets%2Fobjects%2Farrow_back_ios_white_24dp.svg?alt=media&token=4592e2ed-2de3-4d69-892b-d1b39d130cf6"
          />
        </div>
      )}
      <Button
        onClick={() => finishGame()}
        className={gameId === 145 && 'ButtonAction VaccAction'} // we need a reusable button for the future -> could use buttonType and define a specific style for it (see <IntroMessage /> in <InterpretationMachineCalibration />)
        title="Terminer"
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  userId: state.user.id,
  gameId: state.game.id,
  advice: state.questionnary.advice,
});

Advice.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  gameId: PropTypes.number.isRequired,
  advice: PropTypes.shape({
    message: PropTypes.string,
    name: PropTypes.string,
  }),
};

Advice.defaultProps = {
  advice: undefined,
};

export default connect(mapStateToProps)(Advice);
