import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { flip, tada } from 'react-animations';
import '../Modals.css';
import { addObject } from '../../../Action/index';

const FlipAnimation = keyframes`${flip}`;
const TadaAnimation = keyframes`${tada}`;
const FlipDiv = styled.img`
  animation: 3s ${FlipAnimation};
`;

const TadaImg = styled.div`
  animation: infinite 3s ${TadaAnimation};
`;

function ObjectItem({ id, name, image, Objects, dispatch }) {
  const handleClick = () => {
    if (id === 67) {
      dispatch({
        type: 'MODAL_404',
        payload: {
          modalType: 'modalRefillPaper',
          showModal: true,
        },
      });
    }
    return null;
  };

  const AttachPaper = () => {
    dispatch({
      type: 'REMOVE_OBJECT',
      payload: {
        nameObject: 'Papier déchiré',
      },
    });
    dispatch(
      addObject({
        name: Objects.AllObjects.filter((item) => item.id === 67).map(
          (item) => item.name
        )[0],
        image: Objects.AllObjects.filter((item) => item.id === 67).map(
          (item) => item.image
        )[0],
        id: 67,
      })
    );
    dispatch({
      type: 'MODAL_404',
      payload: {
        modalType: 'modalRefillPaper',
        showModal: true,
      },
    });
  };

  const condition =
    Objects.object
      .filter((item) => item.id === 65)
      .map((item) => item.id === 65)[0] &&
    Objects.object
      .filter((item) => item.id === 66)
      .map((item) => item.id === 66)[0] &&
    id === 65;

  return (
    <div className="ObjectList">
      <div className="Object-square">
        {image && (
          <FlipDiv
            src={image}
            alt="mug test"
            onClick={() => handleClick()}
            className="Object-Image"
          />
        )}
      </div>
      <div className="Object-name">
        <p>{name}</p>
        {condition && (
          <TadaImg>
            <button
              className="ButtonAction404 Action404"
              type="button"
              onClick={AttachPaper}
            >
              Rattacher
            </button>
          </TadaImg>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Objects: state.Objects,
});

ObjectItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  Objects: PropTypes.shape({
    ClockClicked: PropTypes.bool.isRequired,
    MugClicked: PropTypes.bool.isRequired,
    PhoneNumber: PropTypes.bool.isRequired,
    LetterOpen: PropTypes.bool.isRequired,
    LetterTear: PropTypes.bool.isRequired,
    LetterClicked: PropTypes.bool.isRequired,
    Modal: PropTypes.shape({
      ModalPowerchord: PropTypes.bool.isRequired,
      ModalMug: PropTypes.bool.isRequired,
      ModalLetter: PropTypes.bool.isRequired,
      ModalKnife: PropTypes.bool.isRequired,
      ModalOpenLetter: PropTypes.bool.isRequired,
      ModalClock: PropTypes.bool.isRequired,
      ModalLetterAnim: PropTypes.bool.isRequired,
    }),
    AllObjects: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
    object: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(ObjectItem);
