import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import '../../../../assets/css/components/GameBoard/RPS/Enigma/HelpDoc.css';
import '../../ReusableComponents/Inventory';
import startedMessage from 'components/constants';

function HelpDoc({
  dispatch,
  allObjects,
  DocNumber,
  placeObject,
  HandleDrop,
  progression,
  idSessionHasRoom,
  objectContent,
}) {
  const allowDrop = (e) => {
    e.preventDefault();
  };

  const handleClick = (key) => {
    if (placeObject[key].object) {
      dispatch({
        type: 'SHOW_ITEM',
        payload: placeObject[key].object,
      });
      const newPlaceObject = placeObject;
      newPlaceObject[key].goodPlacementObject = false;
      newPlaceObject[key].object = null;
      return 0;
    }
    return 0;
  };

  useEffect(() => {
    if (progression === 100) {
      const collectObject = allObjects.find(
        (objectElement) => objectElement.id === DocNumber
      );

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
          {
            current_step: collectObject.id_message,
            start_message: startedMessage,
          }
        )
        .then(() => {
          dispatch({
            type: 'CLOSE_MODAL_ENIGMA',
          });
          dispatch({
            type: 'CURRENT_STEP',
            payload: collectObject.id_message,
          });
          dispatch({
            type: 'START_MESSAGE_PREVENTION',
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progression]);
  const { height, left } = objectContent;

  const listDoctor = [
    {
      id: 255,
      index: 0,
      height: `${Number(height.substring(0, height.length - 1))}%`,
      left: `${Number(left.substring(0, left.length - 1)) - 36}%`,
      content: {
        name: 'Jean',
        description: `J’ai l'impression de faire des erreurs dans tout ce que j’entreprend, je suis vraiment bon à rien de toute façon.`,
      },
    },
    {
      id: 256,
      index: 2,
      height: `${Number(height.substring(0, height.length - 1)) - 27}%`,
      left: `${Number(left.substring(0, left.length - 1)) - 65}%`,
      content: {
        name: 'Christophe',
        description: `Depuis peu j’ai des douleurs au ventre qui ne cessent d’augmenter quand je suis au travail. Je pense que ça vient du stress mais je ne vois pas comment arranger ça.`,
      },
    },
    {
      id: 257,
      index: 4,
      height: `${Number(height.substring(0, height.length - 1)) - 18}%`,
      left: `${Number(left.substring(0, left.length - 1)) - 69.5}%`,
      content: {
        name: 'Pauline',
        description: `Ça fait quelques jours que je pars plus tôt du travail, je sais que ce n’est pas raisonnable mais ma motivation est au plus bas. Je n’arrive pas à rester jusqu’à 19h.`,
      },
    },
    {
      id: 258,
      index: 6,
      height: `${Number(height.substring(0, height.length - 1)) - 19}%`,
      left: `${Number(left.substring(0, left.length - 1)) - 50}%`,
      content: {
        name: 'Nathalie',
        description: `Ces derniers temps je fais tout à cent à l’heure, pourtant j’ai l’impression d’être de moins en moins efficace. En plus, rester pour faire tout ça m’empêche de vivre ma vie perso`,
      },
    },
  ];

  const doctor = listDoctor.find((content) => content.id === DocNumber);

  return (
    <div
      className={`HelpDoc${doctor.index}`}
      style={{
        top: `${doctor.height}`,
        left: `${doctor.left}`,
      }}
    >
      <div className={`HelpDoc${doctor.index}_text`}>
        Bonjour, je m’appelle {doctor.content.name}.
        <br />
        {doctor.content.description}
      </div>
      <div className="SideBoxBubbleSpeechRPS">
        <div className="ObjectsNeeded">Objets attendus :</div>
        <div className="boxContainerRPS">
          <div
            className="BoxObject"
            onDrop={
              !placeObject[doctor.index].goodPlacementObject &&
              ((e) => HandleDrop(e, doctor.index))
            }
            onDragOver={(e) => allowDrop(e)}
          >
            {placeObject[doctor.index].object && (
              <>
                <img
                  className="image-HelpDoc"
                  alt=""
                  src={
                    allObjects[
                      allObjects.findIndex(
                        (obj) => obj.id === Number(placeObject[doctor.index].object)
                      )
                    ].images.find((img) => img.type === 'inventory').image
                  }
                />
                {!placeObject[doctor.index].goodPlacementObject && (
                  <button
                    className="close"
                    type="button"
                    onClick={() => handleClick(placeObject[doctor.index].key)}
                    aria-label="Retrieve placed item"
                  />
                )}
              </>
            )}
          </div>

          <div
            className="BoxObject"
            onDrop={
              !placeObject[doctor.index + 1].goodPlacementObject &&
              ((e) => HandleDrop(e, doctor.index + 1))
            }
            onDragOver={(e) => allowDrop(e)}
          >
            {placeObject[doctor.index + 1].object && (
              <>
                <img
                  className="image-HelpDoc"
                  alt=""
                  src={
                    allObjects[
                      allObjects.findIndex(
                        (obj) =>
                          obj.id === Number(placeObject[doctor.index + 1].object)
                      )
                    ].images.find((img) => img.type === 'inventory').image
                  }
                />
                {!placeObject[doctor.index + 1].goodPlacementObject && (
                  <button
                    className="close"
                    type="button"
                    onClick={() => handleClick(placeObject[doctor.index + 1].key)}
                    aria-label="Retrieve placed item"
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  allObjects: state.Objects.AllObjects,
  Items: state.Inventory.list.id,
  progression: state.RPS.dataGame.progression,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
});

HelpDoc.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  placeObject: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      object: PropTypes.objectOf({
        id: PropTypes.number,
      }),
      goodPlacementObject: PropTypes.bool,
    })
  ).isRequired,
  HandleDrop: PropTypes.func.isRequired,
  DocNumber: PropTypes.number.isRequired,
  progression: PropTypes.number.isRequired,
  idSessionHasRoom: PropTypes.number,
  objectContent: PropTypes.shape({
    height: PropTypes.string,
    left: PropTypes.string,
  }),
};

HelpDoc.defaultProps = {
  idSessionHasRoom: null,
  objectContent: null,
};
export default connect(mapStateToProps)(HelpDoc);
