import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';

// selector
import { selectSession } from 'components/Reducers/session';
import { selectInfoGame } from 'components/Reducers/game';

// components
import GameEmotionMulti from 'components/GameBoard/Emotion/Multi/GameEmotionMulti';
import WaitingRoomParticipants from 'components/GameBoard/ReusableComponents/Multi/CheckRoom/WaitingRoomParticipants';
import CurrentParticipant from 'components/GameBoard/ReusableComponents/Multi/CheckRoom/CurrentParticipant';

const baseURL = process.env.REACT_APP_BASE_URL;

function GameMulti() {
  const dispatch = useDispatch();

  const [statusCheckRoom, setStatusCheckRoom] = useState('current-participant');

  const session = useSelector(selectSession);
  const game = useSelector(selectInfoGame);

  useEffect(() => {
    if (game.type === 'multi') {
      const socket = io.connect(baseURL);
      socket.on('connect', () => {
        dispatch({
          type: 'INIT_SOCKET',
          payload: socket,
        });
      });
      const room = session.id;
      if (room) {
        socket.emit('join_room', room);
      }
    }
  }, [dispatch, game.type, session.id]);

  // Launch the checkRoom with current partcipant
  if (statusCheckRoom === 'current-participant') {
    return <CurrentParticipant setStatusCheckRoom={setStatusCheckRoom} />;
  }

  // Launch the checkRoom with all participants
  if (
    statusCheckRoom === 'all-participants' ||
    statusCheckRoom === 'introduction'
  ) {
    return (
      <WaitingRoomParticipants
        setStatusCheckRoom={setStatusCheckRoom}
        statusCheckRoom={statusCheckRoom}
      />
    );
  }

  // Launch the correct game
  if (statusCheckRoom === 'finish') {
    switch (game.id) {
      case 198:
        return <GameEmotionMulti />;
      default:
        return <p>The game id doesn&apos;t refer to any component game</p>;
    }
  }
}

export default GameMulti;
