import { useState } from 'react';

import ChatWindow from 'components/GameBoard/ReusableComponents/ChatBot/ChatWindow';

import './ChatBot.css';

function ChatBot() {
  const [isChatWindow, setIsChatWindow] = useState(false);

  const handleClick = () => {
    setIsChatWindow(!isChatWindow);
  };
  return (
    <div className="ChatBot">
      {isChatWindow && <ChatWindow />}
      <button className="ChatBot-img" type="button" onClick={() => handleClick()}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FCommun%2FCB1-casque.svg?alt=media&token=104a5f7b-7c57-41c7-8814-cb7b388a2c9a"
          alt="Icon chat bot"
        />
      </button>
    </div>
  );
}

export default ChatBot;
