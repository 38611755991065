import styled, { keyframes } from 'styled-components';
import * as imageNext from '../../img/vaccination-solo/PuckLab';

const rotateAnimation = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
`;

const ImageDefault = styled.img`
  margin: 20px;
  width: 50%;
`;

const Coin = styled.img`
  margin: 20px;
  width: 50%;
  animation-name: ${rotateAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

const StepsRPS = [
  {
    target: '.WelcomeRPS_background',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Tutoriel
        </h2>
        <p>
          Pour commencer la journée, vous allez devoir montrer à Aline comment
          réguler son stress lors de l’accueil et la prise en charge des patients
          aux urgences.
        </p>
      </div>
    ),
    placement: 'center',
  },
  {
    target: '.Steps',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Cartes émotions
        </h2>
        <p>Tu peux les collecter</p>
      </div>
    ),
    placement: 'left',
  },
  {
    target: '.Hud-Item-Text-undefined',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Temps
        </h2>
        <p>Chaque minute compte</p>
        <ImageDefault src={imageNext.time} alt="Tricky" />
        <p>
          Obtenez un meilleur score en finissant l&apos;expérience le plus vite
          possible
        </p>
      </div>
    ),
    placement: 'left',
  },
  {
    target: '.Hud-Item-Text-clics',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Coups
        </h2>
        <p>Le nombre de coups est comptabilisé</p>
        <ImageDefault src={imageNext.cursor} alt="Tricky" />{' '}
        <p>
          Obtenez un meilleur score en finissant l&apos;expérience avec le moins de
          coup possible
        </p>
      </div>
    ),
    placement: 'bottom',
  },
  {
    target: '.Hud-Item-Text-pts',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Points
        </h2>
        <p>Gagnez des points en finissant les jeux de chaque étape</p>
        <Coin src={imageNext.coinScore} alt="Tricky" />{' '}
      </div>
    ),
    placement: 'bottom',
  },
  {
    target: '.Hud-Item-Text-sound',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Musique
        </h2>
        <p>Activez ou désactivez la musique d&apos;ambiance ici.</p>
        <ImageDefault src={imageNext.notMuteIcon} alt="Tricky" />{' '}
      </div>
    ),
    placement: 'bottom',
  },

  {
    target: '.InterfaceGame_button-information',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Informations
        </h2>
        <p>
          Pour vous aider à trier les patients, on vous a remis un document
          expliquant toutes les démarches à suivre
        </p>
      </div>
    ),
    placement: 'bottom',
  },
  {
    target: '.numberPatient',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          File d&apos;attente
        </h2>
        <p>Vos patients arrivent au fur et à mesure </p>
      </div>
    ),
    placement: 'bottom',
  },
  {
    target: '.InterfaceGame_actions-contents-left-top',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Patient
        </h2>
        <p>
          Toutes les infos présentes dans le document se retrouvent dans le dossier
          du patient. A vous de comparer les valeurs pour savoir comment prendre en
          charge le patient
        </p>
      </div>
    ),
    placement: 'right',
  },

  {
    target: '.InterfaceGame_actions-contents-bottom',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Prise en charge
        </h2>
        <p>
          Choisissez le meilleur moyen de prise en charge du patient en fonction du
          niveau d&apos;urgence
        </p>
      </div>
    ),
    placement: 'left',
  },

  {
    target: '.InterfaceGame_actions-contents-right',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Barre de stress
        </h2>
        <p>Le but du jeu est de la maintenir au plus bas.</p>
        <p>
          Mais attention, si vous vous trompez sur un patient ou que la file
          d&apos;attente est trop importante vous risquez de la faire augmenter.
        </p>
      </div>
    ),
    placement: 'left',
  },
  {
    target: '.InterfaceGame_actions-helps',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Aides
        </h2>
        <p>
          Si vous êtes bloqué ou que votre barre de stress est proche de son
          maximum, essayez les boutons en haut de l&apos;écran.
        </p>
        <p>
          <strong>Aides</strong> vous fera passer directement au patient suivant et{' '}
          <strong>Repos</strong> fera diminuer votre barre de stress
        </p>
      </div>
    ),
    placement: 'left',
  },
  {
    target: '.WelcomeRPS_background',
    content: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space evenly',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: 700,
            color: '#000',
          }}
        >
          Bonne chance
        </h2>
        <p>Une dernière petite information...</p>
        <p>
          Regardez bien l&apos;horloge. Le temps avance et à midi ça sera la pause
          déjeuner.
        </p>
        <p style={{ fontWeight: 'bold' }}>Maintenant, à vous de jouer !</p>
      </div>
    ),
    placement: 'center',
  },
];

export default StepsRPS;
