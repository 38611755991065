const electricalPanelState = {
  // buttons: ['green', 'red', 'blue', 'yellow'],
  // solution: [],
  // step: 1,
  // tryArray: [],
  // error: false,
  // introduction: true,
  // demo: false,
  win: false,
  // mute: false,
  // clickSound1: false,
  // clickSound2: false,
  // timer: 0,
  // errors: 0,
  // score: 0,
};

export default electricalPanelState;
