// CoLoveDuty
import * as images from '../../../img/vaccination-solo/index';

const coLoveDutyState = {
  score: 0,
  timer: null,
  gameStarted: 0,
  hasWon: false,
  rotation: 0,
  CoLoveFrame: [
    { id: 1, image: images.tile1, rotation: 4, isActive: false },
    { id: 2, image: images.tile2, rotation: 3 },
    { id: 3, image: images.tile3, rotation: 3 },
    { id: 4, image: images.tile4, rotation: 4 },
    { id: 5, image: images.tile5, rotation: 3 },
    { id: 6, image: images.tile6, rotation: 2 },
    { id: 7, image: images.tile7, rotation: 2 },
    { id: 8, image: images.tile8, rotation: 4 },
    { id: 9, image: images.tile9, rotation: 3, isActive: false },
    { id: 10, image: images.tile10, rotation: 2 },
    { id: 11, image: images.tile11, rotation: 3, isActive: false },
    { id: 12, image: images.tile12, rotation: 4 },
    { id: 13, image: images.tile13, rotation: 2 },
    { id: 14, image: images.tile14, rotation: 2, isActive: false },
    { id: 15, image: images.tile15, rotation: 3 },
  ],
};

export default coLoveDutyState;

// for fast testing
// CoLoveFrame: [
//   { id: 1, image: images.tile1, rotation: 4, isActive: false },
//   { id: 2, image: images.tile2, rotation: 3 },
//   { id: 3, image: images.tile3, rotation: 3 },
//   { id: 4, image: images.tile4, rotation: 4 },
//   { id: 5, image: images.tile5, rotation: 3 },
//   { id: 6, image: images.tile6, rotation: 2 },
//   { id: 7, image: images.tile7, rotation: 2 },
//   { id: 8, image: images.tile8, rotation: 4 },
//   { id: 9, image: images.tile9, rotation: 3, isActive: false },
//   { id: 10, image: images.tile10, rotation: 2 },
//   { id: 11, image: images.tile11, rotation: 3, isActive: false },
//   { id: 12, image: images.tile12, rotation: 4 },
//   { id: 13, image: images.tile13, rotation: 2 },
//   { id: 14, image: images.tile14, rotation: 2, isActive: false },
//   { id: 15, image: images.tile15, rotation: 3 },
// ],

// for TEST
// CoLoveFrame: [
//   { id: 1, image: images.tile1, rotation: 1, isActive: true },
//   { id: 2, image: images.tile2, rotation: 1 },
//   { id: 3, image: images.tile3, rotation: 1 },
//   { id: 4, image: images.tile4, rotation: 1 },
//   { id: 5, image: images.tile5, rotation: 1 },
//   { id: 6, image: images.tile6, rotation: 1 },
//   { id: 7, image: images.tile7, rotation: 1 },
//   { id: 8, image: images.tile8, rotation: 1 },
//   { id: 9, image: images.tile9, rotation: 1, isActive: true },
//   { id: 10, image: images.tile10, rotation: 1 },
//   { id: 11, image: images.tile11, rotation: 1, isActive: true },
//   { id: 12, image: images.tile12, rotation: 1 },
//   { id: 13, image: images.tile13, rotation: 1 },
//   { id: 14, image: images.tile14, rotation: 1, isActive: true },
//   { id: 15, image: images.tile15, rotation: 1 },
// ],
