/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
// Constants
import { stickStatus } from 'components/GameBoard/Emotion/Solo/constants';
// CSS
import 'assets/css/components/GameBoard/ReusableComponents/Objects/ObjectContent.css';
// Functions
import { selectStickObjects } from 'components/Reducers/GameData/fetchObjects';
import { putSessionsHasRoomHasObject } from 'API/sessionsHasRoomHasObject';

import stickClick from 'sound/switch20.ogg';
import wrongStickClick from 'sound/car-brake-release.mp3';
import emoticonClick from 'sound/Vaccination/PuckLab/Wooden Select 2.mp3';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const idLightObject = 148;
const idCodeLightObject = 149;
const idLampObject = 147;
const idLampInRoom = 217;
const idHatch = 176;
const idPlant = 179;
const idPlantInventory = 180;
const idRobot = 189;
const idRobotTwo = 199;
const idRobotThree = 205;
const idLockerOne = 206;
const idLockerOneOpen = 208;
const idLockerTwo = 212;
const idLockerTwoOpen = 214;
const idRecapTms = 215;
const idTabletTMS = 230;
const idLocker4 = 235;
const idLocker3 = 234;
const idDiary = 182;
const idDesk = 146;

function ObjectContent({
  dispatch,
  object,
  idSessionHasRoom,
  objects,
  type,
  modal,
  specificHandleClick,
  handleImageUrl,
  soundtrack,
}) {
  const objectLight = objects.find(
    (objectContent) => objectContent.id === idLightObject
  );
  const recapTmsObject = objects.filter(
    (objectElement) => objectElement.id === idRecapTms
  );
  const locker3Object = objects.filter(
    (objectElement) => objectElement.id === idLocker3
  );
  const locker4Object = objects.filter(
    (objectElement) => objectElement.id === idLocker4
  );

  const diaryObject = objects.filter(
    (objectElement) => objectElement.id === idDiary
  );

  // Emotion game
  const stickObjects = useSelector(selectStickObjects);
  const stickEnigma = stickObjects.find((el) => el.type === 'enigma')?.id;
  const wrongSticks = stickObjects.filter((el) => el.type === 'modal');

  // Selected emoticon
  const selectedEmoticon = objects.find(
    (objectElement) =>
      objectElement.name.includes('emoticon') && objectElement.status === 'selected'
  );

  const handleImageBackground = (description) => {
    let image;

    if (description && description.images) {
      const objectIndex = description.images.findIndex(
        (objectContent) => objectContent.type === 'background'
      );

      let objectIndexNight;
      if (objectLight && objectLight.status === 'open') {
        objectIndexNight = description.images.findIndex(
          (objectContent) => objectContent.type === 'night'
        );
      }

      image =
        description.images[objectIndexNight > 0 ? objectIndexNight : objectIndex]
          ?.image;
    }

    if (
      description &&
      description.images &&
      description.id === idLockerTwoOpen &&
      recapTmsObject[0].isInventory
    ) {
      const objectIndex = description.images.findIndex(
        (objectContent) => objectContent.type === 'checked'
      );
      image = description.images[objectIndex].image;
    }

    if (
      description &&
      description.images &&
      description.id === idDesk &&
      diaryObject[0].isInventory
    ) {
      let objectIndex;

      if (objectLight.status === 'open') {
        objectIndex = description.images.findIndex(
          (objectContent) => objectContent.type === 'empty-background'
        );
      } else {
        objectIndex = description.images.findIndex(
          (objectContent) => objectContent.type === 'empty-day-background'
        );
      }

      image = description.images[objectIndex].image;
    }

    // Emotion game
    // When the object is a stick and launch an enigma when clicked
    if (description?.images && description.id === stickEnigma) {
      let objectIndex;
      if (description.status === stickStatus[1]) {
        objectIndex = description.images?.findIndex(
          (objectContent) => objectContent.type === stickStatus[1]
        );
      } else {
        objectIndex = description.images?.findIndex(
          (objectContent) => objectContent.type === stickStatus[0]
        );
      }
      image = description.images?.[objectIndex].image;
    }
    // Url for a selected/unselected emoticon
    if (description?.images && description.name?.includes('emoticon')) {
      let objectIndex;
      if (description.status === 'selected') {
        objectIndex = description.images?.findIndex(
          (objectContent) => objectContent.type === 'selected'
        );
      } else {
        objectIndex = description.images?.findIndex(
          (objectContent) => objectContent.type === 'background'
        );
      }
      image = description.images?.[objectIndex].image;
    }

    return image;
  };

  const changeStatusOfObject = async (objectElement) => {
    // Index of objectElement in objects
    const objectIndex = objects.findIndex(
      (objectContent) => objectContent.id === objectElement.id
    );

    if (idLightObject === objectElement.id) {
      //  TODO seems not useful as we do the same than above with objectIndex
      const objectLightIndex = objects.findIndex(
        (objectContent) => objectContent.id === idLightObject
      );

      axios
        .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
          object_id: objectElement.id,
          isClicked:
            objectElement.isClicked !== undefined ? objectElement.isClicked + 1 : 1,
          status: 'open',
        })
        .then(() => {
          dispatch({
            type: 'CLICKED_OBJECT',
            payload: {
              index: objectLightIndex,
              isClicked:
                objectElement.isClicked !== undefined
                  ? objectElement.isClicked + 1
                  : 1,
              type: 'clicked',
              status: 'open',
            },
          });
          dispatch({
            type: 'COUNT_MOVE',
          });
        });
      // Emotion game - stick
    } else if (objectElement.id === stickEnigma) {
      const modification = {
        object_id: objectElement.id,
        isClicked: objectElement.isClicked ? objectElement.isClicked + 1 : 1,
      };
      /* Save `isClicked` in DB. Don't save `status` in DB otherwise when the
       stick is down and the page is refreshed, the stick will be down but no enigma will be displayed */
      await putSessionsHasRoomHasObject(idSessionHasRoom, modification);
      // Save in the store
      dispatch({
        type: 'CLICKED_OBJECT',
        payload: {
          index: objectIndex,
          isClicked: objectElement.isClicked ? objectElement.isClicked + 1 : 1,
          type: 'clicked',
          status:
            !objectElement.status || objectElement.status === stickStatus[0]
              ? stickStatus[1]
              : stickStatus[0],
        },
      });

      //  Emotion game - emoticons
    } else if (objectElement.name?.includes('emoticon')) {
      const modification = {
        object_id: objectElement.id,
        isClicked: objectElement.isClicked ? objectElement.isClicked + 1 : 1,
      };
      /* Save `isClicked` in DB. Don't save `status` in DB otherwise when the
       stick is down and the page is refreshed, the stick will be down but no enigma will be displayed */
      await putSessionsHasRoomHasObject(idSessionHasRoom, modification);
      // Save in the store
      dispatch({
        type: 'CLICKED_OBJECT',
        payload: {
          index: objectIndex,
          isClicked: objectElement.isClicked ? objectElement.isClicked + 1 : 1,
          type: 'clicked',
          status:
            !objectElement.status || objectElement.status === 'background'
              ? 'selected'
              : 'background',
        },
      });
    } else {
      axios
        .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
          object_id: objectElement.id,
          isClicked:
            objectElement.isClicked !== undefined ? objectElement.isClicked + 1 : 1,
        })
        .then(() =>
          dispatch({
            type: 'CLICKED_OBJECT',
            payload: {
              index: objectIndex,
              isClicked:
                objectElement.isClicked !== undefined
                  ? objectElement.isClicked + 1
                  : 1,
              type: 'clicked',
            },
          })
        );
    }
  };

  const handleImage = (description) => {
    let image;
    if (description && description.images) {
      const objectIndex = description.images.findIndex(
        (objectElement) => objectElement.type === 'clicked'
      );
      let objectIndexNight;
      if (objectLight && objectLight.status === 'open') {
        objectIndexNight = description.images.findIndex(
          (objectContent) => objectContent.type === 'clicked-night'
        );
      }

      image =
        description.images[objectIndexNight > 0 ? objectIndexNight : objectIndex]
          .image;
    }

    return image;
  };

  const handleMessage = (description) => {
    let message;
    if (description.images) {
      const objectIndex = description.images.findIndex(
        (objectElement) => objectElement.type === 'clicked'
      );

      message = description.images[objectIndex].message;
    }

    return message;
  };

  const addToInventory = (objectElement) => {
    const objectIndex = objects.findIndex(
      (objectContent) => objectContent.id === objectElement.id
    );

    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: objectElement.id,
        isInventory: true,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index: objectIndex,
            isInventory: true,
            type: 'inventory',
          },
        });
        dispatch({
          type: 'ADD_INVENTORY',
          payload: objectElement,
        });
        dispatch({
          type: 'UPDATE_GAME_SCORE',
          payload: 100,
        });
        dispatch({
          type: 'COUNT_MOVE',
        });
        dispatch({
          type: 'CLOSE_MODAL',
        });
      });

    // EXCEPTION REMOVE TO INVENTORY LAMP
    const lampObjectIndex = objects.findIndex(
      (objectContent) => objectContent.id === idLampObject
    );
    const lampObject = objects.filter(
      (objectContent) => objectContent.id === idLampObject
    )[0];
    if (objectElement.id === idCodeLightObject) {
      axios
        .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
          object_id: lampObject.id,
          isChecked: true,
        })
        .then(() => {
          dispatch({
            type: 'CLICKED_OBJECT',
            payload: {
              index: lampObjectIndex,
              isChecked: true,
              type: 'checked',
            },
          });
          dispatch({
            type: 'REMOVE_OF_INVENTORY',
            payload: lampObject.id,
          });
        });
    }
  };

  const openModal = (objectElement, index) => {
    changeStatusOfObject(objectElement, index);

    const descriptionModal = {};
    descriptionModal.message = handleMessage(objectElement);
    descriptionModal.image = handleImage(objectElement);

    const objectIndex = objects.findIndex(
      (objectContent) => objectContent.id === objectElement.id
    );

    if (!objectElement.wrongobject) {
      descriptionModal.buttonDescription = {
        title: 'Inventaire',
        onClick: () => addToInventory(objectElement, objectIndex),
      };
    }

    if (type === 'zoom') {
      descriptionModal.objectContent = objectElement;
      dispatch({
        type: 'OPEN_MODAL_ZOOM',
        payload: descriptionModal,
      });
    } else {
      dispatch({
        type: 'OPEN_MODAL',
        payload: descriptionModal,
      });
    }

    dispatch({
      type: 'COUNT_MOVE',
    });
  };

  const openModalRobot = (objectElement, index) => {
    changeStatusOfObject(objectElement, index);
    const descriptionModal = {
      type: objectElement,
    };

    dispatch({
      type: 'OPEN_MODAL_ROBOT',
      payload: descriptionModal,
    });

    dispatch({
      type: 'COUNT_MOVE',
    });
  };

  const openModalEnigma = (objectElement) => {
    changeStatusOfObject(objectElement);
    const descriptionModal = {};
    descriptionModal.content = objectElement;
    dispatch({
      type: 'OPEN_MODAL_ENIGMA',
      payload: descriptionModal,
    });
  };

  const openModalEnigmaEmotion = (objectElement) => {
    changeStatusOfObject(objectElement);
    const descriptionModal = {};
    descriptionModal.content = objectElement;
    dispatch({
      type: 'OPEN_MODAL_ENIGMA_EMOTION',
      payload: descriptionModal,
    });
  };

  const closeModalEnigmaEmotion = (objectElement) => {
    changeStatusOfObject(objectElement);
    dispatch({
      type: 'CLOSE_MODAL_ENIGMA_EMOTION',
    });
  };

  const openZoom = (objectElement) => {
    changeStatusOfObject(objectElement);
    axios
      .get(`${BASE_URL}/api/objects/${objectElement.id}/objects`)
      .then((response) => response.data)
      .then((data) => {
        const listOfObjects = [];
        data.map((objectContent) =>
          objects.filter(
            (objectContent2) =>
              objectContent2.id === objectContent.id &&
              listOfObjects.push(objectContent2)
          )
        );
        const descriptionModal = {};
        descriptionModal.image = handleImage(objectElement);
        descriptionModal.listObjects = listOfObjects;
        descriptionModal.type = 'zoom';

        if (idLightObject !== objectElement.id) {
          dispatch({
            type: 'OPEN_ZOOM',
            payload: descriptionModal,
          });
        }

        dispatch({
          type: 'COUNT_MOVE',
        });
      });
  };

  const lampObject = objects.filter(
    (objectElement) => objectElement.id === idLampObject
  );
  const hatchObject = objects.filter(
    (objectElement) => objectElement.id === idHatch
  );
  const plantObject = objects.filter(
    (objectElement) => objectElement.id === idPlantInventory
  );

  const handleClick = async () => {
    if (
      object.id !== idHatch &&
      object.id !== idCodeLightObject &&
      object.id !== idLightObject &&
      // Emotion game -stick
      object.id !== stickEnigma &&
      !wrongSticks.find((el) => el.id === object.id) &&
      // Emotion game - emoticons
      !object.name?.includes('emoticon')
    ) {
      if (object.type === 'zoom') {
        openZoom(object);
      } else if (object.type === 'robot') {
        openModalRobot(object);
      } else if (object.type === 'enigma' || object.type === 'backgroundEnigma') {
        openModalEnigma(object);
      } else {
        openModal(object);
      }
    } else {
      if (object.id === idCodeLightObject) {
        if (lampObject[0].status === 'open') {
          if (object.type === 'zoom') {
            openZoom(object);
          } else {
            openModal(object);
          }
        }
      }
      if (object.id === idLightObject) {
        const objectElement = objects.find(
          (objectContent) => objectContent.id === idLightObject
        );
        const objectLightIndex = objects.findIndex(
          (objectContent) => objectContent.id === idLightObject
        );

        axios
          .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
            object_id: objectElement.id,
            status: object.status === 'open' ? 'closed' : 'open',
          })
          .then(() =>
            dispatch({
              type: 'CLICKED_OBJECT',
              payload: {
                index: objectLightIndex,
                type: 'clicked',
                status: object.status === 'open' ? 'closed' : 'open',
              },
            })
          );
      }

      if (object.id === idHatch) {
        if (hatchObject[0].status === 'open') {
          if (object.type === 'zoom') {
            openZoom(object);
          } else {
            openModal(object);
          }
        }
      }

      // Emotion game - stick
      if (object.id === stickEnigma) {
        if (!object.status || object.status === stickStatus[0]) {
          openModalEnigmaEmotion(object);
        } else {
          closeModalEnigmaEmotion(object);
        }
        if (soundtrack) new Audio(stickClick).play();
      }
      if (wrongSticks.find((el) => el.id === object.id)) {
        if (soundtrack) new Audio(wrongStickClick).play();
        openModal(object);
      }
      // Emotion game - emoticons
      if (object.name?.includes('emoticon')) {
        if (!object.status || object.status === 'background') {
          openModalEnigmaEmotion(object);
          // if another emoticon has already its status `selected`, change its status
          if (selectedEmoticon) await changeStatusOfObject(selectedEmoticon);
          if (soundtrack) new Audio(emoticonClick).play();
        } else {
          // Don't close the modal otherwise it will close all the panels
          changeStatusOfObject(object);
        }
      }
    }
  };

  const handleClassname = () => {
    const objectSwithOn = objects.find(
      (objectContent) => objectContent.id === idLightObject
    );
    const tabletTms = objects.find(
      (objectElement) => objectElement.id === idTabletTMS
    );

    const objectSwitchLampOn = objects.find(
      (objectContent) => objectContent.id === idLampObject
    );
    const robots = [];
    robots.push(objects.find((objectContent) => objectContent.id === idRobot));
    robots.push(objects.find((objectContent) => objectContent.id === idRobotTwo));
    robots.push(objects.find((objectContent) => objectContent.id === idRobotThree));
    if (object.id === idCodeLightObject) {
      if (objectSwithOn.status === 'open' && objectSwitchLampOn.status === 'open') {
        return '--isDisplayCode';
      }
      return '--isNotDisplay';
    }
    if (object.id === idHatch) {
      if (hatchObject[0].status === 'open') {
        return '--isDisplay';
      }
      return '--isNotDisplay';
    }

    if (object.id === idTabletTMS) {
      if (tabletTms.status === 'open') {
        return '--isDisplay Vibrating';
      }
      return '--isDisplay';
    }

    if (object.id === idLocker3) {
      if (locker3Object[0].status === 'open') {
        return '--isDisplay';
      }
      return '--isNotDisplay';
    }
    if (object.id === idLocker4) {
      if (locker4Object[0].status === 'open') {
        return '--isDisplay';
      }
      return '--isNotDisplay';
    }
    if (object.id === idPlant) {
      if (plantObject[0].isInventory) {
        return '--isNotDisplay';
      }
      return '--isDisplay';
    }

    if (object.id === idLampInRoom) {
      if (lampObject[0].isInventory) {
        return '--isNotDisplay';
      }
      return '--isDisplay';
    }
    if (
      object.id === idRobot ||
      object.id === idRobotTwo ||
      object.id === idRobotThree
    ) {
      const conditionRobot = modal.modal.isOpen || modal.modalRobot.isOpen;
      const newRobots = robots.filter((item) => !item.isChecked);
      const max = Math.max(...newRobots.map((item) => Number(item.zindex)));
      const index = newRobots.map((item) => Number(item.zindex)).indexOf(max);
      const greatestRobot = newRobots[index];
      if (conditionRobot || object.isChecked || object.id !== greatestRobot.id) {
        return '--isNotDisplay';
      }
      return '--isDisplay bounceRobot';
    }

    const robotElement = objects.find(
      (robotContent) => robotContent.id === idRobot
    );
    const robotTwoElement = objects.find(
      (robotTwoContent) => robotTwoContent.id === idRobotTwo
    );

    if (
      (object.id === idRobotTwo && object.isChecked) ||
      (object.id === idRobotTwo && !robotElement.isChecked)
    ) {
      return '--isNotDisplay';
    }

    if (
      (object.id === idRobotThree && object.isChecked) ||
      (object.id === idRobotThree && !robotTwoElement.isChecked) ||
      (object.id === idRobotThree && !robotElement.isChecked)
    ) {
      return '--isNotDisplay';
    }

    if (object.id === idLockerOne && object.status === 'open') {
      return '--isNotDisplay';
    }

    if (object.id === idLockerTwo && object.status === 'open') {
      return '--isNotDisplay';
    }

    const objectLockerOne = objects.find(
      (objectContent) => objectContent.id === idLockerOne
    );
    if (object.id === idLockerOneOpen && objectLockerOne.status !== 'open') {
      return '--isNotDisplay';
    }

    const objectLockerTwo = objects.find(
      (objectContent) => objectContent.id === idLockerTwo
    );

    if (object.id === idLockerTwoOpen && objectLockerTwo.status !== 'open') {
      return '--isNotDisplay';
    }

    return '--isDisplay';
  };

  return (
    <img
      src={handleImageUrl ? handleImageUrl(object) : handleImageBackground(object)}
      className={`ObjectContent${handleClassname()} ${object.id}`}
      alt={object.slug}
      draggable="false"
      onClick={(event) =>
        specificHandleClick ? specificHandleClick(event) : handleClick()
      }
      style={{
        zIndex: object.zindex !== null ? object.zindex : '',
        height: object.height !== null ? object.height : '',
        width: object.width !== null ? object.width : '',
        left: object.left !== null ? object.left : '',
        right: object.right !== null ? object.right : '',
        bottom: object.bottom !== null ? object.bottom : '',
        top: object.top !== null ? object.top : '',
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  objects: state.Objects.AllObjects,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  soundtrack: state.GameUsers.soundtrack,
  modal: state.Modal,
});

ObjectContent.propTypes = {
  objects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  idSessionHasRoom: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  modal: PropTypes.shape().isRequired,
  object: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    zindex: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
    top: PropTypes.string,
    isChecked: PropTypes.number,
    status: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  type: PropTypes.string,
  specificHandleClick: PropTypes.func,
  handleImageUrl: PropTypes.func,
  soundtrack: PropTypes.bool.isRequired,
};

ObjectContent.defaultProps = {
  idSessionHasRoom: null,
  type: undefined,
  specificHandleClick: undefined,
  handleImageUrl: undefined,
};

export default connect(mapStateToProps)(ObjectContent);
