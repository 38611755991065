// REFACTO vaccination
import puckLabState from '../../../utils/Vaccination/PuckLab/puckLabState';
import martinSaysState from '../../../utils/Vaccination/PuckLab/martinSaysState';
import dailySeekerState from '../../../utils/Vaccination/PuckLab/dailySeekerState';
import * as constant from '../../../utils/Vaccination/PuckLab/constant';
import * as images from '../../../img/vaccination-solo/PuckLab/index';
import pluggedFloppyBlue from '../../../img/vaccination-solo/floppies/blue.png';
import pluggedFloppyOrange from '../../../img/vaccination-solo/floppies/orange.png';
import pluggedFloppyPurple from '../../../img/vaccination-solo/floppies/purple.png';
import electricalPanelState from '../../../utils/Vaccination/PuckLab/electricalPanelState';
import coLoveDutyState from '../../../utils/Vaccination/PuckLab/coLoveDutyState';

const initialState = {
  ModalWrongObjectVacc: {
    status: false,
    title: '',
    message: '',
    img: '',
  },
  Actions: {
    trash: {
      isChecked: false,
    },
    trickyCoin: {
      isChecked: false,
      isClicked: false,
    },
    key: {
      isChecked: false,
      isClicked: false,
    },
    chest: {
      isChecked: false,
      isClicked: false,
    },
    floppyDisks: {
      isChecked: false,
      isClicked: false,
    },
    puckLab: {
      isChecked: false,
      isClicked: false,
    },
    electricPanel: {
      isChecked: false,
      isClicked: false,
    },
    martinSays: {
      isChecked: false,
      isClicked: false,
    },
    dailySeeker: {
      isChecked: false,
      isClicked: false,
    },
    coloveduty: {
      isChecked: false,
      isClicked: false,
    },
  },
  ModalFloppyGame: {
    status: false,
    datas: {},
    selected: false,
  },
  ModalActionVacc: {
    status: false,
    message: '',
    img: '',
    actionName: '',
    actionFunc: '',
    noReturn: false,
  },
  ModalInventoryVacc: {
    status: false,
    message: '',
    img: '',
  },
  ModalZoomDesk: {
    status: false,
  },
  ModalsGame: {
    ModalDispenser: false,
    ModalChest: false,
    ModalElectric: false,
    ModalFloppy: false,
    ModalChooseFloppy: false,
    ModalMartinSays: false,
    ModalCoLoveDuty: false,
    ModalDailySeeker: false,
    ModalScore: false,
    ModalPuckLab: false,
  },
  objects: [],
  objectsInInventory: [],
  Floppies: [],
  FloppySelected: [],
  choosenFloppy: [],
  Scenes: {
    Introduction: false,
    ChooseFloppy: false,
    InterludePuckLab: false,
    InterludeElectricPanel: false,
    InterludeSimon: false,
    InterludeDailySeeker: false,
    InterludeColoveDuty: false,
    finishFloppy: false,
    finishPuckLab: false,
    finishDailySeeker: false,
    finishSimon: false,
    finishColoveDuty: false,
    conclusionIa: false,
    conclusionLouis: false,
    scoreboard: false,
  },
  Score: {
    ModalScore: {
      title: 'JEU TERMINÉ',
      description: '',
      score: 0,
      maxScore: 0,
      game: '',
      status: false,
    },
    puckLabScore: constant.puckLabScore,
    martinSaysScore: constant.martinSaysScore,
    coLoveDutyScore: constant.coLoveDutyScore,
    dailySeekerScore: constant.dailySeekerScore,
    finalScore: constant.VaccinationFinalScore,
  },
  Games: {
    PuckLab: puckLabState,
    MartinSays: martinSaysState,
    ElectricalPanel: electricalPanelState,
    CoLoveDuty: coLoveDutyState,
    DailySeeker: dailySeekerState,
  },
};

const Vaccination = (state = initialState, action) => {
  const filterFloppies = (object) => {
    const ids = [125, 129, 130, 131, 132, 133];
    if (ids.includes(object.id)) {
      return object;
    }
    return null;
  };
  const setFloppyPlugged = (floppy) => {
    if (floppy === 129) {
      return pluggedFloppyBlue;
    }
    if (floppy === 131) {
      return pluggedFloppyOrange;
    }
    if (floppy === 133) {
      return pluggedFloppyPurple;
    }
    return null;
  };

  // PuckLab function
  const TimeScore = () => {
    switch (action.payload.minute) {
      case 0:
        return constant.SCORE_POINT * 3;
      case 1:
        return constant.SCORE_POINT * 2;
      case 2:
        return constant.SCORE_POINT;
      case 3:
        return constant.SCORE_POINT / 2;
      default:
        return 0;
    }
  };

  const removeLife = () => {
    const lifeRemaining = state.Games.PuckLab.Life.filter((el) => el.isActive).map(
      (e) => e.id
    );
    const indexLifeToRemove = Math.max(...lifeRemaining);
    return state.Games.PuckLab.Life.map((el) => {
      if (el.id === indexLifeToRemove) {
        return {
          ...el,
          isActive: false,
        };
      }
      return el;
    });
  };

  const isLoosingLife = (life) => {
    if (life) {
      const lifeRemaining = state.Games.PuckLab.Life.filter(
        (el) => el.isActive
      ).map((e) => e.id);
      const indexLifeToRemove = Math.max(...lifeRemaining);
      return state.Games.PuckLab.Life.map((el) => {
        if (el.id === indexLifeToRemove) {
          return {
            ...el,
            isBeingLost: true,
          };
        }
        return el;
      });
    }

    const LifeToRemoveFlash = state.Games.PuckLab.Life.filter(
      (el) => el.isBeingLost
    ).map((e) => e.id)[0];
    return state.Games.PuckLab.Life.map((el) => {
      if (el.id === LifeToRemoveFlash) {
        return {
          ...el,
          isBeingLost: false,
        };
      }
      return el;
    });
  };

  const playerMoved = (direction) => {
    const row = state.Games.PuckLab.player.position[0];
    const column = state.Games.PuckLab.player.position[1];
    let newPositionMaze;
    let newPositionCss;

    switch (direction) {
      case 'DOWN': {
        if (state.Games.PuckLab.maze[row + 1][column].passable) {
          newPositionMaze = [
            state.Games.PuckLab.player.position[0] + 1,
            state.Games.PuckLab.player.position[1],
          ];
          newPositionCss = state.Games.PuckLab.player.top + constant.SIZE_TILE;
          return {
            ...state.Games.PuckLab.player,
            top: newPositionCss,
            position: newPositionMaze,
            move: !state.Games.PuckLab.player.move,
            direction: 'DOWN',
            joystick: images.joystickDown,
          };
        }
        return {
          ...state.Games.PuckLab.player,
          joystick: images.joystickDown,
        };
      }
      case 'UP': {
        if (state.Games.PuckLab.maze[row - 1][column].passable) {
          newPositionMaze = [
            state.Games.PuckLab.player.position[0] - 1,
            state.Games.PuckLab.player.position[1],
          ];
          newPositionCss = state.Games.PuckLab.player.top - constant.SIZE_TILE;
          return {
            ...state.Games.PuckLab.player,
            top: newPositionCss,
            position: newPositionMaze,
            move: !state.Games.PuckLab.player.move,
            direction: 'UP',
            joystick: images.joystickUp,
          };
        }
        return {
          ...state.Games.PuckLab.player,
          joystick: images.joystickUp,
        };
      }
      case 'LEFT': {
        if (state.Games.PuckLab.maze[row][column - 1].passable) {
          newPositionMaze = [
            state.Games.PuckLab.player.position[0],
            state.Games.PuckLab.player.position[1] - 1,
          ];
          newPositionCss = state.Games.PuckLab.player.left - constant.SIZE_TILE;
          return {
            ...state.Games.PuckLab.player,
            left: newPositionCss,
            position: newPositionMaze,
            scale: 1,
            move: !state.Games.PuckLab.player.move,
            direction: 'LEFT',
            joystick: images.joystickLeft,
          };
        }
        return {
          ...state.Games.PuckLab.player,
          joystick: images.joystickLeft,
        };
      }
      case 'RIGHT': {
        if (state.Games.PuckLab.maze[row][column + 1].passable) {
          newPositionMaze = [
            state.Games.PuckLab.player.position[0],
            state.Games.PuckLab.player.position[1] + 1,
          ];
          newPositionCss = state.Games.PuckLab.player.left + constant.SIZE_TILE;
          return {
            ...state.Games.PuckLab.player,
            left: newPositionCss,
            position: newPositionMaze,
            scale: -1,
            move: !state.Games.PuckLab.player.move,
            direction: 'RIGHT',
            joystick: images.joystickRight,
          };
        }
        return {
          ...state.Games.PuckLab.player,
          joystick: images.joystickRight,
        };
      }
      default:
        return {
          ...state.Games.PuckLab.player,
        };
    }
  };

  switch (action.type) {
    case 'MODAL_WRONGOBJECTS_VACC': {
      return {
        ...state,
        ModalWrongObjectVacc: {
          status: action.payload.status,
          title: action.payload.title || '',
          message: action.payload.message || '',
          img: action.payload.img || '',
        },
      };
    }
    case 'MODAL_ACTION_VACC': {
      return {
        ...state,
        ModalActionVacc: {
          status: action.payload.status,
          title: action.payload.title || '',
          message: action.payload.message || '',
          img: action.payload.img || '',
          actionName: action.payload.actionName || '',
          actionFunc: action.payload.actionFunc || '',
          noReturn: action.payload.noReturn || false,
        },
      };
    }
    case 'MODAL_ZOOM_VACC': {
      return {
        ...state,
        ModalZoomVacc: {
          status: action.payload.status,
          message: action.payload.message || '',
          img: action.payload.img || '',
        },
      };
    }
    case 'MODAL_INVENTORY_VACC': {
      return {
        ...state,
        ModalInventoryVacc: {
          status: action.payload.status,
          message: action.payload.message || '',
          img: action.payload.img || '',
        },
      };
    }
    case 'VACC_ACTION': {
      const whichObject = action.payload.object;
      const whichAction = action.payload.action;
      return {
        ...state,
        Actions: {
          ...state.Actions,
          [whichObject]: {
            ...state.Actions[whichObject],
            [whichAction]: action.payload.status,
          },
        },
      };
    }
    case 'VACC_MODALS': {
      const whichModal = action.payload.modal;
      return {
        ...state,
        ModalsGame: {
          ...state.ModalsGame,
          [whichModal]: action.payload.status,
        },
      };
    }
    case 'VACC_SCENES': {
      const whichScene = action.payload.scenes;
      return {
        ...state,
        Scenes: {
          ...state.Scenes,
          [whichScene]: action.payload.status,
        },
      };
    }
    case 'MODAL_ZOOMDESK_VACC': {
      return {
        ...state,
        ModalZoomDesk: {
          status: action.payload.status,
        },
      };
    }
    case 'MODAL_FLOPPYGAME': {
      return {
        ...state,
        ModalFloppyGame: {
          status: action.payload.status,
          datas: action.payload.datas,
          selected: action.payload.selected,
        },
      };
    }
    case 'FETCH_OBJECT_VACC': {
      return {
        ...state,
        objects: action.payload,
        Floppies: action.payload.filter((object) => filterFloppies(object)),
      };
    }
    case 'ADD_INVENTORY_VACC': {
      return {
        ...state,
        objectsInInventory: [...state.objectsInInventory, ...action.payload],
      };
    }
    case 'REMOVE_INVENTORY_VACC': {
      return {
        ...state,
        objectsInInventory: state.objectsInInventory.filter(
          (item) => item.id !== action.payload
        ),
      };
    }
    case 'CLEAR_INVENTORY_VACC': {
      return {
        ...state,
        objectsInInventory: [],
      };
    }
    case 'PLUG_FLOPPY': {
      return {
        ...state,
        FloppyPlugged: [...state.FloppyPlugged, action.payload],
      };
    }
    case 'SELECT_FLOPPY': {
      return {
        ...state,
        Floppies: state.Floppies.filter((item) => item !== action.payload),
        FloppySelected: [...state.FloppySelected, action.payload],
      };
    }
    case 'UNSELECT_FLOPPIES': {
      return {
        ...state,
        Floppies: [...state.Floppies, ...state.FloppySelected],
        FloppySelected: [],
      };
    }
    case 'SELECT_FLOPPIES_SUCCESS': {
      return {
        ...state,
        Floppies: [],
        FloppySelected: [],
        Scenes: {
          ...state.Scenes,
          ChooseFloppy: true,
        },
        ModalsGame: {
          ...state.ModalsGame,
          ModalFloppy: false,
        },
      };
    }
    case 'CHOOSE_FLOPPY': {
      return {
        ...state,
        choosenFloppy: [action.payload, setFloppyPlugged(action.payload.id)],
      };
    }
    // PuckLab
    case 'GAME_START':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            gameStarted: action.payload,
          },
        },
      };
    case 'MUTE_SOUND': {
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            mute: !state.Games.PuckLab.mute,
          },
        },
      };
    }
    case 'TIMER_SCORE': {
      const LifeScore = () => {
        switch (state.Games.PuckLab.gameLife) {
          case 0:
            return 0;
          case 1:
            return constant.SCORE_POINT;
          case 2:
            return constant.SCORE_POINT * 2;
          case 3:
            return constant.SCORE_POINT * 3;
          case 4:
            return constant.SCORE_POINT * 4;
          case 5:
            return constant.SCORE_POINT * 5;
          default:
            return undefined;
        }
      };

      const gameOverScore = () => {
        switch (state.Games.PuckLab.gameOverCount) {
          case 0:
            return constant.SCORE_POINT;
          case 1:
            return constant.SCORE_POINT / 2;
          case 2:
            return 0;
          case 3:
            return -(constant.SCORE_POINT / 2);
          default:
            return undefined;
        }
      };

      const calculateScore = () => {
        let finalScore;
        const scorePoint = TimeScore();
        const LifePoint = LifeScore();
        const gameOverPoint = gameOverScore();
        const calculScore =
          scorePoint + LifePoint + gameOverPoint + constant.SCORE_POINT * 3;
        if (calculScore === 0) {
          finalScore = 50;
        } else {
          finalScore = calculScore;
        }
        return finalScore;
      };
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            timer: `${action.payload.minute}:${action.payload.second}`,
            score: calculateScore(),
          },
        },
      };
    }
    case 'MODAL_SCORE': {
      return {
        ...state,
        Score: {
          ...state.Score,
          ModalScore: {
            ...state.Score.ModalScore,
            title: action.payload.title,
            description: action.payload.description,
            score: action.payload.score,
            game: action.payload.game,
            status: action.payload.status,
            maxScore: action.payload.maxScore,
          },
        },
      };
    }
    case 'SCORE_GUI': {
      const whichScore = action.payload.game;
      return {
        ...state,
        Score: {
          ...state.Score,
          [whichScore]: state.Score[whichScore].map((el) => {
            if (el.score <= action.payload.score) {
              return {
                ...el,
                isActive: true,
              };
            }
            return el;
          }),
        },
      };
    }
    case 'UPDATE_GAME_LIFE':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            gameLife: action.payload,
          },
        },
      };
    case 'NEXT_STEP':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            startStep: action.payload,
          },
        },
      };
    case 'IS_READY':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            ready: action.payload,
          },
        },
      };
    case 'SELECT_SOUND':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            selectSound: action.payload,
          },
        },
      };
    case 'MOVE_PLAYER':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            player: playerMoved(action.payload.direction),
          },
        },
      };
    case 'RESET_JOYSTICK':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            player: {
              ...state.Games.PuckLab.player,
              joystick: images.joystickIdle,
            },
          },
        },
      };
    case 'WALK_PLAYER':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            player: {
              ...state.Games.PuckLab.player,
              skin: action.payload,
            },
          },
        },
      };
    case 'DISACTIVE_MONSTER': {
      const { whichCovid } = action.payload;
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            Covid: {
              ...state.Games.PuckLab.Covid,
              [whichCovid]: {
                ...state.Games.PuckLab.Covid[whichCovid],
                isActive: false,
              },
            },
          },
        },
      };
    }
    case 'MOVE_MONSTER': {
      const { whichCovid } = action.payload;
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            Covid: {
              ...state.Games.PuckLab.Covid,
              [whichCovid]: {
                ...state.Games.PuckLab.Covid[whichCovid],
                position: action.payload.position,
                top: action.payload.top,
                left: action.payload.left,
                lastmove: action.payload.lastmove,
                scale: action.payload.scale,
                lastMoveDirection: action.payload.lastMoveDirection,
              },
            },
          },
        },
      };
    }
    case 'HAS_WON':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            hasWon: action.payload,
          },
        },
      };
    case 'IS_COLLECTING_VIRUS': {
      const whichVirus = action.payload.name;
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            virusSound: true,
            Virus: {
              ...state.Games.PuckLab.Virus,
              [whichVirus]: {
                ...state.Games.PuckLab.Virus[whichVirus],
                isBeingCollected: true,
              },
            },
            collectedVirus: state.Games.PuckLab.collectedVirus.map((el) => {
              if (el.id === action.payload.index) {
                return {
                  ...el,
                  isBeingCollected: true,
                };
              }
              return el;
            }),
          },
        },
      };
    }
    case 'COLLECT_VIRUS': {
      const whichVirus = action.payload;
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            virusSound: false,
            Virus: {
              ...state.Games.PuckLab.Virus,
              [whichVirus]: {
                ...state.Games.PuckLab.Virus[whichVirus],
                isCollected: true,
                isBeingCollected: false,
              },
            },
          },
        },
      };
    }
    case 'VIRUS_COLLECTED': {
      const whichVirus = action.payload.name;
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            collectedVirus: state.Games.PuckLab.collectedVirus.map((el) => {
              if (el.id === action.payload.index) {
                return {
                  ...el,
                  isGood: state.Games.PuckLab.Virus[whichVirus].isGood,
                  image: state.Games.PuckLab.Virus[whichVirus].image,
                  isCollected: true,
                  isBeingCollected: false,
                };
              }
              return el;
            }),
          },
        },
      };
    }
    case 'IS_COLLECTING_LIFE': {
      const whichLife = action.payload.name;
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            winLifeSound: true,
            LifeInGame: {
              ...state.Games.PuckLab.LifeInGame,
              [whichLife]: {
                ...state.Games.PuckLab.LifeInGame[whichLife],
                isBeingCollected: true,
              },
            },
            Life: state.Games.PuckLab.Life.map((el) => {
              if (el.id === action.payload.index) {
                return {
                  ...el,
                  isBeingCollected: true,
                };
              }
              return el;
            }),
          },
        },
      };
    }
    case 'COLLECT_LIFE': {
      const whichLife = action.payload.name;
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            winLifeSound: false,
            LifeInGame: {
              ...state.Games.PuckLab.LifeInGame,
              [whichLife]: {
                ...state.Games.PuckLab.LifeInGame[whichLife],
                isCollected: true,
                isBeingCollected: false,
              },
            },
          },
        },
      };
    }
    case 'LIFE_COLLECTED': {
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            winLifeSound: false,
            Life: state.Games.PuckLab.Life.map((el) => {
              if (el.id === action.payload.index) {
                return {
                  ...el,
                  isBeingCollected: false,
                  isActive: true,
                };
              }
              return el;
            }),
          },
        },
      };
    }
    case 'ALREADY_COLLECTED':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            alreadyCollected: !state.Games.PuckLab.alreadyCollected,
          },
        },
      };
    case 'GAME_OVER':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            gameOver: true,
          },
        },
      };
    case 'GAME_OVER_COUNT':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            gameOverCount: action.payload,
          },
        },
      };
    case 'RESET_PLAYER_POSITION':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            player: {
              ...state.Games.PuckLab.player,
              step: constant.SIZE_TILE,
              top: constant.PLAYER_TOP,
              left: constant.PLAYER_LEFT,
              scale: -1,
              position: [constant.PLAYER_ROW, constant.PLAYER_COLUMN],
            },
          },
        },
      };
    case 'INVISIBLE':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            invisible: action.payload,
            lostLifeSound: false,
            Life: isLoosingLife(action.payload),
          },
        },
      };
    case 'LOST_LIFE':
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: {
            ...state.Games.PuckLab,
            gameLife: state.Games.PuckLab.gameLife - 1,
            lostLifeSound: true,
            Life: removeLife(),
          },
        },
      };
    case 'RESET_GAME': {
      const getRightLife = (Life) => {
        switch (Life) {
          case 0:
            return 3;
          case 1:
            return 2;
          case 2:
            return 1;
          default:
            return 3;
        }
      };
      const getAmountOfLife = (Life) => {
        switch (Life) {
          case 0:
            return puckLabState.Life;
          case 1:
            return constant.gameOverLife2;
          case 2:
            return constant.gameOverLife;
          default:
            return puckLabState.Life;
        }
      };
      const gameOverInitial = {
        ...puckLabState,
        gameOverCount: state.Games.PuckLab.gameOverCount,
        gameStarted: 1,
        gameOver: false,
        ready: true,
        score: state.Games.PuckLab.score,
        gameLife: getRightLife(state.Games.PuckLab.gameOverCount),
        Life: getAmountOfLife(state.Games.PuckLab.gameOverCount),
        LifeInGame: state.Games.PuckLab.LifeInGame,
        Covid: state.Games.PuckLab.Covid,
      };
      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: gameOverInitial,
        },
      };
    }
    case 'RESTART_GAME': {
      const restartGame = {
        ...puckLabState,
        gameOverCount: state.Games.PuckLab.gameOverCount,
        gameLife: state.Games.PuckLab.gameLife,
        Life: state.Games.PuckLab.Life,
        Covid: state.Games.PuckLab.Covid,
        Virus: state.Games.PuckLab.Virus,
        collectedVirus: state.Games.PuckLab.collectedVirus,
        hasWon: false,
        gameStarted: 1,
        ready: true,
      };

      return {
        ...state,
        Games: {
          ...state.Games,
          PuckLab: restartGame,
        },
      };
    }
    // MartinSays & DailySeeker
    case 'GAME_ACTION_VACC': {
      const whichAction = action.payload.action;
      const whichGame = action.payload.game;
      return {
        ...state,
        Games: {
          ...state.Games,
          [whichGame]: {
            ...state.Games[whichGame],
            [whichAction]: action.payload.status,
          },
        },
      };
    }
    // CoLoveDuty
    case 'START_COLOVE': {
      return {
        ...state,
        Games: {
          ...state.Games,
          CoLoveDuty: {
            ...state.Games.CoLoveDuty,
            gameStarted: 1,
          },
        },
      };
    }
    case 'TIMER_SCORE_COLOVE': {
      const RotationPoint = () => {
        const { rotation } = state.Games.CoLoveDuty;
        switch (true) {
          case rotation > 60:
            return 0;
          case rotation <= 60 && rotation > 48:
            return constant.SCORE_POINT;
          case rotation <= 48 && rotation > 38:
            return constant.SCORE_POINT * 2;
          case rotation <= 38 && rotation >= 28:
            return constant.SCORE_POINT * 3;
          default:
            return 300;
        }
      };
      const coLoveScore = () => {
        let finalScore;
        const timePoint = TimeScore();
        const rotationPoint = RotationPoint();
        const calculScore = timePoint + rotationPoint;
        if (calculScore === 0) {
          finalScore = 50;
        } else {
          finalScore = calculScore;
        }
        return finalScore;
      };
      return {
        ...state,
        Games: {
          ...state.Games,
          CoLoveDuty: {
            ...state.Games.CoLoveDuty,
            timer: `${action.payload.minute}:${action.payload.second}`,
            score: coLoveScore(),
          },
        },
      };
    }
    case 'IA_SHINE': {
      return {
        ...state,
        Games: {
          ...state.Games,
          CoLoveDuty: {
            ...state.Games.CoLoveDuty,
            CoLoveFrame: state.Games.CoLoveDuty.CoLoveFrame.map((e) => {
              if (e.id === action.payload.id) {
                return {
                  ...e,
                  isActive: action.payload.isActive,
                };
              }
              return e;
            }),
          },
        },
      };
    }
    case 'ROTATE_TILE': {
      const { idTile, rotation } = action.payload;
      return {
        ...state,
        Games: {
          ...state.Games,
          CoLoveDuty: {
            ...state.Games.CoLoveDuty,
            rotation: state.Games.CoLoveDuty.rotation + 1,
            CoLoveFrame: state.Games.CoLoveDuty.CoLoveFrame.map((e) => {
              if (e.id === idTile) {
                return {
                  ...e,
                  rotation: rotation < 4 ? rotation + 1 : 1,
                };
              }
              return e;
            }),
          },
        },
      };
    }
    default:
      return state;
  }
};

export default Vaccination;
