import * as images from '../../../img/vaccination-solo/PuckLab/index';
import * as constant from './constant';
import maze from './maze.json';

const puckLabState = {
  dimensions: {
    width: constant.MAP_WIDTH,
    height: constant.MAP_HEIGHT,
  },
  maze,
  ready: false,
  hasWon: false,
  gameStarted: 0,
  gameOver: false,
  gameOverCount: 0,
  gameLife: 3,
  invisible: false,
  virusSound: false,
  lostLifeSound: false,
  winLifeSound: false,
  selectSound: false,
  mute: false,
  alreadyCollected: false,
  startStep: 1,
  score: 0,
  puckLabScore: constant.puckLabScore,
  timer: null,
  Life: [
    {
      id: 1,
      isActive: true,
      isBeingLost: false,
      isBeingCollected: false,
    },
    {
      id: 2,
      isActive: true,
      isBeingLost: false,
      isBeingCollected: false,
    },
    {
      id: 3,
      isActive: true,
      isBeingLost: false,
      isBeingCollected: false,
    },
    {
      id: 4,
      isActive: false,
      isBeingLost: false,
      isBeingCollected: false,
    },
    {
      id: 5,
      isActive: false,
      isBeingLost: false,
      isBeingCollected: false,
    },
  ],
  collectedVirus: [
    {
      id: 1,
      name: '',
      isGood: false,
      image: images.emptyServer,
      isBeingCollected: false,
      isCollected: false,
    },
    {
      id: 2,
      name: '',
      isGood: false,
      image: images.emptyServer,
      isBeingCollected: false,
      isCollected: false,
    },
    {
      id: 3,
      name: '',
      isGood: false,
      image: images.emptyServer,
      isBeingCollected: false,
      isCollected: false,
    },
  ],
  exit: {
    skin: images.exit,
    top: constant.EXIT_TOP,
    left: constant.EXIT_LEFT,
    position: [constant.EXIT_ROW, constant.EXIT_COLUMN],
    type: 'Exit',
  },
  player: {
    skin: images.player1,
    step: constant.SIZE_TILE,
    top: constant.PLAYER_TOP,
    left: constant.PLAYER_LEFT,
    move: false,
    direction: 'IDLE',
    joystick: images.joystickIdle,
    scale: 1,
    position: [constant.PLAYER_ROW, constant.PLAYER_COLUMN],
  },
  LifeInGame: {
    Life: {
      id: 1,
      skin: images.heartIcon,
      position: [constant.LIFE1_ROW, constant.LIFE1_COLUMN],
      top: constant.LIFE1_TOP,
      left: constant.LIFE1_LEFT,
      isCollected: false,
      isBeingCollected: false,
      type: 'Life',
    },
    Life2: {
      id: 2,
      skin: images.heartIcon,
      position: [constant.LIFE2_ROW, constant.LIFE2_COLUMN],
      top: constant.LIFE2_TOP,
      left: constant.LIFE2_LEFT,
      isCollected: false,
      isBeingCollected: false,
      type: 'Life',
    },
  },
  Covid: {
    Covid1: {
      id: 1,
      skin: images.monster,
      step: constant.SIZE_TILE,
      top: constant.COVID1_TOP,
      left: constant.COVID1_LEFT,
      scale: -1,
      position: [constant.COVID1_ROW, constant.COVID1_COLUMN],
      lastmove: 'DOWN',
      lastMoveDirection: null,
      isActive: true,
      isBeingCollected: null,
      type: 'Covid',
    },
    Covid2: {
      id: 2,
      skin: images.monster,
      step: constant.SIZE_TILE,
      top: constant.COVID2_TOP,
      left: constant.COVID2_LEFT,
      scale: -1,
      position: [constant.COVID2_ROW, constant.COVID2_COLUMN],
      lastmove: 'UP',
      lastMoveDirection: null,
      isActive: true,
      isBeingCollected: null,
      type: 'Covid',
    },
    Covid3: {
      id: 3,
      skin: images.monster,
      step: constant.SIZE_TILE,
      top: constant.COVID3_TOP,
      left: constant.COVID3_LEFT,
      scale: -1,
      position: [constant.COVID3_ROW, constant.COVID3_COLUMN],
      lastmove: 'RIGHT',
      lastMoveDirection: null,
      isActive: true,
      isBeingCollected: null,
      type: 'Covid',
    },
  },
  Virus: {
    Virus1: {
      skin: images.server1,
      position: [constant.VIRUS1_ROW, constant.VIRUS1_COLUMN],
      top: constant.VIRUS1_TOP,
      left: constant.VIRUS1_LEFT,
      isCollected: false,
      isBeingCollected: false,
      image: images.server1Hud,
      isGood: true,
      name: 'Virus1',
      type: 'Virus',
    },
    Virus2: {
      skin: images.server2,
      position: [constant.VIRUS2_ROW, constant.VIRUS2_COLUMN],
      top: constant.VIRUS2_TOP,
      left: constant.VIRUS2_LEFT,
      isCollected: false,
      isBeingCollected: false,
      image: images.server2Hud,
      isGood: true,
      name: 'Virus2',
      type: 'Virus',
    },
    Virus3: {
      skin: images.server3,
      position: [constant.VIRUS3_ROW, constant.VIRUS3_COLUMN],
      top: constant.VIRUS3_TOP,
      left: constant.VIRUS3_LEFT,
      isCollected: false,
      isBeingCollected: false,
      image: images.server3Hud,
      isGood: true,
      name: 'Virus3',
      type: 'Virus',
    },
    Virus4: {
      skin: images.server4,
      position: [constant.VIRUS4_ROW, constant.VIRUS4_COLUMN],
      top: constant.VIRUS4_TOP,
      left: constant.VIRUS4_LEFT,
      isCollected: false,
      isBeingCollected: false,
      image: images.server4Hud,
      isGood: false,
      name: 'Virus4',
      type: 'Virus',
    },
    Virus5: {
      skin: images.server5,
      position: [constant.VIRUS5_ROW, constant.VIRUS5_COLUMN],
      top: constant.VIRUS5_TOP,
      left: constant.VIRUS5_LEFT,
      isCollected: false,
      isBeingCollected: false,
      image: images.server5Hud,
      isGood: false,
      name: 'Virus5',
      type: 'Virus',
    },
  },
};

export default puckLabState;
