import PropTypes from 'prop-types';

function ChestItem({ id, number, incrementCounter }) {
  return (
    <div
      className={`buttonChestCode${id}`}
      role="presentation"
      onClick={() => incrementCounter(id, number)}
    >
      {number}
    </div>
  );
}

ChestItem.propTypes = {
  id: PropTypes.number.isRequired,
  number: PropTypes.number.isRequired,
  incrementCounter: PropTypes.number.isRequired,
};

export default ChestItem;
