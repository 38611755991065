import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

// selectors
import { selectUser } from 'components/Reducers/user';

// components
import Participant from 'components/GameBoard/ReusableComponents/Multi/Participants/Participant/Participant';

import styles from 'components/GameBoard/ReusableComponents/Multi/Participants/Participants.module.scss';

import { filterObject } from 'utils/utilityFunctions';

const Participants = () => {
  const videoRefCurrentParticipant = useRef(null);
  const videoRefParticipants = useRef([]);
  const { participants, mainStream, currentUser } = useSelector(selectUser);
  const participantKey = Object.keys(participants);

  const notCurrentParticipants = filterObject(
    participants,
    (obj) => !obj.currentUser
  );

  useEffect(() => {
    if (videoRefCurrentParticipant.current) {
      videoRefCurrentParticipant.current.srcObject = mainStream;
      videoRefCurrentParticipant.current.onloadedmetadata = () => {
        videoRefCurrentParticipant.current.play();
      };
    }
  }, [currentUser, mainStream]);

  const currentUserObject = currentUser ? Object.values(currentUser)[0] : null;

  const participantsArray = Object.keys(notCurrentParticipants).map((element) => {
    const currentParticipant = participants[element];
    const pc = currentParticipant.peerConnection;
    const remoteStream = new MediaStream();
    const currentIndex = element;
    if (pc) {
      pc.ontrack = (event) => {
        event.streams[0].getTracks().forEach((track) => {
          remoteStream.addTrack(track);
        });

        const videoElement = videoRefParticipants.current[currentIndex];
        if (videoElement) videoElement.srcObject = remoteStream;
      };
    }

    return (
      <Participant
        key={currentIndex}
        videoRef={videoRefParticipants}
        currentParticipant={currentParticipant}
        currentIndex={currentIndex}
        showAvatar={currentParticipant.name && !currentParticipant.video}
      />
    );
  });

  return (
    <div className={styles.participants}>
      {participantsArray}
      <Participant
        currentParticipant={currentUserObject}
        currentIndex={participantKey.length}
        videoRef={videoRefCurrentParticipant}
        showAvatar={currentUserObject && !currentUserObject.video}
        currentUser
      />
    </div>
  );
};

export default Participants;
