import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

/**
 * Get the description/content of a user
 * @param {number} userId
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const getUserDescription = async (userId) => {
  // REFACTO API DJANGO
  const { data } = await axios.get(`${baseUrl}/api/users/${userId}/`);
  return data;
};

export default getUserDescription;
