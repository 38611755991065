import { combineReducers } from 'redux';
import Steps from 'components/Reducers/Steps/Steps';
import Modal from 'components/Reducers/Modal/Modal';
import emotion from 'components/Reducers/emotion';
import fetchMessage from 'components/Reducers/GameData/fetchMessage';
import fetchObject from 'components/Reducers/GameData/fetchObjects';
import GameUsers from 'components/Reducers/GameData/GameUsers';
import Room from 'components/Reducers/Room';
import Room404 from 'components/Reducers/Room404';
import Vaccination from 'components/Reducers/Vaccination';
import Tde from 'components/Reducers/Tde';
import Inventory from 'components/Reducers/Inventory';
import Obesity from 'components/Reducers/Obesity';
import Tms from 'components/Reducers/Tms';
import ChatBot from 'components/Reducers/ChatBot';
import RPS from 'components/Reducers/RPS';
import game from 'components/Reducers/game';
import session from 'components/Reducers/session';
import user from 'components/Reducers/user';
import questionnary from 'components/Reducers/questionnary';

const appReducer = combineReducers({
  user,
  game,
  session,
  GameUsers,
  Objects: fetchObject,
  prevention_messages: fetchMessage,
  Room,
  Room404,
  Vaccination,
  Tde,
  Modal,
  Steps,
  Inventory,
  Obesity,
  Tms,
  ChatBot,
  RPS,
  emotion,
  questionnary,
});

export default appReducer;
