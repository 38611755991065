import { useSelector } from 'react-redux';
import { useEffect } from 'react';
// api
import { putSessionsHasRoom } from 'API/sessionsHasRoom';

// selector functions
import { selectSession } from 'components/Reducers/session';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectRoom } from 'components/Reducers/Room';
import { selectInfoGame } from 'components/Reducers/game';

// components
import GameSolo from './GameSolo';
import GameMulti from './GameMulti';

function Game() {
  const session = useSelector(selectSession);
  const gameUser = useSelector(infoGameUser);
  const rooms = useSelector(selectRoom);
  const game = useSelector(selectInfoGame);

  useEffect(() => {
    if (gameUser.gameStarted === 1) {
      putSessionsHasRoom(session.id, {
        count: gameUser.count,
      });
    }
    // case if we recharge the page we need to retrieve the last count of the game
  }, [gameUser.count, gameUser.gameStarted, session.id]);

  // REFACTO when we upgrade to redux toolkit we would have a variable with the state of the API call. Then if and if successful we would redirect to or .
  if (rooms.listRooms !== null) {
    if (game.type === 'solo') {
      return <GameSolo />;
    }
    if (game.type === 'multi') {
      return <GameMulti />;
    }
  }
}

export default Game;
