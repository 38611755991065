import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as images from '../../../../../img/obesity/index';
import rickRoll from '../../../../../sound/Obesity/rickroll.ogg';
import Timer from './Timer';

import Audio from '../../../ReusableComponents/Actions/Audio';
import click from '../../../../../sound/iphone-click.mp3';

import '../../../../../assets/css/components/GameBoard/Obesity/Enigma/Television.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Television({ dispatch, allObjects, idSessionHasRoom, game }) {
  const idTv = 194;
  const isRemoteOpen = allObjects.find((remote) => remote.status === 'open');
  const isRemoteInventory = allObjects.find((remote) => remote.isInventory);

  const [televisionOpen, setTelevisionOpen] = useState(false);
  const [gifOpen, setGifOpen] = useState(false);
  const [channel, setChannel] = useState(null);
  const [currentChannel, setCurrentChannel] = useState(0);
  const firstChannel = 0;
  const previousChannel = currentChannel - 1;
  const nextChannel = currentChannel + 1;
  const [valueDate, onChangeDate] = useState(null);
  const [soundClick, setSoundClick] = useState(false);
  const channelsArray = ['picture', 'sound', 'tv', 'channel', 'timer', 'source'];
  const screenVersions = allObjects.filter((item) => item.id === idTv);
  const [screen, setScreen] = useState(
    screenVersions[0].images.filter((item) => item.type === 'closed')[0].image
  );

  useEffect(() => {
    if (televisionOpen) {
      if (channel !== null) {
        setScreen(
          screenVersions[0].images.filter((item) => item.type === channel)[0].image
        );
      } else {
        setScreen(
          screenVersions[0].images.filter((item) => item.type === 'open')[0].image
        );
      }
    } else {
      setScreen(
        screenVersions[0].images.filter((item) => item.type === 'closed')[0].image
      );
    }
    return () => {
      setScreen(
        screenVersions[0].images.filter((item) => item.type === 'closed')[0].image
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, televisionOpen]);

  const tvObject = allObjects.find((objectContent) => objectContent.id === idTv);
  const tvIndex = allObjects.findIndex(
    (objectContent) => objectContent.id === idTv
  );

  const handleMessage = () => {
    return 'Cliquez sur les boutons de la télécommande pour pouvoir l’utiliser.';
  };
  const usedObject = (collectObject, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        status: 'open',
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            status: 'open',
            type: 'used',
          },
        });
      });
  };

  useEffect(() => {
    if (game.music && game.soundtrack) {
      if (channel === 'tv' && televisionOpen) {
        document.getElementById('Obesity-Theme').volume = 0;
        document.getElementById('Obesity-RickRoll').volume = 0.1;
      } else {
        document.getElementById('Obesity-Theme').volume = 0.2;
        document.getElementById('Obesity-RickRoll').volume = 0;
      }
    } else {
      document.getElementById('Obesity-RickRoll').volume = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, televisionOpen]);

  const handleStartTv = () => {
    return (
      <div className="television-enigma-container">
        <Audio sound={rickRoll} loop id="Obesity-RickRoll" muted={!game.music} />
        <div className="television-enigma-container-images">
          <img
            src={screen}
            alt="Télévision allumée"
            className="television-enigma-container-tvbackground"
          />
          {gifOpen && (
            <img
              alt="open-tv"
              src={images.switchAnimation}
              className="television-enigma-animation"
            />
          )}
          {televisionOpen && !gifOpen && channel === null && (
            <div className="television-enigma-icons-grid">
              <img
                src={images.TvLogo}
                alt="Bienvenue"
                className="television-enigma-tv-welcome"
              />
              <button
                type="button"
                className={`television-enigma-tv-picture-app${
                  channelsArray[currentChannel] === 'picture' ? ' active' : ''
                }`}
                onClick={() => {
                  setChannel('picture');
                  setSoundClick(true);
                  setTimeout(() => setSoundClick(false));
                }}
              >
                <img
                  src={
                    channelsArray[currentChannel] === 'picture'
                      ? images.pictureSelect
                      : images.picture
                  }
                  alt="Current channel"
                  className="television-enigma-tv-app-images"
                />
              </button>
              <button
                type="button"
                className={`television-enigma-tv-sound-app${
                  channelsArray[currentChannel] === 'sound' ? ' active' : ''
                }`}
                onClick={() => {
                  setChannel('sound');
                  setSoundClick(true);
                  setTimeout(() => setSoundClick(false));
                }}
              >
                <img
                  src={
                    channelsArray[currentChannel] === 'sound'
                      ? images.musicSelect
                      : images.music
                  }
                  alt="Current channel"
                  className="television-enigma-tv-app-images"
                />
              </button>
              <button
                type="button"
                className={`television-enigma-tv-tv-app${
                  channelsArray[currentChannel] === 'tv' ? ' active' : ''
                }`}
                onClick={() => {
                  setChannel('tv');
                  setSoundClick(true);
                  setTimeout(() => setSoundClick(false));
                }}
              >
                <img
                  src={
                    channelsArray[currentChannel] === 'tv'
                      ? images.tvSelect
                      : images.tv
                  }
                  alt="Current channel"
                  className="television-enigma-tv-app-images"
                />
              </button>
              <button
                type="button"
                className={`television-enigma-tv-channel-app${
                  channelsArray[currentChannel] === 'channel' ? ' active' : ''
                }`}
                onClick={() => {
                  setChannel('channel');
                  setSoundClick(true);
                  setTimeout(() => setSoundClick(false));
                }}
              >
                <img
                  src={
                    channelsArray[currentChannel] === 'channel'
                      ? images.channelSelect
                      : images.channel
                  }
                  alt="Current channel"
                  className="television-enigma-tv-app-images"
                />
              </button>
              <button
                type="button"
                className={`television-enigma-tv-timer-app${
                  channelsArray[currentChannel] === 'timer' ? ' active' : ''
                }`}
                onClick={() => {
                  setChannel('timer');
                  setSoundClick(true);
                  setTimeout(() => setSoundClick(false));
                }}
              >
                <img
                  src={
                    channelsArray[currentChannel] === 'timer'
                      ? images.timerSelect
                      : images.timer
                  }
                  alt="Current channel"
                  className="television-enigma-tv-app-images"
                />
              </button>
              <button
                type="button"
                className={`television-enigma-tv-source-app${
                  channelsArray[currentChannel] === 'source' ? ' active' : ''
                }`}
                onClick={() => {
                  setChannel('source');
                  setSoundClick(true);
                  setTimeout(() => setSoundClick(false));
                }}
              >
                <img
                  src={
                    channelsArray[currentChannel] === 'source'
                      ? images.sourceSelect
                      : images.source
                  }
                  alt="Current channel"
                  className="television-enigma-tv-app-images"
                />
              </button>{' '}
            </div>
          )}
          {channel === 'tv' && televisionOpen && (
            <img
              src="https://c.tenor.com/_4YgA77ExHEAAAAC/rick-roll.gif"
              className="television-enigma-animation"
              alt="You got rick rolled"
            />
          )}
          {channel === 'timer' && (
            <div className="television-enigma-timer-grid">
              <Timer value={valueDate} onChange={onChangeDate} />
            </div>
          )}
        </div>
      </div>
    );
  };

  /**
   * Handle the TV navigation with the remote control
   * @param {string} position - which button of the remote control is pressed
   */
  const handleCurrentChannel = (position) => {
    const numberTotalOfChannel = channelsArray.length - 1;

    if (position === 'left') {
      setCurrentChannel(previousChannel);
    }

    if (position === 'right') {
      setCurrentChannel(nextChannel);
    }

    if (position === 'bottom') {
      if (currentChannel < 3) {
        setCurrentChannel(currentChannel + 3);
      }
    }

    if (position === 'top') {
      if (currentChannel >= 3) {
        setCurrentChannel(currentChannel - 3);
      }
    }

    if (
      (currentChannel === 0 && position === 'top') ||
      (currentChannel === 0 && position === 'left')
    ) {
      setCurrentChannel(numberTotalOfChannel);
    }

    if (
      (currentChannel === numberTotalOfChannel && position === 'right') ||
      (currentChannel === numberTotalOfChannel && position === 'bottom')
    ) {
      setCurrentChannel(firstChannel);
    }
  };

  const checkChannel = () => {
    setChannel(channelsArray[currentChannel]);
  };

  /**
   * Handle the television turn on
   */
  const turnOnTelevision = () => {
    setTelevisionOpen(true);
    setGifOpen(true);
    setTimeout(() => {
      setGifOpen(false);
    }, 2000);
    usedObject(tvObject, tvIndex);
    setChannel(null);
    setCurrentChannel(firstChannel);
  };

  /**
   * Handle the television turn off
   */
  const turnOffTelevision = () => {
    setTelevisionOpen(false);
    setChannel(null);
  };

  return (
    <>
      <Audio condition={soundClick && game.soundtrack} sound={click} />
      {handleStartTv()}
      {isRemoteOpen ? (
        <div className="television-enigma-remote">
          {isRemoteInventory && (
            <p className="television-enigma-text">{handleMessage()}</p>
          )}
          <div className="television-enigma-remote-object">
            <button
              type="button"
              className="television-enigma-remote-arrows-switch"
              onClick={() => {
                setSoundClick(true);
                setTimeout(() => setSoundClick(false));
                if (!televisionOpen) {
                  turnOnTelevision();
                } else {
                  turnOffTelevision();
                }
              }}
            >
              <img src={images.switch} alt="Bouton allumage" />
            </button>
            <img
              src={images.numbers}
              alt="Pad numéroté"
              className="television-enigma-remote-arrows-numbers"
            />
            <div className="television-enigma-remote-arrows">
              <button
                className="television-enigma-remote-arrows-left"
                onClick={() => {
                  handleCurrentChannel('left');
                  setSoundClick(true);
                  setTimeout(() => setSoundClick(false));
                }}
                type="button"
              >
                <img
                  src={images.left}
                  alt="flèche de gauche"
                  className="television-enigma-remote-arrow"
                />
              </button>
              <button
                className="television-enigma-remote-arrows-up"
                onClick={() => {
                  handleCurrentChannel('top');
                  setSoundClick(true);
                  setTimeout(() => setSoundClick(false));
                }}
                type="button"
              >
                <img
                  src={images.up}
                  alt="flèche du haut"
                  className="television-enigma-remote-arrow"
                />
              </button>
              <button
                className="television-enigma-remote-arrows-ok"
                onClick={() => {
                  checkChannel();
                  setSoundClick(true);
                  setTimeout(() => setSoundClick(false));
                }}
                type="button"
              >
                <img
                  src={images.ok}
                  alt="Bouton ok"
                  className="television-enigma-remote-arrow"
                />
              </button>
              <button
                className="television-enigma-remote-arrows-right"
                onClick={() => {
                  handleCurrentChannel('right');
                  setSoundClick(true);
                  setTimeout(() => setSoundClick(false));
                }}
                type="button"
              >
                <img
                  src={images.right}
                  alt="flèche de droite"
                  className="television-enigma-remote-arrow"
                />
              </button>
              <button
                className="television-enigma-remote-arrows-down"
                onClick={() => {
                  handleCurrentChannel('bottom');
                  setSoundClick(true);
                  setTimeout(() => setSoundClick(false));
                }}
                type="button"
              >
                <img
                  src={images.down}
                  alt="flèche du bas"
                  className="television-enigma-remote-arrow"
                />
              </button>
            </div>
            <img
              src={images.pad}
              alt="Pad"
              className="television-enigma-remote-pad"
            />
            <button
              className="television-enigma-remote-menu"
              onClick={() => {
                setChannel(null);
                setSoundClick(true);
                setTimeout(() => setSoundClick(false));
              }}
              type="button"
            >
              <img src={images.menu} alt="Menu" />
            </button>
          </div>
        </div>
      ) : (
        <p className="television-enigma-remote-text">
          {isRemoteInventory
            ? "Utiliser la télécommande dans l'inventaire"
            : 'Il vous manque une télécommande pour allumer la télévision'}
        </p>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  contents: state.Modal.modalEnigma.description.content,
  allObjects: state.Objects.AllObjects,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  game: state.GameUsers,
});

Television.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
    })
  ).isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  game: PropTypes.shape({
    soundtrack: PropTypes.bool,
    music: PropTypes.bool,
  }).isRequired,
};

export default connect(mapStateToProps)(Television);
