import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Type/Note/Note.module.scss';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

const Note = ({ question, activeIndex, handleAnswer, handleReturn }) => {
  const { t } = useTranslation('common');

  const [selectChoice, setSelectChoice] = useState(null);
  const numberOfResponse =
    question.responses.length % 2
      ? question.responses.length - 1
      : question.responses.length;
  const middleChoice = Math.round(numberOfResponse / 2);

  const handleChoice = () => {
    if (!selectChoice) {
      return question.responses[middleChoice];
    }
    return selectChoice;
  };

  return (
    <div className={styles.note}>
      <h4 className={styles.question}>{question.title}</h4>
      <div className={styles['list-response']}>
        <p className={styles['response-minimum']}>{question.minimum}</p>
        <div className={styles.responses}>
          {question.responses.map((response, index) => (
            <div key={response.id}>
              <div className={styles.bar} />
              <button
                className={styles.response}
                type="button"
                onClick={() => setSelectChoice(response)}
                aria-label="Response"
              />
              {((selectChoice === null && index === middleChoice) ||
                selectChoice === response) && (
                <div className={styles['select-choice']} />
              )}
            </div>
          ))}
        </div>
        <p className={styles['response-maximum']}>{question.maximum}</p>
      </div>
      {activeIndex !== 0 && (
        <Button
          buttonType="action"
          title={t('buttonFunction.return')}
          onClick={() => handleReturn()}
        />
      )}
      <Button
        buttonType="action"
        title={t('buttonFunction.next')}
        onClick={() => {
          handleAnswer(handleChoice(), question);
          setSelectChoice(null);
        }}
      />
    </div>
  );
};

Note.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    image: PropTypes.string,
    is_debriefing: PropTypes.number,
    level: PropTypes.number,
    maximum: PropTypes.string,
    minimum: PropTypes.string,
    response_type: PropTypes.string,
    responses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        level: PropTypes.number,
        weighting: PropTypes.number,
      })
    ),
  }).isRequired,
  activeIndex: PropTypes.number.isRequired,
  handleAnswer: PropTypes.func.isRequired,
  handleReturn: PropTypes.func.isRequired,
};

export default Note;
