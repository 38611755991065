import PropTypes from 'prop-types';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

/**
 * Generic component to display the intro messages
 */
function IntroMessage({
  title,
  message,
  buttonType,
  buttonTitle,
  clickFunction,
  backgroundImageUrl,
}) {
  return (
    // TODO review css
    <div className="intro-first">
      {title && <h1>{title}</h1>}
      <p>{message}</p>
      <Button
        backgroundImageUrl={backgroundImageUrl}
        title={buttonTitle}
        buttonType={buttonType}
        onClick={clickFunction}
      />
    </div>
  );
}

IntroMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  buttonType: PropTypes.string,
  clickFunction: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  backgroundImageUrl: PropTypes.string,
};

IntroMessage.defaultProps = {
  title: undefined,
  buttonType: 'action',
  backgroundImageUrl: undefined,
};

export default IntroMessage;
