import styled from 'styled-components';

const BaseAnimation = styled.img`
  animation-duration: ${({ duration }) => duration};
  animation-timing-function: ${({ timingFunction }) => timingFunction};
  animation-delay: ${({ delay }) => delay};
  animation-iteration-count: ${({ iterationCount }) => iterationCount};
  animation-direction: ${({ direction }) => direction};
  animation-fill-mode: ${({ fillMode }) => fillMode};
  animation-play-state: ${({ playState }) => playState};
`;

export default BaseAnimation;
