import styles from 'components/Joyride/Steps.module.scss';
import styled, { keyframes } from 'styled-components';
import { imagesFooter } from 'components/GameBoard/ReusableComponents/Multi/constants';
import * as imageNext from '../../img/vaccination-solo/PuckLab';
import * as images from '../../img/vaccination-solo/index';

const rotateAnimation = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
`;

const ImageDefault = styled.img`
  margin: 20px;
  width: 50%;
`;

const Coin = styled.img`
  margin: 20px;
  width: 50%;
  animation-name: ${rotateAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;
// Define a function to use the translation function as parameter
const stepsEmotionMulti = (t) => {
  const steps = [
    {
      target: '.ChangingRoom',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.welcome.title', { ns: 'common-multi' })}</h2>
          <p>{t('joyride.welcome.description', { ns: 'common-multi' })}</p>
        </div>
      ),
      placement: 'center',
    },
    {
      target: '#video-wrapper',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.players.title', { ns: 'common-multi' })}</h2>
          <p>{t('joyride.players.description', { ns: 'common-multi' })}</p>
          <p>{t('joyride.players.boost', { ns: 'common-multi' })}</p>
          <div className={styles['camera-icons']}>
            <img src={imagesFooter.micro} alt="micro" />
            <img src={imagesFooter.video} alt="camera" />
          </div>
        </div>
      ),
      placement: 'left',
    },
    // target the object desktop : not working
    // for now modal displaying in center of screen .Changingroom
    {
      target: '.ChangingRoom',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.objects.title', { ns: 'common-multi' })}</h2>
          <p>{t('joyride.objects.description', { ns: 'common-multi' })}</p>
        </div>
      ),
      placement: 'center',
    },

    {
      target: '.Inventory',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.inventory.title')}</h2>
          <p>{t('joyride.inventory.description')}</p>
          <ImageDefault src={images.trickyCoin} alt="Tricky" />
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.Steps',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.steps.title')}</h2>
          <p>{t('joyride.steps.description')}</p>{' '}
        </div>
      ),
      placement: 'left',
    },
    {
      target: '.Hud-Item-Text-time',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.time.title')}</h2>
          <p>{t('joyride.time.description')}</p>
          <p>{t('joyride.time.score')}</p>
          <ImageDefault src={imageNext.time} alt="Tricky" />
        </div>
      ),
      placement: 'left',
    },
    {
      target: '.Hud-Item-Text-click',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.hits.title')}</h2>
          <p> {t('joyride.hits.description')}</p>
          <p> {t('joyride.hits.score')}</p>
          <ImageDefault src={imageNext.cursor} alt="Tricky" />{' '}
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.Hud-Item-Text-score',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.points.title')}</h2>
          <p>{t('joyride.points.description')}</p>
          <Coin
            className={styles.coin}
            src={imageNext.coinScore}
            alt="Tricky"
          />{' '}
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.Hud-Item-Text-sound',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.sound.title')}</h2>
          <p>{t('joyride.sound.description')}</p>
          <ImageDefault src={imageNext.notMuteIcon} alt="Tricky" />{' '}
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.Hud-Item-Text-music',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.music.title')}</h2>
          <p>{t('joyride.music.description')}</p>
          <ImageDefault src={imageNext.unMuteMusic} alt="Tricky" />{' '}
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.ChatBot-img',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.chatbot.title')}</h2>
          <p>{t('joyride.chatbot.description')}</p>
        </div>
      ),
      placement: 'top',
    },

    {
      target: '.ChangingRoom',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.letsGo.title', { ns: 'common-multi' })}</h2>
          <p>{t('joyride.letsGo.description', { ns: 'common-multi' })}</p>
        </div>
      ),
      placement: 'center',
    },
  ];
  return steps;
};
export default stepsEmotionMulti;
