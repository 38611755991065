import axios from 'axios';

const baseUrlDjango = process.env.REACT_APP_BASE_URL_DJANGO;
const tokenDjando = process.env.REACT_APP_TOKEN_API_DJANGO;

/**
 * Get the description/content of a game
 * @param {number} gameId
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const getGameDescription = async (gameId) => {
  const { data } = await axios.get(`${baseUrlDjango}/api/game/${gameId}/`, {
    headers: {
      Authorization: `Token ${tokenDjando}`,
    },
  });
  return data;
};

export default getGameDescription;
