import { useEffect, useState, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

// utils
import useInitGame from 'utils/useInitGame';
import { loadUserGameSession } from 'utils/utilityFunctions';
import styles from 'components/Introduction/StartPage.module.scss';

// components
import Game from 'components/Game';
import Loading from 'components/Introduction/Loading';
import { selectInfoGame } from 'components/Reducers/game';
import BackgroundStyle from 'components/GameBoard/ReusableComponents/BackgroundStyle';

let isMounted = false;

const StartButtonLazy = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(resolve, 5000);
  }).then(() => import('components/Introduction/StartButton'));
});

function StartPage() {
  const [startButtonClicked, setStartButtonClicked] = useState(false);

  const dispatch = useDispatch();

  // Retrieve data in url
  const [params] = useSearchParams();
  const gameId = params.get('game');
  const roomName = params.get('RoomName');
  const userId = params.get('user') || params.get('admin');
  const sessionId = Number(roomName?.match(/(\d+)/g)?.[0]);

  const backgroundImage = useSelector(selectInfoGame).intro_image;

  useInitGame(null, sessionId);

  useEffect(() => {
    if (!isMounted) {
      isMounted = true;
      // Retrieve all data for a game
      loadUserGameSession(gameId, userId, sessionId, dispatch);
    }
  }, [dispatch, gameId, userId, sessionId]);

  const handleClick = () => {
    setStartButtonClicked(true);
  };

  return startButtonClicked ? (
    <Game />
  ) : (
    <>
      <BackgroundStyle
        position="center"
        start="0px"
        backgroundWidth="267vh"
        as="div"
      >
        <img
          src={backgroundImage}
          alt="background"
          width="100%"
          height="100%"
          className={styles['img-background']}
        />
      </BackgroundStyle>
      <Suspense fallback={<Loading />}>
        <StartButtonLazy handleClick={handleClick} />
      </Suspense>
    </>
  );
}

export default StartPage;
