/* eslint-disable no-return-assign */
const initialState = {
  language: 'FR',
  gameName: '',
  gameId: null,
  ModalFinishGame: false,
  currentRoomId: null,
  data: null,
  listRooms: null,
};

const Room = (state = initialState, action) => {
  switch (action.type) {
    case 'INCREMENT_COUNTER':
      return {
        ...state,
        data: {
          ...state.data,
          count: state.data.count + 1,
        },
      };
    case 'INTRO_IS_DONE': {
      return {
        ...state,
        data: {
          ...state.data,
          isStarted: 1,
        },
      };
    }
    case 'INIT_GAME_ROOM': {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          background: null,
        },
      };
    }
    case 'COUNT_MOVE': {
      return {
        ...state,
        data: {
          ...state.data,
          moves: state.data.count + 1,
        },
      };
    }
    case 'ROOM_ACTIVE': {
      const roomType = action.payload.room;

      if (action.payload.isSurvey) {
        return {
          ...state,
          currentRoomId: null,
          [roomType]: {
            ...state[roomType],
            isActive: action.payload.active,
          },
        };
      }
      return {
        ...state,
        currentRoomId: state.listRooms[`${roomType}`].id,
        listRooms: {
          ...state.listRooms,
          [roomType]: {
            ...state.listRooms[roomType],
            isActive: action.payload.active,
          },
        },
      };
    }

    case 'FETCH_ROOM': {
      const descriptionRoom = action.payload;
      return {
        ...state,
        currentRoomId: descriptionRoom.id,
      };
    }
    case 'FETCH_ROOMS': {
      const newRooms = {};
      action.payload.rooms.map(
        (room) =>
          (newRooms[room.slug] = {
            name: {
              FR: room.FR,
              EN: room.EN,
              ES: room.ES,
            },
            welcomeMessage: {
              FR: room.message_FR,
              EN: room.message_EN,
              ES: room.message_ES,
            },
            typage: room.typage,
            niveau: room.niveau,
            slug: room.slug,
            id: room.id,
            url: room.url,
            isActive:
              action.payload.room && action.payload.room.name === room.name
                ? action.payload.room.isActive
                : false,
          })
      );
      return {
        ...state,
        listRooms: newRooms,
      };
    }

    case 'SET_MODAL_FINISHGAME':
      return {
        ...state,
        ModalFinishGame: action.payload,
      };

    default:
      return state;
  }
};

// Define the selector functions
export const selectRoom = (state) => state.Room;
export const selectAllRooms = (state) => state.Room.listRooms;

export default Room;
