import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

/**
 * Modify the table sessions
 * @param {number} sessionId
 * @param {Object} modification - contains the data we want to modify in DB
 * @returns
 */
const sessionUpdate = async (sessionId, modification) => {
  // REFACTO API DJANGO
  await axios.put(
    `${baseURL}/api/sessions/${sessionId}/modification`,
    modification
  );
};

/**
 * Retrieve the description of one session with table sessions
 * @param {number} sessionId
 * @returns
 */
// REFACTO API DJANGO
const getSessionDescription = async (sessionId) => {
  const { data } = await axios.get(`${baseURL}/api/sessions/${sessionId}`);
  return data;
};

export { sessionUpdate, getSessionDescription };
