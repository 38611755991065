import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

/**
 * Initialize the link between a session a room
 * @param {number} sessionId
 * @param {number} currentRoomId
 */
const postSessionsHasRoom = async (sessionId, currentRoomId) => {
  // TODO: is async/await really useful here as there is no code after the await ?
  // TODO every API call should be wrapped in a try/catch, either in the function or where it's called
  const { data } = await axios.post(`${baseURL}/api/sessionsHasRoom`, {
    sessions_id: sessionId,
    rooms_id: currentRoomId,
    date: new Date().toISOString().slice(0, 19).replace('T', ' '),
  });
  return data;
};

/**
 * Modidy the table `sessions_has_room`
 * @param {number} idSessionHasRoom
 * @param {Object} modification - contains the data we want to modify in DB
 * @returns
 */
const putSessionsHasRoom = async (idSessionHasRoom, modification) => {
  const { data } = await axios.put(
    `${baseURL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
    modification
  );
  return data;
};

/**
 * Get the data from the table sessions_has_room for a particular sessionId
 * @param {number} sessionId
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const getSessionsHasRoom = async (sessionId, currentRoomId) => {
  const { data } = await axios.get(
    `${baseURL}/api/sessionsHasRoom/rooms/${currentRoomId}/sessions/${sessionId}`
  );
  return data;
};

/**
 * Get the data from sessions_has_room_has_mesage for a particular sessionId and currentRoomId
 * @param {number} sessionId
 * @param {number} currentRoomId
 * @returns Promise<object[]>} Response of the API call if successful
 */
const getSessionMessage = async (sessionId, currentRoomId) => {
  const { data } = await axios.get(
    `${baseURL}/api/sessionsHasRoom/${sessionId}/rooms/${currentRoomId}`
  );
  return data;
};

export {
  postSessionsHasRoom,
  putSessionsHasRoom,
  getSessionsHasRoom,
  getSessionMessage,
};
