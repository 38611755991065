import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

/**
 * Initialize the link between a room, a session and messages
 * @param {number} currentRoomId
 * @param {Object} message
 * @param {number} idSessionHasRoom
 */
const postSessionsHasRoomHasMessage = async (
  currentRoomId,
  message,
  idSessionHasRoom
) => {
  await axios.post(`${baseURL}/api/sessionsHasRoomHasMessage`, {
    sessions_has_room_id: idSessionHasRoom,
    message_id: message.id,
    room_id: currentRoomId,
    isFound: 0,
    date: new Date().toISOString().slice(0, 19).replace('T', ' '),
    startMessage: 0,
    startQuestionnary: 0,
  });
};

/**
 * Modify the table `sessionsHasRoomHasMessage`
 * @param {number} idSessionHasRoom
 * @param {Object} modification - contains the data we want to modify in DB
 */
const putSessionsHasRoomHasMessage = async (idSessionHasRoom, modification) => {
  await axios.put(
    `${baseURL}/api/sessionsHasRoomHasMessage/${idSessionHasRoom}`,
    modification
  );
};

/**
 * Get the data from the table sessions_has_room_has_message for a particular idSessionHasRoom and currentRoomId
 * @param {number} idSessionHasRoom
 * @param {number} currentRoomId
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const getSessionsHasRoomHasMessage = async (idSessionHasRoom, currentRoomId) => {
  const { data } = await axios.get(
    `${baseURL}/api/sessionsHasRoomHasMessage/${idSessionHasRoom}/rooms/${currentRoomId}/messages`
  );
  return data;
};

export {
  postSessionsHasRoomHasMessage,
  putSessionsHasRoomHasMessage,
  getSessionsHasRoomHasMessage,
};
