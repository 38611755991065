const imagesFooter = {
  micro:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Freusable%2Fvisio%2Fmicro-icon.svg?alt=media&token=d9ba2801-2c7b-4300-a4eb-a497fb5e8d6d',
  microSlash:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Freusable%2Fvisio%2Fmicro-icon-activated.svg?alt=media&token=2c2184ae-9046-46e7-8f0c-9c298ecc2e87',
  video:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Freusable%2Fvisio%2Fcamera-icon.svg?alt=media&token=4fc4dcd3-9d4d-4544-ba71-cae5d4e0bf5c',
  videoSlash:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Multi%20Room%2FEmotion%2Fassets%2Freusable%2Fvisio%2Fcamera-icon-activated.svg?alt=media&token=95b9efe5-3ac4-4793-a7b7-f5db3ba3f1cd',
};

const maxOfUser = 4;
const minOfUser = 2;

const secondsOfDebriefing = 60;

export { imagesFooter, maxOfUser, minOfUser, secondsOfDebriefing };
