import styled from 'styled-components';

const RoomContainer = styled.div`
  height: 100%;
  overflow: hidden;

  header {
    display: flex;
    position: absolute;
    width: 100%;
  }
`;

export default RoomContainer;
