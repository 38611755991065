import InterpretationMachineCalibrationRoom from 'components/GameBoard/Emotion/Solo/Rooms/InterpretationMachineCalibrationRoom';
import EmotionGenerationRoom from 'components/GameBoard/Emotion/Solo/Rooms/EmotionGenerationRoom';
import BehaviourMachineCalibrationRoom from 'components/GameBoard/Emotion/Solo/Rooms/BehaviourMachineCalibrationRoom';
import ComplexSituationRoom from 'components/GameBoard/Emotion/Solo/Rooms/ComplexSituationRoom';
import KitchenLivingRoom from 'components/GameBoard/Obesity/Rooms/KitchenLivingRoom';
import WelcomeRPS from 'components/GameBoard/RPS/Rooms/WelcomeRPS';
import BreakRoomRPS from 'components/GameBoard/RPS/Rooms/BreakRoomRPS';
import TabletRPS from 'components/GameBoard/RPS/Rooms/TabletRPS';
import WelcomeRPSCongres from 'components/GameBoard/RPSCongres/Rooms/WelcomeRPS';
import BedroomTDE from 'components/GameBoard/TDE/Rooms/BedroomTDE';
import ChangingRoomTMS from 'components/GameBoard/TMS/Rooms/ChangingRoomTMS';
import CorridorTMS from 'components/GameBoard/TMS/Rooms/CorridorTMS';
import RestaurantTMS from 'components/GameBoard/TMS/Rooms/RestaurantTMS';
import CorridorTMSCongres from 'components/GameBoard/TMSCongres/Rooms/CorridorTMSCongres';
import DesktopV2 from 'components/GameBoard/Emotion/Multi/Rooms/DesktopV2';

// List of the rooms for each game - put the name of the game folder in the key
const RoomsComponents = {
  Emotion: {
    list: {
      InterpretationMachineCalibrationRoom,
      EmotionGenerationRoom,
      BehaviourMachineCalibrationRoom,
      ComplexSituationRoom,
    },
    theme: { tealBlue: '#107679' },
  },
  Obesity: { list: { KitchenLivingRoom } },
  RPS: { list: { WelcomeRPS, BreakRoomRPS, TabletRPS } },
  RPSCongres: {
    type: 'demo',
    list: { WelcomeRPS },
    WelcomeRPS: { WelcomeRPSCongres },
  },
  TDE: { list: { BedroomTDE } },
  TMS: { list: { ChangingRoomTMS, CorridorTMS, RestaurantTMS } },
  TMSCongres: {
    type: 'demo',
    list: { CorridorTMS },
    CorridorTMS: { CorridorTMSCongres },
  },
  EmotionMulti: {
    list: { DesktopV2 },
  },
};

export default RoomsComponents;
