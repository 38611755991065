import styled, { css, keyframes } from 'styled-components';

/* Background style using styled-components library */
// Define a keyframe for the translation of the background
const translateKeyframe = (start, end) => keyframes`
from {
  transform: translateX(${start});
}
to {
  transform: translateX(${end});
}
`;

/**
 * Define the animations according the current position of the background
 * @param {string} position - current background position
 * @param {string} start - start position of the background when we launch the room
 * @param {string} right - right position of the background when we click on the right chevron
 * @param {string} left - left position of the background when we click on the left chevron
 * @param {string} duration - animation duration
 * @param {string} timing - animation timing function
 * @param {string} fillMode - animation fill mode
 * @returns {string}
 */
const translationAnimation = (
  position,
  start,
  right,
  left,
  duration,
  timing,
  fillMode
) => {
  switch (position) {
    case 'right-from-center':
      return css`
        ${translateKeyframe(start, right)} ${duration} ${timing} ${fillMode}
      `;
    case 'center-from-left':
      return css`
        ${translateKeyframe(left, start)} ${duration} ${timing} ${fillMode}
      `;
    case 'left-from-center':
      return css`
        ${translateKeyframe(start, left)} ${duration} ${timing} ${fillMode}
      `;
    case 'center-from-right':
      return css`
        ${translateKeyframe(right, start)} ${duration} ${timing} ${fillMode}
      `;
    default:
      return '';
  }
};

// Define a styled component for the background
const BackgroundStyle = styled.button`
  transform: ${({ position, start }) =>
    position === 'center' && `translateX(${start})`};
  animation: ${({ position, start, right, left, duration, timing, fillMode }) =>
    translationAnimation(position, start, right, left, duration, timing, fillMode)};
  height: 100%;
  width: ${(props) => props.backgroundWidth};
  padding: 0;
  border: 0;
  background-color: transparent;
`;

export default BackgroundStyle;
