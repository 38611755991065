import { useSelector } from 'react-redux';
import { selectRoom } from 'components/Reducers/Room';

import InterpretationMachineCalibration from 'components/GameBoard/Emotion/Solo/Enigma/InterpretationMachineCalibration';
import EmotionGeneration from 'components/GameBoard/Emotion/Solo/Enigma/EmotionGeneration';
import BehaviourMachineCalibration from 'components/GameBoard/Emotion/Solo/Enigma/BehaviourMachineCalibration';
import {
  InterpretationMachineCalibrationRoomId,
  EmotionGenerationRoomId,
  BehaviourMachineCalibrationRoomId,
  ComplexSituationRoomId,
} from 'components/GameBoard/Emotion/Solo/constants';
import ComplexSituation from 'components/GameBoard/Emotion/Solo/Enigma/ComplexSituation';

function ModalEnigmaEmotion() {
  const { currentRoomId } = useSelector(selectRoom);

  // Return the enigma component of a specific room
  const handleEnigma = () => {
    switch (currentRoomId) {
      case InterpretationMachineCalibrationRoomId:
        return <InterpretationMachineCalibration />;
      case EmotionGenerationRoomId:
        return <EmotionGeneration />;
      case BehaviourMachineCalibrationRoomId:
        return <BehaviourMachineCalibration />;
      case ComplexSituationRoomId:
        return <ComplexSituation />;
      default:
        return null;
    }
  };

  return handleEnigma();
}

export default ModalEnigmaEmotion;
