import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import BaseAnimation from 'assets/css/BaseAnimation';
import FooterPanel, {
  FooterContentStyle,
} from 'components/GameBoard/Emotion/Solo/Enigma/FooterPanel';
import Reactions from 'components/GameBoard/Emotion/Solo/Enigma/Reactions';
import TranslateKeyframe from 'assets/css/TranslateKeyframe';
// CSS
import styles from 'components/GameBoard/Emotion/Solo/Enigma/ComplexSituation.module.scss';

// Selector functions
import { selectComplexSituationEnigma } from 'components/Reducers/emotion';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
// Utils & constants
import { calculatePoints, clickCount } from 'utils/utilityFunctions';
import { enigmas } from 'components/GameBoard/Emotion/Solo/constants';
// Assets
import successSound from 'sound/Success.mp3';
// import failSound from 'sound/fail.mp3';
import energySound from 'sound/power-down-42676.mp3';
import ReasonedReactionsPanel from './ReasonedReactionsPanel';
// import test from './Vector.svg';

const {
  footerStyle,
  behaviourPanelUrl,
  behaviourPanelStyle,
  decreaseForInstinctiveReaction,
  decreaseForReasonedReaction,
  rightAnswers,
  basePoints,
  decreasePoints,
  duration,
  fillMode,
  timing,
} = enigmas.ComplexSituation;

// CSS in JS
const SidePanel = styled(BaseAnimation)`
  animation-name: ${({ coordinates }) =>
    TranslateKeyframe(coordinates.startX, coordinates.endX)};
  display: ${({ isLoaded }) => !isLoaded && 'none'};
  width: ${({ sidePanelImage }) => sidePanelImage.width};
`;
const BehaviourContentStyle = styled(FooterContentStyle)`
  flex-direction: column;
  justify-content: space-evenly;

  .reaction-container {
    > div {
      display: flex;
      align-items: center;

      .reaction-text {
        font-weight: bold;
        font-size: 1.25rem;
      }

      .reasoned-reaction-text {
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }

  img {
    margin-right: 1rem;
  }

  .explanation {
    font-style: italic;
    margin-left: 78px;
    margin-top: 0;
  }
`;

const BehaviourPanel = () => {
  const { t } = useTranslation(['emotion', 'common']);

  const dispatch = useDispatch();

  const behaviours = t('enigma4.behaviours', {
    returnObjects: true,
  });

  const [sideImageDimensions, setSideImageDimensions] = useState({
    height: 0,
    width: 0,
  });

  const [isReasonedReactions, setIsReasonedReactions] = useState(false);

  const { soundtrack } = useSelector(infoGameUser);
  const { shuffledStimuli, stimulusIndex, energyPercentage, stimulusPlayState } =
    useSelector(selectComplexSituationEnigma);
  const currentBehaviours = behaviours.filter(
    (behaviour) => behaviour.stimulusId === shuffledStimuli[stimulusIndex].id
  );
  const currentInstinctiveBehaviour = currentBehaviours.find(
    (behaviour) => behaviour.type === 'instinctive'
  );
  const currentReasonedBehaviours = currentBehaviours.filter(
    (behaviour) => behaviour.type === 'reasoned'
  );

  const handleAnswer = (behaviour) => {
    dispatch({
      type: 'SET_CLICKED_REACTION',
      payload: behaviour,
    });
    clickCount(dispatch, {});

    const rightAnswer = rightAnswers.find(
      (answer) => answer.stimulusId === shuffledStimuli[stimulusIndex].id
    );

    if (behaviour.id !== rightAnswer.behaviourId) {
      const newEnergyPercentage = energyPercentage - decreaseForReasonedReaction;
      dispatch({
        type: 'SET_ENERGY_PERCENTAGE',
        payload: newEnergyPercentage,
      });
      if (soundtrack) new Audio(energySound).play();
    } else {
      // Update the score
      dispatch({
        type: 'UPDATE_GAME_SCORE',
        payload: calculatePoints(basePoints, 0, decreasePoints),
      });
      if (soundtrack) new Audio(successSound).play();
    }
  };

  const coordinates = {
    startX: `-${sideImageDimensions.width}px`,
    endX: '0px',
  };

  const handleReturnClick = () => {
    setIsReasonedReactions(false);
  };

  const handleReactionClick = (e) => {
    if (e.target.parentElement.id === 'choice-1') {
      clickCount(dispatch, e);
      dispatch({
        type: 'SET_CLICKED_REACTION',
        payload: currentInstinctiveBehaviour,
      });
      dispatch({
        type: 'SET_ENERGY_PERCENTAGE',
        payload: energyPercentage - decreaseForInstinctiveReaction,
      });
      if (soundtrack) new Audio(energySound).play();
    } else {
      setIsReasonedReactions(true);
    }
  };

  const handleReactionsDisplay = () => {
    const displayedElements = [];
    for (let reaction = 1; reaction < 3; reaction += 1) {
      displayedElements.push(
        <Reactions
          currentInstinctiveBehaviour={currentInstinctiveBehaviour}
          currentReasonedBehaviours={currentReasonedBehaviours}
          handleReactionClick={handleReactionClick}
          el={reaction}
          key={reaction}
        />
      );
    }
    return displayedElements;
  };

  return (
    stimulusPlayState === 'paused' && (
      <div className={styles['enigma-part-2-container']}>
        <SidePanel
          as="div"
          coordinates={coordinates}
          duration={duration}
          timingFunction={timing}
          iterationCount="1"
          fillMode={fillMode}
          playState="running"
          isLoaded={!!sideImageDimensions.width}
          sidePanelImage={sideImageDimensions.width}
        >
          <img
            alt={t('panel')}
            src={behaviourPanelUrl}
            onLoad={(event) =>
              setSideImageDimensions({
                width: event.target.width,
                height: event.target.height,
              })
            }
          />
          {!!sideImageDimensions.width && !!sideImageDimensions.height && (
            <BehaviourContentStyle
              footerStyle={behaviourPanelStyle.behaviour}
              footerDimensions={sideImageDimensions}
            >
              {!isReasonedReactions ? (
                <>{handleReactionsDisplay()}</>
              ) : (
                <ReasonedReactionsPanel
                  handleAnswer={handleAnswer}
                  handleReturnClick={handleReturnClick}
                  currentReasonedBehaviours={currentReasonedBehaviours}
                />
              )}
            </BehaviourContentStyle>
          )}
        </SidePanel>
        <FooterPanel
          room="ComplexSituation"
          content={shuffledStimuli[stimulusIndex]}
          style={footerStyle}
        />
      </div>
    )
  );
};

export default BehaviourPanel;
