import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

/**
 * Resize the background image when the window is resized
 * @param {Object} backgroundImageRef ref containing the background image DOM element
 */
const useWindowResize = (backgroundImageRef) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      dispatch({
        type: 'SET_BACKGROUND_DIMENSIONS',
        payload: {
          width: backgroundImageRef.current.offsetWidth,
          height: backgroundImageRef.current.offsetHeight,
        },
      });
    };
    // Save to the store the background image dimensions when component is mounted
    if (backgroundImageRef.current) handleResize();

    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [backgroundImageRef, dispatch]);
};
export default useWindowResize;
