const initialState = {
  modal: {
    isOpen: false,
    description: {},
  },
  zoom: {
    isOpen: false,
    description: {},
  },
  modalZoom: {
    isOpen: false,
    description: {},
  },
  modalRobot: {
    isOpen: false,
    description: {},
  },
  modalEnigma: {
    isOpen: false,
    description: {},
  },
  modalEnigmaEmotion: {
    isOpen: false,
    description: {},
  },
};

const Modal = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_MODAL': {
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: true,
          description: action.payload,
        },
      };
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: false,
          description: {},
        },
      };
    }
    case 'OPEN_ZOOM': {
      return {
        ...state,
        zoom: {
          ...state.zoom,
          isOpen: true,
          description: action.payload,
        },
      };
    }
    case 'CLOSE_ZOOM': {
      return {
        ...state,
        zoom: {
          ...state.zoom,
          isOpen: false,
          description: {},
        },
      };
    }
    case 'OPEN_MODAL_ZOOM': {
      return {
        ...state,
        modalZoom: {
          ...state.modalZoom,
          isOpen: true,
          description: action.payload,
        },
      };
    }

    case 'MODIFY_MODAL_ZOOM': {
      return {
        ...state,
        modalZoom: {
          ...state.modalZoom,
          description: {
            ...state.modalZoom.description,
            message: action.payload,
          },
        },
      };
    }
    case 'CLOSE_MODAL_ZOOM': {
      return {
        ...state,
        modalZoom: {
          ...state.modalZoom,
          isOpen: false,
          description: {},
        },
      };
    }
    case 'OPEN_MODAL_ROBOT': {
      return {
        ...state,
        modalRobot: {
          ...state.modalRobot,
          isOpen: true,
          description: action.payload,
        },
      };
    }
    case 'CLOSE_MODAL_ROBOT': {
      return {
        ...state,
        modalRobot: {
          ...state.modalRobot,
          isOpen: false,
          description: {},
        },
      };
    }
    case 'OPEN_MODAL_ENIGMA': {
      return {
        ...state,
        modalEnigma: {
          ...state.modalEnigma,
          isOpen: true,
          description: action.payload,
        },
      };
    }

    // TODO merge with OPEN_MODAL_ENIGMA ?
    case 'OPEN_MODAL_ENIGMA_EMOTION': {
      return {
        ...state,
        modalEnigmaEmotion: {
          ...state.modalEnigmaEmotion,
          isOpen: true,
          description: action.payload,
        },
      };
    }

    case 'CLOSE_MODAL_ENIGMA': {
      return {
        ...state,
        modalEnigma: {
          ...state.modalEnigma,
          isOpen: false,
          description: {},
        },
      };
    }

    // TODO merge with CLOSE_MODAL_ENIGMA ?
    case 'CLOSE_MODAL_ENIGMA_EMOTION': {
      return {
        ...state,
        modalEnigmaEmotion: {
          ...state.modalEnigmaEmotion,
          isOpen: false,
          description: {},
        },
      };
    }

    case 'SWITCH_GARLAND': {
      return {
        ...state,
        modalZoom: {
          ...state.modalZoom,
          description: {
            ...state.modalZoom.description,
            objectContent: {
              ...state.modalZoom.description.objectContent,
              switchLight: action.payload,
            },
          },
        },
      };
    }
    case 'CHAINING_GARLAND': {
      return {
        ...state,
        modalZoom: {
          ...state.modalZoom,
          description: {
            ...state.modalZoom.description,
            objectContent: {
              ...state.modalZoom.description.objectContent,
              chaining: action.payload,
            },
          },
        },
      };
    }
    case 'RESET_CHAINING_GARLAND': {
      return {
        ...state,
        modalZoom: {
          ...state.modalZoom,
          description: {
            ...state.modalZoom.description,
            objectContent: {
              ...state.modalZoom.description.objectContent,
              chaining: [],
            },
          },
        },
      };
    }
    default:
      return state;
  }
};

// Define the selector functions
export const selectModal = (state) => state.Modal;

export default Modal;
