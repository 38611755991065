import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

/**
 * Get the data from the table session_has_room_has_object for a particular room and session
 * @param {number} idSessionHasRoom
 * @param {number} currentRoomId
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const getSessionObjects = async (idSessionHasRoom, currentRoomId) => {
  const { data } = await axios.get(
    `${baseURL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}/rooms/${currentRoomId}/objects`
  );
  return data;
};

/**
 *  * Initialize the link between a session, a room and objects
 * @param {number} idSessionHasRoom
 * @param {number} objectId
 * @param {number} currentRoomId
 */
const postSessionsHasRoomHasObject = async (
  idSessionHasRoom,
  objectId,
  currentRoomId
) => {
  await axios.post(`${baseURL}/api/sessionsHasRoomHasObject`, {
    sessions_has_room_id: idSessionHasRoom,
    object_id: objectId,
    room_id: currentRoomId,
    isChecked: 0,
    isClicked: 0,
    isInventory: 0,
    status: null,
    date: null,
  });
};

/**
 * Update the table `sessions_has_room_has_object`
 * @param {number} idSessionHasRoom
 * @param {Object} modification - contains the data we want to modify in DB
 */
const putSessionsHasRoomHasObject = async (idSessionHasRoom, modification) => {
  await axios.put(
    `${baseURL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`,
    modification
  );
};

export {
  getSessionObjects,
  postSessionsHasRoomHasObject,
  putSessionsHasRoomHasObject,
};
