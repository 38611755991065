import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

/**
 * Get the description/content (audio + text) of a message
 * @param {number} messageId
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const getMessageDescription = async (messageId) => {
  const { data } = await axios.get(`${baseURL}/api/messages/${messageId}/contents`);
  return data;
};

/**
 * Get all messages of one game
 * @param {number} gameId
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const getMessagesOfGame = async (gameId) => {
  const { data } = await axios.get(`${baseURL}/api/games/${gameId}/messages`);
  data.sort(
    (firstMessage, nextMessage) =>
      firstMessage.main_emotion - nextMessage.main_emotion
  );
  return data;
};

export { getMessageDescription, getMessagesOfGame };
