import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import {
  BsFillSunFill,
  BsCloudSunFill,
  BsSnow,
  BsFillCloudRainHeavyFill,
} from 'react-icons/bs';
import { RiThunderstormsFill } from 'react-icons/ri';

import '../../../../../assets/css/components/GameBoard/Obesity/Enigma/Phone/Weather.css';

function Weather() {
  const locationInput = useRef(null);

  const [location, setLocation] = useState('Bordeaux');
  const [days, setDays] = useState([]);
  const [daysFull, setDaysFull] = useState([]);
  const [temps, setTemps] = useState([]);
  const [minTemps, setMinTemps] = useState([]);
  const [maxTemps, setMaxTemps] = useState([]);
  const [weather, setWeather] = useState([]);
  const [icons, setIcons] = useState([]);
  const [displayIndex] = useState(0);

  const buildUrlApi = () => {
    const locationElement = encodeURIComponent(location);
    const urlPrefix = 'https://api.openweathermap.org/data/2.5/forecast?q=';
    const urlSuffix = '&APPID=fb1158dc7dfef5f0967ceac8f71ee3a6&units=metric';

    return [urlPrefix, locationElement, urlSuffix].join('');
  };
  const computeNearestHr = () => {
    const currentTimeInHrs = new Date().getHours();
    const constHrs = [0, 3, 6, 9, 12, 15, 18, 21];
    const differences = constHrs.map((e) => Math.abs(e - currentTimeInHrs));
    const indexofLowestDiff = differences.indexOf(Math.min(...differences));

    return constHrs[indexofLowestDiff];
  };
  const currentData = (dataContent) => {
    const nearestHr = computeNearestHr();

    return dataContent.list.find(
      (e) => new Date(e.dt_txt).getHours() === nearestHr
    );
  };

  const convertWeatherIcons = (weatherContent) => {
    switch (weatherContent) {
      case 'Clear':
        return 'circle-outline';
      case 'Clouds':
        return 'weather-cloudy';
      case 'Snow':
        return 'weather-snowy';
      case 'Rain':
        return 'weather-pouring';
      case 'Drizzle':
        return 'weather-pouring';
      case 'Thunderstorm':
        return 'weather-lightning-rainy';
      default:
        return 'weather-cloudy';
    }
  };

  const fetchData = () => {
    const url = buildUrlApi();
    axios.get(url).then((response) => {
      const currentDataContent = currentData(response.data);
      const dayOfWeek = ['Di', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
      const dayOfWeekFull = [
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
      ];
      const currentDay = "Aujourd'hui";
      const currentDayFull =
        dayOfWeekFull[new Date(currentDataContent.dt_txt).getDay()];
      const currentTemp = Math.round(currentDataContent.main.temp);
      const currentMinTemp = Math.round(currentDataContent.main.temp_min);
      const currentMaxTemp = Math.round(currentDataContent.main.temp_max);
      const currentWeather =
        currentDataContent.weather[0].main === 'Clouds'
          ? 'Cloudy'
          : currentDataContent.weather[0].main;
      const currentIcon = convertWeatherIcons(currentDataContent.weather[0].main);

      const daysContent = [];
      const daysFullContent = [];
      const tempsContent = [];
      const minTempsContent = [];
      const maxTempsContent = [];
      const weatherContent = [];
      const iconsContent = [];
      for (let i = 0; i < response.data.list.length; i += 8) {
        const date = new Date(response.data.list[i].dt_txt);
        const day = dayOfWeek[date.getDay()];
        const dayFull = dayOfWeekFull[date.getDay()];
        daysContent.push(day);
        daysFullContent.push(dayFull);
        tempsContent.push(Math.round(response.data.list[i].main.temp));
        minTempsContent.push(Math.round(response.data.list[i].main.temp_min));
        maxTempsContent.push(Math.round(response.data.list[i].main.temp_max));

        if (response.data.list[i].weather[0].main === 'Clouds') {
          weatherContent.push('Cloudy');
        } else {
          weatherContent.push(response.data.list[i].weather[0].main);
        }

        iconsContent.push(
          convertWeatherIcons(response.data.list[i].weather[0].main)
        );
      }

      setDays([currentDay, ...daysContent.slice(1)]);
      setDaysFull([currentDayFull, ...daysFullContent.slice(1)]);
      setTemps([currentTemp, ...tempsContent.slice(1)]);
      setMinTemps([currentMinTemp, ...minTempsContent.slice(1)]);
      setMaxTemps([currentMaxTemp, ...maxTempsContent.slice(1)]);
      setWeather([currentWeather, ...weatherContent.slice(1)]);
      setIcons([currentIcon, ...iconsContent.slice(1)]);
    });
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFocus = (e) => {
    e.target.select();
  };

  const changeLocation = (e) => {
    e.preventDefault();
    const inputLocation = locationInput.value;

    setLocation(inputLocation);
    fetchData();
  };

  let background = '';
  switch (weather[displayIndex]) {
    case 'Clear':
      background = 'clear';
      break;
    case 'Cloudy':
      background = 'cloudy';
      break;
    case 'Snow':
      background = 'snow';
      break;
    case 'Rain':
      background = 'rain';
      break;
    case 'Drizzle':
      background = 'rain';
      break;
    case 'Thunderstorm':
      background = 'thunderstorm';
      break;
    default:
      background = 'cloudy';
  }

  const handleTextWeather = (weatherContent) => {
    switch (weatherContent) {
      case 'Clear':
        return 'Ensoleillé';
      case 'Cloudy':
        return 'Nuageux';

      case 'Snow':
        return 'Neige';

      case 'Rain':
        return 'Pluvieux';

      case 'Drizzle':
        return 'Pluvieux';
      case 'Thunderstorm':
        return 'Orageux';
      default:
        return weather;
    }
  };

  const handleIconWeather = (item) => {
    switch (item) {
      case 'circle-outline':
        return <BsFillSunFill size={20} style={{ color: 'white' }} />;
      case 'weather-cloudy':
        return <BsCloudSunFill size={20} style={{ color: 'white' }} />;
      case 'snowy':
        return <BsSnow size={20} style={{ color: 'white' }} />;
      case 'weather-pouring':
        return <BsFillCloudRainHeavyFill size={20} style={{ color: 'white' }} />;
      case 'weather-lightning-rainy':
        return <RiThunderstormsFill ize={20} style={{ color: 'white' }} />;
      default:
        return <BsCloudSunFill size={20} style={{ color: 'white' }} />;
    }
  };

  return (
    <div className="Weather">
      <div className={'widget '.concat(...background)}>
        <form onSubmit={(e) => changeLocation(e)}>
          <div className="inline-input">
            <input
              ref={locationInput}
              className="location-input"
              defaultValue={location}
              type="text"
              onFocus={(e) => handleFocus(e)}
            />
          </div>
        </form>

        <div className="main-display">
          <div className="main-info">
            <div className="temp-measurement">{temps[displayIndex]}</div>
            <div className="temp-unit">°C</div>
          </div>

          <div className="sub-info">
            <div className="sub-info-title">{daysFull[displayIndex]}</div>

            <div className="sub-info-text">
              {handleTextWeather(weather[displayIndex])}
            </div>

            <div className="sub-info-text">
              <span className="max-temp">
                <AiOutlineArrowUp size={20} style={{ color: 'white' }} />
                {maxTemps[displayIndex]}
                °C
              </span>
              <span className="min-temp">
                <AiOutlineArrowDown size={20} style={{ color: 'white' }} />
                {minTemps[displayIndex]}
                °C
              </span>
            </div>
          </div>
        </div>

        <div className="selection-panel">
          <div className="selection-row">
            {icons.map((item, index) => {
              if (displayIndex === index) {
                return (
                  <div className="selection-icons selected" key={displayIndex}>
                    {handleIconWeather(item)}
                    <p>{days[index]}</p>
                  </div>
                );
              }
              return (
                <div className="selection-icons" key={displayIndex}>
                  {handleIconWeather(item)}
                  <p>{days[index]}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Weather;
