// tile size for asset and player movement
import * as images from '../../../img/vaccination-solo/index';

// export const SIZE_TILE = 28;
export const SIZE_TILE = 1.4622222222;

// lab's size, CANNOT be changed atm
export const MAP_WIDTH = 27;
export const MAP_HEIGHT = 27;

// max size of the map to avoid player going out the lab
export const MAX_WIDTH = SIZE_TILE * MAP_WIDTH;
export const MAX_HEIGHT = SIZE_TILE * (MAP_HEIGHT - 1);

export const WIN_ROW = 25;
export const WIN_COLUMN = 25;

// player constant inital

// export const PLAYER_ROW = 1;
// export const PLAYER_COLUMN = 1;

// export const PLAYER_TOP = (PLAYER_ROW + 1) * SIZE_TILE;
// export const PLAYER_LEFT = PLAYER_COLUMN * SIZE_TILE;

export const PLAYER_ROW = 1;
export const PLAYER_COLUMN = 1;

// export const PLAYER_TOP = (PLAYER_ROW + 1) * SIZE_TILE;
export const PLAYER_TOP = PLAYER_ROW * SIZE_TILE;
export const PLAYER_LEFT = PLAYER_COLUMN * SIZE_TILE;

// exit test

export const EXIT_ROW = 25;
export const EXIT_COLUMN = 25;

export const EXIT_TOP = EXIT_ROW * SIZE_TILE;
export const EXIT_LEFT = EXIT_COLUMN * SIZE_TILE;

// constant for virus

export const VIRUS1_ROW = 17;
export const VIRUS1_COLUMN = 3;

export const VIRUS1_TOP = VIRUS1_ROW * SIZE_TILE;
export const VIRUS1_LEFT = VIRUS1_COLUMN * SIZE_TILE;

export const VIRUS2_ROW = 3;
export const VIRUS2_COLUMN = 13;

export const VIRUS2_TOP = VIRUS2_ROW * SIZE_TILE;
export const VIRUS2_LEFT = VIRUS2_COLUMN * SIZE_TILE;

export const VIRUS3_ROW = 17;
export const VIRUS3_COLUMN = 25;

export const VIRUS3_TOP = VIRUS3_ROW * SIZE_TILE;
export const VIRUS3_LEFT = VIRUS3_COLUMN * SIZE_TILE;

export const VIRUS4_ROW = 9;
export const VIRUS4_COLUMN = 3;

export const VIRUS4_TOP = VIRUS4_ROW * SIZE_TILE;
export const VIRUS4_LEFT = VIRUS4_COLUMN * SIZE_TILE;

export const VIRUS5_ROW = 7;
export const VIRUS5_COLUMN = 25;

export const VIRUS5_TOP = VIRUS5_ROW * SIZE_TILE;
export const VIRUS5_LEFT = VIRUS5_COLUMN * SIZE_TILE;

// monster constat VIRUS 1 GOOD

export const COVID1_ROW = 6; // * 41
export const COVID1_COLUMN = 17;

export const COVID1_TOP = COVID1_ROW * SIZE_TILE;
export const COVID1_LEFT = COVID1_COLUMN * SIZE_TILE;

// export const COVID1_ROW = 2
// export const COVID1_COLUMN = 2

// export const COVID1_TOP = 96
// export const COVID1_LEFT = 64

// monster constat

export const COVID2_ROW = 19; // * 34,5
export const COVID2_COLUMN = 6;

export const COVID2_TOP = COVID2_ROW * SIZE_TILE;
export const COVID2_LEFT = COVID2_COLUMN * SIZE_TILE;

export const COVID3_ROW = 19;
export const COVID3_COLUMN = 18;

export const COVID3_TOP = COVID3_ROW * SIZE_TILE;
export const COVID3_LEFT = COVID3_COLUMN * SIZE_TILE;

// constant for Life in game

export const LIFE1_ROW = 11; // * 41
export const LIFE1_COLUMN = 15;

export const LIFE1_TOP = LIFE1_ROW * SIZE_TILE;
export const LIFE1_LEFT = LIFE1_COLUMN * SIZE_TILE;

export const LIFE2_ROW = 23; // * 41
export const LIFE2_COLUMN = 5;

export const LIFE2_TOP = LIFE2_ROW * SIZE_TILE;
export const LIFE2_LEFT = LIFE2_COLUMN * SIZE_TILE;

export const PUCK_LAB_SCORE = 300;
export const SCORE_POINT = 100;

export const gameOverLife = [
  {
    id: 1,
    isActive: true,
    isBeingLost: false,
  },
  {
    id: 2,
    isActive: false,
    isBeingLost: false,
  },
  {
    id: 3,
    isActive: false,
    isBeingLost: false,
  },
  {
    id: 4,
    isActive: false,
    isBeingLost: false,
  },
  {
    id: 5,
    isActive: false,
    isBeingLost: false,
  },
];

export const gameOverLife2 = [
  {
    id: 1,
    isActive: true,
    isBeingLost: false,
  },
  {
    id: 2,
    isActive: true,
    isBeingLost: false,
  },
  {
    id: 3,
    isActive: false,
    isBeingLost: false,
  },
  {
    id: 4,
    isActive: false,
    isBeingLost: false,
  },
  {
    id: 5,
    isActive: false,
    isBeingLost: false,
  },
];

export const textHelp = [
  {
    id: 1,
    title: 'Serveur',
    text: 'agréé',
  },
  {
    id: 2,
    title: 'Serveur',
    text: 'disponible',
  },
  {
    id: 3,
    title: 'Serveur',
    text: "disposant de l'antivirus",
  },
];

export const puckLabScore = [
  {
    id: 1,
    image: null,
    score: 300,
    isActive: false,
  },
  {
    id: 2,
    image: null,
    score: 600,
    isActive: false,
  },
  {
    id: 3,
    image: null,
    score: 900,
    isActive: false,
  },
  {
    id: 4,
    image: null,
    score: 1200,
    isActive: false,
  },
];

export const martinSaysScore = [
  {
    id: 1,
    image: null,
    score: 100,
    isActive: false,
  },
  {
    id: 2,
    image: null,
    score: 300,
    isActive: false,
  },
  {
    id: 3,
    image: null,
    score: 500,
    isActive: false,
  },
  {
    id: 4,
    image: null,
    score: 600,
    isActive: false,
  },
];

export const coLoveDutyScore = [
  {
    id: 1,
    image: null,
    score: 150,
    isActive: false,
  },
  {
    id: 2,
    image: null,
    score: 300,
    isActive: false,
  },
  {
    id: 3,
    image: null,
    score: 450,
    isActive: false,
  },
  {
    id: 4,
    image: null,
    score: 600,
    isActive: false,
  },
];

export const dailySeekerScore = [
  {
    id: 1,
    image: null,
    score: 300,
    isActive: false,
  },
  {
    id: 2,
    image: null,
    score: 600,
    isActive: false,
  },
  {
    id: 3,
    image: null,
    score: 900,
    isActive: false,
  },
  {
    id: 4,
    image: null,
    score: 1200,
    isActive: false,
  },
];

export const VaccinationFinalScore = [
  {
    id: 1,
    image: null,
    score: 850,
    isActive: false,
  },
  {
    id: 2,
    image: null,
    score: 1800,
    isActive: false,
  },
  {
    id: 3,
    image: null,
    score: 2750,
    isActive: false,
  },
  {
    id: 4,
    image: null,
    score: 3600,
    isActive: false,
  },
];

// End of PUCK LAB

//  = [CoLoveGood1,]

const CoLoveGood1 = [
  { id: 1, image: images.tile1, rotation: 1, isActive: true },
  { id: 2, image: images.tile2, rotation: 1 },
  { id: 3, image: images.tile3, rotation: 1 },
  { id: 4, image: images.tile4, rotation: 1 },
  { id: 5, image: images.tile5, rotation: 1 },
  { id: 6, image: images.tile6, rotation: 1 },
  { id: 7, image: images.tile7, rotation: 1 },
  { id: 8, image: images.tile8, rotation: 1 },
  { id: 9, image: images.tile9, rotation: 1, isActive: true },
  { id: 10, image: images.tile10, rotation: 1 },
  { id: 11, image: images.tile11, rotation: 1, isActive: true },
  { id: 12, image: images.tile12, rotation: 1 },
  { id: 13, image: images.tile13, rotation: 3 },
  { id: 14, image: images.tile14, rotation: 3, isActive: true },
  { id: 15, image: images.tile15, rotation: 1 },
];

const CoLoveGood2 = [
  { id: 1, image: images.tile1, rotation: 1, isActive: true },
  { id: 2, image: images.tile2, rotation: 1 },
  { id: 3, image: images.tile3, rotation: 1 },
  { id: 4, image: images.tile4, rotation: 1 },
  { id: 5, image: images.tile5, rotation: 1 },
  { id: 6, image: images.tile6, rotation: 1 },
  { id: 7, image: images.tile7, rotation: 1 },
  { id: 8, image: images.tile8, rotation: 1 },
  { id: 9, image: images.tile9, rotation: 1, isActive: true },
  { id: 10, image: images.tile10, rotation: 1 },
  { id: 11, image: images.tile11, rotation: 1, isActive: true },
  { id: 12, image: images.tile12, rotation: 1 },
  { id: 13, image: images.tile13, rotation: 3 },
  { id: 14, image: images.tile14, rotation: 1, isActive: true },
  { id: 15, image: images.tile15, rotation: 1 },
];

const CoLoveGood3 = [
  { id: 1, image: images.tile1, rotation: 1, isActive: true },
  { id: 2, image: images.tile2, rotation: 1 },
  { id: 3, image: images.tile3, rotation: 1 },
  { id: 4, image: images.tile4, rotation: 1 },
  { id: 5, image: images.tile5, rotation: 1 },
  { id: 6, image: images.tile6, rotation: 1 },
  { id: 7, image: images.tile7, rotation: 1 },
  { id: 8, image: images.tile8, rotation: 1 },
  { id: 9, image: images.tile9, rotation: 1, isActive: true },
  { id: 10, image: images.tile10, rotation: 1 },
  { id: 11, image: images.tile11, rotation: 1, isActive: true },
  { id: 12, image: images.tile12, rotation: 3 },
  { id: 13, image: images.tile13, rotation: 1 },
  { id: 14, image: images.tile14, rotation: 3, isActive: true },
  { id: 15, image: images.tile15, rotation: 1 },
];

const CoLoveGood4 = [
  { id: 1, image: images.tile1, rotation: 1, isActive: true },
  { id: 2, image: images.tile2, rotation: 1 },
  { id: 3, image: images.tile3, rotation: 1 },
  { id: 4, image: images.tile4, rotation: 1 },
  { id: 5, image: images.tile5, rotation: 1 },
  { id: 6, image: images.tile6, rotation: 1 },
  { id: 7, image: images.tile7, rotation: 1 },
  { id: 8, image: images.tile8, rotation: 1 },
  { id: 9, image: images.tile9, rotation: 1, isActive: true },
  { id: 10, image: images.tile10, rotation: 1 },
  { id: 11, image: images.tile11, rotation: 1, isActive: true },
  { id: 12, image: images.tile12, rotation: 3 },
  { id: 13, image: images.tile13, rotation: 1 },
  { id: 14, image: images.tile14, rotation: 1, isActive: true },
  { id: 15, image: images.tile15, rotation: 1 },
];

const CoLoveGood5 = [
  { id: 1, image: images.tile1, rotation: 1, isActive: true },
  { id: 2, image: images.tile2, rotation: 1 },
  { id: 3, image: images.tile3, rotation: 1 },
  { id: 4, image: images.tile4, rotation: 1 },
  { id: 5, image: images.tile5, rotation: 1 },
  { id: 6, image: images.tile6, rotation: 1 },
  { id: 7, image: images.tile7, rotation: 1 },
  { id: 8, image: images.tile8, rotation: 1 },
  { id: 9, image: images.tile9, rotation: 1, isActive: true },
  { id: 10, image: images.tile10, rotation: 1 },
  { id: 11, image: images.tile11, rotation: 1, isActive: true },
  { id: 12, image: images.tile12, rotation: 1 },
  { id: 13, image: images.tile13, rotation: 1 },
  { id: 14, image: images.tile14, rotation: 3, isActive: true },
  { id: 15, image: images.tile15, rotation: 1 },
];

const CoLoveGood6 = [
  { id: 1, image: images.tile1, rotation: 1, isActive: true },
  { id: 2, image: images.tile2, rotation: 1 },
  { id: 3, image: images.tile3, rotation: 1 },
  { id: 4, image: images.tile4, rotation: 1 },
  { id: 5, image: images.tile5, rotation: 1 },
  { id: 6, image: images.tile6, rotation: 1 },
  { id: 7, image: images.tile7, rotation: 1 },
  { id: 8, image: images.tile8, rotation: 1 },
  { id: 9, image: images.tile9, rotation: 1, isActive: true },
  { id: 10, image: images.tile10, rotation: 1 },
  { id: 11, image: images.tile11, rotation: 1, isActive: true },
  { id: 12, image: images.tile12, rotation: 3 },
  { id: 13, image: images.tile13, rotation: 3 },
  { id: 14, image: images.tile14, rotation: 3, isActive: true },
  { id: 15, image: images.tile15, rotation: 1 },
];

const CoLoveGood7 = [
  { id: 1, image: images.tile1, rotation: 1, isActive: true },
  { id: 2, image: images.tile2, rotation: 1 },
  { id: 3, image: images.tile3, rotation: 1 },
  { id: 4, image: images.tile4, rotation: 3 },
  { id: 5, image: images.tile5, rotation: 1 },
  { id: 6, image: images.tile6, rotation: 1 },
  { id: 7, image: images.tile7, rotation: 1 },
  { id: 8, image: images.tile8, rotation: 1 },
  { id: 9, image: images.tile9, rotation: 1, isActive: true },
  { id: 10, image: images.tile10, rotation: 1 },
  { id: 11, image: images.tile11, rotation: 1, isActive: true },
  { id: 12, image: images.tile12, rotation: 1 },
  { id: 13, image: images.tile13, rotation: 3 },
  { id: 14, image: images.tile14, rotation: 3, isActive: true },
  { id: 15, image: images.tile15, rotation: 1 },
];

const CoLoveGood8 = [
  { id: 1, image: images.tile1, rotation: 1, isActive: true },
  { id: 2, image: images.tile2, rotation: 1 },
  { id: 3, image: images.tile3, rotation: 1 },
  { id: 4, image: images.tile4, rotation: 3 },
  { id: 5, image: images.tile5, rotation: 1 },
  { id: 6, image: images.tile6, rotation: 1 },
  { id: 7, image: images.tile7, rotation: 1 },
  { id: 8, image: images.tile8, rotation: 1 },
  { id: 9, image: images.tile9, rotation: 1, isActive: true },
  { id: 10, image: images.tile10, rotation: 1 },
  { id: 11, image: images.tile11, rotation: 1, isActive: true },
  { id: 12, image: images.tile12, rotation: 1 },
  { id: 13, image: images.tile13, rotation: 3 },
  { id: 14, image: images.tile14, rotation: 1, isActive: true },
  { id: 15, image: images.tile15, rotation: 1 },
];

const CoLoveGood9 = [
  { id: 1, image: images.tile1, rotation: 1, isActive: true },
  { id: 2, image: images.tile2, rotation: 1 },
  { id: 3, image: images.tile3, rotation: 1 },
  { id: 4, image: images.tile4, rotation: 3 },
  { id: 5, image: images.tile5, rotation: 1 },
  { id: 6, image: images.tile6, rotation: 1 },
  { id: 7, image: images.tile7, rotation: 1 },
  { id: 8, image: images.tile8, rotation: 1 },
  { id: 9, image: images.tile9, rotation: 1, isActive: true },
  { id: 10, image: images.tile10, rotation: 1 },
  { id: 11, image: images.tile11, rotation: 1, isActive: true },
  { id: 12, image: images.tile12, rotation: 3 },
  { id: 13, image: images.tile13, rotation: 1 },
  { id: 14, image: images.tile14, rotation: 3, isActive: true },
  { id: 15, image: images.tile15, rotation: 1 },
];

const CoLoveGood10 = [
  { id: 1, image: images.tile1, rotation: 1, isActive: true },
  { id: 2, image: images.tile2, rotation: 1 },
  { id: 3, image: images.tile3, rotation: 1 },
  { id: 4, image: images.tile4, rotation: 3 },
  { id: 5, image: images.tile5, rotation: 1 },
  { id: 6, image: images.tile6, rotation: 1 },
  { id: 7, image: images.tile7, rotation: 1 },
  { id: 8, image: images.tile8, rotation: 1 },
  { id: 9, image: images.tile9, rotation: 1, isActive: true },
  { id: 10, image: images.tile10, rotation: 1 },
  { id: 11, image: images.tile11, rotation: 1, isActive: true },
  { id: 12, image: images.tile12, rotation: 3 },
  { id: 13, image: images.tile13, rotation: 1 },
  { id: 14, image: images.tile14, rotation: 1, isActive: true },
  { id: 15, image: images.tile15, rotation: 1 },
];

const CoLoveGood11 = [
  { id: 1, image: images.tile1, rotation: 1, isActive: true },
  { id: 2, image: images.tile2, rotation: 1 },
  { id: 3, image: images.tile3, rotation: 1 },
  { id: 4, image: images.tile4, rotation: 3 },
  { id: 5, image: images.tile5, rotation: 1 },
  { id: 6, image: images.tile6, rotation: 1 },
  { id: 7, image: images.tile7, rotation: 1 },
  { id: 8, image: images.tile8, rotation: 1 },
  { id: 9, image: images.tile9, rotation: 1, isActive: true },
  { id: 10, image: images.tile10, rotation: 1 },
  { id: 11, image: images.tile11, rotation: 1, isActive: true },
  { id: 12, image: images.tile12, rotation: 1 },
  { id: 13, image: images.tile13, rotation: 1 },
  { id: 14, image: images.tile14, rotation: 3, isActive: true },
  { id: 15, image: images.tile15, rotation: 1 },
];

const CoLoveGood12 = [
  { id: 1, image: images.tile1, rotation: 1, isActive: true },
  { id: 2, image: images.tile2, rotation: 1 },
  { id: 3, image: images.tile3, rotation: 1 },
  { id: 4, image: images.tile4, rotation: 3 },
  { id: 5, image: images.tile5, rotation: 1 },
  { id: 6, image: images.tile6, rotation: 1 },
  { id: 7, image: images.tile7, rotation: 1 },
  { id: 8, image: images.tile8, rotation: 1 },
  { id: 9, image: images.tile9, rotation: 1, isActive: true },
  { id: 10, image: images.tile10, rotation: 1 },
  { id: 11, image: images.tile11, rotation: 1, isActive: true },
  { id: 12, image: images.tile12, rotation: 3 },
  { id: 13, image: images.tile13, rotation: 3 },
  { id: 14, image: images.tile14, rotation: 3, isActive: true },
  { id: 15, image: images.tile15, rotation: 1 },
];

const CoLoveGood13 = [
  { id: 1, image: images.tile1, rotation: 1, isActive: true },
  { id: 2, image: images.tile2, rotation: 1 },
  { id: 3, image: images.tile3, rotation: 1 },
  { id: 4, image: images.tile4, rotation: 1 },
  { id: 5, image: images.tile5, rotation: 1 },
  { id: 6, image: images.tile6, rotation: 1 },
  { id: 7, image: images.tile7, rotation: 1 },
  { id: 8, image: images.tile8, rotation: 1 },
  { id: 9, image: images.tile9, rotation: 1, isActive: true },
  { id: 10, image: images.tile10, rotation: 1 },
  { id: 11, image: images.tile11, rotation: 1, isActive: true },
  { id: 12, image: images.tile12, rotation: 1 },
  { id: 13, image: images.tile13, rotation: 1 },
  { id: 14, image: images.tile14, rotation: 1, isActive: true },
  { id: 15, image: images.tile15, rotation: 1 },
];

export const CoLovePossibilities = [
  CoLoveGood1,
  CoLoveGood2,
  CoLoveGood3,
  CoLoveGood4,
  CoLoveGood5,
  CoLoveGood6,
  CoLoveGood7,
  CoLoveGood8,
  CoLoveGood9,
  CoLoveGood10,
  CoLoveGood11,
  CoLoveGood12,
  CoLoveGood13,
];

// object id :

export const TRASH = 120;
export const TRICKY_KEY = 124;
export const CHEST_CLOSED = 115;
export const DISK1 = 125;
export const CHEST_OPEN_WITH_DISK = 126;
export const CHEST_OPEN_EMPTY = 127;
export const TRICKY_COIN = 128;
export const DISK2 = 129;
export const DISK3 = 130;
export const DISK4 = 131;
export const DISK5 = 132;
export const DISK6 = 133;
