import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../Actions/Button';
import QuestionnaryContent from '../Questionnary/QuestionnaryContent';
import '../../../../assets/css/components/GameBoard/ReusableComponents/Modal.css';
import Audio from '../Actions/Audio';

function Modal({
  soundtrack,
  dispatch,
  title,
  message,
  image,
  buttonDescription,
  type,
  currentStep,
  messages,
  gameId,
  action,
  backgroundMessage,
  stepsList,
}) {
  const { t } = useTranslation('common');

  const [reverseCard, setReverseCard] = useState(false);
  const obesityGameId = 160;
  const [audioElement, setAudio] = useState(null);
  const [indexMessage, setIndexMessage] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [blink, setBlink] = useState(true);
  const [reverse, setReverse] = useState(false);

  // Grab the correct data to be displayed inside the modal
  let contentElement;
  if (type === 'intro') {
    // contentElement is an array of strings
    contentElement = messages;
  } else if (type === 'prevention') {
    contentElement = currentStep.messages;
  }

  // if an intro or prevention message appears, the background music stops
  useEffect(() => {
    if (type === 'intro' || type === 'prevention') {
      dispatch({ type: 'MUTE_MUSIC', payload: false });
    }

    // TODO
    /* At the emotion card step, type is not yet updated so the cleanup function
     is done which unmute the music no matter if the player muted it before entering the prevention message or not */
    return () => {
      if (type === 'intro' || type === 'prevention') {
        dispatch({ type: 'MUTE_MUSIC', payload: true });
      }
    };
  }, [dispatch, type]);

  // Handle the closing of the modal
  useEffect(() => {
    if (contentElement && indexMessage === contentElement.length) {
      if (type === 'intro') {
        dispatch({
          type: 'CLOSE_MODAL',
        });

        if (action) {
          action.onClick();
        }
      } else if (type === 'prevention') {
        buttonDescription.onClick();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexMessage]);

  // typeWriter
  // Handle the progressive writing of the text ?
  useEffect(() => {
    // when reverse is true ?
    if (subIndex === 0 && reverse) {
      setReverse(false);
      setIndexMessage((prev) => prev + 1);
      return;
    }

    const timeout = setTimeout(() => {
      setSubIndex((prev) => {
        if (reverse) {
          return 0;
        }
        return prev + 1;
      });
    }, 50);

    if (contentElement && contentElement[indexMessage]) {
      if (contentElement[indexMessage].audio) {
        setAudio(contentElement[indexMessage].audio);
      }
    }
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subIndex, indexMessage, reverse]);

  // blinker
  useEffect(() => {
    const timeout2 = setTimeout(() => {
      setBlink((prev) => !prev);
    }, 500);
    return () => clearTimeout(timeout2);
  }, [blink]);

  // Handle the progressive display of the message
  const handleMessage = () => {
    let messageContent;

    if (!contentElement[indexMessage].description) {
      messageContent = contentElement[indexMessage].substring(0, subIndex);
    } else {
      messageContent = contentElement[indexMessage].description.substring(
        0,
        subIndex
      );
    }
    return `${messageContent}${blink ? '|' : ' '}`;
  };

  /** audioElement
   * Handle the content of the modal
   * @returns
   */
  const handleContent = () => {
    if (type === 'intro' || type === 'prevention') {
      return gameId === obesityGameId ? (
        <div className="ModalRobot_description">
          <div className="ModalRobot_description-contents">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FObesity%2Fassets%2Fobjects%2Frobot.svg?alt=media&token=30b58f7e-f4f1-4fd4-95bb-936360d3278e"
              alt="robot"
              className="bounceRobot ModalRobot_description-robot"
            />
            <div className="ModalRobot_description-bubble">
              <div className="ModalRobot_description-text">
                <Audio
                  sound={audioElement ?? ''}
                  condition={audioElement !== null && soundtrack}
                />

                {contentElement[indexMessage] && <p>{handleMessage()}</p>}
                <button
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: '41vw',
                  }}
                  className="ButtonAction Action"
                  type="button"
                  onClick={() => setReverse(true)}
                >
                  Suivant
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="Modal_description-content-prevention">
          <img alt="message-intro" draggable="false" src={backgroundMessage} />
          <div className="Modal_description-text">
            <div className="Modal_description-text-typewriter">
              <Audio
                sound={audioElement ?? ''}
                condition={audioElement !== null && soundtrack}
              />
              {contentElement[indexMessage] && <p>{handleMessage()}</p>}
              <button
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: '41vw',
                }}
                className="ButtonAction Action"
                type="button"
                onClick={() => setReverse(true)}
              >
                {/* TODO use translation file */}
                Suivant
              </button>
            </div>
          </div>
        </div>
      );
    }

    if (type === 'emotionCard') {
      const handleStepText = () => {
        switch (currentStep.level) {
          case 0:
            return ' première ';
          case 1:
            return ' deuxième ';
          case 2:
            return ' troisième ';
          case 3:
            return ' quatrième ';
          case 4:
            return ' dernière ';
          default:
            return ' ';
        }
      };
      return (
        <div className="Modal_description-content">
          <div
            className={`Modal_description-image${!reverseCard ? '' : '--reverse'}`}
          >
            <button
              className="Modal_description-button"
              type="button"
              onClick={() => setReverseCard(!reverseCard)}
            >
              <img
                draggable="false"
                src={
                  reverseCard ? currentStep.imageFoundZoom : currentStep.imageFound
                }
                alt={title}
              />
            </button>
          </div>
          {!reverseCard && (
            <div className="Modal_description-card">
              <p>Félicitations !</p>
              <p>
                {t('card.congratulation', {
                  val: `${stepsList.length > 1 ? handleStepText() : ''}`,
                  val2: `${currentStep.card_title ?? 'récompense'}`,
                  val3: `${
                    stepsList.length === 1
                      ? `:
                  "${currentStep.title}"`
                      : ''
                  }`,
                })}
              </p>
              <p>
                Répondez aux questions suivantes afin de tester vos connaissances.
              </p>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="Modal_description-content">
        <div className="Modal_description-text_modal">
          <p>{message}</p>
        </div>
        {image && (
          <div className="Modal_description-image">
            <img src={image} draggable="false" alt={title} />
          </div>
        )}
      </div>
    );
  };

  if (type === 'questionnary') {
    return (
      <div className="Modal">
        <div className="Modal_blur" />
        <div className="Modal_description">
          <div className="Modal_description-content">
            <div className="Modal_description-text">
              <QuestionnaryContent />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="Modal">
      <div className="Modal_blur" />
      <div
        className={`Modal_description ${
          type === 'prevention' && 'Modal_description-prevention'
        } ${gameId === obesityGameId && 'Modal_description-robot'}`}
      >
        {handleContent()}
        <div className="Modal_Card_Emotion_Buttons">
          {type !== 'intro' &&
            type !== 'prevention' &&
            type !== 'emotionCard' &&
            type !== 'questionnary' &&
            !buttonDescription && (
              <Button
                buttonType="return"
                title="Retour"
                onClick={() => {
                  dispatch({
                    type: 'CLOSE_MODAL',
                  });
                }}
              />
            )}
          {type !== 'prevention' && buttonDescription && (
            <Button
              buttonType="action"
              title={buttonDescription.title}
              onClick={() => buttonDescription.onClick()}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  // `title` is not used for an intro type
  title: state.Modal.modal.description.title,
  message: state.Modal.modal.description.message,
  // `image` is not used for an intro type
  image: state.Modal.modal.description.image,
  buttonDescription: state.Modal.modal.description.buttonDescription,
  action: state.Modal.modal.description.action,
  type: state.Modal.modal.description.type,
  currentStep: state.Steps.currentStep,
  stepsList: state.Steps.list,
  gameId: state.game.id,
  messages: state.Modal.modal.description.messages,
  backgroundMessage: state.game.tuto_background,
  soundtrack: state.GameUsers.soundtrack,
});

Modal.propTypes = {
  title: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  message: PropTypes.string,
  image: PropTypes.string,
  gameId: PropTypes.number.isRequired,
  buttonDescription: PropTypes.shape({
    onClick: PropTypes.func,
    title: PropTypes.string,
  }),
  action: PropTypes.shape({
    onClick: PropTypes.func,
  }),
  type: PropTypes.string,
  currentStep: PropTypes.shape({
    message: PropTypes.string,
    level: PropTypes.number,
    imageFoundZoom: PropTypes.string,
    imageFound: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
    card_title: PropTypes.string,
    title: PropTypes.string,
  }),
  stepsList: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array])
  ).isRequired,
  dispatch: PropTypes.func.isRequired,
  backgroundMessage: PropTypes.string,
  soundtrack: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  title: '',
  messages: null,
  message: '',
  image: '',
  action: null,
  buttonDescription: null,
  type: '',
  currentStep: null,
  backgroundMessage: null,
};

export default connect(mapStateToProps)(Modal);
