/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable prefer-object-spread */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import confetti from 'canvas-confetti';

import Audio from '../../ReusableComponents/Actions/Audio';
import Button from '../../ReusableComponents/Actions/Button';

import successSound from '../../../../sound/success-card.mp3';

import '../../../../assets/css/components/GameBoard/TMS/Enigma/Tablet.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Tablet({
  objectContent,
  allObjects,
  dispatch,
  idSessionHasRoom,
  soundtrack,
}) {
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    const duration = 1.5 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }
    if (success) {
      const interval = setInterval(function () {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 500 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
          })
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.6, 0.9), y: Math.random() - 0.2 },
          })
        );
      }, 250);
    }
  }, [success]);
  const [isIntroDone, setIntroDone] = useState(false);
  const dorisPatient = {
    name: 'Doris',
    url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fobjects%2Ficon-doris.svg?alt=media&token=d0f7761c-0910-4662-834a-a8c6fadae1a4',
    info: [
      {
        text: '80 ans',
        url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fobjects%2Ficons%2Ficon-age.svg?alt=media&token=b9da17ef-9d16-4727-a964-1b65fba857e0',
      },
      {
        text: 'Femme',
        url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fobjects%2Ficons%2Ficon-genre.svg?alt=media&token=9108f66a-ac0c-43cc-b164-56c762ef2b1f',
      },
      {
        text: '90 kg',
        url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fobjects%2Ficons%2Ficon-poids.svg?alt=media&token=261fc235-d340-4598-8ee5-7c7ecd8bf52e',
      },
    ],
    description: [
      'Ne peut pas se tenir debout et nécessite un soignant pour les tâches de la vie quotidienne.',
      'Présente un haut risque de surcharge statique et dynamique si équipement pas adapté',
      'Il faut la stimuler pour maintenir sa capacité fonctionnelle',
      'Incontinence totale',
    ],
  };

  const [harnesses] = useState([
    {
      name: 'Harnais 1',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fobjects%2Fharnais%2Ftoiletslinglow.png?alt=media&token=8cc79ee2-9873-4907-baa9-9dd1975f4074',
      description: ['Poids Max: 272 kg', 'Textile: Souple', 'Taille: S'],
      error:
        "Il semblerait que ce harnais ne soit pas adapté aux besoins de Doris et au transfert dont elle a besoin. Doris a besoin d'être tansférée sur son fauteuil roulant pour aller déjeuner, sa toilette a déjà été effectée.",
    },
    {
      name: 'Harnais 2',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fobjects%2Fharnais%2Fharnais2.svg?alt=media&token=4d1b853d-28bc-4933-8d80-bffcc7413539',
      description: ['Poids Max: 228 kg', 'Textile: AllDay', 'Taille: S'],
      error: "Bonne analyse, c'est le bon harnais !",
    },
    {
      name: 'Harnais 3',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fobjects%2Fharnais%2Fbariatricbasicsling.png?alt=media&token=614d8865-86b3-4c33-91b0-1d8729bdbdeb',
      description: ['Poids Max: 454 kg', 'Textile: Rigide', 'Taille: L'],
      error:
        "Il semblerait que ce harnais ne soit pas adapté aux besoins de Doris ! En effet, celui-ci est beaucoup trop grand ! Ce serait dangereux de l'utiliser pour son transfert.",
    },
  ]);
  const [choiceHarness, setChoiceHarness] = useState(null);
  const [isChoice, setIsChoice] = useState(false);
  const goodHarnaisIndex = 1;

  const items = [
    {
      number: '1 ',
      title: 'Se positionner face au patient',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fgifs%2FGIF%20ETAPE%201%20-%20SE%20POSITIONNER%20FACE%20AU%20RÉSIDENT.gif?alt=media&token=b79c3611-2ad2-4b5e-8539-0886b8809bfb',
    },
    {
      number: '2 ',
      title: "Pencher le patient vers l'avant",
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fgifs%2FGIF%20ETAPE%202%20-%20PENCHER%20LE%20RÉSIDENT%20EN%20AVANT.gif?alt=media&token=222ef62f-68ae-43ad-9533-8ace3ba859d5',
    },
    {
      number: '3 ',
      title: 'Passer le harnais dans le dos',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fgifs%2FGIF%20ETAPE%203%20-%20PASSER%20LE%20HARNAIS%20DANS%20LE%20DOS%20DU%20RÉSIDENT.gif?alt=media&token=45c84be3-f052-40a8-b57d-ec9b9fa61e22',
    },
    {
      number: '4 ',
      title: "Glisser le harnais jusqu'au coccyx",
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fgifs%2FGIF%20ETAPE%204%20-%20GLISSER%20LE%20HARNAIS%20JUSQUAU%20COCCYX.gif?alt=media&token=5c78c890-c66e-4015-b867-22b183616cff',
    },
    {
      number: '5 ',
      title: 'Redresser le patient',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fgifs%2FGIF%20ETAPE%205%20-%20REDRESSER%20LE%20RÉSIDENT.gif?alt=media&token=04092050-f402-4b11-ad07-b715a8aa26f3',
    },
    {
      number: '6 ',
      title: 'Contournement des hanches',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fgifs%2FGIF%20ETAPE%206%20-%20CONTOURNEMENT%20DES%20HANCHES.gif?alt=media&token=5b26482b-8567-4143-9d9b-d44ae99250f6',
    },
    {
      number: '7 ',
      title: 'Passer le harnais sous les jambes',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fgifs%2FGIF%20ETAPE%207%20-%20PASSER%20LE%20HARNAIS%20SOUS%20LES%20JAMBES.gif?alt=media&token=a609145d-ea73-426f-8546-241294892fd5',
    },
    {
      number: '8 ',
      title: 'Croiser le harnais entre les jambes',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fgifs%2FGIF%20ETAPE%208%20-%20CROISER%20LES%20BOUCLES%20DU%20HARNAIS%20ENTRE%20LES%20JAMBES.gif?alt=media&token=c09391c7-2418-497e-97cd-4e01081fe400',
    },
    {
      number: '9 ',
      title: 'Attacher les boucles au cintre de transfert',
      url: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fgifs%2FGIF%20ETAPE%209%20-%20ACCROCHER%20LES%20BOUCLES%20AU%20CINTRE%20DE%20TRANSFERT.gif?alt=media&token=09294bfb-3414-477e-bb49-5377d925d0e8',
    },
  ];
  const newItems = [...items];
  const sortItems = newItems.sort(() => Math.random() - 0.5);

  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: [...sortItems],
  };

  const [list, setList] = useState(sortItems);
  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);
  const [stepClick, setStepClick] = useState('begin');
  const [error, setError] = useState(null);

  const checkedObject = (collectObject, index, isMessage) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        isChecked: 1,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isChecked: 1,
            type: 'checked',
          },
        });

        if (!isMessage) {
          axios
            .put(
              `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
              {
                current_step: collectObject.id_message,
              }
            )
            .then(() => {
              dispatch({
                type: 'CURRENT_STEP',
                payload: collectObject.id_message,
              });
              dispatch({
                type: 'START_MESSAGE_PREVENTION',
              });
            });
        }
      });
  };

  const usedObject = (collectObject, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        status: 'open',
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            status: 'open',
            type: 'used',
          },
        });
      });
  };

  const handleSubmitFirstStep = () => {
    setSuccess(true);
    setTimeout(() => {
      setChoiceHarness(null);
      setIsChoice(!isChoice);
      const objectContentIndex = allObjects.findIndex(
        (objectElement) => objectElement.id === objectContent.id
      );
      usedObject(objectContent, objectContentIndex);
      const messageFirstStep = 119;
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
          {
            current_step: messageFirstStep,
          }
        )
        .then(() => {
          dispatch({
            type: 'CURRENT_STEP',
            payload: messageFirstStep,
          });
          dispatch({
            type: 'CLOSE_MODAL_ENIGMA',
          });
          dispatch({
            type: 'START_MESSAGE_PREVENTION',
          });
          setSuccess(false);
        });
    }, 2000);
  };

  const handleDragAndDrop = () => {
    const onDragStart = (event) => {
      setError(null);
      const initialPosition = Number(event.currentTarget.dataset.position);

      setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: initialPosition,
        isDragging: true,
        originalOrder: list,
      });
      event.dataTransfer.setData('text/html', '');
    };

    const onDragOver = (event) => {
      event.preventDefault();

      let newList = dragAndDrop.originalOrder;
      const draggedTo = Number(event.currentTarget.dataset.position);

      const itemDragged = newList[dragAndDrop.draggedFrom];
      const remainingItems = newList.filter(
        (item, index) => index !== dragAndDrop.draggedFrom
      );

      newList = [
        ...remainingItems.slice(0, draggedTo),
        itemDragged,
        ...remainingItems.slice(draggedTo),
      ];

      if (draggedTo !== dragAndDrop.draggedTo) {
        setDragAndDrop({
          ...dragAndDrop,
          updatedOrder: newList,
          draggedTo,
        });
      }
    };

    const onDrop = () => {
      setList(dragAndDrop.updatedOrder);
      setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: null,
        draggedTo: null,
        isDragging: false,
      });
    };

    const onDragLeave = () => {
      setDragAndDrop({
        ...dragAndDrop,
        draggedTo: null,
      });
    };

    return (
      <section>
        <ul>
          {list.map((item, index) => {
            return (
              <li
                aria-hidden="true"
                key={item.number}
                data-position={index}
                draggable
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDrop={() => onDrop(index)}
                onDragLeave={onDragLeave}
                onClick={() => {
                  setStepClick(index);
                  setError(null);
                }}
                className={
                  dragAndDrop && dragAndDrop.draggedTo === Number(index)
                    ? 'dropArea'
                    : ''
                }
              >
                <span className="Tablet_content-drop-item">{index + 1}</span>
                <p className="Tablet_content-drop-text">{item.title}</p>
                <span className="Tablet_content-drop-arrow">↕️</span>
              </li>
            );
          })}
        </ul>
      </section>
    );
  };

  const handleImageClick = () => {
    let title = null;
    let url = null;

    if (stepClick === 'begin') {
      title = 'Etape debut';
      url =
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fsteps%2Fetape%200.gif?alt=media&token=f67ad4d3-95d0-4bd4-93ab-907e633157fd';
    } else if (stepClick === 'stop') {
      title = 'Etape fin';
      url =
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Frestaurant%2Fgifs%2FGIF%20ETAPE%209%20-%20ACCROCHER%20LES%20BOUCLES%20AU%20CINTRE%20DE%20TRANSFERT.gif?alt=media&token=09294bfb-3414-477e-bb49-5377d925d0e8';
    } else {
      title = dragAndDrop.updatedOrder[stepClick].title;
      url = dragAndDrop.updatedOrder[stepClick].url;
    }

    return <img className="Tablet_content-left-img" alt={title} src={url} />;
  };

  const submitSteps = () => {
    const arrayOfIndex = [];
    const arrayOfIndexGood = [];

    dragAndDrop.updatedOrder.map((stepElement) =>
      arrayOfIndex.push(stepElement.number)
    );

    items.map((stepItem) => arrayOfIndexGood.push(stepItem.number));
    if (arrayOfIndex.toString() === arrayOfIndexGood.toString()) {
      setSuccess(true);
      setError({
        type: 'good',
        text: "Le harnais est installé ! Il n'y a plus qu'à réaliser le transfert et amener Doris à la salle de repas.",
      });
      setTimeout(() => {
        dispatch({
          type: 'CLOSE_MODAL_ENIGMA',
        });
        const objectContentIndex = allObjects.findIndex(
          (objectElement) => objectElement.id === objectContent.id
        );
        checkedObject(objectContent, objectContentIndex);
      }, 3000);
    } else {
      setError({
        type: 'fail',
        text: 'Attention à ne pas brûler les étapes ! Chaque chose en son temps. Veillez à ce que Doris soit bien maintenue dans son harnais.',
      });
    }
  };

  const handleContent = () => {
    if (objectContent.status === 'open') {
      return (
        <div className="Tablet_content">
          <div className="Tablet_content-left">
            <h3>Bien utiliser le harnais</h3>
            <p>
              Choisissez dans l&apos;ordre les étapes à suivre pour mettre le
              harnais
            </p>
            {stepClick !== null && handleImageClick()}
            <div>
              {error !== null && <p>{error.text}</p>}
              <Button
                buttonType="action"
                style={{ display: error && error.type === 'good' && 'none' }}
                onClick={() => (error === null ? submitSteps() : setError(null))}
                title={error === null ? 'Valider' : 'Réessayer'}
              />
            </div>
          </div>
          <div className="Tablet_content-right Tablet_drop-down">
            {handleDragAndDrop()}
          </div>
        </div>
      );
    }
    if (isIntroDone) {
      return (
        <div className="Tablet_content">
          <div className="Tablet_content-left">
            <h3>Choisir le bon harnais</h3>
            <p>
              Déterminez quel harnais est utile pour réaliser le transfert de Doris
              sur son fauteil roulant.
            </p>
            <div className="Tablet_content-left-list">
              {harnesses.map((contentHarness, index) => (
                <button
                  key={contentHarness.name}
                  type="button"
                  className="Tablet_content-left-harnais"
                  onClick={() => !isChoice && setChoiceHarness(index)}
                >
                  <div
                    className="Tablet_content-left-harnais-title"
                    style={{
                      backgroundColor:
                        index !== choiceHarness ? '#edebeb' : '#b4639c',
                    }}
                  >
                    <div
                      style={{
                        color: index === choiceHarness ? 'white' : '#797877',
                      }}
                    >
                      {contentHarness.name}
                    </div>
                  </div>
                  <div className="Tablet_content-left-harnais-descriptions">
                    <img src={contentHarness.url} alt={contentHarness.name} />
                    <ul className="Tablet_content-left-harnais-description">
                      {contentHarness.description.map((descriptionHarness) => (
                        <li key={descriptionHarness}>{descriptionHarness}</li>
                      ))}
                    </ul>
                  </div>
                </button>
              ))}
            </div>
            {isChoice ? (
              <>
                <p>
                  {harnesses[choiceHarness]
                    ? harnesses[choiceHarness].error
                    : 'Veuillez sélectionner un harnais'}
                </p>
                {goodHarnaisIndex !== choiceHarness ? (
                  <Button
                    className="Tablet_content-left-error"
                    buttonType="action"
                    onClick={() => {
                      setChoiceHarness(null);
                      setIsChoice(!isChoice);
                    }}
                    title="Réessayer"
                  />
                ) : (
                  <Button
                    className="Tablet_content-left-error"
                    buttonType="action"
                    onClick={() => {
                      handleSubmitFirstStep();
                    }}
                    title="Continuer"
                  />
                )}
              </>
            ) : (
              <Button
                className="Tablet_content-left-error"
                buttonType="action"
                onClick={() => setIsChoice(!isChoice)}
                title="Valider"
              />
            )}
          </div>
          <div className="Tablet_content-right">
            <div className="Tablet_content-right-patient">
              <img src={dorisPatient.url} alt="Doris mobilisation" />
              <p>{dorisPatient.name}</p>
            </div>
            <div className="Tablet_content-right-patient-content">
              <div className="Tablet_content-right-patient-infos">
                {dorisPatient.info.map((infoContent) => (
                  <div
                    key={infoContent.text}
                    className="Tablet_content-right-patient-info"
                  >
                    <img src={infoContent.url} alt={infoContent.text} />
                    <p>{infoContent.text}</p>
                  </div>
                ))}
              </div>
              <div className="Tablet_content-right-patient-descriptions">
                {dorisPatient.description.map((descriptionContent) => (
                  <ul
                    key={descriptionContent}
                    className="Tablet_content-right-patient-description"
                  >
                    <li>{descriptionContent}</li>
                  </ul>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="Tablet_content">
        <div className="Tablet_content-left">
          <h3>Bienvenue</h3>
          <p>
            C&apos;est l&apos;heure du repas ! Mais une résidente manque à
            l&apos;appel !
          </p>
          <p>
            {dorisPatient.name} ne peut pas se déplacer seule, son transfert
            nécessite l’utilisation d’un rail de transfert. Qui dit rail, dit
            harnais...
          </p>
          <p>
            Lequel allez vous choisir ? Prenez bien en compte la corpulence de{' '}
            {dorisPatient.name} pour faire votre choix !
          </p>
          <p>Cliquez sur commencer pour entamer la procédure.</p>
          <Button
            buttonType="action"
            title="Commencer"
            onClick={() => setIntroDone(true)}
          />
        </div>
        <div className="Tablet_content-right">
          <h4>Patient nécessitant une mobilisation</h4>
          <p>Voici la patiente qui nécessite une mobilisation</p>
          <div className="Tablet_content-right-patient">
            <img src={dorisPatient.url} alt="Doris mobilisation" />
            <p>{dorisPatient.name}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="Tablet Modal_description">
      {handleContent()}
      <Audio condition={success && soundtrack} sound={successSound} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  sessionId: state.GameUsers.idSession,
  soundtrack: state.GameUsers.soundtrack,
  allObjects: state.Objects.AllObjects,
});

Tablet.propTypes = {
  allObjects: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  objectContent: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.bool,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  soundtrack: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Tablet);
