/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import court from '../../../../img/tde/court-white.svg';
import long from '../../../../img/tde/long-white.svg';

import '../../../../assets/css/components/GameBoard/TDE/Enigma/RemoteControl.css';

function RemoteControl({ objectContent, description }) {
  const dispatch = useDispatch();
  const [chaining, setChaining] = useState([]);
  useEffect(() => {
    dispatch({
      type: 'CLOSE_ZOOM',
    });
  }, [dispatch]);
  const handleText = () => {
    let textContent;
    if (objectContent.isInventory) {
      const isInventoryCode = objectContent.images.filter(
        (contentImage) => contentImage.type === 'inventory'
      );
      textContent = isInventoryCode[0].message;
    }

    textContent = description.message;

    return textContent;
  };

  const handleChaining = (time) => {
    if (chaining.length < 15) {
      const newChaining = [...chaining];
      newChaining.push(time);
      setChaining(newChaining);
    }
  };

  const sendChaining = () => {
    dispatch({
      type: 'CHAINING_GARLAND',
      payload: chaining,
    });
    setChaining([]);
  };
  return (
    <div className="remoteControl Modal_description">
      <div className="remoteControl Modal_description-text">
        <div className="remoteControl-chaining">
          {chaining.map((item, index) => {
            return (
              <img
                key={`${item}-${index}`}
                src={item === 500 ? court : long}
                alt="Enchainement"
              />
            );
          })}
        </div>
        <p>{handleText()}</p>
      </div>
      <div className="remoteControl-image">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTDE%2Fassets%2Fobjects%2Fremote-tde-zoom.svg?alt=media&token=3dfbf1ea-117d-4096-a110-e52a00bfd7e2"
          alt="telecommande"
          className="remoteControl-image-Remote"
        />
        <div
          className="remoteControl-image-court"
          onClick={() => handleChaining(500)}
          role="presentation"
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTDE%2Fassets%2Fobjects%2Fcourt.svg?alt=media&token=b272ee94-790c-4aa0-a716-d0070f044283"
            alt="Bouton court"
          />
        </div>
        <div
          className="remoteControl-image-long"
          onClick={() => handleChaining(2000)}
          role="presentation"
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTDE%2Fassets%2Fobjects%2Flong.svg?alt=media&token=bcf2e447-9dc5-42a7-992f-dd894df09285"
            alt="Bouton long"
          />
        </div>
        <div
          className="remoteControl-image-play"
          onClick={() => sendChaining()}
          role="presentation"
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTDE%2Fassets%2Fobjects%2Fplay.svg?alt=media&token=55fc191b-a5eb-43f6-aba7-9f351ad7e1b6"
            alt="Bouton play"
          />
        </div>
      </div>
    </div>
  );
}

RemoteControl.propTypes = {
  objectContent: PropTypes.shape({
    title: PropTypes.string,
    isInventory: PropTypes.number,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
      })
    ),
  }).isRequired,
  description: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
};

export default RemoteControl;
