/* eslint-disable import/no-mutable-exports */
import firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  databaseURL: process.env.REACT_APP_FIREBASE_URL,
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase;

let firepadRef = firebase.database().ref();

const urlparams = new URLSearchParams(window.location.search);
const roomIdVisio = urlparams.get('id');
const roomName = urlparams.get('RoomName');
const userId = urlparams.get('user') || urlparams.get('admin');
const userType = urlparams.get('user') ? 'user' : 'admin';
const companyId = urlparams.get('company');
const gameId = urlparams.get('game');
const token = urlparams.get('token');

// for the first time there is no id so we need to create the id
if (roomIdVisio) {
  firepadRef = firepadRef.child(roomIdVisio);
} else {
  // after creation of id we need to use it and used the url
  firepadRef = firepadRef.push();
  window.history.replaceState(
    null,
    'Meet',
    `?id=${firepadRef.key}&RoomName=${roomName}&${userType}=${userId}&company=${companyId}&game=${gameId}&token=${token}}`
  );
}

export default firepadRef;
