import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

/**
 * Get the images of an object
 * @param {number} objectId
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const getObjectImages = async (objectId) => {
  const { data } = await axios.get(`${baseURL}/api/objects/${objectId}/images`);
  return data;
};

export default getObjectImages;
