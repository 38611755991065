import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';

import Button from '../Actions/Button';
import Clue from './Clue';

function ChatWindow({ dispatch, questions, id, inventory, objects, step, level }) {
  useEffect(() => {
    const scrollbar = document.querySelector('#scrollable');
    if (scrollbar) {
      scrollbar.scrollTop = scrollbar.scrollHeight - scrollbar.clientHeight;
    }
  }, [questions]);

  const [textInput, setTextInput] = useState('');
  const [subject, setSubject] = useState(1);
  const [error, setError] = useState(false);

  const handleChange = ({ target }) => {
    let newTextInput = textInput;
    newTextInput = target.value;
    setTextInput(newTextInput);
  };

  const handleClue = () => {
    Clue(dispatch, inventory, objects, step, id, level);
  };

  const handleSubmit = () => {
    setError(false);
    dispatch({
      type: 'SEND_QUESTION',
      payload: [textInput],
    });
    dispatch({
      type: 'LOADING_CHAT-BOT',
    });
    setTextInput('');
    axios
      .get(
        `${process.env.REACT_APP_CHATBOT_URL}/api/predictions/predict/?question=${textInput}&category=${subject}/`,
        { headers: { Authorization: process.env.REACT_APP_CHATBOT_TOKEN } }
      )
      .then(({ data }) => {
        dispatch({
          type: 'LOADING_TRUE',
        });
        dispatch({
          type: 'RETRIEVE_RESPONSE',
          payload: data,
        });
      });
  };

  const handleError = () => {
    setError(true);
  };

  const handleSlect = ({ target }) => {
    setSubject(target.value);
  };

  const handleMouseEnter = ({ target }) => {
    const newTarget = target;
    newTarget.style.color = 'red';
    return newTarget;
  };

  const handleMouseLeave = ({ target }) => {
    const newTarget = target;
    newTarget.style.color = 'black';
    return newTarget;
  };

  const handleFeedBack = (textId, positive) => {
    axios
      .put(
        `${
          process.env.REACT_APP_CHATBOT_URL
        }/api/predictions/${textId}/feedback?feedback=${
          positive ? 'correct' : 'incorrect'
        }`,
        { params: '' },
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: process.env.REACT_APP_TOKEN_CHATBOT,
          },
        }
      )
      .then(() => {
        dispatch({
          type: 'FEEDBACK',
          payload: id,
        });
      });
  };

  const onEnterPress = (e) => {
    const goodKeyCode = 13;
    if (e.keyCode === goodKeyCode && e.shiftKey === false) {
      e.preventDefault();
      if (textInput.length > 3 && subject !== null) {
        handleSubmit();
      } else {
        handleError();
      }
    }
  };

  return (
    <div className="ChatWindow-Clue">
      <div className="ChatWindow">
        <div className="ChatBot-dialog">
          <div>
            <div className="ChatBot-dialog-text" id="scrollable">
              {questions &&
                questions.map((text) => {
                  return text.response ? (
                    <div className="ChatBot-Response-Container">
                      <div className="ChatBot-Response">
                        {text.response.text}
                        {text.id && !text.feedback && (
                          <div className="ChatBot-Response-Feedback">
                            <br />
                            <p>Cette réponse vous a-t-elle aidé ?</p>
                            <button
                              type="button"
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              onClick={() => handleFeedBack(text.id, true)}
                            >
                              oui
                            </button>
                            <button
                              type="button"
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              onClick={() => handleFeedBack(text.id, false)}
                            >
                              non
                            </button>
                            <br />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="ChatBot-Question-Container">
                      <p className="ChatBot-Question">{text}</p>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="ChatBot-Form">
            <div className="ChatBot-input">
              {error && (
                <p style={{ color: 'red' }}>
                  Veuillez entrer une question ET choisir un sujet
                </p>
              )}
              <textarea
                name="Input"
                placeholder="Champs requis"
                value={textInput}
                onChange={handleChange}
                onKeyDown={onEnterPress}
                className="ChatBot-input-Text"
                id="TextArea"
              />
              <select
                name="subject"
                onChange={handleSlect}
                value={subject}
                required
              >
                <option value="1">Choisissez un sujet</option>
                <option value="2">Technique</option>
                <option value="3">Tricky</option>
                <option value="8">Objectif pédagogique</option>
                <option value="9">Contenue pédagogique</option>
              </select>
            </div>
            <div className="ChatBot-Buttons">
              <Button
                primary // TODO primary is not used at all, we could define a specific style in Button
                title="Envoyer"
                onClick={textInput.length > 3 ? handleSubmit : handleError}
              />
              <Button
                primary // TODO primary is not used at all, we could define a specific style in Button
                title="Indices"
                onClick={handleClue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  questions: state.ChatBot.questions,
  id: state.game.id,
  inventory: state.Inventory.list,
  objects: state.Objects.AllObjects,
  step: state.Steps.list.find((step) => step.isFound === 0)
    ? state.Steps.list.find((step) => step.isFound === 0).id
    : null,
  level: state.ChatBot.level,
});

ChatWindow.propTypes = {
  dispatch: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      response: PropTypes.shape({
        text: PropTypes.string,
      }),
      id: PropTypes.number,
      feedback: PropTypes.bool,
    })
  ).isRequired,
  id: PropTypes.number.isRequired,
  inventory: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  objects: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  step: PropTypes.number.isRequired,
  level: PropTypes.number,
};

ChatWindow.defaultProps = {
  level: null,
};

export default connect(mapStateToProps)(ChatWindow);
