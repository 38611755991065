import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

/**
 * Get the data from room_has_objects for a particular room
 * @param {number} currentRoomId
 * @returns {Promise<object[]>} Response of the API call if successful
 */
const getRoomHasObject = async (currentRoomId) => {
  const { data } = await axios.get(
    `${baseURL}/api/roomHasObjects/${currentRoomId}`
  );
  return data;
};

export default getRoomHasObject;
