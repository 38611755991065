import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// Selector functions
import { selectSteps } from 'components/Reducers/Steps/Steps';
import { selectRoom } from 'components/Reducers/Room';
import { selectSession } from 'components/Reducers/session';
import { selectModal } from 'components/Reducers/Modal/Modal';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectEmotion } from 'components/Reducers/emotion';

// Components
import Intro from 'components/GameBoard/ReusableComponents/Intro';
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import ModalEnigmaEmotion from 'components/GameBoard/ReusableComponents/Modal/ModalEnigmaEmotion';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import Chevrons from 'components/GameBoard/ReusableComponents/Actions/Chevrons';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import WarningLights from 'components/GameBoard/Emotion/Solo/WarningLights';
import BackgroundStyle from 'components/GameBoard/ReusableComponents/BackgroundStyle';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import stepsEmotion from 'components/Joyride/StepsEmotion';
import RoomContainer from 'components/GameBoard/Emotion/Solo/Rooms/RoomContainer';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';

// Constant
import {
  backgroundStyle,
  volume,
} from 'components/GameBoard/Emotion/Solo/constants';

// Utils
import {
  centerPoint,
  useStartMessageOrQuestionary,
  getRightCorner,
  clickCount,
} from 'utils/utilityFunctions';
import useInitGame from 'utils/useInitGame';
import useWindowResize from 'utils/useWindowResize';
// Assets
import mainTheme from 'sound/Emotion_solo/chapter_1_theme.mp3';
import click from 'sound/click-ongame.mp3';

function InterpretationMachineCalibrationRoom() {
  // `t` is a function coming from the i18next library used to translate text
  /* Load the `common` namespace = the one containing translations that are common between games
  and the `emotion` one (see public-> locales -> fr-FR) */
  const { t } = useTranslation(['common', 'emotion']);

  const dispatch = useDispatch();

  // This ref is connected to the image background
  const backgroundImageRef = useRef();

  const [backgroundPosition, setBackgroundPosition] = useState('center');
  const [tutorialSteps, setTutorialSteps] = useState({
    run: true,
    steps: stepsEmotion(t),
    stepIndex: 0,
  });

  // Select data from the store
  const { currentRoomId, listRooms } = useSelector(selectRoom);
  const session = useSelector(selectSession);
  const modalIsOpen = useSelector(selectModal).modal.isOpen;
  const modalEnigmaEmotionIsOpen =
    useSelector(selectModal).modalEnigmaEmotion.isOpen;

  const { isTutoFinish, startMessage, startQuestionnary, currentStep } =
    useSelector(selectSteps);
  const { cursor, gameStarted, idSessionHasRoom, soundtrack, music, clicked } =
    useSelector(infoGameUser);
  const { backgroundDimensions } = useSelector(selectEmotion);

  const currentRoom = Object.values(listRooms).find(
    (roomObject) => roomObject.id === currentRoomId
  );
  const backgroundUrl = currentRoom.url;

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  // Initiate the session, the room, the objects in the room and the prevention messages
  useInitGame(currentRoomId, session.id);

  // Save the prevention message/questionary step in database and store and launch it
  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary
  );

  /**
   * Callback used in React Joyride component
   * @param {*} data
   */
  const handleJoyrideCallback = (data) => {
    const { action, index, type, lifecycle, status } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Show that you can navigate in the room
      /* The navigation needs to happen after the opening of the modal showing the chevrons.
      Otherwise the chevrons disapears due to the opacity off */
      if (index === 7 && lifecycle === 'complete') {
        // Show that you can navigate in the room
        setBackgroundPosition('right-from-center');
      }
      // Show that you can navigate in the room
      /* The navigation needs to happen after the opening of the modal showing the chevrons.
      Otherwise the chevrons disapears due to the opacity off */
      if (index === 8 && lifecycle === 'complete' && action === 'next') {
        setBackgroundPosition('left-from-center');
      }
      // Update state to advance the tour
      setTutorialSteps({
        ...tutorialSteps,
        stepIndex: index + (action === ACTIONS.NEXT ? 1 : -1),
      });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      axios
        .put(`${BASE_URL}/api/sessions/${session.id}/modification`, {
          isTutoFinish: 1,
        })
        .then(() => {
          dispatch({ type: 'RESET_TUTO', payload: 1 });
        });
    }
  };

  // Define variables used for the translation of the background
  const screenWidth = window.innerWidth;

  useWindowResize(backgroundImageRef);

  const imgWidth = backgroundDimensions.width;
  /* Number of pixels needed to center a point of interest in the background */
  const pointOfInterestCentered =
    centerPoint(backgroundStyle.pointOfInterest, imgWidth, screenWidth) >= 0
      ? `-${centerPoint(backgroundStyle.pointOfInterest, imgWidth, screenWidth)}px`
      : '0px';

  // Launch a modal after the intro to explain the enigma rules
  useEffect(() => {
    if (gameStarted)
      dispatch({
        type: 'OPEN_MODAL',
        payload: t('enigma1', { returnObjects: true, ns: 'emotion' }),
      });
  }, [dispatch, gameStarted, t]);

  return (
    // `id` is used to target a tutorial step
    <RoomContainer id="container">
      <Audio sound={mainTheme} loop muted={!music} volume={volume} />
      <Audio
        sound={click}
        condition={!clicked && soundtrack && !modalEnigmaEmotionIsOpen}
      />
      <div
        aria-hidden="true"
        className={clicked ? 'cursor-clicked' : 'Hide'}
        style={{ top: cursor.y, left: cursor.x }}
      >
        <p>+1</p>
      </div>
      {/* Display the header with the HUD and the Steps */}
      <header>
        <Steps />
        <Hud />
      </header>
      {/* Display the background */}
      <BackgroundStyle
        position={backgroundPosition}
        start={pointOfInterestCentered}
        right={imgWidth && getRightCorner(imgWidth, screenWidth)}
        left={backgroundStyle.imgLeft}
        duration={backgroundStyle.duration}
        timing={backgroundStyle.timing}
        fillMode={backgroundStyle.fillMode}
        backgroundWidth={backgroundStyle.width}
        onClick={(event) => {
          clickCount(dispatch, event);
        }}
        type="button"
      >
        <img
          src={backgroundUrl}
          width="100%"
          height="100%"
          alt={t('lab', { ns: 'emotion' })}
          id="img-background"
          ref={backgroundImageRef}
        />
        <ListObjects />

        <WarningLights />
      </BackgroundStyle>
      <Chevrons
        position={backgroundPosition}
        setPosition={setBackgroundPosition}
        modalEnigmaIsOpen={modalEnigmaEmotionIsOpen}
        hide={modalEnigmaEmotionIsOpen}
      />
      {!isTutoFinish && (
        <Joyride
          callback={handleJoyrideCallback}
          steps={tutorialSteps.steps}
          run={tutorialSteps.run}
          stepIndex={tutorialSteps.stepIndex}
          showSkipButton
          continuous
          disableCloseOnEsc
          disableOverlayClose
          hideBackButton
          hideCloseButton
          locale={{
            last: t('joyride.locale.last'),
            next: t('joyride.locale.next'),
            skip: t('joyride.locale.skip'),
          }}
          styles={{
            buttonNext: {
              backgroundColor: '#4ed199',
              border: '1px solid #48c48e',
              padding: '15px 25px',
              fontWeight: '700',
            },
            buttonSkip: {
              color: '#9fa2b4',
              fontWeight: '700',
            },
          }}
        />
      )}
      {/* Display the intro once the tuto is finished and if the game has not started yet */}
      {!gameStarted && isTutoFinish && <Intro translationFileName="emotion" />}
      {modalIsOpen && <Modal />}
      {/* Custom modal to open an enigma */}
      {modalEnigmaEmotionIsOpen && <ModalEnigmaEmotion />}
    </RoomContainer>
  );
}

export default InterpretationMachineCalibrationRoom;
