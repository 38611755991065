import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { enigmas } from 'components/GameBoard/Emotion/Solo/constants';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

const { bubbleButtonUrl, behaviourPanelStyle } = enigmas.ComplexSituation;

const Reactions = ({
  currentInstinctiveBehaviour,
  currentReasonedBehaviours,
  handleReactionClick,
  el,
}) => {
  const { t } = useTranslation('emotion');
  return (
    <div className="reaction-container" id={`choice-${el}`}>
      <div>
        <img src={bubbleButtonUrl[`bubble-${el}`]} alt={t('reactionNumber')} />
        <p className="reaction-text">
          {el === 1
            ? currentInstinctiveBehaviour.description
            : t('reasonedReaction', {
                count: currentReasonedBehaviours.length,
              })}
        </p>
      </div>
      <p className="explanation">
        {el === 1 ? t('instinctiveExplanation') : t('reasonedExplanation')}
      </p>
      <Button
        title={t('buttonFunction.choose', {
          ns: 'common',
        })}
        buttonType="emotion"
        onClick={(e) => handleReactionClick(e)}
        additionalStyle={behaviourPanelStyle.behaviour.buttonAdditionalStyle}
      />
    </div>
  );
};

Reactions.propTypes = {
  currentInstinctiveBehaviour: PropTypes.shape({
    description: PropTypes.string,
  }).isRequired,
  currentReasonedBehaviours: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      stimulusId: PropTypes.number,
      type: PropTypes.string,
    })
  ).isRequired,
  el: PropTypes.number.isRequired,
  handleReactionClick: PropTypes.func.isRequired,
};

export default Reactions;
