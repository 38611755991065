import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// actions
import { updateUserVisio } from 'components/Action';

// selectors
import { selectUser } from 'components/Reducers/user';

import { imagesFooter } from 'components/GameBoard/ReusableComponents/Multi/constants';

// scss
import styles from 'components/GameBoard/ReusableComponents/Multi/MeetingFooter/MeetingFooter.module.scss';

const MeetingFooter = () => {
  const [streamState, setStreamState] = useState({
    micro: true,
    video: true,
  });
  const dispatch = useDispatch();
  const { mainStream } = useSelector(selectUser);

  const handleClick = (type) => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        [`${type}`]: !currentState[`${type}`],
      };
    });
  };

  useEffect(() => {
    if (mainStream) {
      const newStream = mainStream;
      newStream.getAudioTracks()[0].enabled = streamState.micro;
      dispatch(updateUserVisio({ audio: streamState.micro }));
    }
  }, [dispatch, mainStream, streamState.micro]);

  useEffect(() => {
    if (mainStream) {
      const newStream = mainStream;
      newStream.getVideoTracks()[0].enabled = streamState.video;
      dispatch(updateUserVisio({ video: streamState.video }));
    }
  }, [dispatch, mainStream, streamState.video]);

  return (
    <div className={styles['meeting-footer']}>
      <button
        type="button"
        className={styles[`meeting-icons`]}
        onClick={() => handleClick('micro')}
        aria-hidden="true"
      >
        <img
          src={!streamState.micro ? imagesFooter.microSlash : imagesFooter.micro}
          alt={streamState.micro ? 'Mute Audio' : 'Unmute Audio'}
        />
      </button>
      <button
        type="button"
        className={styles[`meeting-icons`]}
        onClick={() => handleClick('video')}
        aria-hidden="true"
      >
        <img
          src={!streamState.video ? imagesFooter.videoSlash : imagesFooter.video}
          alt={streamState.video ? 'Hide Video' : 'Show Video'}
        />
      </button>
    </div>
  );
};

export default MeetingFooter;
