import {
  enigmas,
  emotionIntensities,
} from 'components/GameBoard/Emotion/Solo/constants';

const { emoticonsConstants, initialUserAnswers } =
  enigmas.InterpretationMachineCalibration;

const initialState = {
  backgroundDimensions: { width: 0, height: 0 },
  isBackgroundMoving: false,
  interpretationMachineCalibrationEnigma: {
    270: {
      userAnswers: initialUserAnswers,
      rightAnswers: emoticonsConstants[270].rightAnswers,
    },
    271: {
      userAnswers: initialUserAnswers,
      rightAnswers: emoticonsConstants[271].rightAnswers,
    },
    272: {
      userAnswers: initialUserAnswers,
      rightAnswers: emoticonsConstants[272].rightAnswers,
    },
    273: {
      userAnswers: initialUserAnswers,
      rightAnswers: emoticonsConstants[273].rightAnswers,
    },
    274: {
      userAnswers: initialUserAnswers,
      rightAnswers: emoticonsConstants[274].rightAnswers,
    },
    275: {
      userAnswers: initialUserAnswers,
      rightAnswers: emoticonsConstants[275].rightAnswers,
    },
    276: {
      userAnswers: initialUserAnswers,
      rightAnswers: emoticonsConstants[276].rightAnswers,
    },
    277: {
      userAnswers: initialUserAnswers,
      rightAnswers: emoticonsConstants[277].rightAnswers,
    },
  },
  emotionGenerationEnigma: {
    stimulusPlayState: 'running',
    clickedPlutchikEmotion: {},
    stimulusIndex: 0,
    shuffledStimuli: [],
    headerMessageStatus: 'stimulus-creation',
  },
  behaviourMachineCalibrationEnigma: {
    headerMessageStatus: 'intro-1',
    behaviourIndex: 0,
    connectedWires: [],
    wrongBehaviours: [],
  },
  complexSituationEnigma: {
    messageStatus: 'stimulus-creation',
    stimulusPlayState: 'running',
    stimulus2PlayState: 'paused',
    enigmaStep: 1,
    clickedPlutchikEmotion: {},
    stimulusIndex: 0,
    shuffledStimuli: [],
    energyPercentage: 60,
    clickedReaction: {},
  },
};

const pushBehaviour = (state, action) => {
  const newWrongBehaviours = [
    ...state.behaviourMachineCalibrationEnigma.wrongBehaviours,
  ];
  newWrongBehaviours.push(action.payload);
  return newWrongBehaviours;
};
const removeBehaviour = (state, action) => {
  const newWrongBehaviours = [
    ...state.behaviourMachineCalibrationEnigma.wrongBehaviours,
  ];
  newWrongBehaviours.splice(action.payload.index, 1);
  return newWrongBehaviours;
};

const emotion = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_STIMULI_ANIMATION':
      if (action.payload.room === 'EmotionGeneration') {
        return {
          ...state,
          emotionGenerationEnigma: {
            ...state.emotionGenerationEnigma,
            stimulusPlayState: action.payload.playState,
          },
        };
      }

      if (
        action.payload.room === 'ComplexSituation' &&
        action.payload.stimulus === 'stimulus1'
      ) {
        return {
          ...state,
          complexSituationEnigma: {
            ...state.complexSituationEnigma,
            stimulusPlayState: action.payload.playState,
          },
        };
      }

      return {
        ...state,
        complexSituationEnigma: {
          ...state.complexSituationEnigma,
          stimulus2PlayState: action.payload.playState,
        },
      };

    case 'SET_BACKGROUND_DIMENSIONS':
      return {
        ...state,
        backgroundDimensions: {
          ...state.backgroundDimensions,
          width: action.payload.width,
          height: action.payload.height,
        },
      };
    case 'SET_IS_BACKGROUND_MOVING':
      return {
        ...state,
        isBackgroundMoving: action.payload,
      };
    case 'SET_USER_ANSWERS':
      return {
        ...state,
        interpretationMachineCalibrationEnigma: {
          ...state.interpretationMachineCalibrationEnigma,
          [action.payload.emoticonId]: {
            ...state.interpretationMachineCalibrationEnigma[
              action.payload.emoticonId
            ],
            userAnswers: action.payload.userAnswers,
          },
        },
      };

    case 'RESET_USER_ANSWERS':
      return {
        ...state,
        interpretationMachineCalibrationEnigma: {
          ...state.interpretationMachineCalibrationEnigma,
          [action.payload.emoticonId]: {
            ...state.interpretationMachineCalibrationEnigma[
              action.payload.emoticonId
            ],
            userAnswers: initialUserAnswers,
          },
        },
      };
    case 'SET_CLICKED_PLUTCHIK_EMOTION':
      if (action.payload.room === 'EmotionGeneration') {
        return {
          ...state,
          emotionGenerationEnigma: {
            ...state.emotionGenerationEnigma,
            clickedPlutchikEmotion: action.payload.clickedEmotion,
          },
        };
      }
      return {
        ...state,
        complexSituationEnigma: {
          ...state.complexSituationEnigma,
          clickedPlutchikEmotion: action.payload.clickedEmotion,
        },
      };
    case 'SET_STIMULUS_INDEX':
      if (action.payload.room === 'EmotionGeneration') {
        return {
          ...state,
          emotionGenerationEnigma: {
            ...state.emotionGenerationEnigma,
            stimulusIndex: action.payload.stimulusIndex,
          },
        };
      }
      return {
        ...state,
        complexSituationEnigma: {
          ...state.complexSituationEnigma,
          stimulusIndex: action.payload.stimulusIndex,
        },
      };
    case 'SHUFFLE_STIMULI':
      if (action.payload.room === 'EmotionGeneration') {
        return {
          ...state,
          emotionGenerationEnigma: {
            ...state.emotionGenerationEnigma,
            shuffledStimuli: action.payload.shuffledStimuli,
          },
        };
      }
      return {
        ...state,
        complexSituationEnigma: {
          ...state.complexSituationEnigma,
          shuffledStimuli: action.payload.shuffledStimuli,
        },
      };
    case 'SET_BEHAVIOUR_INDEX':
      return {
        ...state,
        behaviourMachineCalibrationEnigma: {
          ...state.behaviourMachineCalibrationEnigma,
          // behaviourIndex: defineBehaviourIndex(action),
          behaviourIndex: action.payload,
        },
      };
    case 'ADD_WRONG_BEHAVIOUR':
      return {
        ...state,
        behaviourMachineCalibrationEnigma: {
          ...state.behaviourMachineCalibrationEnigma,
          wrongBehaviours: pushBehaviour(state, action),
        },
      };
    case 'REMOVE_WRONG_BEHAVIOUR':
      return {
        ...state,
        behaviourMachineCalibrationEnigma: {
          ...state.behaviourMachineCalibrationEnigma,
          wrongBehaviours: removeBehaviour(state, action),
        },
      };
    case 'SET_HEADER_MESSAGE_STATUS':
      if (action.payload.room === 'EmotionGeneration') {
        return {
          ...state,
          emotionGenerationEnigma: {
            ...state.emotionGenerationEnigma,
            headerMessageStatus: action.payload.message,
          },
        };
      }
      return {
        ...state,
        behaviourMachineCalibrationEnigma: {
          ...state.behaviourMachineCalibrationEnigma,
          headerMessageStatus: action.payload,
        },
      };
    case 'SET_CONNECTED_WIRES':
      return {
        ...state,
        behaviourMachineCalibrationEnigma: {
          ...state.behaviourMachineCalibrationEnigma,
          connectedWires: action.payload,
        },
      };
    case 'SET_MESSAGE_STATUS':
      return {
        ...state,
        complexSituationEnigma: {
          ...state.complexSituationEnigma,
          messageStatus: action.payload,
        },
      };
    case 'SET_ENIGMA_STEP':
      return {
        ...state,
        complexSituationEnigma: {
          ...state.complexSituationEnigma,
          enigmaStep: action.payload,
        },
      };
    case 'SET_ENERGY_PERCENTAGE':
      return {
        ...state,
        complexSituationEnigma: {
          ...state.complexSituationEnigma,
          energyPercentage: action.payload,
        },
      };
    case 'SET_CLICKED_REACTION':
      return {
        ...state,
        complexSituationEnigma: {
          ...state.complexSituationEnigma,
          clickedReaction: action.payload,
        },
      };
    default:
      return state;
  }
};

// Define the selector functions
export const selectEmotion = (state) => state.emotion;
export const selectInterpretationMachineCalibrationEnigma = (state) =>
  state.emotion.interpretationMachineCalibrationEnigma;
export const selectEmotionGenerationEnigma = (state) =>
  state.emotion.emotionGenerationEnigma;
export const selectBehaviourMachineCalibrationEnigma = (state) =>
  state.emotion.behaviourMachineCalibrationEnigma;
export const selectComplexSituationEnigma = (state) =>
  state.emotion.complexSituationEnigma;

/**
 * Selector function to know the answer state for one emoticon and one intensity
 * @param state
 * @param {number} emoticonId
 * @param {0|1|2} intensityIndex
 * @returns {"right"|"wrong"|"unanswered"}
 */
export const selectAnswerState = (state, emoticonIndex, intensityIndex) => {
  if (!emoticonIndex) {
    return 'unanswered';
  }

  const enigma = selectInterpretationMachineCalibrationEnigma(state);
  const { userAnswers, rightAnswers } = enigma[emoticonIndex];
  const userAnswer = userAnswers[intensityIndex];
  const rightAnswer = rightAnswers[intensityIndex];

  // Precisely need to check for undefined, not falsy
  if (userAnswer === undefined) {
    return 'unanswered';
  }

  return userAnswer !== rightAnswer ? 'wrong' : 'right';
};

/**
 * Selector function to store the intensities for which a wrong answer was given
 * @param  state
 * @param {number} emoticonIndex
 * @returns {String[]}
 */
export const selectWrongIntensities = (state, emoticonIndex) => {
  const wrongIntensities = [];
  emotionIntensities.forEach((intensityName, intensityIndex) => {
    const answerState = selectAnswerState(state, emoticonIndex, intensityIndex);
    if (answerState === 'wrong') wrongIntensities.push(intensityName);
  });
  return wrongIntensities;
};

/**
 * Selector function to know the answer state for one emoticon
 * @param state
 * @param {number} emoticonIndex
 * @returns {"right"|"wrong"|"unanswered"}
 */
export const selectAnswersState = (state, emoticonIndex) => {
  if (!emoticonIndex) {
    return 'unanswered';
  }

  const enigma = selectInterpretationMachineCalibrationEnigma(state);
  const { userAnswers } = enigma[emoticonIndex];
  const answers = [];
  Object.keys(userAnswers).forEach((intensityIndex) => {
    const userAnswer = selectAnswerState(
      state,
      emoticonIndex,
      Number(intensityIndex)
    );
    answers.push(userAnswer);
  });

  if (answers.find((answer) => answer === 'unanswered')) return 'unanswered';

  return answers.find((answer) => answer === 'wrong') ? 'wrong' : 'right';
};

/**
 * Selector function to know the answers for all emoticons
 * @param state
 * @returns {Object}
 */
export const selectEmoticonsState = (state) => {
  const emoticonsState = {};
  const enigma = selectInterpretationMachineCalibrationEnigma(state);
  Object.keys(enigma).forEach((emoticonIndex) => {
    const answersState = selectAnswersState(state, Number(emoticonIndex));
    emoticonsState[Number(emoticonIndex)] = answersState;
  });
  return emoticonsState;
};

/**
 * Selector function to know if the answer to a stimulus was right or wrong
 * @param  state
 * @param {number} index - index of the current text for which an answer is expected
 * @returns {string}
 */
export const selectStimulusAnswer = (state, index, room) => {
  const { clickedPlutchikEmotion, shuffledStimuli } =
    room === 'EmotionGeneration'
      ? selectEmotionGenerationEnigma(state)
      : selectComplexSituationEnigma(state);

  if (!clickedPlutchikEmotion.name) return 'unanswered';

  const findAnswer = enigmas[room].rightAnswers.find(
    (rightAnswer) => rightAnswer.stimulusId === shuffledStimuli[index].id
  );

  return clickedPlutchikEmotion.name === findAnswer.emotion ? 'right' : 'wrong';
};

export const selectRightWires = (state) => {
  const { connectedWires } = selectBehaviourMachineCalibrationEnigma(state);
  return connectedWires.filter((wire) => wire.isValidated);
};

/**
 * Selector function to know if the answer to a stimulus was right or wrong
 * @param  state
 * @param {number} index - index of the current text for which an answer is expected
 * @returns {string}
 */
export const selectReactionAnswer = (state) => {
  const { clickedReaction } = selectComplexSituationEnigma(state);

  const { rightAnswers } = enigmas.ComplexSituation;

  if (!clickedReaction?.id) return 'unanswered';

  const rightAnswer = rightAnswers.find(
    (answer) => answer.stimulusId === clickedReaction.stimulusId
  );

  return clickedReaction.id === rightAnswer.behaviourId ? 'right' : 'wrong';
};

export default emotion;
