import PropTypes from 'prop-types';

import '../../../../assets/css/components/GameBoard/TDE/Enigma/Code.css';

function Code({ image, objectContent }) {
  const handleText = () => {
    let textContent;
    if (objectContent.isInventory) {
      const isInventoryCode = objectContent.images.filter(
        (contentImage) => contentImage.type === 'inventory'
      );
      textContent = isInventoryCode[0].message;
    }

    const isClickedCode = objectContent.images.filter(
      (contentImage) => contentImage.type === 'clicked'
    );
    textContent = isClickedCode[0].message;

    return textContent;
  };

  return (
    <div className="code Modal_description">
      <div className="Modal_description-text">
        <p>{handleText()}</p>
      </div>
      <img className="code-image" src={image} alt={objectContent.title} />
    </div>
  );
}

Code.propTypes = {
  image: PropTypes.string.isRequired,
  objectContent: PropTypes.shape({
    title: PropTypes.string,
    isInventory: PropTypes.bool,
    images: PropTypes.arrayOf(PropTypes.objectOf),
  }).isRequired,
};

export default Code;
