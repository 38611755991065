/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable prefer-object-spread */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Bureau.css';
import './Modals.css';
import { connect } from 'react-redux';
import bureauEmpty from '../../../img/404/livingroom404.svg';
import img404 from '../../../img/404/404.svg';
import cushion from '../../../img/404/cushion404.svg';
import Clock from './WrongObjects/Clock';
import Trash from './Trash/Trash';
import Book from './Book/Book';
import Notifications from './Notifications';
import succes from '../../../sound/successhorns1.mp3';
import MusicThemeOffice from '../../../sound/My Sunshine.mp3';
import click from '../../../sound/click-ongame.mp3';
import Cat404 from './Cat/Cat404';
import Chest from './Chest/Chest';

function Bureau({ GameUsers, dispatch, Count, MouseClicked }) {
  const [clickSound, setClickSound] = useState(false);
  useEffect(() => {
    if (GameUsers.gameStarted && !GameUsers.timerPause && !GameUsers.soundMuted) {
      document.getElementById('audioOffice').volume = 0.05;
    }
  });

  useEffect(() => {
    dispatch({
      type: 'CLICKED_MOUSE_DOWN',
    });
    setClickSound(true);
    setTimeout(() => {
      dispatch({
        type: 'CLICKED_MOUSE_UP',
      });
      setClickSound(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Count]);

  const updateMousePosition = (ev) => {
    dispatch({
      type: 'PLACE_CURSOR',
      payload: {
        x: ev.clientX,
        y: ev.clientY,
      },
    });
  };

  useEffect(() => {
    document
      .getElementById('bureau404')
      .addEventListener('mousedown', updateMousePosition);
    return () => window.removeEventListener('mousedown', updateMousePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <audio src={clickSound && click} autoPlay>
        <track kind="captions" />
      </audio>
      {GameUsers.gameStarted && !GameUsers.timerPause && !GameUsers.soundMuted && (
        <audio src={MusicThemeOffice} id="audioOffice" autoPlay loop>
          <track kind="captions" />
        </audio>
      )}
      {GameUsers.scoreboard && (
        <audio src={succes} autoPlay>
          <track kind="captions" />
        </audio>
      )}
      <div
        className={MouseClicked ? 'cursor-clicked404' : 'Hide'}
        style={{ top: GameUsers.cursor.y, left: GameUsers.cursor.x }}
      >
        <p>+1</p>
      </div>
      <div
        className={MouseClicked ? 'bubble404' : 'Hide'}
        style={{ top: GameUsers.cursor.y, left: GameUsers.cursor.x }}
      />
      <div className="Bureau-img404" id="bureau404">
        <Notifications />
        <div
          onClick={() => dispatch({ type: 'INCREMENT_COUNTER' })}
          onKeyDown={() => dispatch({ type: 'INCREMENT_COUNTER' })}
          role="button"
          tabIndex={0}
          className="Bureau-img-div404"
        >
          <img src={bureauEmpty} alt="bureau" draggable="false" />
        </div>
        <div
          className="Text404"
          onClick={() => dispatch({ type: 'INCREMENT_COUNTER' })}
          onKeyDown={() => dispatch({ type: 'INCREMENT_COUNTER' })}
          role="button"
          tabIndex={0}
        >
          <p>
            Bonjour mon ami !
            <br />
            <strong>
              Le jeu que tu cherches n&apos;existe pas
              <br />
            </strong>
            mais tu peux résoudre l&apos;énigme 404 en essayant de pas réveiller mon
            chat Ostin.
          </p>
        </div>
        <div
          className="Img404"
          onClick={() => dispatch({ type: 'INCREMENT_COUNTER' })}
          onKeyDown={() => dispatch({ type: 'INCREMENT_COUNTER' })}
          role="button"
          tabIndex={0}
        >
          <img src={img404} alt="Error 404" draggable="false" />
        </div>
        <div className="Cushion404">
          <img src={cushion} alt="Cushion" draggable="false" />
        </div>
        <Cat404 />
        <Clock />
        <Chest />
        <Trash />
        <Book />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  preventionMessages: state.prevention_messages.allMessages,
  ModalBureau: state.prevention_messages.ModalBureau,
  GameUsers: state.GameUsers,
  Room: state.Room,
  Count: state.GameUsers.count,
  MouseClicked: state.GameUsers.clicked,
});

Bureau.propTypes = {
  MouseClicked: PropTypes.bool.isRequired,
  Count: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  ModalBureau: PropTypes.shape({
    ModalAnxiete: PropTypes.bool.isRequired,
    ModalAgressivite: PropTypes.bool.isRequired,
    ModalFatalite: PropTypes.bool.isRequired,
    ModalPessimisme: PropTypes.bool.isRequired,
  }).isRequired,
  GameUsers: PropTypes.shape({
    cursor: PropTypes.arrayOf(PropTypes.objectOf),
    gameStarted: PropTypes.number.isRequired,
    timerPause: PropTypes.bool.isRequired,
    soundMuted: PropTypes.bool.isRequired,
    gameFinished: PropTypes.bool.isRequired,
    scoreboard: PropTypes.bool.isRequired,
    fireworks: PropTypes.bool.isRequired,
  }).isRequired,
  Room: PropTypes.shape({
    Desktop: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Bureau);
