import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../../../../assets/css/components/GameBoard/TDE/Enigma/Grass.css';

const Grass = ({ image, objectContent }) => {
  const handleText = () => {
    const messageInit = objectContent.images.filter(
      (imageContent) => imageContent.type === 'clicked'
    )[0].message;

    return messageInit;
  };
  return (
    <div className="grass Modal_description">
      <div className="Modal_description-text">
        <p>{handleText()}</p>
      </div>
      <img className="grass_image" src={image} alt={objectContent.title} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  allObjects: state.Objects.AllObjects,
});

Grass.propTypes = {
  image: PropTypes.string.isRequired,
  objectContent: PropTypes.shape({
    isInventory: PropTypes.number,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
      })
    ),
    title: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Grass);
