import '../../../../../assets/css/components/GameBoard/Obesity/Enigma/Phone/Settings.css';

const Settings = () => {
  return (
    <div className="Settings">
      <p className="Settings_title Settings_firstTitle">Réglages</p>
      <div className="Settings_top">
        <p className="Settings_secondTitle">
          Il vaut mieux ne pas dérégler le téléphone d&apos;Arthur.
        </p>
      </div>
    </div>
  );
};

export default Settings;
