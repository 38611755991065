import PropTypes from 'prop-types';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import { enigmas } from 'components/GameBoard/Emotion/Solo/constants';
import { useTranslation } from 'react-i18next';

const { behaviourPanelStyle, bubbleButtonUrl } = enigmas.ComplexSituation;

const ReasonedReactionsPanel = ({
  handleReturnClick,
  handleAnswer,
  currentReasonedBehaviours,
}) => {
  const { t } = useTranslation(['emotion', 'common']);

  return (
    <>
      <Button
        title={t('buttonFunction.return', {
          ns: 'common',
        })}
        buttonType="emotion"
        onClick={handleReturnClick}
        additionalStyle={behaviourPanelStyle.returnButtonStyle}
      />
      {currentReasonedBehaviours.map((behaviour, index) => (
        <div className="reaction-container" key={behaviour.id}>
          <div>
            <img
              src={bubbleButtonUrl[`bubble-${index + 1}`]}
              alt={t('reasonedReaction', { count: 1 })}
            />
            <p className="reasoned-reaction-text">{behaviour.description}</p>
          </div>
          <Button
            title={t('buttonFunction.choose', {
              ns: 'common',
            })}
            buttonType="emotion"
            onClick={() => handleAnswer(behaviour)}
            additionalStyle={behaviourPanelStyle.behaviour.buttonAdditionalStyle}
          />
        </div>
      ))}
    </>
  );
};

ReasonedReactionsPanel.propTypes = {
  currentReasonedBehaviours: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
    })
  ).isRequired,
  handleAnswer: PropTypes.func.isRequired,
  handleReturnClick: PropTypes.func.isRequired,
};

export default ReasonedReactionsPanel;
