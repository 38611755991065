const initialState = {
  list: null,
  possibleResponse: null,
  loading: false,
  error: null,
  answers: {},
  currentQuestionId: null,
  currentSlick: 0,
  userHasSurveyId: null,
  debriefing: null,
};

const game = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_HAS_SURVEY':
      return {
        ...state,
        userHasSurveyId: action.payload,
      };
    case 'ADD_QUESTION': {
      return {
        ...state,
        list: action.payload,
      };
    }
    case 'ADD_RESPONSE': {
      return {
        ...state,
        possibleResponse: action.payload,
      };
    }
    case 'CURRENT_QUESTION': {
      return {
        ...state,
        currentQuestionId: action.payload,
      };
    }
    case 'CURRENT_SLICK': {
      return {
        ...state,
        currentSlick: action.payload,
      };
    }
    case 'RESET_ANSWER': {
      return {
        ...state,
        answers: {},
        currentQuestionId: null,
        currentSlick: 0,
      };
    }
    case 'ADD_ANSWER': {
      const newAnswer = { ...state.answers };
      newAnswer[action.payload.question_id] = action.payload;
      return {
        ...state,
        answers: {
          ...state.answers,
          ...newAnswer,
        },
      };
    }
    case 'ADD_ADVICE': {
      return {
        ...state,
        advice: action.payload,
      };
    }
    case 'ADD_QUESTIONNARY': {
      return {
        ...state,
        survey: action.payload,
      };
    }
    default:
      return state;
  }
};

// Define the selector functions
export const selectQuestionnary = (state) => state.questionnary;
export const selectQuestionnaryDebriefing = (state) =>
  state.questionnary.list.filter((question) => question.is_debriefing);

export default game;
