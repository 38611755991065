import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ObjectContent from '../Objects/ObjectContent';

import '../../../../assets/css/components/GameBoard/ReusableComponents/Modal/Zoom.css';

const Zoom = ({ dispatch, image, listObjects, allObjects }) => {
  const idLamp = 147;
  const idLight = 148;
  const idCodeLight = 149;
  const lampObject = allObjects.filter(
    (objectContent) =>
      objectContent.id === idLamp && objectContent.status === 'open'
  );
  const lightObject = allObjects.filter(
    (objectContent) =>
      objectContent.id === idLight && objectContent.status === 'open'
  );

  const codeLightObject = allObjects.filter(
    (objectContent) => objectContent.id === idCodeLight
  );

  let isLampOpen;
  let isLightOpen;
  let isCodeInInventory;
  if (lampObject && listObjects && lampObject[0] && lightObject[0]) {
    isLampOpen = lampObject[0].status === 'open';
    isLightOpen = lightObject[0].status === 'open';
    isCodeInInventory = codeLightObject[0].isInventory;
  }

  return (
    <div
      className={`Zoom${
        isLightOpen && isLampOpen && !isCodeInInventory ? '--night' : '--day'
      }`}
    >
      <img src={image} alt="background" draggable="false" />
      <div className="Modal_description">
        {listObjects
          .filter((object) => !object.isInventory)
          .map((object) => (
            <ObjectContent key={object.id} object={object} type="zoom" />
          ))}
        <div className="Modal_description_button">
          <button
            className="ButtonAction Return"
            type="button"
            onClick={() => {
              dispatch({
                type: 'CLOSE_ZOOM',
              });
            }}
          >
            Retour
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  image: state.Modal.zoom.description.image,
  listObjects: state.Modal.zoom.description.listObjects,
  allObjects: state.Objects.AllObjects,
});

Zoom.propTypes = {
  image: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  listObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
};

export default connect(mapStateToProps)(Zoom);
