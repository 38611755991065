const dailySeekerState = {
  infos: [],
  intox: [],
  news: [],
  newsToDisplay: null,
  playing: false,
  win: false,
  introFinished: false,
  goodAnswersSound: false,
  badAnswersSound: false,
  newsIndex: 0,
  goodAnswers: 0,
  badAnswers: 0,
  infosFounds: 0,
  intoxFounds: 0,
  switchNews: false,
  timer: 0,
  score: 0,
  points: 0,
};

export default dailySeekerState;
