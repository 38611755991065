const organizeEmotionsData = (plutchikEmotions) => [
  [
    plutchikEmotions.boredom,
    plutchikEmotions.distraction,
    plutchikEmotions.acceptance,
    plutchikEmotions.pensiveness,
    plutchikEmotions.interest,
    plutchikEmotions.serenity,
    plutchikEmotions.apprehension,
    plutchikEmotions.annoyance,
  ],
  [
    plutchikEmotions.surprise,
    plutchikEmotions.fear,
    plutchikEmotions.trust,
    plutchikEmotions.sadness,
    plutchikEmotions.disgust,
    plutchikEmotions.anger,
    plutchikEmotions.anticipation,
    plutchikEmotions.joy,
  ],
  [
    plutchikEmotions.rage,
    plutchikEmotions.vigilance,
    plutchikEmotions.loathing,
    plutchikEmotions.grief,
    plutchikEmotions.ecstasy,
    plutchikEmotions.terror,
    plutchikEmotions.amazement,
    plutchikEmotions.admiration,
  ],
];

export default organizeEmotionsData;
