import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../../../../assets/css/components/GameBoard/TDE/Enigma/Lamp.css';

const Lamp = ({ image, objectContent, allObjects }) => {
  const handleText = () => {
    let textContent;
    if (objectContent.isInventory) {
      const isInventoryLamp = objectContent.images.filter(
        (contentImage) => contentImage.type === 'inventory'
      );
      textContent = isInventoryLamp[0].message;
    } else {
      const idBatteryObject = 143;

      const isLampWithoutBattery = objectContent.images.filter(
        (contentImage) =>
          contentImage.type === 'clicked' &&
          contentImage.slug === 'lampe-uv-clicked-without-piles'
      );

      const isLampWithBattery = objectContent.images.filter(
        (contentImage) =>
          contentImage.type === 'clicked' &&
          contentImage.slug === 'lampe-uv-clicked-with-piles'
      );

      const objectBattery = allObjects.filter(
        (objectElement) => objectElement.id === idBatteryObject
      );
      if (objectBattery[0].isInventory && !objectBattery[0].isChecked) {
        textContent = isLampWithBattery[0].message;
      } else {
        textContent = isLampWithoutBattery[0].message;
      }
    }

    return textContent;
  };
  return (
    <div className="l Modal_description">
      <div className="Modal_description-text">
        <p>{handleText()}</p>
      </div>
      <img className="lamp-image" src={image} alt={objectContent.title} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  allObjects: state.Objects.AllObjects,
});

Lamp.propTypes = {
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  image: PropTypes.string.isRequired,
  objectContent: PropTypes.shape({
    isInventory: PropTypes.number,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        slug: PropTypes.string,
      })
    ),
    title: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Lamp);
