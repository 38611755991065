import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import getRoomHasObject from 'API/roomHasObject';
import getObjectImages from 'API/objects';
import {
  postSessionsHasRoomHasObject,
  getSessionObjects,
} from 'API/sessionsHasRoomHasObject';
import {
  postSessionsHasRoom,
  getSessionsHasRoom,
  getSessionMessage,
} from 'API/sessionsHasRoom';
import getRoomHasMessages from 'API/roomHasMessage';
import {
  postSessionsHasRoomHasMessage,
  getSessionsHasRoomHasMessage,
} from 'API/sessionsHasRoomHasMessage';
import { getMessageDescription } from 'API/messages';
import { fetchSteps, fetchObject } from 'components/Action/index';

/**
 * Initialize the link between the session and the room
 * @param {function} dispatch redux store's dispatch function
 * @param {number} currentRoomId
 * @param {number} sessionId
 * @returns {number} idSessionHasRoom
 */
const initSessionsHasRoom = async (dispatch, currentRoomId, sessionId) => {
  // TODO today we create a new session if the player refresh  the page -> should be change in the future
  const responseSessionsHasRoom = await postSessionsHasRoom(
    sessionId,
    currentRoomId
  );
  let idSessionHasRoom;
  let count;

  /* TODO: check if it's possible to get responseSessionsHasRoom
      in postSessionsHasRoom when we refresh the page, instead
      of Room already initialized */
  if (typeof responseSessionsHasRoom !== 'object') {
    // Call the newly created session_has_room entry
    const response = await getSessionsHasRoom(sessionId, currentRoomId);
    idSessionHasRoom = response[0].id;
    count = response[0].count;
  } else {
    idSessionHasRoom = responseSessionsHasRoom.id;
    count = responseSessionsHasRoom.count;
  }

  // Store the id
  dispatch({
    type: 'SET_SESSION_HAS_ROOM_ID',
    payload: idSessionHasRoom,
  });

  // Store the count
  // TODO if we create a session each time, the count will always be 0
  dispatch({
    type: 'SET_COUNT',
    payload: count,
  });

  return idSessionHasRoom;
};

/**
 * Set up the back table session_has_room_has_messages.
 * Returns the prevention messages of the couple session/room
 * @param {number} currentRoomId
 * @param {number} idSessionHasRoom
 * @returns {Object[]} sessionRoomMessages
 */
const initSessionHasRoomHasMessages = async (currentRoomId, idSessionHasRoom) => {
  // Get the prevention messages linked to a room
  const roomMessages = await getRoomHasMessages(currentRoomId);

  // Initiate an entry in sessions_has_room_has_message for each prevention message
  const sessionMessagesPromises = roomMessages.map(async (roomMessage) => {
    await postSessionsHasRoomHasMessage(
      currentRoomId,
      roomMessage,
      idSessionHasRoom
    );
  });
  // Process all the messages in parallel but it will wait for them all to
  // complete before proceeding to next calls
  // https://gist.github.com/joeytwiddle/37d2085425c049629b80956d3c618971
  await Promise.all(sessionMessagesPromises);

  // Retrieve all the prevention messages for a room and a session
  const sessionRoomMessages = await getSessionsHasRoomHasMessage(
    idSessionHasRoom,
    currentRoomId
  );

  return sessionRoomMessages;
};

/**
 * Store the current prevention message if there is one
 * @param {function} dispatch redux store's dispatch function
 * @param {number} currentRoomId
 * @param {number} idSessionHasRoom
 */
const initCurrentMessage = async (
  dispatch,
  currentRoomId,
  sessionId,
  sessionRoomMessages
) => {
  const sessionMessage = await getSessionMessage(sessionId, currentRoomId);

  let currentStepId;

  // If a current prevention message exists
  if (sessionMessage.length) {
    currentStepId = sessionMessage[0].current_step;
  } else {
    currentStepId = sessionRoomMessages[0].id;
  }
  // Store the current prevention message where the user was at
  /* In theory we don't need to copy the whole object in state->Steps->currentStep,
      we could just put the object id. We have the complete object in state->Steps->list.
    If we want to refactor that in the future, there will be consequences in the code where currentStep is used  */
  dispatch({
    type: 'CURRENT_STEP',
    payload: currentStepId,
  });
};

/**
 * Construct complete messages with basic API call done before (sessionRoomMessages)
 * and the one for the messages content
 * @param {function} dispatch redux store's dispatch function
 * @param {Object[]} sessionRoomMessages
 */
const constructCompleteMessages = async (
  dispatch,
  currentRoomId,
  sessionId,
  sessionRoomMessages
) => {
  //  Retrieve the content of the messages
  const completeMessagesPromises = sessionRoomMessages.map(
    async (sessionRoomMessage) => {
      const messagesContent = await getMessageDescription(sessionRoomMessage.id);
      // Avoid eslint warning on modifying function parameter
      const sessionRoomMessageCopy = sessionRoomMessage;
      sessionRoomMessageCopy.messages = messagesContent;
    }
  );
  // Process all the messages content in parallel but it will wait for them all to
  // complete before proceeding to next calls
  await Promise.all(completeMessagesPromises);

  // Sort the prevention messages per level
  const sortedMessages = [...sessionRoomMessages].sort((a, b) => a.level - b.level);

  // Check if there is duplicateStep
  const noDuplicateSortedMessages = Array.from(
    new Set(sortedMessages.map((a) => a.id))
  ).map((id) => {
    return sortedMessages.find((a) => a.id === id);
  });
  // Store the complete messages
  dispatch(fetchSteps(noDuplicateSortedMessages));
  initCurrentMessage(dispatch, currentRoomId, sessionId, sessionRoomMessages);
};

/**
 * Personalized hook to initiate the session, the room, the objects in the room and the
 * prevention messages
 * @param {number} currentRoomId
 * @param {number} sessionId
 * @param {number} handleResidents
 */
const useInitGame = (currentRoomId, sessionId, handleResidents, steps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDataInitialization = async () => {
      // 1. Session & Room
      const idSessionHasRoom = await initSessionsHasRoom(
        dispatch,
        currentRoomId,
        sessionId
      );

      // 2. Session & Messages
      const sessionRoomMessages = await initSessionHasRoomHasMessages(
        currentRoomId,
        idSessionHasRoom
      );

      // sessionRoomMessages shouldn't be an empty array, throw an error in this case
      if (!sessionRoomMessages.length) {
        // TODO: add an error message
        throw new Error();
      }
      // initCurrentMessage(dispatch, currentRoomId, sessionId, sessionRoomMessages);
      constructCompleteMessages(
        dispatch,
        currentRoomId,
        sessionId,
        sessionRoomMessages
      );

      // 3. Objects & Room

      // Get the objects of the room
      const objects = await getRoomHasObject(currentRoomId);

      // Init all Objects of the current room
      dispatch(fetchObject(objects));

      // Map on the objects
      objects.map(async (object) => {
        // Process all the get object images and the post session_has_room_has_object
        //  in parallel but it will wait for them all to
        // complete before proceeding to next calls
        const [objectImages] = await Promise.all([
          getObjectImages(object.id),
          postSessionsHasRoomHasObject(idSessionHasRoom, object.id, currentRoomId),
        ]);

        // UPDATE_OBJECT doesn't always modify the state in the store -> need to look at that in the future
        /* TODO too many dispatch to the store, should be improved in the future, with maybe an update
             of all the images of all the object with 1 dispatch */

        const objectsOfSessionRooms = await getSessionObjects(
          idSessionHasRoom,
          currentRoomId
        );

        /* TO REFACTO but i don't found an other solution to put
         * this function in this utilityfunction I know is not really good but i Try but the objects or not retrieve with
         * isChecked not all the time
         * Test: When i refresh the corridor: retrieve residents and the inventory in database */

        const idRoomOfCorridorTMS = 50;

        if (currentRoomId === idRoomOfCorridorTMS) {
          handleResidents(objectsOfSessionRooms);
        }

        dispatch({
          type: 'UPDATE_OBJECT',
          payload: {
            listImages: objectImages,
            id: object.id,
            listObjects: objectsOfSessionRooms,
          },
        });
      });
    };

    // in which case currentRoomId is null ?
    if (currentRoomId) {
      fetchDataInitialization();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomId, steps]);
};

export default useInitGame;
