import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// scss
import styles from 'components/GameBoard/ReusableComponents/Multi/CheckRoom/CurrentParticipant.module.scss';

// actions
import { updateUser } from 'components/Action/index';

// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import TestingCamera from 'components/GameBoard/ReusableComponents/Multi/CheckRoom/TestingCamera';

function CurrentParticipant({ setStatusCheckRoom }) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  // const [needHelp, setNeedHelp] = useState(false);
  const [pseudo, setPseudo] = useState('');

  // if (needHelp) {
  //   Hugo Design missing design of element or replace with chatbot Tricky we see
  //   return 'Lorem Ipsum';
  // }

  return (
    <div className={styles.background}>
      {/* <Button
        onClick={() => setNeedHelp(!needHelp)}
        title={t('buttonFunction.help')}
        buttonType="action"
      /> */}
      <div className={styles['current-user']}>
        <TestingCamera pseudo={pseudo} setPseudo={setPseudo} />
      </div>
      <div className={styles.footer}>
        <Button
          title={t('buttonFunction.begin')}
          buttonType="action"
          onClick={() => {
            setStatusCheckRoom('all-participants');
            dispatch(updateUser({ userName: pseudo }));
          }}
          disabled={!pseudo}
        />
        <p className={styles.instruction}>{t('multi.visio.instruction')}</p>
      </div>
    </div>
  );
}

CurrentParticipant.propTypes = {
  setStatusCheckRoom: PropTypes.func.isRequired,
};

export default CurrentParticipant;
