import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import BackgroundStyle from 'components/GameBoard/ReusableComponents/BackgroundStyle';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import Chevrons from 'components/GameBoard/ReusableComponents/Actions/Chevrons';
import WarningLights from 'components/GameBoard/Emotion/Solo/WarningLights';
import ModalEnigmaEmotion from 'components/GameBoard/ReusableComponents/Modal/ModalEnigmaEmotion';
import RoomContainer from 'components/GameBoard/Emotion/Solo/Rooms/RoomContainer';
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import Scoreboard from 'components/GameBoard/ReusableComponents/Scoreboard/Scoreboard';

// Constant
import {
  backgroundStyle,
  volume,
  warningLightUrl,
} from 'components/GameBoard/Emotion/Solo/constants';

// Selector functions
import { selectModal } from 'components/Reducers/Modal/Modal';
import { selectSession } from 'components/Reducers/session';
import { selectRoom } from 'components/Reducers/Room';
import { selectSteps } from 'components/Reducers/Steps/Steps';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import {
  selectComplexSituationEnigma,
  selectEmotion,
  selectReactionAnswer,
  selectStimulusAnswer,
} from 'components/Reducers/emotion';

// Utils
import {
  clickCount,
  centerPoint,
  getRightCorner,
  shuffleArray,
  useStartMessageOrQuestionary,
} from 'utils/utilityFunctions';
import useInitGame from 'utils/useInitGame';
import useWindowResize from 'utils/useWindowResize';
// Assets
import mainTheme from 'sound/Emotion_solo/chapter_4_theme.mp3';
import click from 'sound/click-ongame.mp3';

let isMounted = false;

const ComplexSituationRoom = () => {
  // `t` is a function coming from the i18next library used to translate text
  /* Load the `emotion` namespace = the one containing translations for the emotion game (see public-> locales -> fr-FR) */
  const { t } = useTranslation('emotion');

  // Access the i18next stimuli object
  const stimuli = t('enigma4.stimuli', {
    returnObjects: true,
  });

  const dispatch = useDispatch();

  // This ref is connected to the image background
  const backgroundImageRef = useRef();

  // Select data from the store
  const session = useSelector(selectSession);
  const { backgroundDimensions } = useSelector(selectEmotion);
  const { currentRoomId, listRooms } = useSelector(selectRoom);
  const { stimulusPlayState, stimulus2PlayState, enigmaStep, stimulusIndex } =
    useSelector(selectComplexSituationEnigma);
  const stimulusAnswerState = useSelector((state) =>
    selectStimulusAnswer(state, stimulusIndex, 'ComplexSituation')
  );
  const reactionAnswerState = useSelector(selectReactionAnswer);
  const modalIsOpen = useSelector(selectModal).modal.isOpen;
  const modalEnigmaEmotionIsOpen =
    useSelector(selectModal).modalEnigmaEmotion.isOpen;
  const { startMessage, startQuestionnary, currentStep, isScoreBoard } =
    useSelector(selectSteps);
  const { idSessionHasRoom, clicked, soundtrack, music, cursor } =
    useSelector(infoGameUser);

  const currentRoom = Object.values(listRooms).find(
    (roomObject) => roomObject.id === currentRoomId
  );
  const backgroundUrl = currentRoom.url;
  const [lightState, setLightState] = useState('green');
  const [backgroundPosition, setBackgroundPosition] = useState('center');

  useWindowResize(backgroundImageRef);

  // Define variables used for the translation of the background
  const screenWidth = window.innerWidth;
  const imgWidth = backgroundDimensions.width;
  /* Number of pixels needed to center a point of interest in the background */
  const pointOfInterestCentered =
    centerPoint(backgroundStyle.pointOfInterest, imgWidth, screenWidth) >= 0
      ? `-${centerPoint(backgroundStyle.pointOfInterest, imgWidth, screenWidth)}px`
      : '0px';

  // Initiate the session, the room, the objects in the room and the prevention messages
  useInitGame(currentRoomId, session.id);

  // Save the prevention message/questionary step in database and store and launch it
  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary
  );

  /**
   * Define a url for a warning light if it changes during the enigma
   * @param {Object} warningLight - the targeted warning light
   * @param {string} warningLight.id - the id of the warning light
   * @returns
   */
  const handleWarningLight = (warningLight) => {
    if (warningLight.id === 'center') {
      if (stimulusAnswerState === 'wrong') return warningLightUrl.red;
      if (enigmaStep === 1 && stimulusPlayState === 'paused') {
        return warningLightUrl.orange;
      }
    } else if (warningLight.id === 'right') {
      if (reactionAnswerState === 'wrong') return warningLightUrl.red;
      if (
        enigmaStep === 2 &&
        stimulus2PlayState === 'paused' &&
        reactionAnswerState === 'unanswered'
      ) {
        return warningLightUrl.orange;
      }
    }

    if (warningLight.id === 'left' && !modalEnigmaEmotionIsOpen && !modalIsOpen) {
      if (lightState === 'inactive') return warningLightUrl.inactive;
      return warningLightUrl.green;
    }

    return undefined;
  };

  // Make the light blink
  useEffect(() => {
    let intervalId;
    if (!modalEnigmaEmotionIsOpen && !modalIsOpen) {
      intervalId = setInterval(
        () => setLightState((prev) => (prev === 'green' ? 'inactive' : 'green')),
        1000
      );
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [modalEnigmaEmotionIsOpen, modalIsOpen]);

  // Launch a modal to explain how to start the enigma
  useEffect(() => {
    dispatch({
      type: 'OPEN_MODAL',
      payload: t('enigma4', { returnObjects: true }),
    });
  }, [dispatch, t]);

  /* Ensure the player is on the right part of the background when launching the enigma.
  Otherwise the stimulus and conveyor belt objects are misplaced */
  useEffect(() => {
    if (modalEnigmaEmotionIsOpen && backgroundPosition !== 'right-from-center')
      // The position of the conveyor belt only works when the background is at the right part
      setBackgroundPosition('right-from-center');
  }, [backgroundPosition, enigmaStep, modalEnigmaEmotionIsOpen]);

  // Shuffle the stimuli array at mounting
  useEffect(() => {
    if (!isMounted) {
      dispatch({
        type: 'SHUFFLE_STIMULI',
        payload: {
          room: 'ComplexSituation',
          shuffledStimuli: shuffleArray(stimuli),
        },
      });
      isMounted = true;
    }
  }, [dispatch, stimuli]);

  return (
    <RoomContainer id="container">
      <Audio sound={mainTheme} loop muted={!music} volume={volume - 0.2} />
      <Audio
        sound={click}
        condition={!clicked && soundtrack && !modalEnigmaEmotionIsOpen}
      />
      <div
        aria-hidden="true"
        className={clicked ? 'cursor-clicked' : 'Hide'}
        style={{ top: cursor.y, left: cursor.x }}
      >
        <p>+1</p>
      </div>
      {/* Display the header with the HUD and the Steps */}
      <header>
        <Steps />
        <Hud />
      </header>
      {/* Display the background */}
      <BackgroundStyle
        position={backgroundPosition}
        start={pointOfInterestCentered}
        right={imgWidth && getRightCorner(imgWidth, screenWidth)}
        left={backgroundStyle.imgLeft}
        duration={backgroundStyle.duration}
        timing={backgroundStyle.timing}
        fillMode={backgroundStyle.fillMode}
        backgroundWidth={backgroundStyle.width}
        onClick={(event) => {
          clickCount(dispatch, event);
        }}
        type="button"
        onAnimationStart={() =>
          dispatch({ type: 'SET_IS_BACKGROUND_MOVING', payload: true })
        }
        onAnimationEnd={() =>
          dispatch({ type: 'SET_IS_BACKGROUND_MOVING', payload: false })
        }
      >
        <img
          src={backgroundUrl}
          width="100%"
          height="100%"
          alt={t('lab')}
          id="img-background"
          ref={backgroundImageRef}
        />
        <ListObjects />
        <WarningLights
          handleWarningLight={(warningLight) => handleWarningLight(warningLight)}
        />
      </BackgroundStyle>
      <Chevrons
        position={backgroundPosition}
        setPosition={setBackgroundPosition}
        modalEnigmaIsOpen={modalEnigmaEmotionIsOpen}
        hide={modalEnigmaEmotionIsOpen}
      />
      {modalIsOpen && !isScoreBoard && <Modal />}
      {modalEnigmaEmotionIsOpen && <ModalEnigmaEmotion />}
      {isScoreBoard && <Scoreboard />}
    </RoomContainer>
  );
};

export default ComplexSituationRoom;
