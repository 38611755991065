import PropTypes from 'prop-types';

import './Scoreboard.css';

import { trickyCoin } from '../../../../img/vaccination-solo';

function ScoreboardItem({ rank, pseudo, count, style, time }) {
  return (
    <div
      className={
        style
          ? 'ModalTrickyVacc-Scoreboard-User'
          : 'ModalTrickyVacc-Scoreboard-players'
      }
    >
      <div
        className={`col20-Vacc-Score ${
          style ? 'scoreboard-user' : 'scoreboard-rank'
        }`}
      >
        {rank}
      </div>
      <div
        className={`col40-Vacc-Score ${
          style ? 'scoreboard-user' : 'scoreboard-pseudo'
        }`}
      >
        {pseudo}{' '}
      </div>
      <div className="col20-Vacc-Score-Coin">
        <img src={trickyCoin} alt="trickyCoin" className="Score-Tricky-coin" />
        {count}
      </div>
      {time && (
        <div
          className={`col20-Vacc-Score ${
            style ? 'scoreboard-user' : 'scoreboard-rank'
          }`}
        >
          {time}
        </div>
      )}
    </div>
  );
}

ScoreboardItem.propTypes = {
  pseudo: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  rank: PropTypes.number,
  style: PropTypes.bool.isRequired,
  time: PropTypes.string.isRequired,
};

ScoreboardItem.defaultProps = {
  rank: null,
};

export default ScoreboardItem;
