const initialState = {
  Modal: {
    modalRefillPaper: false,
  },
};

// REFACTO Page 404
const Room404 = (state = initialState, action) => {
  switch (action.type) {
    case 'MODAL_404': {
      const typeOfModal = action.payload.modalType;
      return {
        ...state,
        Modal: {
          ...state.Modal,
          [typeOfModal]: action.payload.showModal,
        },
      };
    }
    default:
      return state;
  }
};

export default Room404;
