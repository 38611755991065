import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';

/**
 * Reusable component to handle sound
 * @param {bool}  muted - mute a loop sound or not
 * @param {bool}  condition - a condition to play a one-time sound or not
 * @param {string}  sound - url of the sound
 * @param {bool}  loop - sound played in loop or not
 * @param {string}  id
 * @param {func}  onEnded - function executed after the sound was played
 */
const Audio = ({ muted, condition, sound, loop, id, onEnded, volume }) => {
  const audioRef = useRef();

  useEffect(() => {
    audioRef.current.volume = volume;
  }, [volume]);

  return (
    <audio
      onEnded={onEnded}
      muted={muted}
      src={condition ? sound : undefined}
      id={id}
      autoPlay
      loop={loop}
      ref={audioRef}
    >
      <track kind="captions" />
    </audio>
  );
};

Audio.propTypes = {
  muted: PropTypes.bool,
  condition: PropTypes.bool,
  sound: PropTypes.string.isRequired,
  loop: PropTypes.bool,
  id: PropTypes.string,
  onEnded: PropTypes.func,
  volume: PropTypes.number,
};

Audio.defaultProps = {
  muted: false,
  condition: true,
  loop: false,
  id: undefined,
  onEnded: undefined,
  volume: 1,
};

export default Audio;
