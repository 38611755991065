import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import Slider from 'react-slick';

// selector functions
import { selectSession } from 'components/Reducers/session';

// utils
import {
  linkUserSurveySession,
  linkAnswerBetweenQuestion,
} from 'utils/utilityFunctions';

// actions
import { retrieveCurrentQuestion } from 'components/Action';

// components
import Button from '../Actions/Button';
import Note from './Note';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function QuestionnaryContent({
  dispatch,
  questionnary,
  currentSlick,
  currentQuestionId,
  userId,
  userSurveyId,
  currentStep,
  idSessionHasRoom,
  currentRoomId,
  answers,
  Room,
  listSteps,
}) {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const sliderRef = useRef();
  const session = useSelector(selectSession);

  useEffect(() => {
    let idCurrentQuestion = null;
    if (currentSlick === 0) {
      const idFirstQuestion = questionnary.list[0].id;
      idCurrentQuestion = idFirstQuestion;
    } else {
      idCurrentQuestion = questionnary.list[currentSlick].id;
    }

    dispatch(retrieveCurrentQuestion(idCurrentQuestion));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlick, dispatch]);

  useEffect(() => {
    if (questionnary.survey.id) {
      linkUserSurveySession(userId, questionnary.survey.id, session.id, dispatch);
    }
  }, [dispatch, questionnary.survey.id, session.id, userId]);

  const finishMessage = () => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasMessage/${idSessionHasRoom}`, {
        date: new Date().toISOString().slice(0, 19).replace('T', ' '),
        room_id: currentRoomId,
        message_id: currentStep.id,
        isFound: 1,
      })
      .then((response) => response.data)
      .then(() => {
        dispatch({
          type: 'CLOSE_MODAL',
        });

        dispatch({
          type: 'NEXT_MESSAGE',
          payload: currentStep,
        });
        dispatch({
          type: 'UPDATE_GAME_SCORE',
          payload: 100,
        });
        dispatch({
          type: 'STOP_QUESTIONNARY',
        });
        dispatch({
          type: 'RESET_ANSWER',
        });
        dispatch({
          type: 'PAUSE_TIMER',
          payload: false,
        });
        dispatch({
          type: 'CURRENT_STEP',
        });

        const allStepsFound =
          listSteps.find((steps) => !steps.isFound) === undefined;
        const lastRoomSlug = Object.keys(Room.listRooms).length - 1;
        const lastRoomFinish =
          Room.listRooms[Object.keys(Room.listRooms)[lastRoomSlug]].isActive;

        if (allStepsFound && !lastRoomFinish) {
          const currentRoomIndex = Object.keys(Room.listRooms).findIndex(
            (room) => Room.listRooms[room].id === Room.currentRoomId
          );
          const currentRoomSlug = Object.keys(Room.listRooms)[currentRoomIndex];
          const nextRoomIndex = currentRoomIndex + 1;
          const nextRoomSlug = Object.keys(Room.listRooms)[nextRoomIndex];
          if (nextRoomSlug !== undefined) {
            axios
              .put(`${BASE_URL}/api/sessions/${session.id}/modifications`, {
                room_id: Room.listRooms[`${nextRoomSlug}`].id,
              })
              .then(() => {
                dispatch({
                  type: 'ROOM_ACTIVE',
                  payload: {
                    room: currentRoomSlug,
                    active: false,
                  },
                });
                dispatch({
                  type: 'ROOM_ACTIVE',
                  payload: {
                    room: nextRoomSlug,
                    active: true,
                  },
                });
              });
          } else {
            dispatch({
              type: 'ROOM_ACTIVE',
              payload: {
                room: currentRoomSlug,
                active: true,
              },
            });
          }
        }
      });
  };

  const handleAnswer = (responseId, responseContent) => {
    if (userSurveyId) {
      linkAnswerBetweenQuestion(
        responseContent,
        currentQuestionId,
        userSurveyId,
        dispatch
      );
    }
  };

  return (
    <div className="QuestionnaryContent">
      {questionnary.survey.name}
      <div className="QuestionnaryContent_questions">
        <Slider ref={sliderRef} settings={settings}>
          {questionnary.list.map((questionContent, index) => {
            return (
              <div
                key={questionContent.id}
                className="QuestionnaryContent_question"
              >
                <p>{questionContent.title}</p>
                {questionContent.responses && (
                  <Note
                    index={index}
                    responses={questionContent.responses}
                    sliderRef={sliderRef}
                    handleAnswer={handleAnswer}
                  />
                )}
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="QuestionnaryContent_questions-btns">
        {currentSlick !== 0 && (
          <Button
            onClick={() => {
              let prevSlick = currentSlick;
              if (currentSlick > 0) {
                prevSlick = currentSlick - 1;
              } else {
                prevSlick = 0;
              }
              sliderRef.current.slickGoTo();
              dispatch({
                type: 'CURRENT_SLICK',
                payload: prevSlick,
              });
            }}
            buttonType="return"
            title="Retour"
          />
        )}
        {questionnary.list.length - 1 === currentSlick ? (
          <Button
            onClick={() => finishMessage()}
            buttonType="action"
            title="Suivant"
          />
        ) : (
          answers[currentQuestionId] && (
            <button
              className="ButtonAction VaccAction"
              type="button"
              onClick={() => {
                sliderRef.current.slickGoTo(currentSlick + 1);
                dispatch({
                  type: 'CURRENT_SLICK',
                  payload: currentSlick + 1,
                });
              }}
            >
              <p>Suivant</p>
            </button>
          )
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  questionnary: state.questionnary,
  currentSlick: state.questionnary.currentSlick,
  currentQuestionId: state.questionnary.currentQuestionId,
  userId: state.GameUsers.userDescription.id,
  userSurveyId: state.questionnary.userHasSurveyId,
  currentStep: state.Steps.currentStep,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  currentRoomId: state.Room.currentRoomId,
  answers: state.questionnary.answers,
  Room: state.Room,
  listSteps: state.Steps.list,
});

QuestionnaryContent.propTypes = {
  currentQuestionId: PropTypes.number,
  questionnary: PropTypes.shape({
    list: PropTypes.arrayOf({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    survey: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        response: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
      })
    ),
  }).isRequired,
  currentSlick: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  userSurveyId: PropTypes.number,
  currentStep: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  currentRoomId: PropTypes.number.isRequired,
  answers: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  Room: PropTypes.shape({
    listRooms: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object])),
    currentRoomId: PropTypes.number,
  }).isRequired,
  listSteps: PropTypes.arrayOf(
    PropTypes.shape({
      isFound: PropTypes.bool,
    })
  ).isRequired,
};

QuestionnaryContent.defaultProps = {
  userSurveyId: null,
  currentQuestionId: null,
};

export default connect(mapStateToProps)(QuestionnaryContent);
