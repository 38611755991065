import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Notifications.css';

function Notifications({ preventionMessages, dispatch }) {
  useEffect(() => {
    if (preventionMessages.show) {
      setTimeout(() => {
        dispatch({
          type: 'MESSAGE_NOTIF',
          payload: false,
        });
      }, 5020);
    }
  }, [dispatch, preventionMessages.show]);

  return (
    <div className={preventionMessages.show ? 'Notifications-Container' : 'Hide'}>
      <div className={preventionMessages.show ? 'Notifications' : 'Hide-Modal'}>
        <p>{preventionMessages.message}</p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  preventionMessages: state.prevention_messages.message,
});

Notifications.propTypes = {
  preventionMessages: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Notifications);
