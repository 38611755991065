/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Bureau.css';
import './Modals.css';
import popwrong from '../../../sound/wrong.mp3';
import { counterClick } from '../../Action/index';

function ModalWrongObject({ title, img, message, closemodal, dispatch }) {
  return (
    <>
      <audio src={popwrong} autoPlay>
        <track kind="captions" />
      </audio>
      <div className="ModalTricky-Background404">
        <div className="ModalTricky-Container404">
          <div className="ModalTricky-p404">
            <p dangerouslySetInnerHTML={{ __html: message }} />
          </div>
          <div className="ModalTricky-WrongObject404">
            <img src={img} alt={title} draggable="false" />
          </div>
          <div>
            <button
              className="ButtonAction404 Action404"
              type="button"
              onClick={() => {
                dispatch({
                  type: 'MODAL_WRONGOBJECTS',
                  payload: {
                    modalType: closemodal,
                    showModal: false,
                  },
                });
                dispatch(counterClick());
              }}
            >
              Bien compris
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

ModalWrongObject.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  closemodal: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ModalWrongObject);
