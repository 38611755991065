import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import trash from '../../../../img/office/trash404.svg';
import wooshSound from '../../../../sound/woosh.mp3';
import pop from '../../../../sound/pop.mp3';
import paperImg from '../../../../img/404/Papiers404_TOP.svg';
import './Trash.css';
import '../Modals.css';
import { addObject } from '../../../Action/index';

function Trash({ dispatch, Objects }) {
  const [show, setShow] = useState(false);
  const [woosh, setWoosh] = useState(false);
  const [popSound, setPopSound] = useState(false);
  const [trashDone, setTrashDone] = useState(false);
  const [trashDoneZoom, setTrashDoneZoom] = useState(false);

  const handleClick = () => {
    setShow(!show);
    setTimeout(() => {
      setPopSound(true);
    }, 200);
    setTimeout(() => {
      setPopSound(false);
    }, 1200);
  };

  const AddObject = () => {
    dispatch(
      addObject({
        name: Objects.AllObjects.filter((item) => item.id === 65).map(
          (item) => item.name
        )[0],
        image: Objects.AllObjects.filter((item) => item.id === 65).map(
          (item) => item.image
        )[0],
        id: 65,
      })
    );
    dispatch({
      type: 'SHOW_MESSAGE',
      payload: {
        message: 'Le bout de papier a été ajouté à votre inventaire',
        show: true,
      },
    });
    setShow(!show);
    setTrashDone(true);
    setTrashDoneZoom(true);
    setWoosh(true);
    setTimeout(() => {
      setTrashDoneZoom(false);
    }, 300);
    setTimeout(() => {
      setWoosh(false);
    }, 1000);
  };

  const handleFind = () => {
    dispatch({
      type: 'SHOW_MESSAGE',
      payload: {
        message: 'Vous avez trouvé un bout de papier. Il est dans votre inventaire',
        show: true,
      },
    });
  };

  return (
    <>
      {trashDoneZoom && (
        <div className="zoomleft404">
          <img src={paperImg} alt="Paper" draggable="false" />
        </div>
      )}
      <audio src={woosh && wooshSound} autoPlay>
        <track kind="captions" />
      </audio>
      <audio src={popSound && pop} autoPlay>
        <track kind="captions" />
      </audio>
      <div className={show ? 'ModalTricky-Background' : 'Hide-Modal'}>
        <div className="ModalTricky-Container">
          <div className="ModalTricky-p">
            <p>Tiens, il y a un bout de papier déchiré à l&apos;intérieur</p>
          </div>
          <div className="ItemToShow404">
            <img src={trash} alt="Trash Bin" draggable="false" />
          </div>
          <div>
            <button
              className="ButtonAction404 Action404"
              type="button"
              onClick={AddObject}
            >
              Ajouter à l&apos;inventaire
            </button>
          </div>
        </div>
      </div>
      <div>
        <div
          className="trash-404"
          onClick={!trashDone ? () => handleClick() : () => handleFind()}
          onKeyDown={() => {}}
          tabIndex={0}
          role="button"
        >
          <img src={trash} alt="Trash of the livingroom" draggable="false" />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  Objects: state.Objects,
});

Trash.propTypes = {
  dispatch: PropTypes.func.isRequired,
  Objects: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
};

export default connect(mapStateToProps)(Trash);
