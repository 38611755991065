import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// scss
import styles from 'components/GameBoard/ReusableComponents/Multi/CheckRoom/TestingCamera.module.scss';

const TestingCamera = ({ pseudo, setPseudo }) => {
  const videoRef = useRef(null);
  const [streamVisio, setStreamVisio] = useState(null);
  const [footerButton, setFooterButton] = useState({
    video: true,
    audio: true,
    sound: true,
  });

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          width: 1280,
          height: 720,
          frameRate: {
            ideal: 10,
            min: 15,
          },
        },
      })
      .then((streamElement) => {
        setStreamVisio(streamElement);
        const video = videoRef.current;
        video.srcObject = streamElement;
        video.onloadedmetadata = () => {
          video.play();
        };
      });
  };

  const handleClose = (type) => {
    setFooterButton({
      ...footerButton,
      [`${type}`]: !footerButton[`${type}`],
    });

    if (type === 'video' || type === 'audio') {
      streamVisio.getTracks().forEach((track) => {
        if (track.readyState === 'live' && track.kind === type) {
          const newTrack = track;
          newTrack.enabled = !footerButton[`${type}`];
        }
      });
    }
  };

  useEffect(() => {
    getVideo();
  }, []);

  return (
    <div className={styles.testing}>
      <div className={styles.camera}>
        <video ref={videoRef} className={styles.video}>
          <track kind="captions" />
        </video>
        <div className={styles.footer}>
          <input
            className={styles['input-pseudo']}
            type="text"
            name="pseudo"
            // for the ux-ui of the users, we need to autofus on the input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            placeholder="Pseudo"
            value={pseudo}
            onChange={(e) => setPseudo(e.target.value)}
          />
          {Object.keys(footerButton).map((actionButton) => (
            <button
              key={actionButton}
              aria-hidden="true"
              className={`${styles[`${actionButton}-button`]} ${
                !footerButton[actionButton] && styles.activated
              }`}
              type="button"
              onClick={() => handleClose(actionButton)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

TestingCamera.propTypes = {
  pseudo: PropTypes.string.isRequired,
  setPseudo: PropTypes.func.isRequired,
};

export default TestingCamera;
