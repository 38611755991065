import { useState } from 'react';
import { useSelector } from 'react-redux';
import { GiSandsOfTime, GiNetworkBars } from 'react-icons/gi';
import { IoIosBatteryCharging } from 'react-icons/io';
import { ImCamera } from 'react-icons/im';
import { FcCalculator } from 'react-icons/fc';
import { TiWeatherPartlySunny } from 'react-icons/ti';
import { GoDeviceCameraVideo } from 'react-icons/go';
import { GrSettingsOption } from 'react-icons/gr';
import { AiFillHeart } from 'react-icons/ai';

// selectors
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

import Audio from '../../../ReusableComponents/Actions/Audio';
import click from '../../../../../sound/iphone-click.mp3';

import Timer from './Timer';
import Camera from './Camera';
import Settings from './Settings';
import Health from './Health';
import Weather from './Weather';

import '../../../../../assets/css/components/GameBoard/Obesity/Enigma/Phone/Phone.css';
import Facetime from './Facetime';
import Calculator from './Calculator';

function Phone() {
  const [currentApp, setCurrentApp] = useState(null);
  const [soundClick, setSoundClick] = useState(false);

  const { soundtrack } = useSelector(infoGameUser);

  const handleScreenContent = () => {
    if (currentApp === 'timer') {
      return <Timer />;
    }
    if (currentApp === 'camera') {
      return <Camera />;
    }
    if (currentApp === 'settings') {
      return <Settings />;
    }
    if (currentApp === 'facetime') {
      return <Facetime />;
    }
    if (currentApp === 'health') {
      return <Health />;
    }
    if (currentApp === 'calculator') {
      return <Calculator />;
    }
    if (currentApp === 'weather') {
      return <Weather />;
    }
    return (
      <div className="Phone_screen-content-buttons">
        <div className="Phone_screen-content-button">
          <button
            type="button"
            className="Phone_screen-app grey"
            onClick={() => {
              setCurrentApp('camera');
              setSoundClick(true);
              setTimeout(() => setSoundClick(false));
            }}
          >
            <ImCamera size={30} style={{ color: '#2F2F31' }} />
          </button>
          <p>Caméra</p>
        </div>
        <div className="Phone_screen-content-button">
          <button
            type="button"
            className="Phone_screen-app greylight"
            onClick={() => {
              setCurrentApp('calculator');
              setSoundClick(true);
              setTimeout(() => setSoundClick(false));
            }}
          >
            <FcCalculator size={30} style={{ color: 'white' }} />
          </button>
          <p>Calculatrice</p>
        </div>
        <div className="Phone_screen-content-button">
          <button
            type="button"
            className="Phone_screen-app bluelight"
            onClick={() => {
              setCurrentApp('weather');
              setSoundClick(true);
              setTimeout(() => setSoundClick(false));
            }}
          >
            <TiWeatherPartlySunny size={30} style={{ color: 'white' }} />
          </button>
          <p>Météo</p>
        </div>
        <div className="Phone_screen-content-button">
          <button
            type="button"
            className="Phone_screen-app greenlight"
            onClick={() => {
              setCurrentApp('facetime');
              setSoundClick(true);
              setTimeout(() => setSoundClick(false));
            }}
          >
            <GoDeviceCameraVideo size={30} style={{ color: 'white' }} />
          </button>
          <p>Facetime</p>
        </div>
        <div className="Phone_screen-content-button">
          <button
            type="button"
            className="Phone_screen-app grey"
            onClick={() => {
              setCurrentApp('settings');
              setSoundClick(true);
              setTimeout(() => setSoundClick(false));
            }}
          >
            <GrSettingsOption size={30} style={{ color: '#2F2F31' }} />
          </button>
          <p>Réglages</p>
        </div>
        <div className="Phone_screen-content-button">
          <button
            type="button"
            className="Phone_screen-app violet"
            onClick={() => {
              setCurrentApp('timer');
              setSoundClick(true);
              setTimeout(() => setSoundClick(false));
            }}
          >
            <GiSandsOfTime size={30} style={{ color: 'white' }} />
          </button>
          <p>Temps d&apos;écran</p>
        </div>
      </div>
    );
  };

  const handleFooter = () => {
    return (
      <div className="Phone_screen-content-footer">
        <div className="Phone_screen-content-button">
          <button
            type="button"
            className="Phone_screen-app white"
            onClick={() => {
              setCurrentApp('health');
              setSoundClick(true);
              setTimeout(() => setSoundClick(false));
            }}
          >
            <AiFillHeart size={30} style={{ color: '#EB5781' }} />
          </button>
          <p>Santé</p>
        </div>
      </div>
    );
  };
  return (
    <div className="Phone">
      <Audio condition={soundClick && soundtrack} sound={click} />
      <div className="Phone_enigma">
        <div className="Phone_bar" />
        <div
          className={`Phone_screen-content ${currentApp === null ? 'menu' : 'app'}`}
        >
          <div
            className={`Phone_screen-content-header ${
              currentApp === null ? 'menu' : 'app'
            }`}
          >
            <GiNetworkBars
              size={20}
              style={{ color: currentApp === null ? 'white' : '#272929' }}
            />
            <div>9:54</div>
            <IoIosBatteryCharging
              size={20}
              style={{ color: currentApp === null ? 'white' : '#272929' }}
            />
          </div>
          {handleScreenContent()}
          {currentApp === null && handleFooter()}
        </div>
        <button
          type="button"
          className="Phone_touch"
          aria-label="home"
          onClick={() => {
            setCurrentApp(null);
            setSoundClick(true);
            setTimeout(() => setSoundClick(false));
          }}
        />
      </div>

      {/* </div> */}
    </div>
  );
}

export default Phone;
