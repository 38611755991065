const backgroundStyle = {
  width: '267vh',
  // Location of the point of interest (% of the svg width)
  pointOfInterest: 40,
  // Transition when we move in the background
  duration: '1s',
  timing: 'ease',
  fillMode: 'forwards',
  /* Number of pixels needed to reach the left end of the background */
  imgLeft: 'Opx',
};
const InterpretationMachineCalibrationRoomId = 55;
const EmotionGenerationRoomId = 56;
const BehaviourMachineCalibrationRoomId = 57;
const ComplexSituationRoomId = 58;

const stickStatus = ['up', 'down'];

const emotionIntensities = ['weak', 'medium', 'strong'];

const warningLightUrl = {
  green:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2Fwarning-light-big-green.svg?alt=media&token=e83b3876-fe3e-454b-a502-ce74467aa22b',
  red: 'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2Fwarning-light-big-red.svg?alt=media&token=70a416cb-59f4-48db-90f5-153127b39d7f',
  orange:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2Fwarning-light-big-orange.svg?alt=media&token=6cd4c488-71c1-49af-85b4-ec1bda066ddf',
  inactive:
    'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2Fwarning-light-big-inactive.svg?alt=media&token=854993ad-ddf3-4078-b9ff-488cba28a601',
};

const stimulusUrl =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%202%2Fstimulus.svg?alt=media&token=5f2275c3-9d45-404b-9e5b-e85514ca30b0';

const maintenanceStickerUrl =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2Fmaintenance.svg?alt=media&token=a91b48c2-26b9-4ed4-ada6-94c359050596';

const buttonBackgroundUrl = [
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%201%2Fbutton-light-effect.svg?alt=media&token=a8194af4-1307-41f3-8b26-bfaef700f471',
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%201%2Fbutton.svg?alt=media&token=91c659a1-7c5b-4410-a15d-dacd65fa3644',
];

const chatBubbleUrl =
  'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2Fchat-background.svg?alt=media&token=d02bc777-98d3-4d7f-a999-558a89d09719';

const warningLightsStyle = {
  left: {
    top: '37%',
    left: '40%',
    width: '32px',
    height: '37px',
    zIndex: '5',
  },
  center: {
    top: '37%',
    right: '40.5%',
    width: '32px',
    height: '37px',
    zIndex: '5',
  },
  right: {
    top: '37%',
    right: '22.3%',
    width: '32px',
    height: '37px',
    zIndex: '5',
  },
};

// State of the warning lights per room
const warningLights = {
  // Position and size is common between rooms and warning lights
  style: warningLightsStyle,
  [InterpretationMachineCalibrationRoomId]: [
    {
      id: 'left',
      url: warningLightUrl.green,
    },
    {
      id: 'center',
      url: warningLightUrl.red,
    },
    {
      id: 'right',
      url: warningLightUrl.green,
    },
  ],
  [EmotionGenerationRoomId]: [
    {
      id: 'left',
      url: warningLightUrl.green,
    },
    {
      id: 'center',
      url: warningLightUrl.green,
    },
    {
      id: 'right',
      url: warningLightUrl.green,
    },
  ],
  [BehaviourMachineCalibrationRoomId]: [
    {
      id: 'left',
      url: warningLightUrl.green,
    },
    {
      id: 'center',
      url: warningLightUrl.green,
    },
    {
      id: 'right',
      url: warningLightUrl.red,
    },
  ],
  [ComplexSituationRoomId]: [
    {
      id: 'left',
      url: warningLightUrl.green,
    },
    {
      id: 'center',
      url: warningLightUrl.green,
    },
    {
      id: 'right',
      url: warningLightUrl.green,
    },
  ],
};

const maintenanceSticker = {
  [InterpretationMachineCalibrationRoomId]: {
    url: maintenanceStickerUrl,
    style: {
      top: '50%',
      right: '39%',
      width: '100px',
      height: '37px',
      zIndex: '5',
    },
  },
  [BehaviourMachineCalibrationRoomId]: {
    url: maintenanceStickerUrl,
    style: {
      top: '50%',
      right: '21%',
      width: '100px',
      height: '37px',
      zIndex: '5',
    },
  },
};

const volume = 0.4;

const enigmas = {
  InterpretationMachineCalibration: {
    duration: '3s',
    timing: 'linear',
    fillMode: 'forwards',
    volume: 0.4,
    emotionsBackground:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%201%2Femotions-background.svg?alt=media&token=22610a77-ba5e-4fec-aab5-4d50e243178f',
    footerPanelUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%201%2Femoticons-background.svg?alt=media&token=fd4b8097-fba2-4886-81f6-c23cadda1e9d',
    // small lights below emoticons + in the emotions panel -> can be inactive, green or red
    lightsUrl: {
      unanswered:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2Fwarning-light-small-inactive.svg?alt=media&token=474be5f5-c226-49c3-afed-b1572c1f2c3d',
      right:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2Fwarning-light-small-green.svg?alt=media&token=efa50a88-7d21-4839-9b77-778c05e5a2a7',
      wrong:
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2Fwarning-light-small-red.svg?alt=media&token=bf796e81-c3d1-42f1-aa97-337b5969bea7',
    },
    emoticonsBig:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%201%2Fanger-big.svg?alt=media&token=f0605d51-2d21-4604-995f-28266b6b57ab',
    cursorBackground:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%201%2Fbackground-cursor.svg?alt=media&token=1d4f6b5a-cb9a-4507-ab01-0f0d24ab4e35',
    pointer:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%201%2Fpointer.svg?alt=media&token=ef567f6a-3448-475a-8e10-80367211e9d6',
    emotionsButtons:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%201%2Femotions-buttons-full.svg?alt=media&token=f6197b21-1ae7-4170-ac8f-d3781be8a25a',
    emotionsSelectedButton:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%201%2Femotion-button-selected.svg?alt=media&token=e960a441-40d6-45c1-80c3-2103c383d063',
    initialRotation: 90,
    rotationValue: 45,
    initialCursorIndexes: { 0: 0, 1: 0, 2: 0 },
    initialUserAnswers: { 0: undefined, 1: undefined, 2: undefined },
    // rightAnswers store the index of the right emotion (value) for each of the 3 intensities (keys)
    // index 0 is the selected emotions when we start the enigma, then the index is incremented cloakwise
    emoticonsConstants: {
      270: { rightAnswers: { 0: 7, 1: 5, 2: 0 } },
      271: { rightAnswers: { 0: 4, 1: 6, 2: 1 } },
      272: { rightAnswers: { 0: 5, 1: 7, 2: 4 } },
      273: { rightAnswers: { 0: 2, 1: 2, 2: 7 } },
      274: { rightAnswers: { 0: 6, 1: 1, 2: 5 } },
      275: { rightAnswers: { 0: 1, 1: 0, 2: 6 } },
      276: { rightAnswers: { 0: 3, 1: 3, 2: 3 } },
      277: { rightAnswers: { 0: 0, 1: 4, 2: 2 } },
    },
    basePoints: 100,
    decreasePoints: 25,
    initialWrongCount: 0,
  },
  EmotionGeneration: {
    initialStimulusTranslationX: '54.5',
    initialStimulusTranslationY: '41',
    finalStimulusTranslation: '47.8',
    initialBeltTranslationX: '54.5',
    initialBeltTranslationY: '34.5',
    stimulusDuration: '5s',
    duration: '3s',
    timing: 'linear',
    fillMode: 'forwards',
    footerPanelUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%202%2Fstimulus-bubble.svg?alt=media&token=2a7a911b-ce85-4e6b-bcf0-093c6dd8b79b',
    footerStyle: {
      leftRatio: '0.05',
      widthRatio: '0.93',
      heightRatio: '0.9',
      alignItems: 'center',
      justifyContent: 'center',
      textWidthRatio: '0.73',
    },
    sidePanelUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%202%2Femotion-wheel-panel.svg?alt=media&token=901b910f-83c5-487f-827a-81f3f6a4ddda',
    generatedStimulus:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%202%2Fselected-stimulus.svg?alt=media&token=5cf12b88-0bc6-498a-b2f1-fd2c71901a1a',
    conveyorBeltUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%202%2Fconveyor-belt.gif?alt=media&token=0ef1942d-5122-422a-bdb8-d443eb760ed4',
    rightAnswers: [
      { stimulusId: 1, emotion: 'trust' },
      { stimulusId: 2, emotion: 'surprise' },
      { stimulusId: 3, emotion: 'disgust' },
      { stimulusId: 4, emotion: 'anger' },
      { stimulusId: 5, emotion: 'anticipation' },
      { stimulusId: 6, emotion: 'joy' },
      { stimulusId: 7, emotion: 'fear' },
      { stimulusId: 8, emotion: 'sadness' },
      { stimulusId: 9, emotion: 'anger' },
      { stimulusId: 10, emotion: 'surprise' },
    ],
    basePoints: 50,
    decreasePoints: 25,
  },
  BehaviourMachineCalibration: {
    firstIndex: 0,
    footerStyle: {
      top: '3rem',
      leftRatio: '0.25',
      widthRatio: '0.76',
      heightRatio: '1',
      textWidthRatio: '1',
      marginLeft: '0',
      h1: '1.5rem',
      p: '1.25rem',
    },
    // The radius to determine if the wire is close to one of the connector end
    validatedRadius: 10,
    // The radius of the snap zone
    snapRadius: 20,
    // Bezier weight defines the curve angle
    bezierWeight: 0.5,
    connectorEndUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter3%2Fprise-blanche.svg?alt=media&token=2a4db59c-d101-4191-956e-f7ab0e425cb7',
    // Used for the live snap
    connectorEndCoordinates: [
      { x: 588, y: 0 },
      { x: 588, y: 46 },
      { x: 588, y: 92 },
      { x: 588, y: 139 },
      { x: 588, y: 186 },
      { x: 588, y: 232 },
      { x: 588, y: 280 },
      { x: 588, y: 325 },
      { x: 588, y: -48 },
      { x: 588, y: -95 },
      { x: 588, y: -140 },
      { x: 588, y: -187 },
      { x: 588, y: -233 },
      { x: 588, y: -281 },
      { x: 588, y: -326 },
    ],
    wires: [
      {
        name: 'anger',
        color: '#d76043',
        urlStart:
          'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter3%2Fprise-rouge.svg?alt=media&token=76a8e594-4a4f-43b2-a352-8b17b67d398f',
        coordinates: {
          start: { x: 22, y: 22 },
        },
        isConnected: false,
        isValidated: undefined,
      },
      {
        name: 'anticipation',
        color: '#e1873d',
        urlStart:
          'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter3%2Fprise-orange.svg?alt=media&token=4d603f42-bd99-46a0-9f20-c02586762513',
        coordinates: {
          start: { x: 22, y: 68 },
        },
        isConnected: false,
        isValidated: undefined,
      },
      {
        name: 'joy',
        color: '#f8d82f',
        urlStart:
          'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter3%2Fprise-jaune.svg?alt=media&token=7f537960-8d7e-4ebe-849a-d8c95e2444c4',
        coordinates: {
          start: { x: 22, y: 116 },
        },
        isConnected: false,
        isValidated: undefined,
      },
      {
        name: 'trust',
        color: '#c5c547',
        urlStart:
          'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter3%2Fprise-vertclair.svg?alt=media&token=1b51fc20-4f5e-45bc-8a92-ece2ab1f9b6b',
        coordinates: {
          start: { x: 22, y: 162 },
        },
        isConnected: false,
        isValidated: undefined,
      },
      {
        name: 'fear',
        color: '#86b245',
        urlStart:
          'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter3%2Fprise-vert.svg?alt=media&token=58bbb2bf-8e0d-427d-b6e8-253e4167e531',
        coordinates: {
          start: { x: 22, y: 209 },
        },
        isConnected: false,
        isValidated: undefined,
      },
      {
        name: 'surprise',
        color: '#6aa192',
        urlStart:
          'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter3%2Fprise-turquoise.svg?alt=media&token=46cc22bb-78e1-4b58-8f71-84708191bc80',
        coordinates: {
          start: { x: 22, y: 255 },
        },
        isConnected: false,
        isValidated: undefined,
      },
      {
        name: 'sadness',
        color: '#4a8ec8',
        urlStart:
          'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter3%2Fprise-bleu.svg?alt=media&token=7bc70e7a-a4c6-4f3b-a90b-fddf381037ad',
        coordinates: {
          start: { x: 22, y: 302 },
        },
        isConnected: false,
        isValidated: undefined,
      },
      {
        name: 'disgust',
        color: '#55589a',
        urlStart:
          'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter3%2Fprise-violet.svg?alt=media&token=435b5d0e-5d37-476c-aa58-72a277e2baef',
        coordinates: {
          start: { x: 22, y: 348 },
        },
        isConnected: false,
        isValidated: undefined,
      },
    ],
    wiresPanelUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter3%2Fwires-panel.svg?alt=media&token=e68bca85-fe1c-4c53-b44d-e78117513e43',
    yOffset: 6,
    footerPanelUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter3%2Fbehaviour-bubble.svg?alt=media&token=709dc8e9-a08a-4312-ac76-a2d5c28475c4',
    basePoints: 100,
    decreasePoints: 25,
  },
  ComplexSituation: {
    initialRightBeltTranslationX: '36.3',
    initialStimulus2TranslationX: '36.3',
    finalStimulus2TranslationX: '29.5',
    duration: '3s',
    timing: 'linear',
    fillMode: 'forwards',
    footerPanelUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%204%2Fbehaviour-bubble.svg?alt=media&token=82727dbd-0e5a-479e-a255-b1646c0768cd',
    footerStyle: {
      leftRatio: '0.25',
      widthRatio: '0.69',
      heightRatio: '1',
      top: '2rem',
      textWidthRatio: '1',
      marginLeft: '0.5rem',
    },
    sidePanelUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%202%2Femotion-wheel-panel.svg?alt=media&token=901b910f-83c5-487f-827a-81f3f6a4ddda',
    behaviourPanelUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%204%2Freactions-bubble.svg?alt=media&token=7543907d-ad42-48f1-bfee-7d1b9e262190',
    bubbleButtonUrl: {
      'bubble-1':
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%204%2Fbubble-button-1.svg?alt=media&token=246facd7-c30d-4524-9f3b-f5a8f74de385',
      'bubble-2':
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%204%2Fbubble-button-2.svg?alt=media&token=8ab95768-6337-40aa-9c0a-75afa6b2593d',
      'bubble-3':
        'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%204%2Fbubble-button-3.svg?alt=media&token=d46a09a2-bb84-40ae-a49d-b6dee5c154be',
    },
    behaviourPanelStyle: {
      behaviour: {
        leftRatio: '0.05',
        widthRatio: '0.87',
        heightRatio: '0.87',
        top: '1rem',
        buttonAdditionalStyle: {
          marginLeft: '78px',
        },
      },
      returnButtonStyle: {
        position: 'absolute',
        bottom: '-3.25rem',
        left: '17rem',
      },
    },
    narratorTextHeaderStyle: {
      leftRatio: '0.31',
      widthRatio: '0.64',
      heightRatio: '0.8',
    },
    rightAnswers: [
      { stimulusId: 1, emotion: 'fear', behaviourId: 3 },
      { stimulusId: 2, emotion: 'anger', behaviourId: 2 },
      { stimulusId: 3, emotion: 'awe', behaviourId: 3 },
      { stimulusId: 4, emotion: 'disapproval', behaviourId: 4 },
      { stimulusId: 5, emotion: 'disgust', behaviourId: 2 },
    ],
    energyBarUrl:
      'https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FComprendre%20mes%20%C3%A9motions%2Fassets%2FChapter%204%2Fenergy-bar.svg?alt=media&token=f77640ac-4122-4054-90af-14f9cdbc4a17',
    energyBarContainerStyle: {
      widthRatio: '0.6',
      heightRatio: 0.8,
      leftRatio: 0.25,
    },
    energyBarStyle: {
      heightRatio: 0.165,
      widthRatio: 0.86,
      topRatio: 0.12,
      leftRatio: 0.135,
    },
    decreaseForInstinctiveReaction: 25,
    decreaseForReasonedReaction: 30,
    basePoints: 100,
    decreasePoints: 25,
    step1: 1,
    step2: 2,
  },
};

const narratorTextHeader = {
  leftRatio: '0.31',
  widthRatio: '0.64',
  heightRatio: '0.9',
};

export {
  backgroundStyle,
  volume,
  warningLightUrl,
  warningLights,
  buttonBackgroundUrl,
  chatBubbleUrl,
  emotionIntensities,
  maintenanceSticker,
  stickStatus,
  enigmas,
  stimulusUrl,
  BehaviourMachineCalibrationRoomId,
  InterpretationMachineCalibrationRoomId,
  EmotionGenerationRoomId,
  ComplexSituationRoomId,
  narratorTextHeader,
};
