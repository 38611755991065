import { useState } from 'react';
import './Dashboard.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ObjectList from './ObjectList';
import TurnCounter from './TurnCounter';
import Volume from '../../../../img/volume-up-solid-white.svg';
import VolumeMute from '../../../../img/volume-mute-solid-white.svg';
import logo from '../../../../img/Logotype_cpt_blanc.svg';

function Dashboard({ dispatch, GameUsers }) {
  const [muteSound, setMuteSound] = useState(true);

  const handleMuteSoundtrack = () => {
    setMuteSound(!muteSound);
    dispatch({
      type: 'GAME_ACTION',
      payload: {
        typeOfAction: 'soundMuted',
        action: muteSound,
      },
    });
  };

  return (
    <div className="Dashboard-container404">
      <div className="Dashboard-Logo">
        <img src={logo} alt="Logo Tricky" />
      </div>
      <a className="Dashboard-Preco" href="https://tricky.fr/">
        <button type="button" className="ButtonAction404 Action404">
          Revenir à l&apos;accueil
        </button>
      </a>
      <h2 className="Object-title404">Inventaire</h2>
      <ObjectList />
      <TurnCounter />
      <div
        onClick={handleMuteSoundtrack}
        onKeyDown={handleMuteSoundtrack}
        role="button"
        tabIndex={0}
        className={GameUsers.gameStarted ? 'Sound-Game' : 'Hide'}
      >
        <p>{GameUsers.soundMuted ? 'Activer le son' : 'Couper le son'}</p>
        <img
          src={GameUsers.soundMuted ? VolumeMute : Volume}
          alt={GameUsers.soundMuted ? 'Activer le son' : 'Couper le son'}
          title={GameUsers.soundMuted ? 'Activer le son' : 'Couper le son'}
          draggable="false"
        />
      </div>
      <a
        className="Dashboard-p"
        href="https://website.getricky.fr/digital-room"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p>En savoir plus</p>
      </a>
    </div>
  );
}

const mapStateToProps = (state) => ({
  GameUsers: state.GameUsers,
});

Dashboard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  GameUsers: PropTypes.shape({
    soundMuted: PropTypes.bool.isRequired,
    gameStarted: PropTypes.number.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Dashboard);
