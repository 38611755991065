import PropTypes from 'prop-types';

// scss
import styles from 'components/GameBoard/ReusableComponents/Multi/Shared/Card/Card.module.scss';

const Card = ({ children }) => {
  return <div className={styles.card}>{children}</div>;
};

Card.propTypes = {
  children: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
};

export default Card;
