import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ObjectItem from './ObjectItem';

function ObjectList({ Objects, heightBag }) {
  return (
    <section className="Object-Item-Container" style={{ height: heightBag }}>
      {Objects.map((item) => (
        <ObjectItem name={item.name} id={item.id} image={item.image} />
      ))}
    </section>
  );
}

const mapStateToProps = (state) => ({
  Objects: state.Objects.object,
  heightBag: state.Objects.HeightBag,
});

ObjectList.propTypes = {
  Objects: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  heightBag: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, null)(ObjectList);
