const initialState = {
  list: [],
  error: false,
  loading: true,
  datas: {
    count: 0,
    image: '',
  },
};

const Inventory = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_INVENTORY': {
      if (action.payload.images) {
        const newList = [...state.list];
        const newElement = action.payload;
        newElement.rpsDisplay = true;
        newList.push(newElement);

        return {
          ...state,
          list: newList,
          datas: {
            count: state.datas.count + 1,
            image: action.payload.images.filter(
              (item) => item.type === 'inventory'
            )[0].image,
          },
        };
      }
      const newList = [...state.list];
      const newElement = action.payload;
      newElement.rpsDisplay = true;
      newList.push(newElement);
      return {
        ...state,
        list: newList,
      };
    }
    case 'HIDE_ITEM': {
      const listofItem = state.list.map((list) => {
        const newListOfItem = list;
        if (Number(list.id) === Number(action.payload)) {
          newListOfItem.rpsDisplay = false;
        }
        return newListOfItem;
      });
      return {
        ...state,
        list: listofItem,
      };
    }
    case 'SHOW_ITEM': {
      const listofItem = state.list.map((list) => {
        const newListOfItem = list;
        if (Number(list.id) === Number(action.payload)) {
          newListOfItem.rpsDisplay = true;
        }
        return newListOfItem;
      });
      return {
        ...state,
        list: listofItem,
      };
    }
    case 'REMOVE_OF_INVENTORY': {
      const newList = state.list.filter((object) => object.id !== action.payload);
      return {
        ...state,
        list: newList,
      };
    }
    case 'RESET_INVENTORY': {
      return {
        ...state,
        list: [],
      };
    }
    default:
      return state;
  }
};

export default Inventory;
