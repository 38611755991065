import { filterObject } from 'utils/utilityFunctions';

import firepadRef from '../GameBoard/ReusableComponents/Multi/server/firebase';

const initialState = {
  id: null,
  firstname: '',
  lastname: '',
  mainStream: null,
  participants: {},
  currentUser: null,
};

const servers = {
  iceServers: [
    {
      urls: [
        'stun:stun1.l.google.com:19302',
        'stun:stun2.l.google.com:19302',
        'stun:stun.l.google.com:19302',
        'stun:stun3.l.google.com:19302',
        'stun:stun4.l.google.com:19302',
        'stun:stun.services.mozilla.com',
      ],
    },
  ],
  iceCandidatePoolSize: 10,
};

const generateColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

const participantRef = firepadRef.child('participants');

/**
 * Initiates the creation of an SDP offer for the purpose of starting a new WebRTC
 * connection to a remote peer
 * @param {number} receiverId // one RTP receiver
 * @param {createdID} createdID // userId in the session
 * @param {object} peerConnection // connection between each of the browsers
 */
const createOffer = async (peerConnection, receiverId, createdID) => {
  const currentParticipantRef = participantRef.child(receiverId);
  const peerConnectionEvent = peerConnection;
  peerConnectionEvent.onicecandidate = (event) => {
    if (event.candidate && currentParticipantRef) {
      currentParticipantRef
        .child('offerCandidates')
        .push({ ...event.candidate.toJSON(), userId: createdID });
    }
  };

  const offerDescription = await peerConnection.createOffer();
  await peerConnection.setLocalDescription(offerDescription);

  const offer = {
    sdp: offerDescription.sdp,
    type: offerDescription.type,
    userId: createdID,
  };

  await currentParticipantRef.child('offers').push().set({ offer });
};

const addConnection = (newUser, currentUser, stream) => {
  const peerConnection = new RTCPeerConnection(servers);
  stream.getTracks().forEach((track) => {
    peerConnection.addTrack(track, stream);
  });
  const newUserId = Object.keys(newUser)[0];
  const currentUserId = Object.keys(currentUser)[0];

  const offerIds = [newUserId, currentUserId].sort((a, b) => a.localeCompare(b));

  const newUserElement = newUser;
  newUserElement[newUserId].peerConnection = peerConnection;
  if (offerIds[0] !== currentUserId) {
    createOffer(peerConnection, offerIds[0], offerIds[1]);
  }
  return newUserElement;
};

/**
 * Creates an SDP response to an offer received from a remote peer during
 * offer/answer negotiation of a WebRTC connection
 * @param {number} otherUserId
 * @param {createdID} userId // userId in the session
 */
const createAnswer = async (otherUserId, userId, participants) => {
  const peerConnectionStore = participants[otherUserId].peerConnection;
  const participantRef1 = participantRef.child(otherUserId);
  peerConnectionStore.onicecandidate = (event) => {
    if (event.candidate && participantRef1) {
      participantRef1
        .child('answerCandidates')
        .push({ ...event.candidate.toJSON(), userId });
    }
  };

  const answerDescription = await peerConnectionStore.createAnswer();
  await peerConnectionStore.setLocalDescription(answerDescription);

  const answer = {
    type: answerDescription.type,
    sdp: answerDescription.sdp,
    userId,
  };

  await participantRef1.child('answers').push().set({ answer });
};

/**
 * Update Date Visio of the user
 * @param {number} userId
 * @param {object} preference // data of the visio
 */
const updatePreference = (userId, preference) => {
  const currentParticipantRef = participantRef.child(userId).child('preferences');
  setTimeout(() => {
    currentParticipantRef.update(preference);
  });
};

const initializeListeners = async (userId, participants) => {
  const currentUserRef = participantRef.child(userId);

  /**
   * Each RTCSessionDescription consists of a description type
   * indicating which part of the offer/answer negotiation process
   * it describes and of the SDP descriptor of the session
   */
  currentUserRef.child('offers').on('child_added', async (snapshot) => {
    const data = snapshot.val();
    // if there is an error with webcam of the user
    if (participants && data?.offer && participants[data.offer.userId]) {
      const peerConnectionStore = participants[data.offer.userId].peerConnection;
      await peerConnectionStore.setRemoteDescription(
        new RTCSessionDescription(data.offer)
      );
      await createAnswer(data.offer.userId, userId, participants);
    }
  });

  /**
   * The RTCIceCandidate interface, which is part of the WebRTC API, represents
   * an Interactive Connectivity Establishment (ICE) candidate configuration that
   * can be used to establish an RTCPeerConnection connection.
   */
  currentUserRef.child('offerCandidates').on('child_added', (snapshot) => {
    const data = snapshot.val();
    if (participants && data?.userId && participants[data.userId]) {
      const peerConnectionStore = participants[data.userId].peerConnection;
      peerConnectionStore.addIceCandidate(new RTCIceCandidate(data));
    }
  });

  currentUserRef.child('answers').on('child_added', (snapshot) => {
    const data = snapshot.val();
    // if there is an error with webcam of the user
    if (participants && data?.answer && participants[data?.answer?.userId]) {
      const peerConnectionStore = participants[data.answer.userId].peerConnection;
      const answerDescription = new RTCSessionDescription(data.answer);
      peerConnectionStore.setRemoteDescription(answerDescription);
    }
  });

  currentUserRef.child('answerCandidates').on('child_added', (snapshot) => {
    const data = snapshot.val();
    if (participants && data?.userId && participants[data.userId]) {
      const peerConnectionStore = participants[data.userId].peerConnection;
      peerConnectionStore.addIceCandidate(new RTCIceCandidate(data));
    }
  });
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_USER':
      return {
        ...state,
        ...action.payload,
        mainStream: null,
        participants: {},
        currentUser: null,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_MAIN_STREAM':
      return { ...state, ...action.payload };
    case 'SET_USER_VISIO': {
      const newCurrentUser = action.payload.currentUser;

      const userId = Object.keys(newCurrentUser)[0];

      newCurrentUser[userId].avatarColor = generateColor();
      initializeListeners(userId);

      return {
        ...state,
        currentUser: { ...newCurrentUser },
        participants: { ...state.participants },
      };
    }
    case 'UPDATE_PARTICIPANT': {
      const newUserElement = action.payload.newUser;

      const newUserId = Object.keys(newUserElement)[0];
      newUserElement[newUserId] = {
        ...state.participants[newUserId],
        ...action.payload.newUser[newUserId],
      };
      return {
        ...state,
        participants: { ...state.participants, ...newUserElement },
      };
    }
    case 'ADD_PARTICIPANT': {
      let newUserElement = action.payload.newUser;
      const currentUserId = Object.keys(state.currentUser)[0];
      const newUserId = Object.keys(newUserElement)[0];
      if (state.mainStream && currentUserId !== newUserId) {
        newUserElement = addConnection(
          action.payload.newUser,
          state.currentUser,
          state.mainStream
        );
      }

      if (currentUserId === newUserId) {
        newUserElement[newUserId].currentUser = true;
        newUserElement[newUserId].avatarColor = generateColor();
      } else {
        newUserElement[newUserId].currentUser = false;
      }
      const newParticipants = { ...state.participants, ...newUserElement };

      initializeListeners(currentUserId, newParticipants);

      return {
        ...state,
        participants: { ...state.participants, ...newUserElement },
      };
    }

    case 'UPDATE_USER_VISIO': {
      const newCurrentUser = { ...state.currentUser };
      const userId = Object.keys(newCurrentUser)[0];
      updatePreference(userId, action.payload.currentUser);
      newCurrentUser[userId] = {
        ...newCurrentUser[userId],
        ...action.payload.currentUser,
      };

      return {
        ...state,
        currentUser: { ...newCurrentUser },
      };
    }

    case 'REMOVE_PARTICIPANT': {
      const participants = { ...state.participants };
      delete participants[action.payload.id];
      return { ...state, participants };
    }

    case 'ADD_RESPONSES_USER_VISIO': {
      const newParticipants = state.participants;
      const { currentUser, userResponseOfQuestion } = action.payload;
      const currentUserId = Object.keys(currentUser)[0];

      newParticipants[currentUserId].responses = [];
      newParticipants[currentUserId].responses.push(userResponseOfQuestion);
      return {
        ...state,
        participants: { ...newParticipants },
      };
    }

    case 'UPDATE_STATUS_QUESTIONNARY_OF_USER': {
      const newParticipants = state.participants;
      const { currentUser, isFinish } = action.payload;
      const currentUserId = Object.keys(currentUser)[0];

      newParticipants[currentUserId].isQuestionnaryFinish = isFinish;

      return {
        ...state,
        participants: { ...newParticipants },
      };
    }
    default:
      return state;
  }
};

// Define the selector functions
export const selectUser = (state) => state.user;
export const selectParticipantWithoutMediator = (state) =>
  Object.keys(filterObject(state.user.participants, (obj) => obj.type !== 'admin'));
export const selectCurrentUser = (state) => Object.keys(state.user.currentUser)[0];

export default user;
