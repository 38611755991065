import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// API
import { sessionUpdate, getSessionDescription } from 'API/session';
import getGameDescription from 'API/games';
import getUserDescription from 'API/user';
import { putSessionsHasRoomHasMessage } from 'API/sessionsHasRoomHasMessage';
import {
  getQuestions,
  createResponse,
  createLinkBetweenUserSurveySession,
  createLinkResponseBetweenUserHasSurvey,
} from 'API/questionnary';

// actions
import {
  fetchMessagesOfGame,
  fetchRooms,
  retrieveCurrentQuestion,
} from 'components/Action';
import { getMessagesOfGame } from 'API/messages';

/**
 * Generate a random number between 2 limits
 * @param {number} min
 * @param {number} max
 * @returns number
 */
const getRandomNumber = (min, max) => {
  const minCeil = Math.ceil(min);
  const maxFloor = Math.floor(max);
  return Math.floor(Math.random() * (maxFloor - minCeil + 1)) + minCeil;
};

/**
 * Increment or reset an index
 * @param {number} index
 * @param {function} setIndex
 * @param {number} max - maximum value the index can take
 */
const incrementIndex = (index, max) => {
  let newIndex = index;
  if (index < max) {
    newIndex += 1;
  } else {
    newIndex = 0;
  }
  return newIndex;
};

/**
 * Calculate the points earned
 * @param {number} basePoints
 * @param {number} numberOfTries
 * @param {number} decreasePoints
 * @returns
 */
const calculatePoints = (basePoints, numberOfTries, decreasePoints) => {
  const calculatedPoints = basePoints - numberOfTries * decreasePoints;

  return calculatedPoints >= 0 ? calculatedPoints : 0;
};

/**
 * Shuffle an array. Not the best method because it is biased.
 * The sorting function is not meant to be used this way, so not all permutations have the same probability.
 * But it is good enough for now.
 * @param {[]} array
 */
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

/**
 * Modify the background classname depending on the current position and which chevron was clicked
 * @param {string} chevron - clicked chevron
 * @param {string} position - current background position
 * @param {function} setPosition
 * @param {function} setButtonRight
 * @param {function} setButtonLeft
 */
const changeBackgroundClassName = (chevron, position, setPosition) => {
  switch (chevron) {
    case 'left-chevron':
      if (
        position === 'center' ||
        position === 'center-from-left' ||
        position === 'center-from-right'
      ) {
        setPosition('left-from-center');
      }
      if (position === 'right-from-center') {
        setPosition('center-from-right');
      }
      break;
    case 'right-chevron':
      if (
        position === 'center' ||
        position === 'center-from-left' ||
        position === 'center-from-right'
      ) {
        setPosition('right-from-center');
      }
      if (position === 'left-from-center') {
        setPosition('center-from-left');
      }
      break;
    default:
      break;
  }
};

/**
 * Construct the css value of `background-image`
 * @param {string[]|string } images
 * @returns {string}
 */
const handleBackgroundImages = (images) => {
  if (Array.isArray(images)) {
    let backgroundImages = '';
    images.forEach((image, index) =>
      index
        ? (backgroundImages += `,url(${image})`)
        : (backgroundImages += `url(${image})`)
    );
    return backgroundImages;
  }
  return `url(${images})`;
};

/**
 * Calculate the translation needed to center a point of interest in an image
 * @param {number} pointLocation - location of a point expressed as a % of the image width
 * @param {number} imageWidth - image width
 * @param {number} screenWidth - viewport width
 * @returns {number}
 */
const centerPoint = (pointLocation, imageWidth, screenWidth) => {
  return (pointLocation / 100) * imageWidth - screenWidth / 2;
};

/**
 *  Calculate the number of pixels needed to translate to the right end of an image
 * @param {number} imgWidth
 * @param {number} screenWidth
 * @returns {string}
 * */
const getRightCorner = (imgWidth, screenWidth) => {
  return `-${imgWidth - screenWidth}px`;
};

/**
 * Set the dimensions of an image on loading
 * @param {function} setState
 * @param {string} imageUrl
 */
const setOnLoadImageDimensions = (setState, imageUrl) => {
  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    setState({
      width: img.width,
      height: img.height,
    });
  };
};

/**
 *
 * @param {Object} obj
 * @param {function} filter - condition of the filter
 * @returns {Object}
 */
const filterObject = (obj, filter) => {
  const objCopy = { ...obj };
  // Iterate the object
  Object.keys(objCopy).forEach((key) => {
    const val = objCopy[key];
    // Current val fails filter condition
    if (filter(val) === false) {
      delete objCopy[key];
    }
  });
  return objCopy;
};

// REDUX STORE + DATABASE

/**
 * Load content of one game when the user click on the button to start the game
 * @param {number} gameId
 * @param {number} userId
 * @param {number} sessionId
 * @param {function} dispatch
 */
const loadUserGameSession = async (gameId, userId, sessionId, dispatch) => {
  // Init user
  const dataUser = await getUserDescription(userId);
  dispatch({
    type: 'INIT_USER',
    payload: dataUser,
  });
  // REFACTO TO DELETE
  dispatch({
    type: 'SET_USER',
    payload: dataUser,
  });

  // Init game
  const dataGame = await getGameDescription(gameId);
  dispatch({ type: 'SET_INFOGAME', payload: dataGame });
  // REFACTO VACCINATION
  dispatch(fetchRooms(dataGame.rooms));

  // TO REFACTO
  // retrieve all messages pf one game it's hotfix for scoreboard
  const listMessagesOfGame = await getMessagesOfGame(gameId);
  dispatch(fetchMessagesOfGame(listMessagesOfGame));

  // Init session
  const dataSession = await getSessionDescription(sessionId);
  dispatch({
    type: 'START_GAME',
    payload: dataSession.isStarted,
  });
  dispatch({
    type: 'INIT_SESSION',
    payload: dataSession,
  });
  // REFACTO TO DELETE
  dispatch({
    type: 'INIT_GAME_ROOM',
    payload: dataSession,
  });
};

/**
 * Signal that the game started
 * @param {boolean} sessionId
 */
const beginGame = async (sessionId, dispatch) => {
  sessionUpdate(sessionId, {
    isStarted: 1,
  });
  dispatch({ type: 'START_GAME', payload: 1 });
  dispatch({ type: 'PAUSE_TIMER', payload: false });
};

/**
 * Function that starts the emotion card step and end the prevention message step
 * @param {function} dispatch - redux store's dispatch function
 * @param {function} t - i18next translation function
 */
const handleNextStep = (dispatch, t) => {
  const descriptionModal = {};
  descriptionModal.buttonDescription = {
    title: t('buttonFunction.next'),
    onClick: () => {
      dispatch({
        type: 'START_QUESTIONNARY',
      });
    },
  };
  descriptionModal.type = 'emotionCard';

  dispatch({
    type: 'OPEN_MODAL',
    payload: descriptionModal,
  });

  dispatch({
    type: 'END_MESSAGE_PREVENTION',
  });
};

/**
 * Launch the modal with the survey or the questionary
 * @param {function} dispatch - redux store's dispatch function
 * @param {function} t - i18next translation function
 * @param {string} type - type of modal
 */
const openModal = (dispatch, t, type) => {
  const descriptionModal = {};
  if (type === 'prevention')
    descriptionModal.buttonDescription = {
      onClick: () => {
        handleNextStep(dispatch, t);
      },
    };
  descriptionModal.type = type;
  dispatch({
    type: 'OPEN_MODAL',
    payload: descriptionModal,
  });
};

/**
 * Personalized hook to save the prevention message/questionary step in database
 * and store and launch it
 * @param {Object} currentStep
 * @param {number} idSessionHasRoom
 * @param {number} startMessage
 * @param {number} startQuestionnary
 */
const useStartMessageOrQuestionary = (
  currentStep,
  idSessionHasRoom,
  startMessage,
  startQuestionnary
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  useEffect(() => {
    const saveAndLaunch = async () => {
      const currentDate = new Date().toISOString().slice(0, 19).replace('T', ' ');
      const currentStepId = currentStep.id;
      // Prevention message
      if (startMessage && !startQuestionnary) {
        await putSessionsHasRoomHasMessage(idSessionHasRoom, {
          date: currentDate,
          startMessage: 1,
          message_id: currentStepId,
        });
        openModal(dispatch, t, 'prevention');
      }
      // Questionary
      if (startQuestionnary) {
        await putSessionsHasRoomHasMessage(idSessionHasRoom, {
          date: currentDate,
          startQuestionnary: 1,
          message_id: currentStepId,
        });
        openModal(dispatch, t, 'questionnary');
      }
    };

    if (currentStep) {
      saveAndLaunch();
    }
  }, [currentStep, dispatch, idSessionHasRoom, startMessage, startQuestionnary, t]);
};

/**
 * Retrieve Questionnary with questions and responses of the message (alias survey)
 * @param {function} dispatch - redux store's dispatch function
 * @param {isStarted} boolean - when then questionnary begin after finish an enigma
 * @param {number} messageId
 */
const retrieveQuestionnary = async (messageId, dispatch, isStarted) => {
  const { questions, survey } = await getQuestions(messageId);

  await dispatch({
    type: 'ADD_QUESTIONNARY',
    payload: survey,
  });

  await dispatch({
    type: 'ADD_QUESTION',
    payload: questions,
  });

  if (isStarted) {
    await dispatch(retrieveCurrentQuestion(questions[0].id));
    setTimeout(() => {
      dispatch({
        type: 'START_QUESTIONNARY',
      });
    }, 1000);
  }
};

/**
 * Retrieve Questionnary with questions and responses of the message (alias survey)
 * @param {function} dispatch - redux store's dispatch function
 * @param {number} questionId
 * @param {number} userHasSurveyId - id of link between user and is survey
 * @param {boolean} isNew - if the question is type of textarea exemple we need
 * to create a response in db responses
 */
const linkAnswerBetweenQuestion = async (
  answer,
  questionId,
  userHasSurveyId,
  dispatch,
  isNew
) => {
  let responseUser = answer;
  if (isNew) {
    const response = await createResponse(answer);
    responseUser = response;
  }

  await createLinkResponseBetweenUserHasSurvey(
    userHasSurveyId,
    responseUser,
    questionId,
    isNew
  );

  dispatch({
    type: 'ADD_ANSWER',
    payload: {
      question_id: questionId,
      response_id: Number(isNew ? responseUser.response_id : responseUser.id),
      weighting: responseUser.weighting,
      text: responseUser.text,
    },
  });
};

/**
 * Link an User, a survey and a session
 * @param {function} dispatch - redux store's dispatch function
 * @param {number} userId
 * @param {number} surveyId
 * @param {number} sessionId
 */
const linkUserSurveySession = async (userId, surveyId, sessionId, dispatch) => {
  const data = await createLinkBetweenUserSurveySession(
    userId,
    surveyId,
    sessionId
  );

  await dispatch({
    type: 'USER_HAS_SURVEY',
    payload: data.id,
  });
};

/**
 * Retrieve Time complete hour / minute / second with seconds
 * @param {number} seconds
 * @returns {string} - time complete (H:M:S)
 */
const secondsToTime = (seconds) => {
  const hour = Math.floor(seconds / (60 * 60));

  const divisorForMinutes = seconds % (60 * 60);
  const minute = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  const second = Math.floor(divisorForSeconds);

  return `${hour ? `${hour}:` : ''}${
    minute ? `${minute}:${second}` : `${second}s`
  }`;
};

/**
 * Display the background click and save the count
 * @param {function} dispatch
 * @param {Object} event
 */
const clickCount = (dispatch, event) => {
  dispatch({ type: 'INCREMENT_COUNTER' });
  dispatch({
    type: 'PLACE_CURSOR',
    payload: {
      x: event?.clientX,
      y: event?.clientY,
    },
  });
  if (event?.target?.id === 'img-background') {
    dispatch({
      type: 'CLICKED_MOUSE_DOWN',
    });
    setTimeout(() => {
      dispatch({
        type: 'CLICKED_MOUSE_UP',
      });
    }, 500);
  }
};

export {
  getRandomNumber,
  incrementIndex,
  calculatePoints,
  shuffleArray,
  getRightCorner,
  setOnLoadImageDimensions,
  filterObject,
  beginGame,
  handleBackgroundImages,
  changeBackgroundClassName,
  centerPoint,
  useStartMessageOrQuestionary,
  loadUserGameSession,
  retrieveQuestionnary,
  linkUserSurveySession,
  linkAnswerBetweenQuestion,
  secondsToTime,
  clickCount,
};
