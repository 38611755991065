/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import '../../../../assets/css/components/GameBoard/TDE/Enigma/DrawerMorse.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function DrawerMorse({
  image,
  objectContent,
  idSessionHasRoom,
  dispatch,
  allObjects,
}) {
  const [inputs, setInputs] = useState([0, 0, 0, 0]);

  const changeStatusOfObject = (object, status) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: object.id,
        status,
      })
      .then(() => {
        dispatch({
          type: 'UPDATE_OBJECT',
          payload: {
            id: object.id,
            status,
          },
        });
      });
  };

  useEffect(() => {
    const goodCode = 7483;
    const isGoodCode = Number(inputs.join('')) === goodCode;
    if (isGoodCode) {
      changeStatusOfObject(objectContent, 'open');
    }
    // TO REFACTO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  const handleText = () => {
    let objectIndex;
    if (objectContent.status) {
      objectIndex = objectContent.images.findIndex(
        (object) => object.type === objectContent.status
      );
      return objectContent.images[objectIndex].message;
    }
    const isClickedCode = objectContent.images.filter(
      (contentImage) => contentImage.type === 'clicked'
    );
    return isClickedCode[0].message;
  };

  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    if (newInputs[index] < 9) {
      newInputs[index] = Number(e.target.value) + 1;
    } else {
      newInputs[index] = 0;
    }
    setInputs(newInputs);
  };

  const handleImage = (description) => {
    let objectIndex;
    if (!description) {
      if (objectContent.status) {
        objectIndex = objectContent.images.findIndex(
          (object) => object.type === objectContent.status
        );
        return objectContent.images[objectIndex].image;
      }
      return image;
    }
    objectIndex = description.images.findIndex(
      (object) => object.type === 'clicked'
    );
    return description.images[objectIndex].image;
  };

  const handleMessage = (description) => {
    let message;
    if (description && description.images) {
      const objectIndex = description.images.findIndex(
        (object) => object.type === 'clicked'
      );
      message = description.images[objectIndex].message;
    }

    return message;
  };

  const addToInventory = (object, index) => {
    const idCodeLightObject = 149;

    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: object.id,
        isInventory: true,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isInventory: true,
            type: 'inventory',
          },
        });
        dispatch({
          type: 'ADD_INVENTORY',
          payload: object,
        });
        dispatch({
          type: 'UPDATE_GAME_SCORE',
          payload: 100,
        });
        dispatch({
          type: 'CLOSE_MODAL',
        });
        axios
          .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
            object_id: idCodeLightObject,
            isChecked: true,
          })
          .then(() => {
            const codeLightObjectIndex = allObjects.findIndex(
              (objectElement) => objectElement.id === idCodeLightObject
            );
            dispatch({
              type: 'CLICKED_OBJECT',
              payload: {
                index: codeLightObjectIndex,
                isChecked: true,
                type: 'checked',
              },
            });
            dispatch({
              type: 'REMOVE_OF_INVENTORY',
              payload: idCodeLightObject,
            });
          });
      });
  };

  const handleClick = () => {
    if (objectContent.status === 'open') {
      changeStatusOfObject(objectContent, 'openWithoutMorse');
      const idCodeMorse = 160;
      const codeMorseObject = allObjects.filter(
        (objectElement) => objectElement.id === idCodeMorse
      )[0];
      const indexOfObject = allObjects.findIndex(
        (objectElement) => objectElement.id === idCodeMorse
      );

      dispatch({
        type: 'CLICKED_OBJECT',
        payload: {
          index: indexOfObject,
          isClicked:
            codeMorseObject.isClicked !== undefined
              ? codeMorseObject.isClicked + 1
              : 1,
          type: 'clicked',
        },
      });
      const descriptionModal = {};
      descriptionModal.message = handleMessage(codeMorseObject);
      descriptionModal.image = handleImage(codeMorseObject);
      if (!codeMorseObject.wrongobject) {
        descriptionModal.buttonDescription = {
          title: 'Inventaire',
          onClick: () => addToInventory(codeMorseObject, indexOfObject),
        };
      }
      dispatch({
        type: 'OPEN_MODAL',
        payload: descriptionModal,
      });
    } else if (objectContent.status === 'openWithoutMorse') {
      changeStatusOfObject(objectContent, 'empty');
      const idPaper = 161;
      const paperObject = allObjects.filter(
        (objectElement) => objectElement.id === idPaper
      )[0];
      const indexOfObject = allObjects.findIndex(
        (objectElement) => objectElement.id === idPaper
      );

      dispatch({
        type: 'CLICKED_OBJECT',
        payload: {
          index: indexOfObject,
          isClicked:
            paperObject.isClicked !== undefined ? paperObject.isClicked + 1 : 1,
          type: 'clicked',
        },
      });
      const descriptionModal = {};
      descriptionModal.message = handleMessage(paperObject);
      descriptionModal.image = handleImage(paperObject);
      if (!paperObject.wrongobject) {
        descriptionModal.buttonDescription = {
          title: 'Inventaire',
          onClick: () => addToInventory(paperObject, indexOfObject),
        };
      }
      dispatch({
        type: 'OPEN_MODAL',
        payload: descriptionModal,
      });
    }
  };

  return (
    <div className="drawerMorse Modal_description">
      <div className="Modal_description-text">
        <p>{handleText()}</p>
      </div>
      {!objectContent.status && (
        <div className="drawerMorse-inputs">
          {inputs.map((inputElement, index) => (
            <input
              type="number"
              name={index}
              min="0"
              max="9"
              defaultValue={inputElement}
              onClick={(e) => handleInput(e, index)}
              key={`${inputElement}-${index}`}
            />
          ))}
        </div>
      )}
      <img
        className="drawerMorse-image"
        src={handleImage()}
        alt={objectContent.title}
        onClick={() =>
          (objectContent.status === 'open' ||
            objectContent.status === 'openWithoutMorse') &&
          handleClick()
        }
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  allObjects: state.Objects.AllObjects,
});

DrawerMorse.propTypes = {
  image: PropTypes.string.isRequired,
  objectContent: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    isInventory: PropTypes.number,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string,
        image: PropTypes.string,
      })
    ),
    status: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
};

export default connect(mapStateToProps)(DrawerMorse);
