import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// Components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
// CSS
import styles from 'components/GameBoard/Emotion/Solo/Enigma/NarratorTextHeader.module.scss';
// Constants
import {
  chatBubbleUrl,
  enigmas,
  narratorTextHeader,
} from 'components/GameBoard/Emotion/Solo/constants';
// Selector functions
import { selectComplexSituationEnigma } from 'components/Reducers/emotion';

// CSS in JS
const TextStyle = styled.p`
  ref: ${({ ref }) => ref};
  position: absolute;
  top: 0;
  // Text needs to be on the right side of the character image
  left: ${({ left }) => `${left}px`};
  width: ${({ imageWidth }) => `${imageWidth}px`};
  // Needed to vertically center the text with align items
  height: ${({ imageHeight }) => `${imageHeight}px`};
  line-height: 1.5;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* Cause a line break when ('\n') is used in the text */
  white-space: pre-wrap;
  margin: 0;

  @media (max-width: 1350px) {
    line-height: 1;
  }

  @media (min-width: 1350px) {
    line-height: 1.25;
  }

  @media (min-width: 1500px) {
    font-size: 18px;
    line-height: 1.25;
  }

  @media (min-width: 1800px) {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  @media (min-width: 1940px) {
    font-size: 1.5rem;
    line-height: 1.25;
  }
`;

const { energyBarUrl, energyBarContainerStyle, energyBarStyle } =
  enigmas.ComplexSituation;

const EnergyBarContainerStyle = styled.div`
  position: absolute;
  top: ${({ panel }) => energyBarContainerStyle.heightRatio * panel.height}px;
  width: ${({ panel }) => energyBarContainerStyle.widthRatio * panel.width}px;
  left: ${({ panel }) => energyBarContainerStyle.leftRatio * panel.width}px;

  img {
    width: 100%;
  }

  div {
    position: absolute;
    top: ${({ panel }) =>
      energyBarStyle.topRatio *
      (energyBarContainerStyle.heightRatio * panel.height)}px;
    height: ${({ panel }) =>
      energyBarStyle.heightRatio *
      (energyBarContainerStyle.heightRatio * panel.height)}px;
    width: ${({ energyPercent, panel }) =>
      energyPercent * (energyBarContainerStyle.widthRatio * panel.width)}px;
    left: ${({ panel }) =>
      energyBarStyle.leftRatio *
      (energyBarContainerStyle.widthRatio * panel.width)}px;
    color: white;
    background: linear-gradient(94.53deg, #417D82 21.36%, #85B19F 62.94%, #98C0A7 74.67%);
    display: flex;
    align-items: center;
    justify-content: center;
    // Design a left and right arrow shape
    clip-path: polygon(
      3px 0,
      9% 0%,
      100% 0%,
      calc(100% - 0.25vw) 0%,
      100% 49%,
      calc(100% - 0.25vw) 100%,
      100% 100%,
      4px 100%,
      3px 100%,
      0% 50%
    );
`;

/**
 * Header part with the narrator text
 * */
function NarratorTextHeader({
  message,
  isButton,
  buttonTitle,
  buttonType,
  handleClick,
  namespace,
  energyBar,
  textStyle,
  narratorTextRef,
}) {
  const { t } = useTranslation(namespace);

  const { energyPercentage } = useSelector(selectComplexSituationEnigma);

  /**
   * @param {number} percentage
   * @returns {number}
   */
  const calculateWidthRatio = (percentage) => {
    return (energyBarStyle.widthRatio * percentage) / 100;
  };

  const energyPercent = calculateWidthRatio(energyPercentage);

  const [panelImageDimension, setPanelImageDimension] = useState({
    width: 0,
    height: 0,
  });

  const panelImgRef = useRef();

  const onLoad = () => {
    setPanelImageDimension({
      width: panelImgRef.current.width,
      height: panelImgRef.current.height,
    });
  };

  // Resize the header when the window is resized
  useEffect(() => {
    window.addEventListener('resize', onLoad);
    // Cleanup function
    return () => {
      window.removeEventListener('resize', onLoad);
    };
  }, []);

  return (
    <>
      <div className={styles['enigma-header']}>
        <div>
          <img
            onLoad={onLoad}
            alt={t('chatBubble', { ns: 'emotion' })}
            src={chatBubbleUrl}
            ref={panelImgRef}
          />
          {!!panelImgRef.current?.width && !!panelImgRef.current?.height && (
            <TextStyle
              left={textStyle.leftRatio * panelImageDimension.width}
              imageWidth={textStyle.widthRatio * panelImageDimension.width}
              imageHeight={textStyle.heightRatio * panelImageDimension.height}
              ref={narratorTextRef}
            >
              {message}
              {isButton && (
                <Button
                  onClick={handleClick}
                  buttonType={buttonType}
                  additionalStyle={{
                    marginTop: '0.5rem',
                    fontSize: '18px',
                    height: '35px',
                    width: '113px',
                  }}
                  title={t(`buttonFunction.${buttonTitle}`)}
                />
              )}
            </TextStyle>
          )}
        </div>
      </div>
      {energyBar && (
        <EnergyBarContainerStyle
          panel={panelImageDimension}
          energyPercent={energyPercent >= 0 ? energyPercent : 0}
        >
          <img alt={t('energyBar', { ns: 'emotion' })} src={energyBarUrl} />
          {energyPercentage > 0 && (
            // Hide the text % when the energy bar is too small
            <div>{energyPercentage > 10 && `${energyPercentage}%`}</div>
          )}
        </EnergyBarContainerStyle>
      )}
    </>
  );
}

NarratorTextHeader.propTypes = {
  message: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string,
  isButton: PropTypes.bool,
  buttonType: PropTypes.string,
  handleClick: PropTypes.func,
  namespace: PropTypes.arrayOf(PropTypes.string),
  energyBar: PropTypes.bool,
  textStyle: PropTypes.shape({
    leftRatio: PropTypes.string,
    widthRatio: PropTypes.string,
    heightRatio: PropTypes.string,
  }),
  narratorTextRef: PropTypes.shape({
    current: PropTypes.shape({
      innerText: PropTypes.string,
    }),
  }),
};

NarratorTextHeader.defaultProps = {
  buttonTitle: 'next',
  isButton: false,
  buttonType: 'emotion',
  handleClick: () => {},
  namespace: ['common', 'emotion'],
  energyBar: false,
  textStyle: {
    leftRatio: narratorTextHeader.leftRatio,
    widthRatio: narratorTextHeader.widthRatio,
    heightRatio: narratorTextHeader.heightRatio,
  },
  narratorTextRef: undefined,
};

export default NarratorTextHeader;
