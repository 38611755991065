import PropTypes from 'prop-types';

import chevronRight from 'img/arrow_forward_ios_white_24dp.svg';
import chevronLeft from 'img/arrow_back_ios_white_24dp.svg';
import { changeBackgroundClassName } from 'utils/utilityFunctions';
import styled, { css } from 'styled-components';

const Chevron = styled.button`
  ${({ hide }) =>
    !hide &&
    css`
      position: absolute;
      top: 35%;
      height: 30%;
      width: 2%;
      ${({ direction }) => direction}: 2%;
      background-color: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(7px);
      border: solid 1px #fff;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      &:hover {
        background-color: rgba(0, 0, 0, 0.4);
      }
      &:active {
        background-color: rgba(0, 0, 0, 0.6);
      }

      img {
        width: 2vw;
      }
    `}
`;

const ChevronLeft = styled(Chevron)`
  ${({ position }) =>
    position === 'left-from-center' &&
    css`
      display: none;
    `}
`;
const ChevronRight = styled(Chevron)`
  ${({ position }) =>
    position === 'right-from-center' &&
    css`
      display: none;
    `}
`;

function Chevrons({
  position,
  setPosition,
  specificFunctionRight,
  specificFunctionLeft,
  modalEnigmaIsOpen,
  hide,
}) {
  return (
    <>
      <ChevronRight
        position={position}
        direction="right"
        type="button"
        hide={hide}
        onClick={(event) => {
          // Moving is inactive when the enigma has started
          if (!modalEnigmaIsOpen) {
            changeBackgroundClassName(
              event.currentTarget.id,
              position,
              setPosition
            );
            // When we want to execute a function once we click on a chevron
            if (typeof specificFunctionRight === 'function')
              specificFunctionRight();
          }
        }}
        id="right-chevron"
      >
        <img
          src={chevronRight}
          alt="Move to the right of the background"
          draggable="false"
        />
      </ChevronRight>

      <ChevronLeft
        direction="left"
        position={position}
        hide={hide}
        onClick={(event) => {
          if (!modalEnigmaIsOpen) {
            changeBackgroundClassName(
              event.currentTarget.id,
              position,
              setPosition
            );
            // When we want to execute a function once we click on a chevron
            if (typeof specificFunctionLeft === 'function') specificFunctionLeft();
          }
        }}
        type="button"
        id="left-chevron"
      >
        <img
          src={chevronLeft}
          alt="Move to the left of the background"
          draggable="false"
        />
      </ChevronLeft>
    </>
  );
}

Chevrons.propTypes = {
  position: PropTypes.string.isRequired,
  setPosition: PropTypes.func.isRequired,
  specificFunctionRight: PropTypes.func,
  specificFunctionLeft: PropTypes.func,
  modalEnigmaIsOpen: PropTypes.bool,
  hide: PropTypes.bool,
};

Chevrons.defaultProps = {
  specificFunctionRight: undefined,
  specificFunctionLeft: undefined,
  modalEnigmaIsOpen: false,
  hide: false,
};

export default Chevrons;
