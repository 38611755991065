import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../../../../assets/css/components/GameBoard/RPS/Enigma/InterfaceGame.css';

// Sound for the medical information document
import pageTurn from '../../../../sound/page-turn.mp3';

function InterfaceGame({
  isInformationOpen,
  setIsInformationOpen,
  patients,
  informations,
  setInformations,
  buttonsAction,
  setButtonsAction,
  countHelp,
  countRest,
  handleHelp,
  handleRest,
  showButton,
  setShowButton,
  pourcentStress,
  indexPatient,
  dispatch,
  handleChoice,
  numberStressAdded,
  typeStress,
}) {
  const [buttonRest, setButtonRest] = useState({});
  const [gameOver, setGameOver] = useState(false);
  // Audio element for the medical information document
  const audioInformation = new Audio(pageTurn);

  const changeMenuDescription = (index, activeIndex) => {
    const newInformation = [...informations];
    newInformation[index].isActive = true;
    const activeInformation = newInformation.findIndex(
      (information) => information.id === activeIndex.id
    );
    newInformation[activeInformation].isActive = false;
    setInformations(newInformation);
    // Play method on the audio element when switching between tabs of the medical information document
    audioInformation.play();
  };

  const handleHelpDescription = () => {
    const informationActive = informations.find(
      (information) => information.isActive
    );
    const informationActiveIndex = informations.find(
      (information) => information.isActive
    );
    return (
      <div className="InterfaceGame_informations">
        <div className="InterfaceGame_informations-menu">
          {informations.map((informationContent, index) => (
            <button
              className={`InterfaceGame_informations-menu-button ${
                informationActive.id === informationContent.id ? '--active' : ''
              }`}
              type="button"
              onClick={() =>
                informationActive.id !== informationContent.id &&
                changeMenuDescription(index, informationActiveIndex)
              }
              key={informationContent.id}
            >
              {informationContent.title}
            </button>
          ))}
        </div>
        <div className="InterfaceGame_informations-contents">
          {informationActive.contents.map((activeContent) => (
            <div
              key={activeContent.title}
              className="InterfaceGame_informations-content"
            >
              <div
                className="InterfaceGame_informations-content-title"
                style={{ backgroundColor: activeContent.color }}
              >
                {activeContent.title}
              </div>
              <div className="InterfaceGame_informations-content-title-elements">
                {activeContent.elements.map((contentElement) => (
                  <div
                    key={contentElement.titleCount}
                    className="InterfaceGame_informations-content-title-element"
                  >
                    <p className="InterfaceGame_informations-content-title-element-title">
                      {contentElement.titleCount}
                    </p>
                    <p className="InterfaceGame_informations-content-title-element-text">
                      {contentElement.count}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        {informationActive.description && (
          <p className="InterfaceGame_informations-plus">
            {informationActive.description}
          </p>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (pourcentStress > 100) {
      setGameOver(true);
      setShowButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pourcentStress]);

  const handleTypeStress = () => {
    switch (typeStress) {
      case 'positive':
        return 'add-stress';
      case 'negative':
        return 'remove-stress';
      default:
        return '';
    }
  };

  const handleTypeStressSymbol = () => {
    switch (typeStress) {
      case 'positive':
        return '+';
      case 'negative':
        return '-';
      default:
        return '';
    }
  };

  if (indexPatient === 11) {
    return null;
  }

  return (
    <div className="InterfaceGame">
      <div
        className={`${
          isInformationOpen
            ? 'InterfaceGame_informations-open'
            : 'InterfaceGame_informations-closed'
        }
          ${gameOver ? 'blur' : ''}`}
      >
        <button
          type="button"
          className="InterfaceGame_button-information"
          onClick={() => {
            setIsInformationOpen(!isInformationOpen);
            // Play method on the audio element when opening/closing the medical information document
            audioInformation.play();
          }}
        >
          <img
            alt="information"
            src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Frps-1-info2.svg?alt=media&token=ef56c3f9-3377-4e17-b823-e5f4dcd23b05"
          />
        </button>
        {handleHelpDescription()}
      </div>
      <div className="InterfaceGame_actions">
        {gameOver && (
          <div className="InterfaceGame_gameover">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2FGroup.svg?alt=media&token=623973fd-326f-4c75-925f-a811c6601a63"
              alt="croix perdu"
              // Intrinsic size needed to avoid a shift of the elements
              width="190"
              height="193"
            />
            <p>Malheureusement vous avez échoué. Retentez votre chance.</p>
            <button
              className="ButtonAction Action"
              type="button"
              onClick={() => {
                window.location.reload();
              }}
            >
              Réeesayer
            </button>
          </div>
        )}
        <div className={gameOver ? 'blur' : ''}>
          <div className="InterfaceGame_actions-helps">
            <div className="InterfaceGame_actions-button helps">
              <button
                className={`${
                  countHelp.length !== 0
                    ? 'InterfaceGame_actions-help'
                    : 'InterfaceGame_actions-help-disabled'
                }`}
                type="button"
                onClick={() => countHelp.length !== 0 && handleHelp()}
              >
                Aides
                <div className="InterfaceGame_actions-help-icons">
                  {countHelp.map((count) => (
                    <img
                      key={count}
                      className="InterfaceGame_actions-help-icon"
                      src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Frps-1-gemme-aides.svg?alt=media&token=31f0f754-d340-4eae-9d4e-50dcb547521c"
                      alt={count}
                    />
                  ))}
                </div>
              </button>
            </div>

            <div className="InterfaceGame_actions-button">
              <button
                className={`${
                  countRest.length !== 0
                    ? 'InterfaceGame_actions-help'
                    : 'InterfaceGame_actions-help-disabled'
                }`}
                type="button"
                onClick={(event) => {
                  if (countRest.length) {
                    handleRest();
                    /* Add a temporary key/value to buttonRest to indicate
that the button is clicked. Allow to display temporary style */
                    const param = `${event.timeStamp}`;
                    setButtonRest({ ...buttonRest, [param]: true });
                    setTimeout(() => {
                      delete buttonRest[param];
                      setButtonRest(buttonRest);
                    }, 1000);
                  }
                }}
              >
                Repos
                <div className="InterfaceGame_actions-help-icons">
                  {countRest.map((count) => (
                    <img
                      key={count}
                      className="InterfaceGame_actions-help-icon"
                      src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FWelcome%2Frps-1-gemme-repos.svg?alt=media&token=65b3a00a-d200-40b5-b238-a524014c4634"
                      alt={count}
                    />
                  ))}
                </div>
              </button>
            </div>
          </div>
          <div className="InterfaceGame_actions-contents">
            <div className="InterfaceGame_actions-contents-left">
              <div className="InterfaceGame_actions-contents-left-top">
                <div className="InterfaceGame_actions-contents-left-top-header">
                  <img
                    src={patients[indexPatient].url}
                    alt={patients[indexPatient].firstname}
                  />
                  <p>{patients[indexPatient].firstname}</p>
                </div>
                <div className="InterfaceGame_actions-contents-left-top-contents">
                  {Object.keys(patients[indexPatient].constant).map(
                    (constantKey) => (
                      <div
                        key={constantKey}
                        className="InterfaceGame_actions-contents-left-top-content"
                      >
                        <p>{constantKey}</p>
                        <p>
                          <strong>
                            {patients[indexPatient].constant[`${constantKey}`]}
                          </strong>
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="InterfaceGame_actions-contents-right">
              <p>Stress</p>
              <div className="InterfaceGame_actions-contents-right-bar">
                <div className="InterfaceGame_actions-contents-right-outer">
                  <div
                    className={
                      pourcentStress > 98
                        ? 'InterfaceGame_actions-contents-right-inner-rounded'
                        : 'InterfaceGame_actions-contents-right-inner'
                    }
                    style={{
                      height: `${pourcentStress}%`,
                      // Color the stress bar according to the % of stress
                      backgroundColor:
                        // eslint-disable-next-line no-nested-ternary
                        pourcentStress >= 50 && pourcentStress < 80
                          ? 'orange'
                          : pourcentStress >= 80
                          ? `var(--red)`
                          : '',
                    }}
                  >
                    <div className="InterfaceGame_actions-contents-right-inner-label">
                      {pourcentStress >= 10 && `${Math.round(pourcentStress)}%`}
                    </div>
                    <div
                      className={numberStressAdded ? handleTypeStress() : 'Hide'}
                    >
                      <div>
                        {handleTypeStressSymbol()}
                        {numberStressAdded}
                      </div>
                    </div>
                    <div
                      className={
                        Object.keys(buttonRest).length ? 'remove-stress' : 'Hide'
                      }
                    >
                      <div>-30</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="InterfaceGame_actions-contents-bottom">
          {buttonsAction.map((buttonAction) => (
            <button
              key={buttonAction.id}
              type="button"
              className={`
                ${
                  showButton
                    ? 'InterfaceGame_actions-contents-bottom-button'
                    : 'InterfaceGame_actions-contents-bottom-button-disabled'
                }
                ${Object.keys(buttonAction.click).length ? 'red' : ''}

              `}
              style={{ backgroundColor: buttonAction.color, color: 'white' }}
              onClick={(event) => {
                if (showButton) {
                  handleChoice(buttonAction.id, event);
                  /* Add a temporary key/value to buttonsAction to indicate
that the button is clicked. Allow to display temporary style */
                  if (buttonAction.id !== patients[indexPatient].choice) {
                    const param = `${event.timeStamp}`;
                    const newButtonsAction = [...buttonsAction];
                    newButtonsAction[buttonAction.id - 1].click = {
                      [param]: true,
                    };
                    setButtonsAction(newButtonsAction);
                    setTimeout(() => {
                      delete newButtonsAction[buttonAction.id - 1].click[param];
                      setButtonsAction(newButtonsAction);
                    }, 1000);
                  }
                  dispatch({ type: 'INCREMENT_COUNTER' });
                }
              }}
            >
              {buttonAction.title}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  pourcentStress: state.RPS.dataGame.pourcentStress,
  numberStressAdded: state.RPS.dataGame.numberStressAdded,
  typeStress: state.RPS.dataGame.typeStress,
  indexPatient: state.RPS.dataGame.indexPatient,
});

InterfaceGame.propTypes = {
  isInformationOpen: PropTypes.bool.isRequired,
  setIsInformationOpen: PropTypes.func.isRequired,
  patients: PropTypes.arrayOf(
    PropTypes.shape({
      firstname: PropTypes.string,
      constant: PropTypes.shape({
        'T°': PropTypes.number,
        FC: PropTypes.number,
        FR: PropTypes.number,
        Oxy: PropTypes.number,
      }),
      url: PropTypes.string,
      choice: PropTypes.number,
    })
  ).isRequired,
  informations: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      id: PropTypes.number,
    })
  ).isRequired,
  setInformations: PropTypes.func.isRequired,
  buttonsAction: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      color: PropTypes.string,
    })
  ).isRequired,
  countHelp: PropTypes.arrayOf(PropTypes.number).isRequired,
  countRest: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleHelp: PropTypes.func.isRequired,
  handleRest: PropTypes.func.isRequired,
  pourcentStress: PropTypes.number.isRequired,
  numberStressAdded: PropTypes.number.isRequired,
  typeStress: PropTypes.string.isRequired,
  indexPatient: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  showButton: PropTypes.bool.isRequired,
  setButtonsAction: PropTypes.func.isRequired,
  setShowButton: PropTypes.func.isRequired,
  handleChoice: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(InterfaceGame);
