import styles from 'components/Joyride/Steps.module.scss';

import * as images from 'img/vaccination-solo/index';
import * as imageNext from 'img/vaccination-solo/PuckLab';

// Define a function to use the translation function as parameter
const stepsTDE = (t) => {
  const steps = [
    {
      target: '.bedroomTDE',
      content: (
        <div className={styles['main-content']}>
          <h2>
            {t('joyride.welcome', {
              val1: 'la',
              val2: `${t('room1', { ns: 'tde' })}`,
            })}
          </h2>
        </div>
      ),
      placement: 'center',
    },
    {
      target: '.Inventory',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.inventory.title')}</h2>
          <img src={images.trickyCoin} alt="Tricky" />
          <p>{t('joyride.inventory.description')}</p>
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.Steps',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.steps.title')}</h2>
          <p>{t('joyride.steps.description')}</p>
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.Hud-Item-Text-undefined',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.time.title')}</h2>
          <p>{t('joyride.time.description')}</p>
          <img src={imageNext.time} alt="Tricky" />
          <p>{t('joyride.time.score')}</p>
        </div>
      ),
      placement: 'left',
    },
    {
      target: '.Hud-Item-Text-clics',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.hits.title')}</h2>
          <p>{t('joyride.hits.description')}</p>
          <img src={imageNext.cursor} alt="Tricky" />{' '}
          <p>{t('joyride.hits.score')}</p>
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.Hud-Item-Text-pts',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.points.title')}</h2>
          <p>{t('joyride.points.description')}</p>
          <img className={styles.coin} src={imageNext.coinScore} alt="Tricky" />
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.Hud-Item-Text-sound',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.music.title')}</h2>
          <p>{t('joyride.music.description')}</p>
          <img src={imageNext.notMuteIcon} alt="Tricky" />
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.bedroomTDE',
      content: (
        <div className={styles['main-content']}>
          <h2>{t('joyride.goodLuck.title')}</h2>
          <p>
            {t('joyride.goodLuck.description', {
              val: `${t('narrator', { ns: 'tde' })}`,
            })}
          </p>
        </div>
      ),
      placement: 'center',
    },
  ];
  return steps;
};

export default stepsTDE;
