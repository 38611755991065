import { keyframes } from 'styled-components';

const TranslateKeyframe = (startX, endX, startY, endY, startZ, endZ) => keyframes`
from {

  transform:  translate3d(${startX ?? 0}, ${startY ?? 0} , ${startZ ?? 0});
}
to {

  transform: translate3d(${endX ?? 0}, ${endY ?? 0}, ${endZ ?? 0});
}
`;

export default TranslateKeyframe;
