import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from 'components/GameBoard/Emotion/Solo/Enigma/BehaviourMachineCalibration.module.scss';
import { putSessionsHasRoom } from 'API/sessionsHasRoom';
// Components
import WiresPanel from 'components/GameBoard/Emotion/Solo/Enigma/WiresPanel';
import NarratorTextHeader from 'components/GameBoard/Emotion/Solo/Enigma/NarratorTextHeader';
import FooterPanel from 'components/GameBoard/Emotion/Solo/Enigma/FooterPanel';
// Selector functions
import { selectBehaviourMachineCalibrationEnigma } from 'components/Reducers/emotion';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectSteps } from 'components/Reducers/Steps/Steps';

// Constants & utils
import { enigmas } from 'components/GameBoard/Emotion/Solo/constants';
import startedMessage from 'components/constants';
import { shuffleArray } from 'utils/utilityFunctions';

let isMounted = false;
const { footerStyle, firstIndex } = enigmas.BehaviourMachineCalibration;

function BehaviourMachineCalibration() {
  const { t } = useTranslation('emotion');
  // Access the i18next object about the messages displayed at the top
  const messages = t('enigma3.rules.messages', {
    returnObjects: true,
  });

  const behaviours = t('enigma3.behaviours', {
    returnObjects: true,
  });

  const shuffledBehaviours = useRef();

  // Shuffle the behaviours order, only once at mounting
  useEffect(() => {
    if (isMounted) return;

    shuffledBehaviours.current = shuffleArray(behaviours);
    isMounted = true;
  }, [behaviours]);

  const { idSessionHasRoom } = useSelector(infoGameUser);
  const { behaviourIndex, headerMessageStatus, wrongBehaviours } = useSelector(
    selectBehaviourMachineCalibrationEnigma
  );
  const preventionMessage = useSelector(selectSteps).list[0];

  const headerMessageIndex = Object.keys(messages).findIndex(
    (message) => message === headerMessageStatus
  );

  const dispatch = useDispatch();

  const [wrongAnswersCount, setWrongAnswersCount] = useState(0);

  // Retry the combinations that were wrong
  const retryCombination = () => {
    setWrongAnswersCount((previousCount) => previousCount + 1);

    const wrongBehaviourIndex = shuffledBehaviours.current.findIndex(
      (shuffledBehaviour) => shuffledBehaviour.id === wrongBehaviours[firstIndex].id
    );

    dispatch({
      type: 'SET_BEHAVIOUR_INDEX',
      payload: wrongBehaviourIndex,
    });
    dispatch({
      type: 'SET_HEADER_MESSAGE_STATUS',
      payload: 'current-connexion',
    });
  };

  const handleButtonClick = async () => {
    switch (headerMessageStatus) {
      case 'wrong-answers':
        return retryCombination();
      case 'right-answers-1':
        return dispatch({
          type: 'SET_HEADER_MESSAGE_STATUS',
          payload: 'right-answers-2',
        });
      case 'right-answers-2':
        dispatch({
          type: 'CLOSE_MODAL_ENIGMA_EMOTION',
        });
        // Save in DB to retrieve the prevention message in case of reload
        await putSessionsHasRoom(idSessionHasRoom, {
          current_step: preventionMessage.id,
          start_message: startedMessage,
        });
        // Launch the prevention message
        dispatch({
          type: 'CURRENT_STEP',
          payload: preventionMessage.id,
        });
        dispatch({
          type: 'START_MESSAGE_PREVENTION',
        });
        return undefined;
      default:
        dispatch({
          type: 'SET_HEADER_MESSAGE_STATUS',
          // payload: headerMessageIndex + 1,
          payload: Object.keys(messages)[headerMessageIndex + 1],
        });
        return undefined;
    }
  };

  const getFooterContent = () => {
    const currentBehaviour = [...shuffledBehaviours.current][behaviourIndex];
    if (!currentBehaviour.title.match(/\d/))
      currentBehaviour.title += ` ${behaviourIndex + 1}`;
    return currentBehaviour;
  };

  return (
    <>
      <NarratorTextHeader
        message={messages[headerMessageStatus]}
        isButton={
          headerMessageStatus !== 'current-connexion' &&
          headerMessageStatus !== 'no-wire-end' &&
          headerMessageStatus !== 'no-current-behaviour'
        }
        handleClick={handleButtonClick}
      />
      <div className={styles['enigma-container']}>
        <div className={styles['wires-panel-container']}>
          <WiresPanel
            shuffledBehaviours={shuffledBehaviours.current}
            wrongAnswersCount={wrongAnswersCount}
          />
        </div>
        {!headerMessageStatus.includes('intro') && (
          <FooterPanel
            room="BehaviourMachineCalibration"
            content={getFooterContent()}
            style={footerStyle}
          />
        )}
      </div>
    </>
  );
}

export default BehaviourMachineCalibration;
