import { useTranslation } from 'react-i18next';
import styles from 'components/Introduction/StartPage.module.scss';

const Loading = () => {
  const { t } = useTranslation('common');
  return (
    <div className={styles.loading}>
      <p>{t('loading')}</p>
    </div>
  );
};

export default Loading;
