import { useState } from 'react';
import './Cat404.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import catface from '../../../../img/bedroom/catface.svg';
import catbed from '../../../../img/bedroom/catbed.svg';
import catbody from '../../../../img/bedroom/catbody.svg';
import angrycat1 from '../../../../sound/Bedroom/ostin1.mp3';
import angrycat2 from '../../../../sound/Bedroom/ostin2.mp3';

function BedroomCat({ dispatch }) {
  const [meow, setMeow] = useState(false);
  const [countCat, setCountCat] = useState(0);
  const handleCat = () => {
    setCountCat(countCat + 1);
    dispatch({ type: 'INCREMENT_COUNTER' });
    setMeow(true);
    setTimeout(() => {
      setMeow(false);
    }, 1200);
  };

  const conditioncat1 = countCat % 2 === 0 && meow;
  const conditioncat2 = countCat % 2 !== 0 && meow;

  return (
    <>
      {conditioncat1 && (
        <audio src={angrycat1} autoPlay>
          <track kind="captions" />
        </audio>
      )}
      {conditioncat2 && (
        <audio src={angrycat2} autoPlay>
          <track kind="captions" />
        </audio>
      )}
      <div
        className="Bedroom-Cat404"
        onClick={() => handleCat()}
        role="presentation"
      >
        <img src={catbed} id="catbed404" alt="Lit du chat" draggable="false" />
        <div id="catbody404">
          <img src={catbody} alt="Le corps du chat" draggable="false" />
        </div>
        <div id="catface404">
          <img
            src={catface}
            alt="La petite tête de mon petit chat"
            draggable="false"
          />
        </div>
      </div>
    </>
  );
}

BedroomCat.propTypes = {
  dispatch: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    countcat: PropTypes.number.isRequired,
    meowingcat: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect()(BedroomCat);
