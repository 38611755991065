const PlutchikPrimaryDyads = [
  {
    name: 'love',
    color: '#fff6dc',
    path: 'M286.3,162.4C312.4,99.4,250.9,1.4,250,0A249.25,249.25,0,0,1,426.8,73.2S312.6,98.9,286.3,162.4Z',
    textPosition: 'translate(331 54) rotate(22.5)',
    pathPosition: 'translate(-1 3)',
  },
  {
    name: 'submission',
    color: '#f5ffd9',
    path: 'M337.6,213.7c63-26.1,88.8-138.9,89.2-140.5A249.59,249.59,0,0,1,500,250S401.1,187.4,337.6,213.7Z',
    textPosition: 'translate(446 169) rotate(67.5)',
    pathPosition: 'translate(-1 2)',
  },
  {
    name: 'awe',
    color: '#d9ffea',
    path: 'M337.6,286.3c63,26.1,161-35.4,162.4-36.3a249.42,249.42,0,0,1-73.2,176.8S401.1,312.6,337.6,286.3Z',
    textPosition: 'translate(451 333) rotate(-67.5)',
    pathPosition: 'translate(0 2)',
  },
  {
    name: 'disapproval',
    color: '#d9f6ff',
    path: 'M286.3,337.6c26.1,63,138.9,88.8,140.5,89.2A249.59,249.59,0,0,1,250,500S312.6,401.1,286.3,337.6Z',
    textPosition: 'translate(334 455) rotate(-22.5)',
    pathPosition: 'translate(0 1)',
  },
  {
    name: 'remorse',
    color: '#d9edff',
    path: 'M213.7,337.6c-26.1,63,35.4,161,36.3,162.4A249.42,249.42,0,0,1,73.2,426.8S187.4,401.1,213.7,337.6Z',
    textPosition: 'translate(167 451) rotate(22.5)',
    pathPosition: 'translate(0 3)',
  },
  {
    name: 'contempt',
    color: '#e8d9ff',
    path: 'M162.4,286.3c-63,26.1-88.8,138.9-89.2,140.5A249.59,249.59,0,0,1,0,250S98.9,312.6,162.4,286.3Z',
    textPosition: 'translate(49 333) rotate(67.5)',
    pathPosition: 'translate(1 3)',
  },
  {
    name: 'aggressiveness',
    color: '#ffdbd9',
    path: 'M162.4,213.7C99.4,187.6,1.4,249.1,0,250A249.25,249.25,0,0,1,73.2,73.2S98.9,187.4,162.4,213.7Z',
    textPosition: 'translate(54 169) rotate(-67.5)',
    pathPosition: 'translate(1 3)',
  },
  {
    name: 'optimism',
    color: '#ffe8d9',
    path: 'M213.7,162.4C187.6,99.4,74.8,73.6,73.2,73.2A249.59,249.59,0,0,1,250,0S187.4,98.9,213.7,162.4Z',
    textPosition: 'translate(169 54) rotate(-22.5)',
    pathPosition: 'translate(2 3)',
  },
];

export default PlutchikPrimaryDyads;
