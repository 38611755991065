import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/GameBoard/ReusableComponents/PlutchikWheel.module.scss';
import CombinedPlutchikEmotions from 'components/GameBoard/ReusableComponents/CombinedPlutchikEmotions';
import PlutchikEmotions from 'components/GameBoard/ReusableComponents/PlutchikEmotions';
import PlutchikPrimaryDyads from 'components/GameBoard/ReusableComponents/PlutchikPrimaryDyads';
import { useTranslation } from 'react-i18next';

/**
 * Generate a Plutchik wheel, with or without the primary dyads
 * @param {string} primaryDyads
 * @param {function} setOnMouseEmotion
 * @param {function} setSelectedEmotion
 * @returns
 */
function PlutchikWheel({
  primaryDyads,
  combinePetals,
  setOnMouseEmotion,
  setSelectedEmotion,
  responses,
}) {
  const { t } = useTranslation('emotion');

  let PlutchikConstants = [];

  if (combinePetals) {
    PlutchikConstants = CombinedPlutchikEmotions;
  } else {
    PlutchikConstants = PlutchikEmotions;
  }

  if (primaryDyads) {
    PlutchikConstants = PlutchikConstants.concat(PlutchikPrimaryDyads);
  }

  if (responses) {
    const PlutchikEmotionsResponse = [];
    PlutchikConstants.forEach((constantEmotion) => {
      const foundResponse = responses.find(
        (response) => response.id === constantEmotion.id
      );
      if (foundResponse) {
        const newConstantEmotion = {
          ...constantEmotion,
          ...foundResponse,
        };
        PlutchikEmotionsResponse.push(newConstantEmotion);
      }
    });

    PlutchikConstants = PlutchikEmotionsResponse;
  }

  // Function that defines the behaviour when we click on a part of the wheel
  const handleClick = (emotionObject) => {
    setSelectedEmotion(emotionObject);
  };

  // Function that defines the behaviour when the mouse is over a petal
  const previewEmotionOn = (emotionObject) => {
    if (setOnMouseEmotion) setOnMouseEmotion(emotionObject);
  };

  // Function that defines the behaviour when the mouse is off a petal
  const previewEmotionOff = () => {
    if (setOnMouseEmotion) setOnMouseEmotion({ name: '' });
  };

  /**
   * Function that creates each part of the wheel
   * @param {number} id
   * @param {string} path svg <path> to define the form
   * @param {string} textPosition position of the text inside the wheel (with a translate for example)
   * @returns {SVGSVGElement}
   */
  const handlePath = (id, path, textPosition, pathPosition) => {
    return (
      // Container used to group objects
      <g
        id={t(`plutchikEmotions.${PlutchikConstants[id].name}`)}
        className={styles['svg-g']}
        role="button"
        onClick={() => {
          handleClick(PlutchikConstants[id]);
        }}
        onMouseEnter={() => {
          previewEmotionOn(PlutchikConstants[id]);
        }}
        onMouseLeave={() => {
          previewEmotionOff();
        }}
      >
        {/* Generic element to define a shape */}
        <path
          d={path}
          fill={PlutchikConstants[id].color}
          transform={pathPosition}
          stroke={!combinePetals ? '#4F4F4F' : 'transparent'}
        />
        {/* Graphic element containing text */}
        <text
          textAnchor="middle"
          transform={textPosition}
          fill="#000"
          className={`${styles['svg-text']} ${
            combinePetals && styles['big-font-size']
          }
          `}
        >
          {t(`plutchikEmotions.${PlutchikConstants[id].name}`)}
        </text>
      </g>
    );
  };

  return (
    <svg viewBox="0 0 500 500">
      {/* Draw each petal of the wheel */}
      {PlutchikConstants?.map((petal, index) => (
        <React.Fragment key={petal.name}>
          {handlePath(index, petal.path, petal.textPosition, petal?.pathPosition)}
        </React.Fragment>
      ))}
    </svg>
  );
}

PlutchikWheel.propTypes = {
  primaryDyads: PropTypes.bool, // use this prop if you want the primary dyads
  setOnMouseEmotion: PropTypes.func, // when the mouse is over an emotion
  combinePetals: PropTypes.bool, // use this prop if you want to combine the petals intensities
  setSelectedEmotion: PropTypes.func.isRequired, // when an emotion is clicked
  responses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      level: PropTypes.number,
      title: PropTypes.string,
      weighting: PropTypes.number,
    })
  ),
};

PlutchikWheel.defaultProps = {
  combinePetals: false,
  primaryDyads: false,
  setOnMouseEmotion: null,
  responses: null,
};

export default PlutchikWheel;
