import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

// components
import Participants from 'components/GameBoard/ReusableComponents/Multi/Participants/Participants';

// selectors
import { selectUser } from 'components/Reducers/user';

// scss
import styles from 'components/GameBoard/ReusableComponents/Multi/MainScreen/MainScreen.module.scss';

const MainScreen = () => {
  const { participants } = useSelector(selectUser);

  const participantRef = useRef(participants);

  useEffect(() => {
    participantRef.current = participants;
  }, [participants]);

  return (
    <div className={styles.wrapper}>
      <div className={styles['main-screen']} id="video-wrapper">
        <Participants />
      </div>
    </div>
  );
};

export default MainScreen;
