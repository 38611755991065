import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { zoomIn, tada } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import imageChest from '../../../../img/livingroom/SALON-3_coffre_1_noShadow.svg';
import imageChestOpen from '../../../../img/404/ChestOpen404.svg';
import clickSound from '../../../../sound/Click-Switch.mp3';
import successSound from '../../../../sound/Success.mp3';
import chestOpenSound from '../../../../sound/LivingRoom/chest-opening.wav';
import errorSound from '../../../../sound/Error.mp3';
import './Chest.css';
import '../Bureau.css';
import '../../Modals.css';
import ChestItem from './ChestItem';
import img404 from '../../../../img/404/404.svg';
import ActionText from '../../../translation/ActionText.json';
import ObjectItemText from '../../../translation/ObjectItemText.json';

const FlipAnimation = keyframes`${zoomIn}`;
const TadaAnimation = keyframes`${tada}`;
const FlipDiv = styled.div`
  animation: 0.5s ${FlipAnimation};
`;
const TadaImg = styled.div`
  animation: infinite 3s ${TadaAnimation};
`;

function Chest({ dispatch, language }) {
  const { closed, validate } = ActionText[language];
  const { chestWrong } = ObjectItemText[language];

  const [soundClick, setSoundClick] = useState(false);
  const [ModalChest, setModalChest] = useState(false);
  const [ModalWin, setModalWin] = useState(false);
  const [soundSuccess, setSuccessSound] = useState(false);
  const [failSound, setFailSound] = useState(false);
  const [chestSound, setChestSound] = useState(false);
  const [chestSucceed, setChestSucceed] = useState(false);
  const [combination, setCombination] = useState([
    { id: 1, number: 0 },
    { id: 2, number: 0 },
    { id: 3, number: 0 },
    { id: 4, number: 0 },
  ]);

  const soundTyping = () => {
    setSoundClick(true);
    setTimeout(() => {
      setSoundClick(false);
    }, 300);
  };

  const handleClick = () => {
    setModalChest(true);
    setChestSound(true);
    setTimeout(() => {
      setChestSound(false);
    }, 1000);
  };

  const handleButton = (id, number) => {
    soundTyping();
    const checkCombination = () => {
      if (number < 9) {
        return combination.map((item) => {
          if (id === item.id) {
            return {
              ...item,
              number: number + 1,
            };
          }
          return item;
        });
      }
      return combination.map((item) => {
        if (id === item.id) {
          return {
            ...item,
            number: 0,
          };
        }
        return item;
      });
    };
    setCombination(checkCombination());
  };

  const number1 = 5;
  const number2 = 6;
  const number3 = 8;
  const number4 = 9;

  const handleSubmitCode = () => {
    if (
      combination[0].number === number1 &&
      combination[1].number === number2 &&
      combination[2].number === number3 &&
      combination[3].number === number4
    ) {
      setModalChest(false);
      setChestSucceed(true);
      setSuccessSound(true);
      setModalWin(true);
      setTimeout(() => {
        setSuccessSound(false);
      }, 1000);
    } else {
      setModalChest(false);
      dispatch({
        type: 'SHOW_MESSAGE',
        payload: {
          message: chestWrong,
          show: true,
        },
      });
      setFailSound(true);
      setTimeout(() => {
        setFailSound(false);
      }, 1000);
    }
  };

  return (
    <>
      {chestSound && (
        <audio src={chestOpenSound} autoPlay>
          <track kind="captions" />
        </audio>
      )}
      {soundSuccess && (
        <audio src={successSound} autoPlay>
          <track kind="captions" />
        </audio>
      )}
      {failSound && (
        <audio src={errorSound} autoPlay>
          <track kind="captions" />
        </audio>
      )}
      <audio src={soundClick && clickSound} id="audioTyping" autoPlay>
        <track kind="captions" />
      </audio>
      <div>
        <div
          className="chestDiv"
          onClick={
            !chestSucceed
              ? () => {
                  handleClick();
                  dispatch({ type: 'INCREMENT_COUNTER' });
                }
              : () => setModalWin(true)
          }
          role="presentation"
        >
          <img src={imageChest} alt="coffre" draggable="false" />
          <div className="Chest404">
            <img src={img404} alt="404 Enigma" />
          </div>
        </div>
      </div>

      {ModalWin ? (
        <FlipDiv className={chestSucceed ? 'ModalTricky-Background' : 'Hide-Modal'}>
          <div className="ModalTricky-Container">
            <div className="ModalTricky-p">
              <p>
                Muhahaha ! Cette fois-ci le coffre était vide !
                <br />
                Mais mon petit doigt me dit qu&apos;en prenant rendez-vous avec
                Tricky vous ne serez pas au bout de vos surprise !
              </p>
            </div>
            <div className="ItemToShow404">
              <img src={imageChestOpen} alt="Opened Chest" draggable="false" />
            </div>
            <TadaImg>
              <div>
                <a className="Dashboard-Preco" href="https://tricky.fr/contact">
                  <button type="button" className="ButtonAction404 Action404">
                    Nous contacter
                  </button>
                </a>
              </div>
            </TadaImg>
          </div>
        </FlipDiv>
      ) : (
        <div
          className={
            ModalChest && !chestSucceed ? 'ModalTricky-Background' : 'Hide-Modal'
          }
        >
          <div className="ModalTricky-Container">
            <div className="ModalTricky-p">
              <p>Super ! Un coffre à ouvrir !</p>
            </div>
            <div className="chestCode">
              <img
                className="modal_chest_img"
                src={imageChest}
                alt="coffre"
                draggable="false"
              />
            </div>
            <div className="buttonsChestCode">
              {combination.map((item) => (
                <ChestItem
                  id={item.id}
                  number={item.number}
                  incrementCounter={handleButton}
                />
              ))}
            </div>
            <div className="d-flex">
              <button
                className="ButtonAction404 Cancel mr-5"
                type="button"
                onClick={() => {
                  setModalChest(false);
                }}
              >
                {closed}
              </button>
              <button
                className="ButtonAction404 Action404"
                type="button"
                onClick={() => handleSubmitCode()}
              >
                {validate}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  language: state.Room.language,
});

Chest.propTypes = {
  dispatch: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(Chest);
