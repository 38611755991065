import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  warningLights,
  maintenanceSticker,
} from 'components/GameBoard/Emotion/Solo/constants';
import { selectRoom } from 'components/Reducers/Room';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// CSS in JS - style is directly defined in the constant object and is adapted to each room
const ImgStyle = styled.img`
  z-index: ${(props) => props.style?.zIndex};
  height: ${(props) => props.style?.height};
  width: ${(props) => props.style?.width};
  left: ${(props) => props.style?.left};
  right: ${(props) => props.style?.right};
  bottom: ${(props) => props.style?.bottom};
  top: ${(props) => props.style?.top};
  position: absolute;
`;

function WarningLights({ handleWarningLight }) {
  // `t` is a function coming from the i18next library used to translate text
  /* Load the `common` namespace = the one containing translations that are common between games
  and the `emotion` one (see public-> locales -> fr-FR) */
  const { t } = useTranslation('emotion');

  // Select data from the store
  const { currentRoomId } = useSelector(selectRoom);
  // Select warning light state for a particular room
  const warningLightsState = warningLights[currentRoomId];
  const currentMaintenanceSticker = maintenanceSticker[currentRoomId];

  return (
    <>
      {/* Display the maintenance sticker if there is one */}
      {currentMaintenanceSticker && (
        <ImgStyle
          style={currentMaintenanceSticker.style}
          alt={t('maintenance')}
          src={currentMaintenanceSticker.url}
        />
      )}
      {/* Map on each warning light object */}
      {warningLightsState?.map((warningLight) => {
        return (
          <ImgStyle
            style={warningLights.style[warningLight.id]}
            alt={t('warningLight')}
            // handleWarningLight is used if the url needs to change during the enigma
            src={handleWarningLight(warningLight) || warningLight.url}
            key={warningLight.id}
          />
        );
      })}
    </>
  );
}

WarningLights.propTypes = {
  handleWarningLight: PropTypes.func,
};

WarningLights.defaultProps = {
  handleWarningLight: () => {}, // Empty function in case none is passed to props
};

export default WarningLights;
