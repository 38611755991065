import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useTranslation } from 'react-i18next';

// components
import Inventory from 'components/GameBoard/ReusableComponents/Inventory';
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import Zoom from 'components/GameBoard/ReusableComponents/Modal/Zoom';
import ModalZoom from 'components/GameBoard/ReusableComponents/Modal/ModalZoom';
import ObjectInInventory from 'components/GameBoard/ReusableComponents/Actions/ObjectInInventory';
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import Intro from 'components/GameBoard/ReusableComponents/Intro';
import ModalEnigma from 'components/GameBoard/ReusableComponents/Modal/ModalEnigma';
import stepsEmotionMulti from 'components/Joyride/StepsEmotionMulti';
import WaitingRoomParticipants from 'components/GameBoard/ReusableComponents/Multi/CheckRoom/WaitingRoomParticipants';
import ChatBot from 'components/GameBoard/ReusableComponents/ChatBot/ChatBot';
// import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';

// selector functions
import { selectSession } from 'components/Reducers/session';

// utils
import useInitGame from 'utils/useInitGame';
import { useStartMessageOrQuestionary } from 'utils/utilityFunctions';

// css
import 'assets/css/components/GameBoard/TMS/Rooms/ChangingRoomTMS.css';
import './DesktopV2.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function DesktopV2({
  GameUsers,
  modalIsOpen,
  isStarted,
  currentRoom,
  MouseClicked,
  dispatch,
  startMessage,
  currentStep,
  startQuestionnary,
  idSessionHasRoom,
  zoomIsOpen,
  modalZoomIsOpen,
  modalEnigmaIsOpen,
  listRooms,
  isScoreBoard,
  isTutoFinish,
}) {
  const { t } = useTranslation(['common', 'common-multi']);
  const [stepGame, setStepGame] = useState({
    steps: stepsEmotionMulti(t),
    stepIndex: 0,
  });
  const session = useSelector(selectSession);

  const callback = (data) => {
    const { action, index, type, status } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // const stepsOpenInformation = 6;

      // if (index === stepsOpenInformation) {
      //   setIsInformationOpen(!isInformationOpen);
      // }
      setStepGame({
        ...stepGame,
        stepIndex: index + (action === ACTIONS.NEXT ? 1 : -1),
      });
    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED, STATUS.FINISHED].includes(status)
    ) {
      // Need to set our running state to false, so we can restart if we click start again.
      setStepGame({
        ...stepGame,
        run: false,
      });

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/sessions/${session.id}/modification`,
          {
            isTutoFinish: 1,
          }
        )
        .then(() => {
          dispatch({ type: 'RESET_TUTO', payload: 1 });
        });
    }
    if (action === 'reset' || action === 'close') {
      setStepGame({
        ...stepGame,
        run: false,
      });
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/sessions/${session.id}/modification`,
          {
            isTutoFinish: 1,
          }
        )
        .then(() => {
          dispatch({ type: 'RESET_TUTO', payload: 1 });
        });
    }
  };

  useInitGame(currentRoom, session.id);

  // COUNTER
  useEffect(() => {
    if (idSessionHasRoom) {
      axios.put(
        `${BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
        {
          count: GameUsers.count,
        }
      );
    }
    // TO REFACTO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GameUsers.count]);

  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary
  );

  const clickOnGame = () => {
    dispatch({ type: 'INCREMENT_COUNTER' });
  };

  useEffect(() => {
    dispatch({
      type: 'CLICKED_MOUSE_DOWN',
    });
    setTimeout(() => {
      dispatch({
        type: 'CLICKED_MOUSE_UP',
      });
    }, 1000);
    // TO REFACTO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GameUsers.count]);

  const updateMousePosition = (ev) => {
    const offset = document
      .querySelector('#ChangingRoom-BKG')
      .getBoundingClientRect();
    dispatch({
      type: 'PLACE_CURSOR',
      payload: {
        x: ev.pageX - offset.left,
        y: ev.pageY - offset.top,
      },
    });
  };

  useEffect(() => {
    document
      .getElementById('ChangingRoom-BKG')
      .addEventListener('mouseup', updateMousePosition);
    return () => window.removeEventListener('mouseup', updateMousePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const urlRoom = listRooms.DesktopV2.url;
  return (
    <div className="ChangingRoom WelcomeRPS" id="ChangingRoom-BKG">
      <div
        className={MouseClicked ? 'cursor-clicked' : 'Hide'}
        style={{ top: GameUsers.cursor.y, left: GameUsers.cursor.x }}
      >
        <p>+1</p>
      </div>
      {!isScoreBoard && (
        <header>
          <Inventory />
          <Steps />
          <Hud />
        </header>
      )}
      {!isTutoFinish && (
        <Joyride
          callback={callback}
          steps={stepGame.steps}
          run={stepGame.run}
          stepIndex={stepGame.stepIndex}
          showSkipButton
          continuous
          disableCloseOnEsc
          disableOverlayClose
          hideCloseButton
          hideBackButton
          locale={{
            last: t('joyride.locale.last'),
            next: t('joyride.locale.next'),
            skip: t('joyride.locale.skip'),
          }}
          styles={{
            buttonNext: {
              backgroundColor: '#4ed199',
              border: '1px solid #48c48e',
              padding: '15px 25px',
              fontWeight: '700',
            },
            buttonSkip: {
              color: '#9fa2b4',
              fontWeight: '700',
            },
          }}
        />
      )}
      <div
        className="DesktopV2-img-div"
        onClick={() => clickOnGame()}
        role="presentation"
      >
        <img
          className="DesktopV2_background"
          src={urlRoom}
          alt="Fond TMS"
          draggable="false"
        />
        <WaitingRoomParticipants />
      </div>
      {modalIsOpen && <Modal />}
      {modalZoomIsOpen && <ModalZoom />}
      {zoomIsOpen && <Zoom />}
      {modalEnigmaIsOpen && <ModalEnigma />}
      {!isStarted && isTutoFinish && (
        <Intro translationFileName="tms" modalAfterIntro />
      )}
      <ObjectInInventory />
      <ListObjects />
      <ChatBot />
    </div>
  );
}

const mapStateToProps = (state) => ({
  game: state.game,
  modalIsOpen: state.Modal.modal.isOpen,
  isStarted: state.GameUsers.gameStarted,
  currentRoom: state.Room.currentRoomId,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  GameUsers: state.GameUsers,
  startMessage: state.Steps.startMessage,
  currentStep: state.Steps.currentStep,
  startQuestionnary: state.Steps.startQuestionnary,
  zoomIsOpen: state.Modal.zoom.isOpen,
  modalZoomIsOpen: state.Modal.modalZoom.isOpen,
  allObjects: state.Objects.AllObjects,
  isAdvice: state.Steps.isAdvice,
  objects: state.Objects.AllObjects,
  MouseClicked: state.GameUsers.clicked,
  isScoreBoard: state.Steps.isScoreBoard,
  isTutoFinish: state.Steps.isTutoFinish,
  listRooms: state.Room.listRooms,
  count: state.GameUsers.count,
  score: state.GameUsers.gameScore,
  modalEnigmaIsOpen: state.Modal.modalEnigma.isOpen,
});

DesktopV2.propTypes = {
  MouseClicked: PropTypes.bool.isRequired,
  game: PropTypes.shape({
    photo: PropTypes.string,
  }).isRequired,
  GameUsers: PropTypes.shape({
    soundtrack: PropTypes.bool,
    music: PropTypes.bool,
    cursor: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
    count: PropTypes.number,
    time: PropTypes.string,
    gameTour: PropTypes.bool.isRequired,
  }).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  isStarted: PropTypes.number.isRequired,
  currentRoom: PropTypes.number.isRequired,
  idSessionHasRoom: PropTypes.number,
  startMessage: PropTypes.number.isRequired,
  currentStep: PropTypes.shape({
    id: PropTypes.number,
  }),
  startQuestionnary: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  zoomIsOpen: PropTypes.bool.isRequired,
  modalZoomIsOpen: PropTypes.bool.isRequired,
  isScoreBoard: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  modalEnigmaIsOpen: PropTypes.bool.isRequired,
  listRooms: PropTypes.shape({
    DesktopV2: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  isTutoFinish: PropTypes.number.isRequired,
};

DesktopV2.defaultProps = {
  idSessionHasRoom: null,
  currentStep: null,
};

export default connect(mapStateToProps)(DesktopV2);
