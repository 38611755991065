import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as images from '../../../../img/vaccination-solo/index';

import '../../../../assets/css/components/GameBoard/ReusableComponents/Questionnary/Note.css';

const Note = ({ responses, index, answers, currentQuestionId, handleAnswer }) => {
  return (
    <div className="Note" key={index}>
      {responses.map((responseContent) => {
        return (
          <div key={responseContent.id} className="Note_content">
            <img
              src={
                answers[currentQuestionId] &&
                answers[currentQuestionId].response_id === responseContent.id
                  ? images.radioClicked
                  : images.radioIdle
              }
              onClick={() => handleAnswer(responseContent.id, responseContent)}
              role="presentation"
              alt="radioIdle"
              draggable="false"
            />
            <input
              type="radio"
              id={responseContent.id}
              name={responseContent.title}
              value={responseContent.id}
              onClick={(e) => handleAnswer(e.target.value, responseContent)}
            />
            <label htmlFor={responseContent.id}>{responseContent.title}</label>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  answers: state.questionnary.answers,
  currentQuestionId: state.questionnary.currentQuestionId,
});

Note.propTypes = {
  answers: PropTypes.shape({
    response_id: PropTypes.number,
  }).isRequired,
  currentQuestionId: PropTypes.number,
  responses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
  handleAnswer: PropTypes.func.isRequired,
};

Note.defaultProps = {
  currentQuestionId: null,
};

export default connect(mapStateToProps)(Note);
