import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import Input from '../../ReusableComponents/Actions/Input';
import '../../../../assets/css/components/GameBoard/Obesity/Enigma/Fridge.css';
import * as images from '../../../../img/obesity/index';
import Button from '../../ReusableComponents/Actions/Button';
import Audio from '../../ReusableComponents/Actions/Audio';

import fail from '../../../../sound/Error.mp3';
import click from '../../../../sound/iphone-click.mp3';
import success from '../../../../sound/Success.mp3';
import cling from '../../../../sound/cling.mp3';

const BASE_URL = process.env.REACT_APP_BASE_URL;

// ADD FILE CONSTANT, put this object in file constant when we refacto this component
const initIngredients = [
  {
    id: 1,
    name: 'Yaourt',
    caloric: 59,
    image: images.img1,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 2,
    name: 'Steak',
    caloric: 157,
    image: images.img2,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 3,
    name: 'Potiron',
    caloric: 26,
    image: images.img3,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
    type: 'gram',
  },
  {
    id: 4,
    name: 'Fromage',
    caloric: 413,
    image: images.img4,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
    type: 'gram',
  },
  {
    id: 5,
    name: 'Pain',
    caloric: 265,
    image: images.img5,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 6,
    name: 'Maïs',
    caloric: 100,
    image: images.img6,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
    type: 'gram',
  },
  {
    id: 7,
    name: 'Saucisson',
    caloric: 416,
    image: images.img7,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 8,
    name: 'Pâte',
    caloric: 131,
    image: images.img8,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
    type: 'gram',
  },
  {
    id: 9,
    name: 'Chocolat',
    caloric: 1092,
    image: images.img9,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 10,
    name: 'Fraise',
    caloric: 33,
    image: images.img10,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
    type: 'gram',
  },
  {
    id: 11,
    name: 'Gateau au chocolat',
    caloric: 371,
    image: images.img11,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 12,
    name: 'Brioche',
    caloric: 375,
    image: images.img12,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 13,
    name: 'Hamburger',
    caloric: 295,
    image: images.img13,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
  {
    id: 14,
    name: 'Brocolis',
    caloric: 28.7,
    image: images.img14,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
    type: 'gram',
  },
  {
    id: 15,
    name: 'Pâte à tartiner',
    caloric: 235,
    image: images.img15,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
    type: 'gram',
  },
  {
    id: 16,
    name: 'Bonbon',
    caloric: 535,
    image: images.img16,
    isClicked: false,
    quantity: null,
    totalCaloric: null,
  },
];

function Fridge({ dispatch, allObjects, idSessionHasRoom, soundtrack }) {
  const idList = 191;
  const idApportColoric = 192;
  const goodApport = 4250.5;
  const listUsed = allObjects.find((objectContent) => objectContent.id === idList);
  const [ingredientClicked, setIngredientClicked] = useState(null);
  const [openModalQuantity, setOpenModalQuantity] = useState({
    status: false,
    gram: false,
  });
  const [soundFail, setSoundFail] = useState(false);
  const [soundClick, setSoundClick] = useState(false);
  const [soundSuccess, setSoundSuccess] = useState(false);
  const [soundTotalCaloricIngredients, setSoundTotalCaloricIngredients] =
    useState(false);

  const [totalCaloricIngredients, setTotalCaloricIngredients] = useState(0);
  const [ingredients, setIngredients] = useState(initIngredients);

  const resetIngredients = () => {
    const updateIngredients = ingredients.map((ingredient) => {
      const newIngredient = ingredient;
      newIngredient.isClicked = false;
      newIngredient.quantity = null;
      newIngredient.totalCaloric = null;
      return newIngredient;
    });
    setIngredients(updateIngredients);
  };

  const handleImage = () => {
    let imageSrc = '';
    const contentElement = listUsed.images.find(
      (image) => image.type === 'clicked'
    );
    imageSrc = contentElement.image;
    return imageSrc;
  };

  const handleClickedIngredient = (index, ingredient) => {
    if (ingredients.filter((item) => item.id === index + 1)[0].isClicked) {
      setOpenModalQuantity({
        status: false,
        gram: false,
      });
      const newIngredients = ingredients;
      newIngredients[index].isClicked = !newIngredients[index].isClicked;
      setIngredients(newIngredients);
      setIngredientClicked({
        id: index,
        type: ingredient.type ? ingredient.type : null,
      });
    } else {
      setSoundClick(true);
      setTimeout(() => setSoundClick(false));

      setOpenModalQuantity({
        status: true,
        gram: ingredient.type === 'gram',
      });
      const newIngredients = ingredients;

      newIngredients[index].isClicked = !newIngredients[index].isClicked;
      setIngredients(newIngredients);
      setIngredientClicked({
        id: index,
        type: ingredient.type ? ingredient.type : null,
      });
    }
  };

  const calculateCaloric = (newIngredients) => {
    if (ingredientClicked.type === 'gram') {
      return (
        (Number(ingredientClicked.quantity) *
          newIngredients[ingredientClicked.id].caloric) /
        100
      );
    }
    return (
      Number(ingredientClicked.quantity) *
      newIngredients[ingredientClicked.id].caloric
    );
  };

  const calculateCaloricOfIngredient = () => {
    const newIngredients = [...ingredients];
    newIngredients[ingredientClicked.id].quantity = ingredientClicked.quantity;

    newIngredients[ingredientClicked.id].totalCaloric =
      calculateCaloric(newIngredients);
    setIngredients(newIngredients);
    setOpenModalQuantity({
      status: false,
      gram: false,
    });
    setIngredientClicked(null);

    setSoundTotalCaloricIngredients(true);
    setTimeout(() => setSoundTotalCaloricIngredients(false), 1000);

    setTotalCaloricIngredients(
      totalCaloricIngredients + calculateCaloric(newIngredients)
    );
  };

  const addInventory = (objectElement, index) => {
    const imageInventoryApport = objectElement.images.find(
      (image) => image.type === 'inventory'
    );
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: objectElement.id,
        isInventory: true,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isInventory: true,
            type: 'inventory',
          },
        });
        dispatch({
          type: 'ADD_INVENTORY',
          payload: objectElement,
        });
        dispatch({
          type: 'COUNT_MOVE',
        });
        setTimeout(() => {
          dispatch({
            type: 'CLOSE_MODAL_ENIGMA',
          });
        }, 1000);
        setTimeout(() => {
          dispatch({
            type: 'OPEN_MODAL',
            payload: {
              image: imageInventoryApport.image,
            },
          });
        }, 1000);
      });
  };

  const checkedObject = (collectObject, index, isMessage) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        isChecked: 1,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            isChecked: 1,
            type: 'checked',
          },
        });
        dispatch({
          type: 'REMOVE_OF_INVENTORY',
          payload: collectObject.id,
        });
        if (!isMessage) {
          dispatch({
            type: 'START_MESSAGE_PREVENTION',
          });
        }
      });
  };

  const handleTotalIngredientsCaloric = () => {
    if (totalCaloricIngredients === goodApport) {
      const apportElement = allObjects.find(
        (apportContent) => apportContent.id === idApportColoric
      );
      const apportElementIndex = allObjects.findIndex(
        (apportContent) => apportContent.id === idApportColoric
      );
      const listElement = allObjects.find(
        (listContent) => listContent.id === idList
      );
      const listElementIndex = allObjects.findIndex(
        (listContent) => listContent.id === idList
      );

      setSoundSuccess(true);
      // Delay the addition to inventory sound to let success sound finish
      setTimeout(() => addInventory(apportElement, apportElementIndex), 1000);
      checkedObject(listElement, listElementIndex, 'not-message');
    } else {
      setTotalCaloricIngredients(0);
      setSoundFail(true);
      resetIngredients();
      setTimeout(() => {
        setSoundFail(false);
      }, 1500);
    }
  };

  const isApportValidate = allObjects.find(
    (objectContent) =>
      objectContent.id === idApportColoric && objectContent.isInventory
  );

  const isCaloricInventory = allObjects.find(
    (objectContent) => objectContent.id === idList && objectContent.isInventory
  );

  const handleImageList = () => {
    if (isCaloricInventory && !isApportValidate) {
      return (
        <img className="Fridge_enigma-left-list" src={handleImage()} alt="tablet" />
      );
    }
    if (isApportValidate) {
      return (
        <p className="Fridge_enigma-left-sentence">
          Vous avez trouvé l&apos;apport calorique journalier d&apos;Arthur
        </p>
      );
    }
    return null;
  };

  return (
    <div className="Fridge">
      <Audio condition={soundFail && soundtrack} sound={fail} />
      <Audio condition={soundClick && soundtrack} sound={click} />
      <Audio condition={soundSuccess && soundtrack} sound={success} />
      <Audio condition={soundTotalCaloricIngredients && soundtrack} sound={cling} />
      <div className="Fridge_enigma">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FObesity%2Fassets%2Fobjects%2Ffridge-zoom-vide1.svg?alt=media&token=142840e9-09b7-4554-8f1f-b64d4014a542"
          alt="Fridge background"
          className="Fridge_enigma-BKG"
        />
        <div className="Fridge_enigma-left">
          {handleImageList()}
          {openModalQuantity.status && (
            <div className="Fridge_enigma-Quantity">
              <p>Quelle quantité voulez-vous ajouter ?</p>
              <div className="Fridge_enigma-gramme">
                <Input
                  name="quantity"
                  type="number"
                  isRequired
                  onChange={(e) =>
                    setIngredientClicked({
                      ...ingredientClicked,
                      quantity: e.quantity,
                    })
                  }
                  inputs={ingredientClicked}
                />
                {openModalQuantity.gram && 'g'}
              </div>
              <Button
                buttonType="action"
                title="Valider"
                onClick={() => calculateCaloricOfIngredient()}
              />
            </div>
          )}
        </div>
        <div className="Fridge_enigma-right">
          <div className="Fridge_enigma-items">
            {ingredients.map((ingredient, index) => (
              <button
                className={`Fridge_enigma-item ${
                  ingredient.isClicked && 'Fridge_enigma-itemClicked'
                }`}
                type="button"
                onClick={
                  !isApportValidate &&
                  (() => handleClickedIngredient(index, ingredient))
                }
                key={ingredient.id}
              >
                <img
                  src={ingredient.image}
                  alt={ingredient.name}
                  title={ingredient.name}
                />
              </button>
            ))}
          </div>
          {totalCaloricIngredients > 0 && (
            <div className="Fridge_enigma-kcal">
              <p>{isApportValidate ? goodApport : totalCaloricIngredients} kcal</p>
              {!isApportValidate && (
                <div className="Fridge_enigma-kcal-Buttons">
                  <button
                    type="button"
                    className="Fridge_enigma-kcal-Save"
                    onClick={() => handleTotalIngredientsCaloric()}
                  >
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    className="Fridge_enigma-kcal-Reset"
                    onClick={() => {
                      resetIngredients();
                      setTotalCaloricIngredients(0);
                    }}
                  >
                    Effacer
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  contents: state.Modal.modalEnigma.description.content,
  allObjects: state.Objects.AllObjects,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  soundtrack: state.GameUsers.soundtrack,
});

Fridge.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  soundtrack: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Fridge);
