import { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import confetti from 'canvas-confetti';
import ReactJoyride from 'react-joyride';
import { PropTypes } from 'prop-types';

import Button from '../../ReusableComponents/Actions/Button';
import Progression from './Progression';
import Audio from '../../ReusableComponents/Actions/Audio';

function Tablet({ dispatch, progression, idSessionHasRoom }) {
  const [badAnswer, setBadAnswer] = useState(false);
  const [goodAnswer, setGoodAnswer] = useState(false);
  const steps = [
    {
      target: '.Inventory',
      content: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space evenly',
            alignItems: 'center',
          }}
        >
          <p>
            Ici se trouve la liste des événements à replacer. Cliquer sur “+
            d’infos” pour obtenir des détails sur l’événement en question.
          </p>
        </div>
      ),
      placement: 'center',
    },
    {
      target: '.Years',
      content: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space evenly',
            alignItems: 'center',
          }}
        >
          <p>
            Cliquer sur une année afin d’afficher les mois corespondants. L’année
            avec le fond de couleur correspond à l’année selectionnée.
          </p>
        </div>
      ),
      placement: 'center',
    },
    {
      target: '.Months',
      content: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space evenly',
            alignItems: 'center',
          }}
        >
          <p>
            Vous pouvez déplacer les événements dans les cases correspondant à
            chaque mois.
          </p>
        </div>
      ),
      placement: 'center',
    },
    {
      target: '.defaultZone',
      content: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space evenly',
            alignItems: 'center',
          }}
        >
          <p>
            Retrouvez ici la jauge de dépersonnalisation. Si vous réussissez, votre
            jauge augmente. Cependant, si vous vous trompez, celle-ci redescend.
          </p>
        </div>
      ),
      placement: 'center',
    },
  ];

  const goodTasks = [
    'Mai2020',
    'Fevrier2017',
    'Janvier2022',
    'Decembre2014',
    'Aout2019',
    'Novembre2021',
    'Mars2016',
  ];

  const [tasks, setTasks] = useState([
    {
      name: 'Dégradation du climat de travail',
      position: null,
      id: 0,
      message:
        "4 ans après la réorganisation des services, je suis toujours dans le même. Je n’y supporte plus les conditions de travail, et les collègues sont d’accord avec ça. L’entente avec la cadre de santé s’est clairement dégradée, le climat de travail est plus que tendu. Et la chute de Sabine en pleine canicule de la quinzaine d'août l’année dernière n'a rien arrangé à la situation !",
    },

    {
      name: 'Manque d’effectif',
      position: null,
      id: 1,
      message:
        'Il y a de plus en plus d’absences ces derniers temps… Alors les collègues, on les remplace. Le problème ? On ne nous laisse pas gérer notre propre planning ! Je connais mon métier, je connais mes priorités. Mais bon, on nous imprime une feuille, et on doit suivre les instructions. On ne nous demande jamais notre avis. C’est à croire que l’on ne nous fait pas confiance.',
    },

    {
      name: 'Décès de patients',
      position: null,
      id: 2,
      message:
        'J’en peux vraiment plus. Deux décès sur quatre entrées depuis à peine une heure… La nouvelle année 2022 commence bien. J’arrive au bout de mes ressources, ce n’est plus possible. Il faut que je me fasse aider pour éviter d’en arriver au burn out comme Guillaume en novembre dernier.',
    },

    {
      name: 'Signature du contrat',
      position: null,
      id: 3,
      message:
        'Mon premier contrat, décembre 2014 ! On m’a promis de me faire intégrer le service de cardiologie quand j’aurais fait mes preuves ! C’est génial ! Le petit hic… c’est que la cadre a promis la même chose à 3 de mes collègues… On verra bien d’ici un an ou deux. Une réorganisation des services est prévue pour mars 2016 !',
    },

    {
      name: "Chute d'une collègue",
      position: null,
      id: 4,
      message:
        'Voilà, avec la cadence infernale que l’on nous impose, on se précipite, on ne fait pas attention. Sabine a trébuché en allant chercher un verticalisateur pour aider une patiente à se lever. Depuis février l’année dernière, on est en sous-effectif. Ca pèse sur le moral.',
    },

    {
      name: 'Démission d’un très bon collègue',
      position: null,
      id: 5,
      message:
        'Le quotidien au travail, c’est du stress, encore du stress ! Guillaume, mon meilleur collègue, n’a pas tenu, il est parti ce mois-ci. Et moi, je vais peut-être suivre… Tous ces patients desquels on doit s’occuper, et en un minimum de temps. Bien évidemment, la qualité de notre travail en prend un coup… Et moi, j’ai pas signé pour ça il y 7ans.',
    },

    {
      name: 'Pas de changement de service',
      position: null,
      id: 6,
      message:
        'Enfin le printemps ! Quand j’y pense, ca fait deja plus d’un an que je suis arrivé dans l’établissement, mais pas de nouvelles de changement de service. La réorganisation des services n’a rien changé à ma situation. La cadre semble avoir ses préférences et laisse même le choix des services à ses collègues préférés. Je changerai peut-être de service au mois de mai dans 4 ans, qui sait ?!',
    },
  ]);

  const years = [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022];
  const months = [
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ];
  const [currentYear, setCurrentYear] = useState(years[0]);
  const [info, setInfo] = useState(null);
  const [moovedTask, setMoovedTask] = useState(null);
  const [dialogue, setDialogue] = useState(null);

  const handleClick = () => {
    let newTasks = tasks;
    newTasks = newTasks.map((tache) => {
      // eslint-disable-next-line no-param-reassign
      tache.position = null;
      return tache;
    });
    setTasks(newTasks);
  };

  useEffect(() => {
    let i = 0;
    goodTasks.forEach((goodTask, index) => {
      if (goodTask === tasks[index].position) {
        i += 1;
      }
    });
    if (Number(i) === 0) {
      i = 0.35;
    }
    dispatch({
      type: 'SET_PROGRESSION',
      payload: (i * 100) / 7,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  useEffect(() => {
    switch (moovedTask) {
      case '0':
        if (
          tasks[tasks.findIndex((task) => task.id === Number(moovedTask))]
            .position ===
          goodTasks[tasks.findIndex((task) => task.id === Number(moovedTask))]
        ) {
          setDialogue('Réponse exacte ! On y est presque !');
          setGoodAnswer(true);
        } else {
          setDialogue(
            'Cela ne semble pas être la bonne réponse. Essayez autre chose.'
          );
          setBadAnswer(true);
        }
        break;
      case '1':
        if (
          tasks[tasks.findIndex((task) => task.id === Number(moovedTask))]
            .position ===
          goodTasks[tasks.findIndex((task) => task.id === Number(moovedTask))]
        ) {
          setDialogue(
            'Super, vous avez réussi à associer chaque événement à sa bonne date !'
          );
          setGoodAnswer(true);
        } else {
          setDialogue("Ce n'est pas la bonne date...");
          setBadAnswer(true);
        }
        break;
      case '2':
        if (
          tasks[tasks.findIndex((task) => task.id === Number(moovedTask))]
            .position ===
          goodTasks[tasks.findIndex((task) => task.id === Number(moovedTask))]
        ) {
          setDialogue("C'est bien ça. Janvier 2022. ");
          setGoodAnswer(true);
        } else {
          setDialogue(
            "Il semblerait que cet événement n'ait pas eu lieu à ce moment-là... Relisez bien la description !"
          );
          setBadAnswer(true);
        }
        break;
      case '3':
        if (
          tasks[tasks.findIndex((task) => task.id === Number(moovedTask))]
            .position ===
          goodTasks[tasks.findIndex((task) => task.id === Number(moovedTask))]
        ) {
          setDialogue("Bravo, c'est bien ça ! Décembre 2014.");
          setGoodAnswer(true);
        } else {
          setDialogue(
            'Aline ne semble pas avoir signé son contrat à cette période. Essayez encore.'
          );
          setBadAnswer(true);
        }
        break;
      case '4':
        if (
          tasks[tasks.findIndex((task) => task.id === Number(moovedTask))]
            .position ===
          goodTasks[tasks.findIndex((task) => task.id === Number(moovedTask))]
        ) {
          setDialogue("Bravo, c'est la bonne réponse. On approche du but !");
          setGoodAnswer(true);
        } else {
          setDialogue(
            "Bien tenté, mais ce n'est pas la bonne réponse. Essayez encore."
          );
          setBadAnswer(true);
        }
        break;
      case '5':
        if (
          tasks[tasks.findIndex((task) => task.id === Number(moovedTask))]
            .position ===
          goodTasks[tasks.findIndex((task) => task.id === Number(moovedTask))]
        ) {
          setDialogue('Bien joué, continuez sur votre lancée !');
          setGoodAnswer(true);
        } else {
          setDialogue("Guillaume n'a pas démissioné à cette période...");
          setBadAnswer(true);
        }
        break;
      case '6':
        if (
          tasks[tasks.findIndex((task) => task.id === Number(moovedTask))]
            .position ===
          goodTasks[tasks.findIndex((task) => task.id === Number(moovedTask))]
        ) {
          setDialogue(
            'Cela ne semble pas être la bonne réponse. Essayez autre chose.'
          );
          setGoodAnswer(true);
        } else {
          setDialogue(
            `Il semblerait que cet événement n'ait pas eu lieu à ce moment-là... Relisez bien la description de l'événement "Signature de contrat".`
          );
          setBadAnswer(true);
        }
        break;
      default:
        setDialogue(null);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  const handleDragStart = (event, id) => {
    const crt = event.target.cloneNode(true);
    crt.className = event.target.className;
    crt.style.position = 'absolute';
    crt.style.top = '0px';
    crt.style.left = '-1000px';
    crt.style.boxShadow = '0px 0px 0px transparent';
    document.body.appendChild(crt);
    const position = event.target.getBoundingClientRect();
    event.dataTransfer.setDragImage(
      crt,
      event.clientX - position.left,
      event.clientY - position.top
    );
    event.dataTransfer.setData('id', id);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, cat) => {
    const id = event.dataTransfer.getData('id');
    if (cat) {
      setMoovedTask(id);
    } else setMoovedTask(null);

    const tache = tasks.map((task) => {
      const newTask = task;
      if (task.position === cat + currentYear) {
        newTask.position = null;
      }
      if (Number(task.id) === Number(id)) {
        if (cat) {
          newTask.position = cat + currentYear;
        } else newTask.position = null;
      }
      return newTask;
    });
    setTasks(tache);
  };

  const chooseYear = (year) => {
    setCurrentYear(year);
  };

  const handleInfo = (id) => {
    setInfo(id);
  };

  const handleEnded = () => {
    setBadAnswer(false);
    setGoodAnswer(false);
  };

  const handleRetour = () => {
    setInfo(null);
  };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  const handleSubmit = () => {
    if (progression === 100) {
      const duration = 1.5 * 1000;
      const animationEnd = Date.now() + duration;
      const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

      const interval = setInterval(function () {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 500 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti({
          ...defaults,
          particleCount,
          origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
        });
        confetti({
          ...defaults,
          particleCount,
          origin: { x: randomInRange(0.6, 0.9), y: Math.random() - 0.2 },
        });
        return 0;
      }, 250);

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
          {
            current_step: 126,
          }
        )
        .then(() => {
          dispatch({
            type: 'CURRENT_STEP',
            payload: 126,
          });
          dispatch({
            type: 'START_MESSAGE_PREVENTION',
          });
        });
    }
  };

  return (
    <div className="test">
      <Audio
        onEnded={handleEnded}
        sound="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Fsounds%2FsoundBadResponse.wav?alt=media&token=5432f6a3-6cec-4d36-b8ad-d103bc6e63e8"
        condition={badAnswer}
      />
      <Audio
        onEnded={handleEnded}
        sound="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Fsounds%2FsoundGoodResponse.wav?alt=media&token=c04a32c7-e1bf-469d-ba53-44d4fde42353"
        condition={goodAnswer}
      />

      <ReactJoyride
        debug
        steps={steps}
        showSkipButton
        continuous
        disableCloseOnEsc
        disableOverlayClose
        hideBackButton
        hideCloseButton
        locale={{
          close: 'Fermer',
          last: 'Commencer',
          next: 'Suivant',
          skip: 'Passer',
        }}
        styles={{
          options: {
            overlayColor: 'rgba(0,0,0, 0.7)',
            arrowColor: '#fff',
          },
          buttonNext: {
            backgroundColor: '#4ed199',
            border: '1px solid #48c48e',
            padding: '15px 25px',
            fontWeight: '700',
          },
          buttonBack: {
            marginRight: 10,
            color: '#000',
            border: '1px solid #9fa2b4',
            backgroundColor: '#fff',
          },
          buttonLast: {
            marginRight: 20,
          },
          buttonSkip: {
            color: '#9fa2b4',
            fontWeight: '700',
          },
        }}
      />
      <div className="tablet-screen">
        <div className="Completion-Container">
          <Progression />
        </div>
        {info !== null && (
          <div className="Modal-Info">
            <h1>{tasks[info].name}</h1>
            <p>{tasks[info].message}</p>
            <Button
              title="Retour"
              buttonType="action"
              onClick={() => handleRetour()}
            />
          </div>
        )}
        <div className="Task-zone">
          <div className="Nurse">
            <img
              className="Tablet-img"
              alt="Tablette"
              src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FRPS%2Fassets%2Frooms%2FBreakRoom%2Fobjects%2Frps-2-dame%20tuto.svg?alt=media&token=25a5470d-e46e-4061-a55a-d43c1a030864"
            />
            {dialogue && <p className="bubble-nurse">{dialogue}</p>}
          </div>
          <div
            className="defaultZone"
            onDragOver={handleDragOver}
            onDrop={(event) => handleDrop(event, null)}
          >
            {tasks.map((tache, index) =>
              !tache.position ? (
                <div
                  className="Task"
                  style={{ display: 'flex' }}
                  draggable="true"
                  onDragStart={(event) => handleDragStart(event, tache.id)}
                >
                  <p>{tache.name}</p>
                  <button
                    type="button"
                    className="info"
                    onClick={() => handleInfo(index)}
                  >
                    + d&apos;infos
                  </button>
                </div>
              ) : (
                <div
                  className="usedTask"
                  style={{ display: 'flex' }}
                  draggable="false"
                >
                  <p>{tache.name}</p>
                  <button
                    type="button"
                    className="info"
                    onClick={() => handleInfo(index)}
                  >
                    + d&apos;infos
                  </button>
                </div>
              )
            )}
          </div>
          <div className="Button-Reset">
            <Button title="Reset" buttonType="action" onClick={handleClick} />
            <Button title="Valider" buttonType="action" onClick={handleSubmit} />
          </div>
        </div>
        <div className="Years">
          {years.map((year) => {
            return (
              <div
                className={
                  year === currentYear ? 'button-current-year' : 'button-year'
                }
              >
                <Button
                  title={year}
                  buttonType="action"
                  onClick={() => chooseYear(year)}
                />
              </div>
            );
          })}
        </div>
        <div className={info !== null ? 'Blur-Info' : 'months'}>
          {months.map((month) => {
            return (
              <div
                className="month"
                onDragOver={handleDragOver}
                onDrop={info === null && ((event) => handleDrop(event, month))}
              >
                {month}
                {tasks.map(
                  (tache, index) =>
                    tache.position === month + currentYear && (
                      <div
                        className={
                          tache.position === goodTasks[index]
                            ? 'Good-Task'
                            : 'Bad-Task'
                        }
                        style={{ display: 'flex' }}
                        draggable={
                          tache.position === goodTasks[index] ? 'false' : 'true'
                        }
                        onDragStart={(event) => handleDragStart(event, tache.id)}
                      >
                        <p>{tache.name}</p>
                        <button
                          type="button"
                          className="info"
                          onClick={() => handleInfo(index)}
                        >
                          + d&apos;infos
                        </button>
                      </div>
                    )
                )}
                {!tasks.find((task) => task.position === month + currentYear) && (
                  <div className="Task-Container" />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  progression: state.RPS.dataGame.progression,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
});

Tablet.propTypes = {
  progression: PropTypes.number.isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Tablet);
