import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// selector functions
import { selectInfoGame } from 'components/Reducers/game';

// scss
import styles from 'components/GameBoard/ReusableComponents/Multi/Shared/PreventMessage/PreventMessage.module.scss';
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

const PreventMessage = ({
  setIntroductionStatus,
  introductionStatus,
  messages,
}) => {
  const { t } = useTranslation('emotion-multi');

  const game = useSelector(selectInfoGame);

  const [index, setIndex] = useState(0);
  const [message, setMessage] = useState(messages[index]);

  const nextMessage = () => {
    if (index + 1 < messages.length) {
      setMessage(messages[index + 1]);
      setIndex(index + 1);
    } else {
      // After the last begin the questionnary
      setIntroductionStatus({ ...introductionStatus, status: 'questionnary' });
    }
  };

  return (
    <div className={styles.messages}>
      <div
        className={styles['tutorial-background']}
        style={{ backgroundImage: `url(${game.tuto_background})` }}
      >
        <p>{message}</p>
      </div>
      <Button
        onClick={() => nextMessage()}
        title={t('introMessage.buttonMessage')}
        buttonType="action"
      />
    </div>
  );
};

PreventMessage.propTypes = {
  setIntroductionStatus: PropTypes.func.isRequired,
  introductionStatus: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PreventMessage;
