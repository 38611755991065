import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NewsPaper from '../../../../img/livingroom/SALON-1_journal_1.svg';
import RefillPaper from '../../../../img/404/Paper404_onePiece.svg';
import paperImg from '../../../../img/404/Papiers404_BOTTOM.svg';
import TextPaper from '../../../../img/404/Paper404_Text.svg';
import wooshSound from '../../../../sound/woosh.mp3';
import pop from '../../../../sound/pop.mp3';
import wind from '../../../../sound/Kitchen/soundOnSymbols.mp3';
import './Book.css';
import '../Modals.css';
import { addObject } from '../../../Action/index';

function Book({ dispatch, Objects, Modal }) {
  const [show, setShow] = useState(false);
  const [woosh, setWoosh] = useState(false);
  const [popSound, setPopSound] = useState(false);
  const [bookDone, setBookDone] = useState(false);
  const [bookDoneZoom, setBookDoneZoom] = useState(false);

  const handleClick = () => {
    setShow(!show);
    setTimeout(() => {
      setPopSound(true);
    }, 200);
    setTimeout(() => {
      setPopSound(false);
    }, 1200);
  };

  const AddObject = () => {
    dispatch(
      addObject({
        name: Objects.AllObjects.filter((item) => item.id === 66).map(
          (item) => item.name
        )[0],
        image: Objects.AllObjects.filter((item) => item.id === 66).map(
          (item) => item.image
        )[0],
        id: 66,
      })
    );
    dispatch({
      type: 'SHOW_MESSAGE',
      payload: {
        message: 'Le bout de papier a été ajouté à votre inventaire',
        show: true,
      },
    });
    setShow(!show);
    setBookDone(true);
    setBookDoneZoom(true);
    setWoosh(true);
    setTimeout(() => {
      setBookDoneZoom(false);
    }, 300);
    setTimeout(() => {
      setWoosh(false);
    }, 1000);
  };

  const handleFind = () => {
    dispatch({
      type: 'SHOW_MESSAGE',
      payload: {
        message:
          'Vous avez trouvé un bout de page déchiré. Il est dans votre inventaire',
        show: true,
      },
    });
  };

  const CloseRefillPaperModal = () => {
    dispatch({
      type: 'MODAL_404',
      payload: {
        modalType: 'modalRefillPaper',
        showModal: false,
      },
    });
  };

  return (
    <>
      <audio src={Modal ? wind : ''} autoPlay loop>
        <track kind="captions" />
      </audio>
      {bookDoneZoom && (
        <div className="zoomleft404">
          <img src={paperImg} alt="Paper" draggable="false" />
        </div>
      )}
      <audio src={popSound && pop} autoPlay>
        <track kind="captions" />
      </audio>
      <audio src={woosh && wooshSound} autoPlay>
        <track kind="captions" />
      </audio>
      <div className={Modal ? 'ModalTricky-Background' : 'Hide-Modal'}>
        <div className="ModalTricky-Container">
          <div className="ModalTricky-p">
            <p>C&apos;est un indice !</p>
          </div>
          <div className="ItemToShow404">
            <img src={RefillPaper} alt="Trash Bin" draggable="false" />
            <div className="TextPaper">
              <img src={TextPaper} alt="Trash Bin" draggable="false" />
            </div>
          </div>
          <div>
            <button
              className="ButtonAction404 Cancel404"
              type="button"
              onClick={CloseRefillPaperModal}
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
      <div className={show ? 'ModalTricky-Background' : 'Hide-Modal'}>
        <div className="ModalTricky-Container">
          <div className="ModalTricky-p">
            <p>
              Intéressant, une note déchirée est cachée dans le journal, il manque
              un bout pour pouvoir lire.
            </p>
          </div>
          <div className="ItemToShow404">
            <img src={NewsPaper} alt="Trash Bin" draggable="false" />
          </div>
          <div>
            <button
              className="ButtonAction404 Action404"
              type="button"
              onClick={AddObject}
            >
              Ajouter à l&apos;inventaire
            </button>
          </div>
        </div>
      </div>
      <div>
        <div
          className="book-404"
          onClick={!bookDone ? () => handleClick() : () => handleFind()}
          tabIndex={0}
          onKeyDown={() => {}}
          role="button"
        >
          <img src={NewsPaper} alt="A simple book" draggable="false" />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  Objects: state.Objects,
  Modal: state.Room404.Modal.modalRefillPaper,
});

Book.propTypes = {
  dispatch: PropTypes.func.isRequired,
  Objects: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  Modal: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Book);
