const initialState = {
  id: null,
  date: '',
  end_date: '',
};

const session = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_SESSION':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

// Define the selector functions
export const selectSession = (state) => state.session;

export default session;
