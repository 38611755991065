import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Fridge from '../../Obesity/Enigma/Fridge';
import Television from '../../Obesity/Enigma/Television/Television';
import BoardSun from '../../Obesity/Enigma/BoardSun/BoardSun';
import Phone from '../../Obesity/Enigma/Phone/Phone';
import LockerOne from '../../TMS/Enigma/LockerOne';
import DocumentHolder from '../../TMS/Enigma/DocumentHolder';
import LockerTwo from '../../TMS/Enigma/LockerTwo';
import Door from '../../TMS/Enigma/Door';
import Tablet from '../../TMS/Enigma/Tablet';
import Computer from '../../RPS/Enigma/Computer';
import HelpDoc from '../../RPS/Enigma/HelpDoc';
import '../../../../assets/css/components/GameBoard/ReusableComponents/Modal/ModalEnigma.css';
import Board from '../../TMS/Enigma/Board';

function ModalEnigma({
  position,
  gameName,
  modalEnigmaIsOpen,
  dispatch,
  enigma,
  residents,
  setInventory,
  setResidents,
  inventory,
  placeObject,
  HandleDrop,
}) {
  const fridgeEnigma = 190;
  const tvEnigma = 194;
  const boardSunEnigma = 203;
  const phoneEnigma = 204;
  const lockerOneEnigma = 206;
  const documentHolderEnigma = 209;
  const lockerTwoEnigma = 212;
  const boardEnigma = 216;
  const doorOne = 222;
  const doorTwo = 225;
  const doorThree = 226;
  const doorFour = 227;
  const doorFive = 228;
  const TabletTMS = 230;
  const ComputerRPS = 252;
  const DocOne = 255;
  const DocTwo = 256;
  const DocThree = 257;
  const DocFour = 258;

  const handleEnigma = () => {
    switch (enigma.content.id) {
      case fridgeEnigma:
        return <Fridge />;
      case tvEnigma:
        return <Television />;
      case boardSunEnigma:
        return <BoardSun />;
      case phoneEnigma:
        return <Phone />;
      case lockerOneEnigma:
        return <LockerOne objectContent={enigma} />;
      case documentHolderEnigma:
        return <DocumentHolder objectContent={enigma} />;
      case lockerTwoEnigma:
        return <LockerTwo objectContent={enigma} />;
      case boardEnigma:
        return <Board objectContent={enigma} />;
      case doorOne:
        return (
          <Door
            enigma={enigma.content}
            inventory={inventory}
            setInventory={setInventory}
            index={0}
            residents={residents}
            setResidents={setResidents}
          />
        );
      case doorTwo:
        return (
          <Door
            enigma={enigma.content}
            inventory={inventory}
            setInventory={setInventory}
            index={1}
            residents={residents}
            setResidents={setResidents}
          />
        );
      case doorThree:
        return (
          <Door
            enigma={enigma.content}
            inventory={inventory}
            setInventory={setInventory}
            index={2}
            residents={residents}
            setResidents={setResidents}
          />
        );
      case doorFour:
        return (
          <Door
            enigma={enigma.content}
            inventory={inventory}
            setInventory={setInventory}
            index={3}
            residents={residents}
            setResidents={setResidents}
          />
        );
      case doorFive:
        return (
          <Door
            enigma={enigma.content}
            inventory={inventory}
            setInventory={setInventory}
            index={4}
            residents={residents}
            setResidents={setResidents}
          />
        );
      case TabletTMS:
        return <Tablet objectContent={enigma.content} />;
      case ComputerRPS:
        return <Computer objectContent={enigma.content} dispatch={dispatch} />;
      case DocOne:
      case DocTwo:
      case DocThree:
      case DocFour:
        return (
          <HelpDoc
            objectContent={enigma.content}
            dispatch={dispatch}
            placeObject={placeObject}
            HandleDrop={HandleDrop}
            DocNumber={enigma.content.id}
          />
        );
      default:
        return null;
    }
  };
  if (enigma.content.id >= 255 && enigma.content.id <= 258) {
    if (
      (position === 'center' ||
        position === 'center-from-right' ||
        position === 'center-from-left') &&
      gameName === 'Bien dans son job'
    ) {
      return (
        <div className="ModalEnigma_RPS">
          <div className="ModalEnigma_blur_RPS" />

          <div className="ModalEnigma_description">
            <div className="ModalEnigma_description-contents">{handleEnigma()}</div>
            <button
              className="ButtonActionDoc ReturnDoc"
              type="button"
              onClick={() => {
                dispatch({
                  type: 'CLOSE_MODAL_ENIGMA',
                });
              }}
            >
              Retour
            </button>
          </div>
        </div>
      );
    }
    if (gameName === 'Bien dans son job') {
      if (modalEnigmaIsOpen) {
        dispatch({
          type: 'CLOSE_MODAL_ENIGMA',
        });
      }
      if (!modalEnigmaIsOpen) return null;
    }
  }

  return (
    <div className="ModalEnigma">
      <div className="ModalEnigma_blur" />

      <div className="ModalEnigma_description">
        <div className="ModalEnigma_description-contents">{handleEnigma()}</div>
        <button
          className="ButtonAction Return"
          type="button"
          onClick={() => {
            dispatch({
              type: 'CLOSE_MODAL_ENIGMA',
            });
          }}
        >
          Retour
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  enigma: state.Modal.modalEnigma.description,
  position: state.RPS.dataGame.position,
  gameName: state.game.title,
  modalEnigmaIsOpen: state.Modal.modalEnigma.isOpen,
});

ModalEnigma.propTypes = {
  HandleDrop: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  enigma: PropTypes.shape({
    content: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  inventory: PropTypes.arrayOf(
    PropTypes.shape({
      list: PropTypes.shape({
        useBy: PropTypes.arrayOf(PropTypes.number),
      }),
      isOpen: PropTypes.bool,
      id: PropTypes.number,
    })
  ),
  placeObject: PropTypes.arrayOf(
    PropTypes.shape({
      object: PropTypes.shape(PropTypes.objectOf(PropTypes.string)),
      key: PropTypes.number,
    })
  ),
  residents: PropTypes.arrayOf({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
    urlInfo: PropTypes.string,
    description: PropTypes.arrayOf(PropTypes.string),
    isOpen: PropTypes.bool,
    residentGoodObject: PropTypes.arrayOf(PropTypes.number),
    inventoryResident: PropTypes.arrayOf(PropTypes.number),
    isChecked: PropTypes.bool,
  }),
  setInventory: PropTypes.func,
  setResidents: PropTypes.func,
  position: PropTypes.string,
  gameName: PropTypes.string,
  modalEnigmaIsOpen: PropTypes.bool,
};

ModalEnigma.defaultProps = {
  HandleDrop: null,
  enigma: null,
  placeObject: null,
  setResidents: null,
  residents: null,
  setInventory: null,
  position: null,
  gameName: null,
  modalEnigmaIsOpen: null,
  inventory: null,
};

export default connect(mapStateToProps)(ModalEnigma);
