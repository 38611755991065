import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clock from '../../../../img/office/clock-office.svg';
import wrongobjects from '../WrongObjects.json';
import '../ModalWrongObject.css';
import '../Modals.css';
import ModalWrongObject from '../ModalWrongObject';

function Clock({ Modal, dispatch }) {
  const handleClick = () => {
    dispatch({
      type: 'MODAL_WRONGOBJECTS',
      payload: {
        modalType: 'modalClock404',
        showModal: true,
      },
    });
    dispatch({
      type: 'INCREMENT_COUNTER',
    });
  };

  return (
    <>
      {Modal.modalClock404 && (
        <ModalWrongObject
          title={wrongobjects.office.clock.title}
          message={wrongobjects.office.clock.message}
          closemodal={wrongobjects.office.clock.closemodal}
          img={clock}
        />
      )}
      <div>
        <div
          className="clock-office404"
          onClick={() => handleClick()}
          onKeyDown={() => handleClick()}
          tabIndex={0}
          role="button"
        >
          <img src={clock} alt="clock of the office" draggable="false" />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  Objects: state.Objects,
  Modal: state.WrongObjects.Modal,
});

Clock.propTypes = {
  dispatch: PropTypes.func.isRequired,
  WrongObjectsModal: PropTypes.shape({
    OfficeClock: PropTypes.bool.isRequired,
  }).isRequired,
  Modal: PropTypes.shape({
    modalClock404: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Clock);
