import '../../../../../assets/css/components/GameBoard/Obesity/Enigma/Phone/Facetime.css';

const Facetime = () => {
  return (
    <div className="Facetime">
      <p className="Facetime_title Facetime_firstTitle">Facetime</p>
      <div className="Facetime_top">
        <p className="Facetime_secondTitle">
          Vous ne pouvez pas utiliser Facetime pour le moment.
        </p>
      </div>
    </div>
  );
};

export default Facetime;
