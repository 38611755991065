import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// selectors
import {
  selectUser,
  selectParticipantWithoutMediator,
  selectCurrentUser,
} from 'components/Reducers/user';
import { selectQuestionnaryDebriefing } from 'components/Reducers/questionnary';

// utils
import { secondsToTime } from 'utils/utilityFunctions';

// components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';

// constants
import { secondsOfDebriefing } from 'components/GameBoard/ReusableComponents/Multi/constants';

// scss
import styles from 'components/GameBoard/ReusableComponents/Multi/Shared/Questionnary/Debriefing/Debriefing.module.scss';

const Debriefing = ({ setStatusCheckRoom }) => {
  const { t } = useTranslation('common');

  const { participants } = useSelector(selectUser);
  const participantsWithoutMediator = useSelector(selectParticipantWithoutMediator);
  const allQuestionsDebriefing = useSelector(selectQuestionnaryDebriefing);
  const currentUserId = useSelector(selectCurrentUser);
  const [index, setIndex] = useState(0);
  const [seconds, setSeconds] = useState(secondsOfDebriefing);

  const isAllUsersReplied =
    participantsWithoutMediator.filter(
      (participant) => !participants[participant].isQuestionnaryFinish
    ).length === 0;

  const nextQuestion = () => {
    if (index + 1 < allQuestionsDebriefing.length) {
      setIndex(index + 1);
    } else {
      setStatusCheckRoom('finish');
    }
  };

  useEffect(() => {
    let mainTime;

    if (isAllUsersReplied) {
      if (seconds > 0) {
        mainTime = setInterval(() => {
          setSeconds((second) => {
            const updatedSeconds = second - 1;
            return updatedSeconds;
          });
        }, 1000);
      }
    }
    return () => {
      clearInterval(mainTime);
    };
  }, [isAllUsersReplied, seconds]);

  const handleAnswer = (participant) => {
    let answer = '?';
    if (
      (currentUserId === participant && participants[participant].responses) ||
      isAllUsersReplied
    ) {
      answer = participants[participant].responses[index].answer.title;
    }
    return answer;
  };

  const handleFooter = () => {
    if (!seconds) {
      return (
        <Button
          onClick={() => nextQuestion()}
          title={t('buttonFunction.next')}
          buttonType="action"
        />
      );
    }
    if (isAllUsersReplied) {
      return (
        <div>
          <p>{t('multi.visio.discussion')}</p>
          <p className={styles.time}>{secondsToTime(seconds)}</p>
        </div>
      );
    }
    return <p>{t('multi.visio.wait')}</p>;
  };

  return (
    <div className={styles.debriefing}>
      <p>{t('multi.visio.titleDebriefing')}</p>
      <div className={styles.question}>{allQuestionsDebriefing[index].title}</div>
      {allQuestionsDebriefing[index]?.image && (
        <img
          className={styles['question-image']}
          src={allQuestionsDebriefing[index].image}
          alt="emotion react"
        />
      )}
      <div className={styles.participants}>
        {participantsWithoutMediator.map((participant) => (
          <div className={styles.participant} key={participant}>
            <p>{participants[participant].name}</p>
            <p>{handleAnswer(participant)}</p>
          </div>
        ))}
      </div>
      {handleFooter()}
    </div>
  );
};

Debriefing.propTypes = {
  setStatusCheckRoom: PropTypes.func.isRequired,
};

export default Debriefing;
