const initialState = {
  dataGame: {
    indexLastPatientLine: 2,
    indexPatient: 1,
    numberTotalPatientInLine: 0,
    pourcentStress: 0,
    numberStressAdded: 0,
    typeStress: '',
    isStarted: 0,
    win: false,
    time: 0,
    position: '',
    progression: 5,
  },
};

const RPS = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_STRESS': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          typeStress: action.payload.type,
          numberStressAdded: action.payload.stress,
          pourcentStress: state.dataGame.pourcentStress + action.payload.stress,
        },
      };
    }
    case 'INIT_NUMBER_STRESS': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          numberStressAdded: 0,
          typeStress: '',
        },
      };
    }
    // TODO see if we used it
    case 'INIT_GAME_ROOM': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          ...action.payload,
          background: null,
          points: 0,
          moves: 0,
        },
      };
    }
    case 'INTRO_IS_DONE': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          isStarted: 1,
        },
      };
    }
    case 'DECREASE_STRESS': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          pourcentStress: action.payload,
        },
      };
    }
    case 'RESET_STRESS': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          pourcentStress: 0,
        },
      };
    }
    case 'ADD_PROGRESSION': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          progression: state.dataGame.progression + 12.5,
        },
      };
    }
    case 'BACKGROUND_POSITION': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          position: action.payload,
        },
      };
    }

    case 'SET_PROGRESSION': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          progression: action.payload,
        },
      };
    }

    case 'LOWER_PROGRESSION': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          progression: state.dataGame.progression - 12.5,
        },
      };
    }

    case 'RESET_PROGRESSION': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          progression: 0,
        },
      };
    }
    case 'INIT_PATIENT': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          indexPatient: 1,
          indexLastPatientLine: 2,
          pourcentStress: 0,
        },
      };
    }
    case 'NEXT_PATIENT': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          indexPatient: state.dataGame.indexPatient + 1,
        },
      };
    }
    case 'SET_PATIENT_LINE': {
      let newPatientLine;

      if (state.dataGame.indexLastPatientLine === 10) {
        newPatientLine = 10;
      } else {
        newPatientLine = state.dataGame.indexLastPatientLine + 1;
      }
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          indexLastPatientLine: newPatientLine,
        },
      };
    }
    case 'SET_TOTAL_NUMBER_PATIENTS': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          numberTotalPatientInLine:
            state.dataGame.numberTotalPatientInLine <
            11 - state.dataGame.indexPatient
              ? state.dataGame.numberTotalPatientInLine + 1
              : 11 - state.dataGame.indexPatient,
        },
      };
    }
    case 'REMOVE_PATIENT': {
      return {
        ...state,
        dataGame: {
          ...state.dataGame,
          numberTotalPatientInLine:
            state.dataGame.numberTotalPatientInLine > 0
              ? state.dataGame.numberTotalPatientInLine - 1
              : 0,
        },
      };
    }
    default:
      return state;
  }
};

export default RPS;
