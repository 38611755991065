import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import TranslateKeyframe from 'assets/css/TranslateKeyframe';
import BaseAnimation from 'assets/css/BaseAnimation';
import PlutchikWheel from 'components/GameBoard/ReusableComponents/PlutchikWheel';
// Selector functions
import {
  selectComplexSituationEnigma,
  selectEmotionGenerationEnigma,
  selectStimulusAnswer,
} from 'components/Reducers/emotion';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
// Constants & utils
import { enigmas } from 'components/GameBoard/Emotion/Solo/constants';
import { calculatePoints, clickCount } from 'utils/utilityFunctions';
// CSS
import styled from 'styled-components';
import styles from 'components/GameBoard/Emotion/Solo/Enigma/PlutchikSidePanel.module.scss';
// Assets
import successSound from 'sound/Success.mp3';
import failSound from 'sound/fail.mp3';

// CSS in JS
const SidePanelStyle = styled(BaseAnimation)`
  animation-name: ${({ coordinates }) =>
    TranslateKeyframe(coordinates.startX, coordinates.endX)};
  display: ${({ isLoaded }) => !isLoaded && 'none'};
  width: ${({ sidePanelImageDimensions }) =>
    `max(35vw, ${sidePanelImageDimensions.width}px)`};
`;

const PlutchikSidePanel = ({ room }) => {
  const { t } = useTranslation('emotion');

  const {
    sidePanelUrl,
    rightAnswers,
    duration,
    timing,
    fillMode,
    basePoints,
    decreasePoints,
  } = enigmas[room];

  const [sidePanelImageDimensions, setSidePanelImageDimensions] = useState({
    height: 0,
    width: 0,
  });

  const dispatch = useDispatch();

  const { clickedPlutchikEmotion, stimulusIndex, stimulusPlayState } = useSelector(
    room === 'EmotionGeneration'
      ? selectEmotionGenerationEnigma
      : selectComplexSituationEnigma
  );
  const { soundtrack } = useSelector(infoGameUser);
  const answerState = useSelector((state) =>
    selectStimulusAnswer(state, stimulusIndex, room)
  );
  const [wrongEmotionCount, setWrongEmotionCount] = useState({});

  // State related to the Plutchik wheel
  const [mousedEmotion, setMousedEmotion] = useState({ name: '' });

  // Display the selected emotion or the emotion moused over
  const handleEmotionDisplay = () => {
    if (mousedEmotion.name) return t(`plutchikEmotions.${mousedEmotion?.name}`);
    if (clickedPlutchikEmotion.name)
      return t(`plutchikEmotions.${clickedPlutchikEmotion?.name}`);

    return '';
  };

  // Reset clickedPlutchikEmotion and resume the stimulus move
  useEffect(() => {
    if (room === 'EmotionGeneration') {
      if (answerState === 'right' && stimulusIndex !== rightAnswers.length - 1) {
        dispatch({
          type: 'SET_CLICKED_PLUTCHIK_EMOTION',
          payload: { room, clickedEmotion: {} },
        });
        dispatch({
          type: 'SET_STIMULI_ANIMATION',
          payload: { playState: 'running', room },
        });
        // Update the score
        dispatch({
          type: 'UPDATE_GAME_SCORE',
          payload: calculatePoints(
            basePoints,
            wrongEmotionCount[stimulusIndex] ?? 0,
            decreasePoints
          ),
        });
        if (soundtrack) new Audio(successSound).play();
      } else if (answerState === 'right') {
        if (soundtrack) new Audio(successSound).play();
      } else if (answerState === 'wrong') {
        if (soundtrack) new Audio(failSound).play();
      }
    } else if (answerState === 'right') {
      if (soundtrack) new Audio(successSound).play();
    } else if (answerState === 'wrong') {
      if (soundtrack) new Audio(failSound).play();
    }
  }, [
    answerState,
    basePoints,
    decreasePoints,
    dispatch,
    rightAnswers,
    room,
    stimulusIndex,
    wrongEmotionCount,
    soundtrack,
  ]);

  const coordinates = {
    startX: `${sidePanelImageDimensions.width}px`,
    endX: '0px',
  };
  return (
    <SidePanelStyle
      as="div"
      coordinates={coordinates}
      duration={duration}
      timingFunction={timing}
      iterationCount="1"
      fillMode={fillMode}
      playState="running"
      isLoaded={!!sidePanelImageDimensions.width}
      className={styles['side-panel']}
      sidePanelImageDimensions={sidePanelImageDimensions}
    >
      <img
        alt={t('panel')}
        src={sidePanelUrl}
        onLoad={(event) => {
          setSidePanelImageDimensions({
            width: event.target.width,
            height: event.target.height,
          });
        }}
      />
      <div className={styles['side-content']}>
        <p>{handleEmotionDisplay()}</p>
        <PlutchikWheel
          setOnMouseEmotion={setMousedEmotion}
          setSelectedEmotion={(event) => {
            if (stimulusPlayState === 'paused') {
              dispatch({
                type: 'SET_CLICKED_PLUTCHIK_EMOTION',
                payload: { room, clickedEmotion: event },
              });
              clickCount(dispatch, {});
              // Count the number of attempt to get the right answer
              if (answerState === 'wrong') {
                const newWrongEmotionCount = wrongEmotionCount[stimulusIndex]
                  ? wrongEmotionCount[stimulusIndex] + 1
                  : 1;
                setWrongEmotionCount((previousCount) => ({
                  ...previousCount,
                  [stimulusIndex]: newWrongEmotionCount,
                }));
              }
            }
          }}
          combinePetals
          primaryDyads={room === 'ComplexSituation'}
        />
        {/* Make the wheel unclickable after the right answer is found */}
        {answerState === 'right' && <div />}
      </div>
    </SidePanelStyle>
  );
};

PlutchikSidePanel.propTypes = {
  room: PropTypes.string.isRequired,
};

export default PlutchikSidePanel;
