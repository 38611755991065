import { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Constants
import { enigmas } from 'components/GameBoard/Emotion/Solo/constants';
// CSS
import styled, { css } from 'styled-components';
import styles from 'components/GameBoard/Emotion/Solo/Enigma/EmotionsPanel.module.scss';
import { useSelector } from 'react-redux';
import { selectAnswerState } from 'components/Reducers/emotion';
import { selectSelectedEmoticon } from 'components/Reducers/GameData/fetchObjects';
import organizeEmotionsData from 'components/GameBoard/Emotion/Solo/utilityFunctions';

import iphoneClick from 'sound/iphone-click.mp3';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';

const {
  initialRotation,
  rotationValue,
  cursorBackground,
  emotionsButtons,
  emotionsSelectedButton,
  pointer,
  lightsUrl,
} = enigmas.InterpretationMachineCalibration;

// CSS in JS
const Pointer = styled.button`
  transform: rotate(${({ rotation }) => `${rotation}deg`});
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}

  // To make the clickable zone bigger
  &:after {
    content: '';
    position: absolute;
    left: -22px;
    top: 12px;
    width: 53px;
    height: 54px;
  }
`;

const SelectedButtonStyle = styled.img`
  position: absolute;
  // hide image of the selected button when it is not selected
  ${({ emotionIndex, cursorIndex }) =>
    emotionIndex !== cursorIndex &&
    css`
      display: none;
    `}
`;

const EmotionsSelection = ({
  intensityIndex,
  buttonTitle,
  setCursorIndexes,
  cursorIndex,
}) => {
  const { t } = useTranslation('emotion');

  const { retry } = t('buttonFunction', {
    returnObjects: true,
    ns: 'common',
  });
  const plutchikEmotions = t('plutchikEmotions', {
    returnObjects: true,
  });

  // Selector functions
  const selectedEmoticon = useSelector(selectSelectedEmoticon);

  const answerState = useSelector((state) =>
    selectAnswerState(state, selectedEmoticon?.id, intensityIndex)
  );
  const { soundtrack } = useSelector(infoGameUser);

  /**
   * Calculate the rotation angle
   * @param {number} currentIndex
   * @returns {number}
   */
  const calculateRotation = (currentIndex) => {
    return initialRotation + currentIndex * rotationValue;
  };

  // Derived state
  const [cursorRotation, setCursorRotation] = useState(
    calculateRotation(cursorIndex)
  );

  const emotionsPerIntensity = organizeEmotionsData(plutchikEmotions);

  // Recalculate cursorRotation state
  useEffect(() => {
    setCursorRotation(calculateRotation(cursorIndex));
  }, [cursorIndex]);

  /**
   * Set the new cursor index
   */
  const handlePointerClick = () => {
    // 8 possible cursor state so after 7 we go back to 0
    const newCursorIndex = cursorIndex >= 7 ? 0 : cursorIndex + 1;
    setCursorIndexes((prevState) => ({
      ...prevState,
      [intensityIndex]: newCursorIndex,
    }));
    if (soundtrack) new Audio(iphoneClick).play();
  };

  return (
    <>
      <img
        src={lightsUrl[answerState]}
        alt={t('warningLight')}
        className={styles[`light-${intensityIndex}`]}
        id={intensityIndex}
      />
      <img
        className={styles[`normal-button-${intensityIndex}`]}
        alt={t('emotionsButton')}
        src={emotionsButtons}
      />
      <img
        alt={t('cursor')}
        src={cursorBackground}
        className={styles[`cursor-${intensityIndex}`]}
      />
      <Pointer
        type="button"
        disabled={buttonTitle === retry}
        className={styles[`pointer-${intensityIndex}`]}
        onClick={() => handlePointerClick()}
        rotation={cursorRotation}
      >
        <img alt={t('pointer')} src={pointer} />
      </Pointer>
      {/* Map the emotions names
          emotionsPerIntensity contains 1 array per emotion intensity
          each array contains the emotions names already sorted from the 1st
          selected when we start the enigma to the last, clockwise  */}
      {emotionsPerIntensity[intensityIndex].map((el, i) => (
        <Fragment key={el}>
          <p className={styles[`emotion-${intensityIndex}-${i}`]}>{el}</p>
          {/* Image of the selected button */}
          <SelectedButtonStyle
            src={emotionsSelectedButton}
            emotionIndex={i}
            intensityIndex={intensityIndex}
            cursorIndex={cursorIndex}
            className={styles[`selected-button-${intensityIndex}-${i}`]}
          />
        </Fragment>
      ))}
    </>
  );
};

EmotionsSelection.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  cursorIndex: PropTypes.number.isRequired,
  intensityIndex: PropTypes.number.isRequired,
  setCursorIndexes: PropTypes.func.isRequired,
};

export default EmotionsSelection;
