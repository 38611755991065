/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable prefer-object-spread */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import confetti from 'canvas-confetti';
import Audio from '../../ReusableComponents/Actions/Audio';
import successSound from '../../../../sound/success-card.mp3';
import '../../../../assets/css/components/GameBoard/TMS/Enigma/Door.css';
import Button from '../../ReusableComponents/Actions/Button';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Door({
  dispatch,
  inventory,
  index,
  residents,
  setInventory,
  setResidents,
  idSessionHasRoom,
  enigma,
  allObjects,
  soundtrack,
}) {
  const [intro, setIntro] = useState(false);
  const [intro2, setIntro2] = useState(false);
  const [showDetailChoice, setShowDetailChoice] = useState(null);
  const [openInfo, setOpenInfo] = useState(null);
  const [isError, setError] = useState(false);

  const [success, setSuccess] = useState(false);
  useEffect(() => {
    const duration = 1.5 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }
    if (success) {
      const interval = setInterval(function () {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 500 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.4), y: Math.random() - 0.2 },
          })
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.6, 0.9), y: Math.random() - 0.2 },
          })
        );
      }, 250);
    }
  }, [success]);

  const checkedObject = (collectObject, indexObject) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        isChecked: 1,
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index: indexObject,
            isChecked: 1,
            type: 'checked',
          },
        });
        dispatch({
          type: 'UPDATE_GAME_SCORE',
          payload: 100,
        });

        const isFinishRoom = residents.find(
          (residentElement) => !residentElement.isChecked
        );
        if (isFinishRoom === undefined) {
          setSuccess(true);
          setTimeout(() => {
            dispatch({ type: 'CLOSE_MODAL_ENIGMA' });
          }, 1000);
          setTimeout(() => {
            axios
              .put(
                `${process.env.REACT_APP_BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
                {
                  current_step: enigma.id_message,
                }
              )
              .then(() => {
                dispatch({
                  type: 'CURRENT_STEP',
                  payload: enigma.id_message,
                });
                dispatch({
                  type: 'START_MESSAGE_PREVENTION',
                });
              });
          }, 2000);
        }
      });
  };

  const handleIntroFinish = (indexResident) => {
    setIntro2(true);
    const newResidents = [...residents];
    newResidents[indexResident].isOpen = true;

    setResidents(newResidents);
  };

  // eslint-disable-next-line consistent-return
  const handleContent = () => {
    const isPadOpenOneTime = residents.find(
      (residentElement) => residentElement.isOpen
    );

    if (residents[index].isChecked) {
      return (
        <div className="Door_content-text-background">
          <p>Félicitations ! Vous avez trouvé tout le mobilier de cette chambre.</p>
          <Button
            buttonType="action"
            title="Retour"
            onClick={() => dispatch({ type: 'CLOSE_MODAL_ENIGMA' })}
          />
        </div>
      );
    }
    if (!intro && isPadOpenOneTime === undefined) {
      return (
        <div className="Door_content-text-background">
          <div className="Door_content-text">
            <h1>
              Voici la tablette vous permettant d&apos;équiper les chambres des
              résidents. Attention tous les équipements ne sont pas illimités et
              cela tombe bien, car les résidents ont des besoins spécifiques.
            </h1>
          </div>
          <Button
            buttonType="action"
            title="Suivant"
            onClick={() => setIntro(true)}
          />
        </div>
      );
    }

    if (!intro2 && !residents[index].isOpen) {
      return (
        <div className="Door_content-text-background">
          <img
            src={residents[index].url}
            alt={`Resident ${residents[index].name}`}
          />

          <p>
            Vous êtes dans la chambre de votre résident : {residents[index].name}
          </p>
          <br />
          {residents[index].description.map((residentDescription) => (
            <p key={residentDescription}>{residentDescription}</p>
          ))}
          <Button
            buttonType="action"
            title="Suivant"
            onClick={() => handleIntroFinish(index)}
          />
        </div>
      );
    }

    return (
      <div className="Door_content-objects">
        {inventory.map((inventoryContent) => {
          let elementObjectContent = {};
          // eslint-disable-next-line array-callback-return
          inventoryContent.list.filter((objectList) => {
            const isResident = objectList.usedBy.find(
              (residentChoice) => residentChoice === residents[index].id
            );

            if (isResident !== undefined) {
              elementObjectContent = objectList;
            }
          });

          if (Object.keys(elementObjectContent).length === 0) {
            return null;
          }

          return (
            <img
              key={elementObjectContent.id}
              className="Door_content-object"
              style={{
                top: elementObjectContent.top,
                width: elementObjectContent.width,
                left: elementObjectContent.left,
              }}
              alt="test"
              src={elementObjectContent.url}
            />
          );
        })}
      </div>
    );
  };

  const showDetail = (indexDetail, id) => {
    const newInventory = [...inventory];
    // eslint-disable-next-line array-callback-return
    newInventory.map((categoryContent, indexContent) => {
      if (categoryContent.id === id) {
        newInventory[indexContent].isOpen = !inventory[indexContent].isOpen;
      } else {
        newInventory[indexContent].isOpen = false;
      }
    });

    setInventory(newInventory);
  };

  const handleChoice = (detail) => {
    const newInventory = [...inventory];
    const newPatients = [
      ...newInventory[detail.indexInventory].list[detail.indexObject].usedBy,
    ];

    const newResidents = [...residents];
    const newResidentsInventory = [...residents[index].inventoryResident];
    if (detail.isActive === undefined) {
      newInventory[detail.indexInventory].list.filter(
        // eslint-disable-next-line array-callback-return
        (objectContent, indexObjectContent) => {
          if (indexObjectContent !== detail.indexObject) {
            const indexObject = newResidentsInventory.findIndex(
              (inventoryContentId) =>
                inventoryContentId ===
                newInventory[detail.indexInventory].list[indexObjectContent].id
            );

            const indexPatient = newInventory[detail.indexInventory].list[
              indexObjectContent
            ].usedBy.findIndex(
              (residentsContent) => residentsContent === residents[index].id
            );
            if (indexObject !== -1) {
              newResidentsInventory.splice(indexObject, 1);
            }
            newResidents[index].inventoryResident = [...newResidentsInventory];
            if (indexPatient !== -1) {
              newInventory[detail.indexInventory].list[
                indexObjectContent
              ].usedBy.splice(indexPatient, 1);
            }
          } else if (showDetailChoice.objectDisponibility > 0) {
            newResidentsInventory.push(detail.objectContent.id);
            newResidents[index].inventoryResident = [...newResidentsInventory];

            newPatients.push(residents[index].id);
            newInventory[detail.indexInventory].list[detail.indexObject].usedBy = [
              ...newPatients,
            ];
          }
        }
      );
    } else {
      const filterResidents = newPatients.filter(
        (residentContent) => residentContent !== residents[index].id
      );

      const filterInventoryResident = newResidents[index].inventoryResident.filter(
        (objectInventoryId) => objectInventoryId !== detail.objectContent.id
      );

      newResidents[index].inventoryResident = [...filterInventoryResident];

      newInventory[detail.indexInventory].list[detail.indexObject].usedBy = [
        ...filterResidents,
      ];
    }

    setResidents(newResidents);
    setInventory(newInventory);

    setShowDetailChoice(null);
  };

  const handleValidationObject = () => {
    const { residentGoodObject, inventoryResident } = residents[index];

    const sortResidentGoodObject = residentGoodObject.sort().toString();
    const sortInventoryResident = inventoryResident.sort().toString();
    const goodAnswer = sortResidentGoodObject === sortInventoryResident;

    if (goodAnswer) {
      const newResidents = [...residents];
      newResidents[index].isChecked = true;

      setResidents(newResidents);

      const doorIndex = allObjects.findIndex(
        (listContent) => listContent.id === enigma.id
      );

      checkedObject(enigma, doorIndex, 'not-message');
      setError(null);
    } else {
      setError(true);
    }
  };

  const handleInventory = () => {
    return (
      <>
        {showDetailChoice !== null && (
          <div className="Door_content-Toadd">
            <img
              src={showDetailChoice.objectContent.url}
              alt={showDetailChoice.objectContent.name}
            />
            <p>{showDetailChoice.objectContent.title}</p>
            <p>{showDetailChoice.objectContent.description}</p>
            <div className="Door_content-Toadd-Buttons">
              <Button
                buttonType="action"
                title={
                  showDetailChoice.isActive === undefined ? 'Ajouter' : 'Retirer'
                }
                onClick={() => handleChoice(showDetailChoice)}
              />
              <Button
                buttonType="return"
                title="Retour"
                onClick={() => setShowDetailChoice(null)}
              />
            </div>
          </div>
        )}
        <div className="Door_content-inventory">
          <h2>Inventaire</h2>
          {inventory.map((category, indexInventory) => (
            <div
              key={category.name}
              className="Door_content-inventory-content-category"
            >
              <div className="Door_content-inventory-content-category-title">
                <p>{category.name} </p>
                <button
                  type="button"
                  onClick={() => {
                    showDetail(indexInventory, category.id);
                    setError(null);
                  }}
                >
                  {category.isOpen ? '-' : '+'}
                </button>
              </div>
              {category.isOpen && (
                <div className="Door_content-inventory-content-category-elements">
                  {category.list.map((objectContent, indexObject) => {
                    const isActive = objectContent.usedBy.find(
                      (residentId) => residentId === residents[index].id
                    );

                    const objectDisponibility =
                      objectContent.count - objectContent.usedBy.length;

                    return (
                      <button
                        key={objectContent.id}
                        type="button"
                        className={`Door_content-inventory-content-category-element ${
                          isActive !== undefined ? '--active' : '--no-active'
                        }`}
                        onClick={() => {
                          setShowDetailChoice({
                            objectContent,
                            indexInventory,
                            indexObject,
                            isActive,
                            objectDisponibility,
                          });
                          setOpenInfo(null);
                        }}
                      >
                        <div className="Door_content-inventory-content-category-element-image">
                          <img src={objectContent.url} alt={objectContent.name} />
                          <p className="Door_content-inventory-content-category-element-disponibility">
                            {objectDisponibility}
                          </p>
                        </div>
                        <p className="Door_content-inventory-content-category-element-text">
                          {objectContent.title}
                        </p>
                      </button>
                    );
                  })}
                </div>
              )}
            </div>
          ))}
          <Button
            buttonType="action"
            title="Valider ma selection"
            onClick={() => {
              handleValidationObject();
              setOpenInfo(null);
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div className="Door Modal_description">
      <Audio condition={success && soundtrack} sound={successSound} />
      <div className="Door_content">
        {handleContent()}
        {residents[index].isOpen && !residents[index].isChecked && (
          <div className="Door_content-toolbar">
            <div className="Door_content-container">
              {handleInventory()}
              <div className="Door_content-resident">
                <button
                  type="button"
                  className="Door_content-resident-button"
                  onClick={() =>
                    openInfo === null ? setOpenInfo(index) : setOpenInfo(null)
                  }
                >
                  <img src={residents[index].urlInfo} alt={residents[index].name} />
                </button>
                {openInfo === index && (
                  <div className="Door_content-resident-info">
                    {residents[index].description.map((residentDescription) => (
                      <p key={residentDescription}>{residentDescription}</p>
                    ))}
                  </div>
                )}
                {isError && (
                  <div className="Door_content-resident-info">
                    <p>La combinaison n&apos;est pas bonne, veuillez réessayer</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  sessionId: state.GameUsers.idSession,
  soundtrack: state.GameUsers.soundtrack,
  allObjects: state.Objects.AllObjects,
  Room: state.Room,
});

Door.propTypes = {
  dispatch: PropTypes.func.isRequired,
  inventory: PropTypes.arrayOf(
    PropTypes.shape({
      list: PropTypes.shape({
        useBy: PropTypes.arrayOf(PropTypes.number),
      }),
      isOpen: PropTypes.bool,
      id: PropTypes.number,
    })
  ).isRequired,
  residents: PropTypes.arrayOf(
    PropTypes.shape({
      isChecked: PropTypes.number,
      isOpen: PropTypes.bool,
      name: PropTypes.string,
      url: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number,
      inventoryResident: PropTypes.arrayOf(PropTypes.number),
      residentGoodObject: PropTypes.bool,
      urlInfo: PropTypes.string,
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
  setInventory: PropTypes.func.isRequired,
  setResidents: PropTypes.func.isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  soundtrack: PropTypes.bool.isRequired,
  enigma: PropTypes.shape({
    id: PropTypes.number,
    id_message: PropTypes.number,
  }).isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
};

export default connect(mapStateToProps)(Door);
