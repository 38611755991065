/* eslint-disable react/self-closing-comp */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../../../assets/css/components/GameBoard/RPS/Enigma/Progression.css';

const Progression = ({ progression }) => {
  return (
    <div className="Progression-contents-right">
      <p>Progression</p>
      <div className="Progression-contents-right-bar">
        <div className="Progression-contents-right-outer">
          <div
            className="Progression-contents-right-inner"
            style={{
              width: `${progression}%`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  progression: state.RPS.dataGame.progression,
  inventory: state.Inventory,
});

Progression.propTypes = {
  progression: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(Progression);
