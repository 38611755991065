import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { putSessionsHasRoomHasObject } from 'API/sessionsHasRoomHasObject';
import { putSessionsHasRoom } from 'API/sessionsHasRoom';
// Components
import Button from 'components/GameBoard/ReusableComponents/Actions/Button';
import EmotionsSelection from 'components/GameBoard/Emotion/Solo/Enigma/EmotionsSelection';
import AudioComponent from 'components/GameBoard/ReusableComponents/Actions/Audio';

// Selector functions
import {
  selectEmoticonObjects,
  selectSelectedEmoticon,
  selectValidatedEmoticons,
  selectObjectIndex,
} from 'components/Reducers/GameData/fetchObjects';
import { selectSteps } from 'components/Reducers/Steps/Steps';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import {
  selectAnswersState,
  selectInterpretationMachineCalibrationEnigma,
} from 'components/Reducers/emotion';
// Style
import styles from 'components/GameBoard/Emotion/Solo/Enigma/EmotionsPanel.module.scss';
import styled from 'styled-components';
// Constant
import {
  enigmas,
  emotionIntensities,
} from 'components/GameBoard/Emotion/Solo/constants';
import startedMessage from 'components/constants';
// Assets
import successSound from 'sound/Success.mp3';
import failSound from 'sound/fail.mp3';
import { calculatePoints, clickCount } from 'utils/utilityFunctions';

// Constants
const {
  initialCursorIndexes,
  emotionsBackground,
  initialWrongCount,
  basePoints,
  decreasePoints,
} = enigmas.InterpretationMachineCalibration;

// CSS in JS - enable to use JS constants
const EmotionsPanelStyle = styled.div`
  background-image: url(${({ imageBackground }) => imageBackground});
  color: ${({ theme }) => theme.tealBlue};
`;

function EmotionsPanel() {
  // `t` is a function coming from the i18next library used to translate text
  /* Load the `common` namespace = the one containing translations that are common between games
  and the `emotion` one (see public-> locales -> fr-FR) */
  const { t } = useTranslation('emotion', 'common');

  // Access i18next arrays and objects
  const { next, validate, retry, close } = t('buttonFunction', {
    returnObjects: true,
    ns: 'common',
  });

  const dispatch = useDispatch();

  // Select from the store
  const preventionMessage = useSelector(selectSteps).list[0];
  const { idSessionHasRoom, soundtrack } = useSelector(infoGameUser);

  // Sorted emoticons objects
  const emoticons = useSelector(selectEmoticonObjects);

  // Validated emoticons
  const validatedEmoticons = useSelector(selectValidatedEmoticons);

  // Selected emoticon
  const selectedEmoticon = useSelector(selectSelectedEmoticon);
  const selectedEmoticonIndex = useSelector((state) =>
    selectObjectIndex(state, selectedEmoticon.id)
  );

  const enigma = useSelector(selectInterpretationMachineCalibrationEnigma);
  const { rightAnswers, userAnswers } = enigma[selectedEmoticon?.id];

  const answersState = useSelector((state) =>
    selectAnswersState(state, selectedEmoticon?.id)
  );
  const [wrongEmoticonCount, setWrongEmoticonCount] = useState(initialWrongCount);

  const defineButtonTitle = () => {
    let buttonTitle = '';
    if (answersState === 'wrong') {
      buttonTitle = retry;
    } else if (emoticons.length === validatedEmoticons.length) {
      buttonTitle = next;
    } else {
      buttonTitle = validate;
    }
    return buttonTitle;
  };
  const buttonTitle = defineButtonTitle();

  // Define states
  const [cursorIndexes, setCursorIndexes] = useState(initialCursorIndexes);

  useEffect(() => {
    if (answersState === 'right' && !selectedEmoticon?.isChecked) {
      dispatch({
        type: 'CLICKED_OBJECT',
        payload: {
          type: 'checked',
          index: selectedEmoticonIndex,
          isChecked: 1,
        },
      });
      putSessionsHasRoomHasObject(idSessionHasRoom, {
        object_id: selectedEmoticon.id,
        isChecked: 1,
      });
      // Update the score
      dispatch({
        type: 'UPDATE_GAME_SCORE',
        payload: calculatePoints(basePoints, wrongEmoticonCount, decreasePoints),
      });
      setWrongEmoticonCount(0);
    }
  }, [
    answersState,
    dispatch,
    idSessionHasRoom,
    selectedEmoticon.id,
    selectedEmoticon?.isChecked,
    selectedEmoticonIndex,
    wrongEmoticonCount,
  ]);

  useEffect(() => {
    if (answersState === 'wrong' && !selectedEmoticon?.isChecked) {
      if (soundtrack) new Audio(failSound).play();
      setWrongEmoticonCount((prevState) => prevState + 1);
    }
  }, [answersState, selectedEmoticon?.isChecked, soundtrack]);

  // // Set cursors position
  useEffect(() => {
    if (selectedEmoticon?.isChecked) {
      setCursorIndexes(rightAnswers);
    } else if (answersState === 'unanswered') {
      setCursorIndexes(initialCursorIndexes);
    } else {
      setCursorIndexes(userAnswers);
    }
  }, [dispatch, selectedEmoticon, rightAnswers, userAnswers, answersState]);

  /**
   * Handle the behaviour when the button Validate/Try again/Next
   * is clicked
   */
  const handleValidateClick = async (event) => {
    if (buttonTitle === validate) {
      dispatch({
        type: 'SET_USER_ANSWERS',
        payload: {
          emoticonId: selectedEmoticon.id,
          userAnswers: cursorIndexes,
        },
      });
      clickCount(dispatch, event);
    } else if (buttonTitle === next) {
      dispatch({
        type: 'CLOSE_MODAL_ENIGMA_EMOTION',
      });
      // Save in DB to retrieve the prevention message in case of reload
      await putSessionsHasRoom(idSessionHasRoom, {
        current_step: preventionMessage.id,
        start_message: startedMessage,
      });
      // Launch the prevention message
      dispatch({
        type: 'CURRENT_STEP',
        payload: preventionMessage.id,
      });
      dispatch({
        type: 'START_MESSAGE_PREVENTION',
      });
    } else {
      dispatch({
        type: 'RESET_USER_ANSWERS',
        payload: {
          emoticonId: selectedEmoticon.id,
        },
      });
      setCursorIndexes(initialCursorIndexes);
      clickCount(dispatch, event);
    }
  };

  const handleCloseClick = () => {
    // Same behaviour than when the user deselects an emoticon
    dispatch({
      type: 'CLICKED_OBJECT',
      payload: {
        index: selectedEmoticonIndex,
        isClicked: selectedEmoticon.isClicked + 1,
        type: 'clicked',
        status: 'background',
      },
    });
  };

  return (
    <EmotionsPanelStyle
      imageBackground={emotionsBackground}
      className={styles['emotion-panel']}
    >
      <AudioComponent
        condition={!!selectedEmoticon.isChecked && soundtrack}
        sound={successSound}
      />
      <AudioComponent
        condition={
          answersState === 'wrong' && !!selectedEmoticon?.isChecked && soundtrack
        }
        sound={failSound}
      />
      {emotionIntensities.map((intensity, intensityIndex) => (
        <EmotionsSelection
          intensityIndex={intensityIndex}
          buttonTitle={buttonTitle}
          setCursorIndexes={setCursorIndexes}
          cursorIndex={cursorIndexes[intensityIndex]}
          key={intensity}
        />
      ))}
      <img
        src={selectedEmoticon?.images.find((el) => el.type === 'big').image}
        alt={t('emoticon')}
        className={styles['emoticons-big']}
      />
      <Button
        onClick={(e) => handleValidateClick(e)}
        title={buttonTitle}
        buttonType="emotion"
        // Disable the button once the emoticon is validated but not when all the emoticons are validated
        disabled={
          !!selectedEmoticon.isChecked &&
          validatedEmoticons.length !== emoticons.length
        }
        additionalStyle={{
          gridArea: '2/1',
          alignSelf: 'end',
          fontSize: '1.5rem',
        }}
      />
      <Button
        onClick={() => handleCloseClick()}
        title={close}
        buttonType="emotion"
        additionalStyle={{
          gridArea: '3/1',
          alignSelf: 'start',
          fontSize: '1.5rem',
        }}
      />
    </EmotionsPanelStyle>
  );
}

export default EmotionsPanel;
