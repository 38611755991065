/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

// components
import Audio from 'components/GameBoard/ReusableComponents/Actions/Audio';
import Inventory from 'components/GameBoard/ReusableComponents/Inventory';
import Modal from 'components/GameBoard/ReusableComponents/Modal/Modal';
import Steps from 'components/GameBoard/ReusableComponents/Steps';
import ListObjects from 'components/GameBoard/ReusableComponents/Objects/ListObjects';
import Zoom from 'components/GameBoard/ReusableComponents/Modal/Zoom';
import ModalZoom from 'components/GameBoard/ReusableComponents/Modal/ModalZoom';
import ModalEnigma from 'components/GameBoard/ReusableComponents/Modal/ModalEnigma';
import ObjectInInventory from 'components/GameBoard/ReusableComponents/Actions/ObjectInInventory';
import Hud from 'components/GameBoard/ReusableComponents/HUD/Hud';
import ChatBot from 'components/GameBoard/ReusableComponents/ChatBot/ChatBot';
import stepsTMS from 'components/Joyride/StepsTMS';
import Intro from 'components/GameBoard/ReusableComponents/Intro';

// selector functions
import { selectSession } from 'components/Reducers/session';

// utils
import useInitGame from 'utils/useInitGame';
import { useStartMessageOrQuestionary } from 'utils/utilityFunctions';

// assets
import tmsTheme from '../../../../sound/TMS/pensees-your-lips.ogg';
import click from '../../../../sound/click-ongame.mp3';

// css
import 'assets/css/components/GameBoard/TMS/Rooms/ChangingRoomTMS.css';
import '../../GameBoard.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function ChangingRoom({
  GameUsers,
  modalIsOpen,
  allObjects,
  currentRoom,
  dispatch,
  startMessage,
  currentStep,
  startQuestionnary,
  idSessionHasRoom,
  zoomIsOpen,
  modalZoomIsOpen,
  count,
  modalEnigmaIsOpen,
  listRooms,
  MouseClicked,
  isScoreBoard,
  isTutoFinish,
  isStarted,
  score,
}) {
  const [stepGame, setStepGame] = useState({
    steps: stepsTMS,
    stepIndex: 0,
  });

  const session = useSelector(selectSession);

  const callback = (data) => {
    const { action, index, type, status } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // const stepsOpenInformation = 6;

      // if (index === stepsOpenInformation) {
      //   setIsInformationOpen(!isInformationOpen);
      // }
      setStepGame({
        ...stepGame,
        stepIndex: index + (action === ACTIONS.NEXT ? 1 : -1),
      });
    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED, STATUS.FINISHED].includes(status)
    ) {
      // Need to set our running state to false, so we can restart if we click start again.
      setStepGame({
        ...stepGame,
        run: false,
      });

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/sessions/${session.id}/modification`,
          {
            isTutoFinish: 1,
          }
        )
        .then(() => {
          dispatch({ type: 'RESET_TUTO', payload: 1 });
        });
    }
    if (action === 'reset' || action === 'close') {
      setStepGame({
        ...stepGame,
        run: false,
      });
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/sessions/${session.id}/modification`,
          {
            isTutoFinish: 1,
          }
        )
        .then(() => {
          dispatch({ type: 'RESET_TUTO', payload: 1 });
        });
    }
  };

  useInitGame(currentRoom, session.id);

  // COUNTER
  useEffect(() => {
    if (idSessionHasRoom) {
      axios.put(
        `${BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
        {
          count,
          score,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useStartMessageOrQuestionary(
    currentStep,
    idSessionHasRoom,
    startMessage,
    startQuestionnary
  );

  const changeStatusOfObject = (objectElement) => {
    const objectIndex = allObjects.findIndex(
      (objectContent) => objectContent.id === objectElement.id
    );
    dispatch({
      type: 'CLICKED_OBJECT',
      payload: {
        index: objectIndex,
        type: 'clicked',
        status: 'open',
      },
    });
  };

  const [displayLocker3, setDisplayLocker3] = useState(true);
  const [displayLocker4, setDisplayLocker4] = useState(true);
  const idLocker3 = 234;
  const idLocker4 = 235;
  const locker3Object = allObjects.filter(
    (objectElement) => objectElement.id === idLocker3
  );
  const locker4Object = allObjects.filter(
    (objectElement) => objectElement.id === idLocker4
  );

  const handleLocker3 = () => {
    setDisplayLocker3(false);
    changeStatusOfObject(locker3Object[0]);
  };
  const handleLocker4 = () => {
    setDisplayLocker4(false);
    changeStatusOfObject(locker4Object[0]);
  };

  const clickOnGame = () => {
    dispatch({ type: 'INCREMENT_COUNTER' });
  };

  useEffect(() => {
    dispatch({
      type: 'CLICKED_MOUSE_DOWN',
    });
    setTimeout(() => {
      dispatch({
        type: 'CLICKED_MOUSE_UP',
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GameUsers.count]);

  const updateMousePosition = (ev) => {
    const offset = document
      .querySelector('#ChangingRoom-BKG')
      .getBoundingClientRect();
    dispatch({
      type: 'PLACE_CURSOR',
      payload: {
        x: ev.pageX - offset.left,
        y: ev.pageY - offset.top,
      },
    });
  };

  useEffect(() => {
    document
      .getElementById('ChangingRoom-BKG')
      .addEventListener('mouseup', updateMousePosition);
    return () => window.removeEventListener('mouseup', updateMousePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const urlRoom = listRooms.ChangingRoomTMS.url;
  return (
    <div className="ChangingRoom WelcomeRPS" id="ChangingRoom-BKG">
      <Audio sound={tmsTheme} loop id="TMS-Theme" muted={!GameUsers.music} />
      <Audio sound={click} condition={!MouseClicked && GameUsers.soundtrack} />
      <div
        className={MouseClicked ? 'cursor-clicked' : 'Hide'}
        style={{ top: GameUsers.cursor.y, left: GameUsers.cursor.x }}
      >
        <p>+1</p>
      </div>
      {!isScoreBoard && (
        <header>
          <Inventory />
          <Steps />
          <Hud />
        </header>
      )}
      {!isTutoFinish && (
        <Joyride
          callback={callback}
          steps={stepGame.steps}
          run={stepGame.run}
          stepIndex={stepGame.stepIndex}
          showSkipButton
          continuous
          disableCloseOnEsc
          disableOverlayClose
          hideBackButton
          hideCloseButton
          locale={{
            close: 'Fermer',
            last: 'Commencer',
            next: 'Suivant',
            skip: 'Passer',
          }}
          styles={{
            options: {
              overlayColor: 'rgba(0,0,0, 0.5)',
              arrowColor: '#fff',
            },
            buttonNext: {
              backgroundColor: '#4ed199',
              border: '1px solid #48c48e',
              padding: '15px 25px',
              fontWeight: '700',
            },
            buttonBack: {
              marginRight: 10,
              color: '#000',
              border: '1px solid #9fa2b4',
              backgroundColor: '#fff',
            },
            buttonLast: {
              marginRight: 20,
            },
            buttonSkip: {
              color: '#9fa2b4',
              fontWeight: '700',
            },
          }}
        />
      )}
      <div
        className="ChangingRoom-img-div"
        onClick={() => clickOnGame()}
        role="presentation"
      >
        <img
          className="ChangingRoom_background"
          src={urlRoom}
          alt="Fond TMS"
          draggable="false"
        />
        <div
          className={displayLocker3 ? 'ChangingRoom-locker3' : 'Hide'}
          onClick={() => handleLocker3()}
          role="presentation"
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fcasiers3-4%2Fcasier3-ferm%C3%A9.svg?alt=media&token=067bedfd-103a-4951-bb4a-7802106480d1"
            alt="locker3"
          />
        </div>
        <div
          className={displayLocker4 ? 'ChangingRoom-locker4' : 'Hide'}
          onClick={() => handleLocker4()}
          role="presentation"
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/Digital%20Room%2FTMS%2Fassets%2Frooms%2Fvestiaires%2Fobjects%2Fcasiers3-4%2Fcasier4-ferm%C3%A9.svg?alt=media&token=6bac96e7-aae4-435e-bc1e-0bc655408d30"
            alt="locker3"
          />
        </div>
      </div>
      {modalIsOpen && <Modal />}
      {modalZoomIsOpen && <ModalZoom />}
      {zoomIsOpen && <Zoom />}
      {modalEnigmaIsOpen && <ModalEnigma />}
      {!isStarted && isTutoFinish && (
        <Intro translationFileName="tms" modalAfterIntro />
      )}
      <ObjectInInventory />
      <ListObjects />
      <ChatBot />
    </div>
  );
}

const mapStateToProps = (state) => ({
  modalIsOpen: state.Modal.modal.isOpen,
  currentRoom: state.Room.currentRoomId,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  GameUsers: state.GameUsers,
  startMessage: state.Steps.startMessage,
  currentStep: state.Steps.currentStep,
  startQuestionnary: state.Steps.startQuestionnary,
  zoomIsOpen: state.Modal.zoom.isOpen,
  modalZoomIsOpen: state.Modal.modalZoom.isOpen,
  allObjects: state.Objects.AllObjects,
  count: state.GameUsers.count,
  score: state.GameUsers.gameScore.present,
  modalEnigmaIsOpen: state.Modal.modalEnigma.isOpen,
  listRooms: state.Room.listRooms,
  MouseClicked: state.GameUsers.clicked,
  isScoreBoard: state.Steps.isScoreBoard,
  isTutoFinish: state.Steps.isTutoFinish,
  isStarted: state.GameUsers.gameStarted,
});

ChangingRoom.propTypes = {
  MouseClicked: PropTypes.bool.isRequired,
  GameUsers: PropTypes.shape({
    soundtrack: PropTypes.bool,
    music: PropTypes.bool,
    count: PropTypes.number,
    cursor: PropTypes.arrayOf({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }).isRequired,
  allObjects: PropTypes.arrayOf({
    id: PropTypes.number,
  }).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  currentRoom: PropTypes.number.isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  startMessage: PropTypes.bool.isRequired,
  currentStep: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  startQuestionnary: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  zoomIsOpen: PropTypes.bool.isRequired,
  modalZoomIsOpen: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
  modalEnigmaIsOpen: PropTypes.bool.isRequired,
  listRooms: PropTypes.shape({
    ChangingRoomTMS: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  isScoreBoard: PropTypes.bool.isRequired,
  isTutoFinish: PropTypes.number.isRequired,
  isStarted: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(ChangingRoom);
