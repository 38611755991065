import { useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import fetchMessage, { fetchObject } from '../../Action';
import Bureau from './Bureau';
import Dashboard from './DashBoard/DashBoard';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Game({ dispatch }) {
  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/messages`)
      .then((res) => {
        dispatch(fetchMessage(res.data));
      })
      .catch((error) => {
        throw error;
      });

    axios
      .get(`${BASE_URL}/api/objects`)
      .then((res) => {
        dispatch(fetchObject(res.data));
      })
      .catch((error) => {
        throw error;
      });
  }, [dispatch]);

  return (
    <div className="GameWindow404">
      <Dashboard />
      <Bureau />
    </div>
  );
}

const mapStateToProps = (state) => ({
  Room: state.Room,
});

Game.propTypes = {
  dispatch: PropTypes.func.isRequired,
  Room: PropTypes.shape({
    DesktopV2: PropTypes.shape({
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
    Bathroom: PropTypes.shape({
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
    LivingRoom: PropTypes.shape({
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Game);
