const fetchMessage = (messages) => {
  return {
    type: 'FETCH_MESSAGE',
    messages,
  };
};

export const fetchRoom = (payload) => {
  return {
    type: 'FETCH_ROOM',
    payload,
  };
};

export const fetchRooms = (rooms, room) => {
  return {
    type: 'FETCH_ROOMS',
    payload: { rooms, room },
  };
};

export const fetchObjectVaccination = (payload) => {
  return {
    type: 'FETCH_OBJECT_VACC',
    payload,
  };
};

export const notifMessage = (payload) => {
  return {
    type: 'MESSAGE_NOTIF',
    payload,
  };
};

export const fetchObject = (objects) => {
  return {
    type: 'FETCH_OBJECT',
    objects,
  };
};

export const addObject = (payload) => {
  return {
    type: 'ADD_OBJECT',
    payload,
  };
};

export const showMessage = (payload) => {
  return {
    type: 'SHOW_MESSAGE',
    payload,
  };
};

export const counterClick = () => {
  return {
    type: 'INCREMENT_COUNTER',
  };
};

export const setUserSurvey = (
  userId,
  token,
  participant,
  playerInfo,
  userType,
  sessionId,
  networkError,
  haveChrome,
  roomName
) => {
  return {
    type: 'SET_USER_SURVEY',
    userId,
    token,
    participant,
    playerInfo,
    userType,
    sessionId,
    networkError,
    haveChrome,
    roomName,
  };
};

export const setSessionName = (roomName, gameId, idSession) => {
  return {
    type: 'SET_SESSION_NAME',
    roomName,
    gameId,
    idSession,
  };
};

export const resetUserAction = () => {
  return {
    type: 'RESET_CLICK_ACTION',
  };
};

export const clickedUsed = () => {
  return {
    type: 'CLICK_USED',
  };
};

export const Logout = () => {
  return {
    type: 'SIGNOUT_REQUEST',
  };
};

export const drawerButton1Click = () => {
  return {
    type: 'INCREMENT_COUNTER1',
  };
};

export const drawerButton2Click = () => {
  return {
    type: 'INCREMENT_COUNTER2',
  };
};

export const drawerButton3Click = () => {
  return {
    type: 'INCREMENT_COUNTER3',
  };
};
export const drawerButton4Click = () => {
  return {
    type: 'INCREMENT_COUNTER4',
  };
};

export const drawerButton1Reset = () => {
  return {
    type: 'RESET_COUNTER1',
  };
};

export const drawerButton2Reset = () => {
  return {
    type: 'RESET_COUNTER2',
  };
};

export const drawerButton3Reset = () => {
  return {
    type: 'RESET_COUNTER3',
  };
};

export const drawerButton4Reset = () => {
  return {
    type: 'RESET_COUNTER4',
  };
};

export const chestButton1Click = () => {
  return {
    type: 'INCREMENT_CHEST_COUNTER1',
  };
};

export const chestButton2Click = () => {
  return {
    type: 'INCREMENT_CHEST_COUNTER2',
  };
};

export const chestButton3Click = () => {
  return {
    type: 'INCREMENT_CHEST_COUNTER3',
  };
};

export const chestButton4Click = () => {
  return {
    type: 'INCREMENT_CHEST_COUNTER4',
  };
};

export const chestButton1Reset = () => {
  return {
    type: 'RESET_CHEST_COUNTER1',
  };
};

export const chestButton2Reset = () => {
  return {
    type: 'RESET_CHEST_COUNTER2',
  };
};

export const chestButton3Reset = () => {
  return {
    type: 'RESET_CHEST_COUNTER3',
  };
};

export const chestButton4Reset = () => {
  return {
    type: 'RESET_CHEST_COUNTER4',
  };
};

export const boardPhoto1Click = () => {
  return {
    type: 'INCREMENT_BOARD_COUNTER1',
  };
};

export const boardPhoto2Click = () => {
  return {
    type: 'INCREMENT_BOARD_COUNTER2',
  };
};

export const boardPhoto3Click = () => {
  return {
    type: 'INCREMENT_BOARD_COUNTER3',
  };
};

export const boardPhoto4Click = () => {
  return {
    type: 'INCREMENT_BOARD_COUNTER4',
  };
};

export const boardPhoto1Reset = () => {
  return {
    type: 'RESET_BOARD_COUNTER1',
  };
};

export const boardPhoto2Reset = () => {
  return {
    type: 'RESET_BOARD_COUNTER2',
  };
};

export const boardPhoto3Reset = () => {
  return {
    type: 'RESET_BOARD_COUNTER3',
  };
};

export const boardPhoto4Reset = () => {
  return {
    type: 'RESET_BOARD_COUNTER4',
  };
};

export const phoneOn = (payload) => {
  return {
    type: 'TURN_ON_PHONE',
    payload,
  };
};

export const phoneGoodNumber = (payload) => {
  return {
    type: 'ENTER_GOOD_NUMBER',
    payload,
  };
};

export const addNumberPhone = (payload) => {
  return {
    type: 'ADD_PHONE_DIGIT',
    payload,
  };
};

export const removeLastNumberOfPhone = () => {
  return {
    type: 'REMOVE_PHONE_DIGIT',
  };
};

export const resetPhoneNumber = () => {
  return {
    type: 'RESET_PHONE_NUMBER',
  };
};

export const turnOnTablet = () => {
  return {
    type: 'TURN_ON_TABLET',
  };
};

export const tabletMessageShown = () => {
  return {
    type: 'TABLET_MESSAGE_SHOWN',
  };
};

export const changeColorButton = (payload) => {
  return {
    type: 'CHANGE_COLOR_BUTTON',
    payload,
  };
};

export const fetchSteps = (payload) => {
  return {
    type: 'ALL_STEPS',
    payload,
  };
};

export const fetchMessagesOfGame = (payload) => {
  return {
    type: 'ALL_MESSAGES_OF_GAME',
    payload,
  };
};

export const changeCombinaisonTopDresser = (payload) => {
  return {
    type: 'TOP_DRESSER_COMBINAISON',
    payload,
  };
};

// MULTI VISIO PARTICIPANT
export const setMainStream = (stream) => {
  return {
    type: 'SET_MAIN_STREAM',
    payload: {
      mainStream: stream,
    },
  };
};

export const setUserVisio = (user) => {
  return {
    type: 'SET_USER_VISIO',
    payload: {
      currentUser: user,
    },
  };
};

export const addParticipant = (user) => {
  return {
    type: 'ADD_PARTICIPANT',
    payload: {
      newUser: user,
    },
  };
};

export const updateUserVisio = (user) => {
  return {
    type: 'UPDATE_USER_VISIO',
    payload: {
      currentUser: user,
    },
  };
};

export const updateParticipant = (user) => {
  return {
    type: 'UPDATE_PARTICIPANT',
    payload: {
      newUser: user,
    },
  };
};

export const removeParticipant = (userId) => {
  return {
    type: 'REMOVE_PARTICIPANT',
    payload: {
      id: userId,
    },
  };
};

export const updateUser = (userDescription) => {
  return {
    type: 'UPDATE_USER',
    payload: userDescription,
  };
};

export const retrieveCurrentQuestion = (questionId) => {
  return {
    type: 'CURRENT_QUESTION',
    payload: questionId,
  };
};

export const addResponsesUserVisio = (payload) => {
  return {
    type: 'ADD_RESPONSES_USER_VISIO',
    payload,
  };
};

export const updateStatusQuestionnaryOfUser = (payload) => {
  return {
    type: 'UPDATE_STATUS_QUESTIONNARY_OF_USER',
    payload,
  };
};

export default fetchMessage;
