import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import woosh from '../../../../sound/woosh.mp3';
import Audio from './Audio';

const ObjectInInventory = ({ inventory, soundtrack }) => {
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    if (inventory.datas.count > 0) {
      setDisplay(true);
      setTimeout(() => {
        setDisplay(false);
      }, 1000);
    }
  }, [inventory.datas.count]);
  if (display) {
    return (
      <div className="ObjectInInventory">
        <Audio condition={display && soundtrack} sound={woosh} />
        <img src={inventory.datas.image} alt="Objet ajouté à l'inventaire" />
      </div>
    );
  }
  return null;
};

ObjectInInventory.propTypes = {
  inventory: PropTypes.shape({
    datas: PropTypes.shape({
      count: PropTypes.number,
      image: PropTypes.string,
    }),
  }).isRequired,
  soundtrack: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  inventory: state.Inventory,
  soundtrack: state.GameUsers.soundtrack,
});

export default connect(mapStateToProps)(ObjectInInventory);
