import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TimeFormat from 'hh-mm-ss';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Timer({ timerPause, dispatch, idSessionHasRoom, count, gameScore }) {
  let mainTime;
  const [seconds, setSeconds] = useState(0);

  const display = TimeFormat.fromS(seconds, 'hh:mm:ss');

  const [h, m, s] = display.split(':');

  const stopTimer = () => {
    clearInterval(mainTime);
  };

  const tick = () => {
    setSeconds((second) => {
      const updatedSeconds = second + 1;
      if (updatedSeconds < 1) {
        stopTimer();
      }
      return updatedSeconds;
    });
  };

  const startTime = () => {
    if (seconds >= 0) {
      mainTime = setInterval(tick, 1000);
    }
  };

  useEffect(() => {
    if (idSessionHasRoom) {
      axios
        .get(`${BASE_URL}/api/sessionsHasRoom/id/${idSessionHasRoom}`)
        .then((res) => {
          let timeOfDatabase = res.data.time;
          const scoreOfDatabase = res.data.score;
          const countOfDatabase = res.data.count;

          dispatch({
            type: 'SET_GAME_TIME',
            payload: timeOfDatabase,
          });

          if (timeOfDatabase === null) {
            timeOfDatabase = 0;
          }
          setSeconds(timeOfDatabase);

          dispatch({
            type: 'SET_SCORE',
            payload: scoreOfDatabase,
          });

          dispatch({
            type: 'SET_COUNT',
            payload: countOfDatabase,
          });
        });
    }
  }, [dispatch, idSessionHasRoom]);

  useEffect(() => {
    if (!timerPause) {
      startTime();
    }
    return () => {
      stopTimer();
    };
    // TO REFACTO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerPause, idSessionHasRoom]);

  useEffect(() => {
    // update in the database the score / time / count for refresh

    if (idSessionHasRoom) {
      axios.put(
        `${BASE_URL}/api/sessionsHasRoom/${idSessionHasRoom}/modifications`,
        {
          time: seconds,
          count,
          score: gameScore,
        }
      );
      const displayHour = TimeFormat.fromS(seconds, 'hh:mm:ss');
      const [hour, minute, second] = displayHour.split(':');
      dispatch({
        type: 'SET_GAME_TIME',
        payload: `${hour}:${minute}:${second}`,
      });
    }
    // TO REFACTO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, gameScore]);

  return (
    <p>
      {h !== '00' && `${h} : `} {m} : {s}
    </p>
  );
}

Timer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  timerPause: PropTypes.bool.isRequired,
  idSessionHasRoom: PropTypes.number,
  count: PropTypes.number,
  gameScore: PropTypes.number.isRequired,
};

Timer.defaultProps = {
  idSessionHasRoom: null,
  count: null,
};

const mapStateToProps = (state) => ({
  timerPause: state.GameUsers.timerPause,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  count: state.GameUsers.count,
  gameScore: state.GameUsers.gameScore.present,
});

export default connect(mapStateToProps)(Timer);
