import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { putSessionsHasRoom } from 'API/sessionsHasRoom';

// Components
import NarratorTextHeader from 'components/GameBoard/Emotion/Solo/Enigma/NarratorTextHeader';
import FooterPanel from 'components/GameBoard/Emotion/Solo/Enigma/FooterPanel';
import PlutchikSidePanel from 'components/GameBoard/Emotion/Solo/Enigma/PlutchikSidePanel';
import BehaviourPanel from 'components/GameBoard/Emotion/Solo/Enigma/BehaviourPanel';
// Reducer functions
import {
  selectEmotion,
  selectComplexSituationEnigma,
  selectStimulusAnswer,
  selectReactionAnswer,
} from 'components/Reducers/emotion';
import { infoGameUser } from 'components/Reducers/GameData/GameUsers';
import { selectSteps } from 'components/Reducers/Steps/Steps';

// CSS
import styles from 'components/GameBoard/Emotion/Solo/Enigma/ComplexSituation.module.scss';
// Constants
import startedMessage from 'components/constants';
import { stimulusUrl, enigmas } from 'components/GameBoard/Emotion/Solo/constants';

import {
  StimulusStyle,
  ConveyorBeltStyle,
} from 'components/GameBoard/Emotion/Solo/Enigma/EmotionGeneration';

const {
  initialStimulusTranslationX,
  initialStimulusTranslationY,
  finalStimulusTranslation,
  initialBeltTranslationX,
  initialBeltTranslationY,
  stimulusDuration,
  timing,
  fillMode,
  conveyorBeltUrl,
} = enigmas.EmotionGeneration;

const {
  footerStyle,
  initialStimulus2TranslationX,
  finalStimulus2TranslationX,
  initialRightBeltTranslationX,
  narratorTextHeaderStyle,
  step1,
  step2,
} = enigmas.ComplexSituation;

const ComplexSituation = () => {
  const { t } = useTranslation('emotion');
  // Access the i18next object about the messages displayed at the top
  const messages = t('enigma4.rules.messages', {
    returnObjects: true,
  });
  const stimuli = t('enigma4.stimuli', {
    returnObjects: true,
  });

  const { backgroundDimensions, isBackgroundMoving } = useSelector(selectEmotion);
  const {
    stimulusIndex,
    stimulusPlayState,
    stimulus2PlayState,
    shuffledStimuli,
    enigmaStep,
    messageStatus,
    energyPercentage,
    clickedReaction,
    clickedPlutchikEmotion,
  } = useSelector(selectComplexSituationEnigma);
  const stimulusAnswerState = useSelector((state) =>
    selectStimulusAnswer(state, stimulusIndex, 'ComplexSituation')
  );
  const reactionAnswerState = useSelector((state) => selectReactionAnswer(state));
  const { idSessionHasRoom } = useSelector(infoGameUser);
  const preventionMessage = useSelector(selectSteps).list[0];

  const dispatch = useDispatch();

  const narratorTextRef = useRef();
  // Define variables used for the translation of the stimulus object
  const screenWidth = window.innerWidth;
  const imgWidth = backgroundDimensions.width;
  const imgHeight = backgroundDimensions.height;
  const initialPositionX = (initialStimulusTranslationX / 100) * imgWidth;
  const initialStimulus2PositionX = (initialStimulus2TranslationX / 100) * imgWidth;
  const initialBeltPositionX = (initialBeltTranslationX / 100) * imgWidth;
  const initialRightBeltPositionX = (initialRightBeltTranslationX / 100) * imgWidth;
  const endPositionX = (finalStimulusTranslation / 100) * imgWidth;
  const endStimulus2PositionX = (finalStimulus2TranslationX / 100) * imgWidth;
  const initialPositionY = (initialStimulusTranslationY / 100) * imgHeight;
  const initialBeltPositionY = (initialBeltTranslationY / 100) * imgHeight;

  const stimulusCoordinates = {
    startX: '0px',
    endX: `${screenWidth - endPositionX - (screenWidth - initialPositionX)}px`,
  };
  const stimulus2Coordinates = {
    startX: '0px',
    endX: `${
      screenWidth -
      endStimulus2PositionX -
      (screenWidth - initialStimulus2PositionX)
    }px`,
  };

  const stimulusFinishedMoving =
    enigmaStep === step1 && stimulusPlayState === 'paused';
  const isEnergyEmpty = energyPercentage <= 0;
  const isTheLastStimulus = stimulusIndex === shuffledStimuli.length - 1;
  const isGameEnded = isEnergyEmpty || isTheLastStimulus;

  const wrongEmotionText =
    'Oups... Il semblerait que cette émotion ne soit pas générée par ce type de stimulus.';

  // Set narrator message
  useEffect(() => {
    const stimulus2Moving =
      enigmaStep === step2 && stimulus2PlayState === 'running';
    const stimulus2FinishedMoving =
      enigmaStep === step2 && stimulus2PlayState === 'paused';

    if (stimulusAnswerState !== 'unanswered') {
      if (
        narratorTextRef.current.innerText === wrongEmotionText &&
        stimulusAnswerState === 'wrong'
      ) {
        dispatch({
          type: 'SET_MESSAGE_STATUS',
          payload: `${stimulusAnswerState}-emotion-2`,
        });
      } else {
        dispatch({
          type: 'SET_MESSAGE_STATUS',
          payload: `${stimulusAnswerState}-emotion`,
        });
      }
    } else if (isEnergyEmpty) {
      dispatch({ type: 'SET_MESSAGE_STATUS', payload: 'no-energy' });
    } else if (isTheLastStimulus && clickedReaction?.id) {
      dispatch({ type: 'SET_MESSAGE_STATUS', payload: 'no-more-stimulus' });
    } else if (reactionAnswerState !== 'unanswered') {
      dispatch({
        type: 'SET_MESSAGE_STATUS',
        payload: `${reactionAnswerState}-reaction`,
      });
    } else if (stimulus2FinishedMoving) {
      dispatch({ type: 'SET_MESSAGE_STATUS', payload: 'reaction-intro' });
    } else if (stimulusFinishedMoving) {
      dispatch({
        type: 'SET_MESSAGE_STATUS',
        payload: 'emotion-selection',
      });
    } else if (stimulus2Moving) {
      dispatch({
        type: 'SET_MESSAGE_STATUS',
        payload: 'stimulus-move',
      });
    } else {
      dispatch({
        type: 'SET_MESSAGE_STATUS',
        payload: 'stimulus-creation',
      });
    }
  }, [
    clickedReaction?.id,
    dispatch,
    enigmaStep,
    isEnergyEmpty,
    isTheLastStimulus,
    reactionAnswerState,
    clickedPlutchikEmotion,
    stimulus2PlayState,
    stimulusAnswerState,
    stimulusFinishedMoving,
    stimulusIndex,
  ]);

  const nextSituation = async () => {
    // End the game if there is no more energy or if we reached the last situation
    if (isGameEnded) {
      dispatch({
        type: 'CLOSE_MODAL_ENIGMA_EMOTION',
      });
      // Save in DB to retrieve the prevention message in case of reload
      await putSessionsHasRoom(idSessionHasRoom, {
        current_step: preventionMessage.id,
        start_message: startedMessage,
      });
      // Launch the prevention message
      dispatch({
        type: 'CURRENT_STEP',
        payload: preventionMessage.id,
      });
      dispatch({
        type: 'START_MESSAGE_PREVENTION',
      });
    } else {
      // Launch the next situation
      dispatch({
        type: 'SET_ENIGMA_STEP',
        payload: step1,
      });
      dispatch({
        type: 'SET_STIMULUS_INDEX',
        payload: {
          room: 'ComplexSituation',
          stimulusIndex: stimulusIndex + 1,
        },
      });
      dispatch({
        type: 'SET_STIMULI_ANIMATION',
        payload: {
          room: 'ComplexSituation',
          stimulus: 'stimulus1',
          playState: 'running',
        },
      });
      dispatch({
        type: 'SET_CLICKED_REACTION',
        payload: {},
      });
    }
  };

  const handleButtonClick = () => {
    if (enigmaStep === step1) {
      // Launch the 2nd part of the enigma
      dispatch({
        type: 'SET_STIMULI_ANIMATION',
        payload: {
          playState: 'running',
          room: 'ComplexSituation',
          stimulus: 'stimulus2',
        },
      });
      dispatch({
        type: 'SET_ENIGMA_STEP',
        payload: step2,
      });
      dispatch({
        type: 'SET_CLICKED_PLUTCHIK_EMOTION',
        payload: { room: 'ComplexSituation', clickedEmotion: {} },
      });
    } else if (reactionAnswerState !== 'unanswered') {
      nextSituation();
    } else if (messageStatus === 'reaction-intro') {
      dispatch({
        type: 'SET_MESSAGE_STATUS',
        payload: 'reaction-intro-2nd-part',
      });
    } else {
      dispatch({
        type: 'SET_MESSAGE_STATUS',
        payload: 'reaction-intro-3rd-part',
      });
    }
  };

  const getButtonStatus = () => {
    switch (messageStatus) {
      case 'right-emotion':
      case 'right-reaction':
      case 'wrong-reaction':
      case 'reaction-intro':
      case 'reaction-intro-2nd-part':
      case 'no-energy':
      case 'no-more-stimulus':
        return true;

      default:
        return false;
    }
  };

  return (
    <>
      <NarratorTextHeader
        message={messages[messageStatus]}
        isButton={getButtonStatus()}
        handleClick={handleButtonClick}
        energyBar
        textStyle={narratorTextHeaderStyle}
        narratorTextRef={narratorTextRef}
      />
      {!isBackgroundMoving && (
        <>
          <StimulusStyle
            alt={t('generatedStimulus')}
            src={stimulusUrl}
            screenWidth={screenWidth}
            initialPositionX={initialPositionX}
            initialPositionY={initialPositionY}
            coordinates={stimulusCoordinates}
            duration={stimulusDuration}
            timingFunction={timing}
            iterationCount={stimuli.length}
            fillMode={fillMode}
            playState={stimulusPlayState}
            onAnimationIteration={() => {
              dispatch({
                type: 'SET_STIMULI_ANIMATION',
                payload: {
                  room: 'ComplexSituation',
                  stimulus: 'stimulus1',
                  playState: 'paused',
                },
              });
            }}
            onAnimationEnd={() => {
              dispatch({
                type: 'SET_STIMULI_ANIMATION',
                payload: {
                  room: 'ComplexSituation',
                  stimulus: 'stimulus1',
                  playState: 'paused',
                },
              });
            }}
          />
          <StimulusStyle
            alt={t('generatedStimulus')}
            src={stimulusUrl}
            screenWidth={screenWidth}
            initialPositionX={initialStimulus2PositionX}
            initialPositionY={initialPositionY}
            coordinates={stimulus2Coordinates}
            duration={stimulusDuration}
            timingFunction={timing}
            iterationCount={stimuli.length}
            fillMode={fillMode}
            playState={stimulus2PlayState}
            onAnimationIteration={() => {
              dispatch({
                type: 'SET_STIMULI_ANIMATION',
                payload: {
                  playState: 'paused',
                  room: 'ComplexSituation',
                  stimulus: 'stimulus2',
                },
              });
            }}
            onAnimationEnd={() => {
              dispatch({
                type: 'SET_STIMULI_ANIMATION',
                payload: {
                  playState: 'paused',
                  room: 'ComplexSituation',
                  stimulus: 'stimulus2',
                },
              });
            }}
          />

          <ConveyorBeltStyle
            src={conveyorBeltUrl}
            alt={t('conveyorBelt')}
            playState={stimulusPlayState}
            initialPositionX={initialBeltPositionX}
            initialPositionY={initialBeltPositionY}
            screenWidth={screenWidth}
            imgWidth={imgWidth}
          />
          <ConveyorBeltStyle
            src={conveyorBeltUrl}
            alt={t('conveyorBelt')}
            playState={stimulus2PlayState}
            initialPositionX={initialRightBeltPositionX}
            initialPositionY={initialBeltPositionY}
            screenWidth={screenWidth}
            imgWidth={imgWidth}
          />

          {/* Display panels when stimulus finishes running */}
          {stimulusFinishedMoving && (
            <div className={styles['enigma-container']}>
              <FooterPanel
                room="ComplexSituation"
                content={shuffledStimuli[stimulusIndex]}
                style={footerStyle}
              />
              <PlutchikSidePanel room="ComplexSituation" />
            </div>
          )}
          {messageStatus === 'reaction-intro-3rd-part' && <BehaviourPanel />}
        </>
      )}
    </>
  );
};

export default ComplexSituation;
