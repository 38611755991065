import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import History from './History';
import * as images from '../../../../../img/obesity/index';
import '../../../../../assets/css/components/GameBoard/Obesity/Enigma/BoardSun/BoardSun.css';

import Audio from '../../../ReusableComponents/Actions/Audio';
import click from '../../../../../sound/iphone-click.mp3';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function BoardSun({ dispatch, allObjects, idSessionHasRoom, soundtrack }) {
  const idPanel = 203;
  const [channel, setChannel] = useState(null);
  const [boardOpen, setBoardOpen] = useState(false);
  const [currentChannel, setCurrentChannel] = useState(0);
  const firstChannel = 0;
  const thirdChannel = 2;
  const previousChannel = currentChannel - 1;
  const nextChannel = currentChannel + 1;
  const [gifOpen, setGifOpen] = useState(false);
  const [soundClick, setSoundClick] = useState(false);

  const channelsArray = ['saloon', 'kitchen', 'history'];
  const boardObject = allObjects.find(
    (objectContent) => objectContent.id === idPanel
  );
  const boardIndex = allObjects.findIndex(
    (objectContent) => objectContent.id === idPanel
  );
  const screenVersions = allObjects.filter((item) => item.id === idPanel);
  const [screen, setScreen] = useState(
    screenVersions[0].images.filter((item) => item.type === 'closed')[0].image
  );

  useEffect(() => {
    if (boardOpen) {
      if (channel !== null) {
        setScreen(
          screenVersions[0].images.filter((item) => item.type === channel)[0].image
        );
      } else {
        setScreen(
          screenVersions[0].images.filter((item) => item.type === 'open')[0].image
        );
      }
    } else {
      setScreen(
        screenVersions[0].images.filter((item) => item.type === 'closed')[0].image
      );
    }
    return () => {
      setScreen(
        screenVersions[0].images.filter((item) => item.type === 'closed')[0].image
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, boardOpen]);

  const usedObject = (collectObject, index) => {
    axios
      .put(`${BASE_URL}/api/sessionsHasRoomHasObject/${idSessionHasRoom}`, {
        object_id: collectObject.id,
        status: 'open',
      })
      .then(() => {
        dispatch({
          type: 'CLICKED_OBJECT',
          payload: {
            index,
            status: 'open',
            type: 'used',
          },
        });
      });
  };

  const checkChannel = () => {
    setChannel(channelsArray[currentChannel]);
  };

  const handleCurrentChannel = (position) => {
    const numberTotalOfChannel = channelsArray.length - 1;
    if (position === 'left') {
      setCurrentChannel(previousChannel);
    }
    if (position === 'right') {
      setCurrentChannel(nextChannel);
    }
    if (position === 'up') {
      setCurrentChannel(firstChannel);
    }
    if (position === 'bottom') {
      setCurrentChannel(thirdChannel);
    }
    if (currentChannel === 0 && position === 'left') {
      setCurrentChannel(numberTotalOfChannel);
    }
    if (currentChannel === numberTotalOfChannel && position === 'right') {
      setCurrentChannel(firstChannel);
    }
  };

  const handleStartBoard = () => {
    return (
      <div className="boardsun-enigma-left-description">
        <img
          src={screen}
          alt="Panneau allumée"
          className="boardsun-enigma-container-tvbackground"
        />
        {channel === 'history' && <History />}
        {(channel === 'saloon' || channel === 'kitchen') && (
          <p className="boardsun-enigma-unavailable">
            Cette fonctionnalité n&apos;est pas disponible pour le moment.
          </p>
        )}
        {boardOpen && !gifOpen && channel === null && (
          <div className="boardsun-enigma-animation">
            <div className="boardsun-enigma-left-left">
              <p>Bienvenue</p>
            </div>
            <p className="boardsun-enigma-left-right-top-text">
              Gestion des lumières
            </p>
            <div className="boardsun-enigma-left-right">
              <div className="boardsun-enigma-left-right-top">
                <button
                  className={`boardsun-enigma-left-right-app${
                    channelsArray[currentChannel] === 'saloon' ? '--active' : ''
                  }`}
                  onClick={() => {
                    setChannel('saloon');
                    setSoundClick(true);
                    setTimeout(() => setSoundClick(false));
                  }}
                  type="button"
                >
                  <img src={images.sunBoard} alt="Un peu de soleil" />
                  <p>Salon</p>
                </button>
                <button
                  className={`boardsun-enigma-left-right-app${
                    channelsArray[currentChannel] === 'kitchen' ? '--active' : ''
                  }`}
                  onClick={() => {
                    setChannel('kitchen');
                    setSoundClick(true);
                    setTimeout(() => setSoundClick(false));
                  }}
                  type="button"
                >
                  <img src={images.sunBoard} alt="Un peu de soleil" />
                  <p>Cuisine</p>
                </button>
              </div>
              <p className="boardsun-enigma-left-right-bottom-text">Paramètres</p>
              <div className="boardsun-enigma-left-right-bottom">
                <button
                  className={`boardsun-enigma-left-right-app${
                    channelsArray[currentChannel] === 'history' ? '--active' : ''
                  }`}
                  onClick={() => {
                    setChannel('history');
                    setSoundClick(true);
                    setTimeout(() => setSoundClick(false));
                  }}
                  type="button"
                >
                  <img src={images.timeBoard} alt="Historique" />
                  <p>Historique</p>
                </button>
              </div>
            </div>
          </div>
        )}
        {gifOpen && (
          <img
            alt="open-tv"
            src={images.switchAnimation}
            className="boardsun-enigma-animation"
          />
        )}
      </div>
    );
  };

  /**
   * Handle the boardsun turn on
   */
  const turnOnBoard = () => {
    setBoardOpen(true);
    setGifOpen(true);
    setTimeout(() => {
      setGifOpen(false);
    }, 2000);
    usedObject(boardObject, boardIndex);
    setChannel(null);
    setCurrentChannel(firstChannel);
  };

  /**
   * Handle the boardsun turn off
   */
  const turnOffBoard = () => {
    setBoardOpen(false);
    setChannel(null);
  };

  return (
    <div className="boardsun">
      <Audio condition={soundClick && soundtrack} sound={click} />
      <div className="boardsun-enigma">
        <div className="boardsun-enigma-left">{handleStartBoard()}</div>
        <div className="boardsun-enigma-right">
          <button
            className="boardsun-enigma-right-remote-shutdown"
            onClick={() => {
              setSoundClick(true);
              setTimeout(() => setSoundClick(false));
              if (!boardOpen) {
                turnOnBoard();
              } else {
                turnOffBoard();
              }
            }}
            type="button"
          >
            <img src={images.switchBoard} alt="Bouton allumage" />
          </button>
          <button
            className="boardsun-enigma-right-remote-arrow-up"
            onClick={() => {
              handleCurrentChannel('up');
              setSoundClick(true);
              setTimeout(() => setSoundClick(false));
            }}
            type="button"
          >
            <img src={images.upBoard} alt="flèche du haut" />
          </button>
          <button
            className="boardsun-enigma-right-remote-arrow-left"
            onClick={() => {
              handleCurrentChannel('left');
              setSoundClick(true);
              setTimeout(() => setSoundClick(false));
            }}
            type="button"
          >
            <img src={images.leftBoard} alt="flèche de gauche" />
          </button>
          <button
            className="boardsun-enigma-right-remote-center"
            onClick={() => {
              checkChannel();
              setSoundClick(true);
              setTimeout(() => setSoundClick(false));
            }}
            type="button"
          >
            <img src={images.okBoard} alt="Bouton ok" />
          </button>
          <button
            className="boardsun-enigma-right-remote-arrow-right"
            onClick={() => {
              handleCurrentChannel('right');
              setSoundClick(true);
              setTimeout(() => setSoundClick(false));
            }}
            type="button"
          >
            <img src={images.rightBoard} alt="flèche de droite" />
          </button>
          <button
            className="boardsun-enigma-right-remote-arrow-down"
            onClick={() => {
              handleCurrentChannel('bottom');
              setSoundClick(true);
              setTimeout(() => setSoundClick(false));
            }}
            type="button"
          >
            <img src={images.downBoard} alt="flèche du bas" />
          </button>

          <button
            className="boardsun-enigma-right-remote-go-back"
            onClick={() => {
              setChannel(null);
              setSoundClick(true);
              setTimeout(() => setSoundClick(false));
            }}
            type="button"
          >
            <img src={images.returnBoard} alt="Retour" />
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  contents: state.Modal.modalEnigma.description.content,
  allObjects: state.Objects.AllObjects,
  idSessionHasRoom: state.GameUsers.idSessionHasRoom,
  soundtrack: state.GameUsers.soundtrack,
});

BoardSun.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  idSessionHasRoom: PropTypes.number.isRequired,
  soundtrack: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(BoardSun);
